import styled from "styled-components";

export const CheckboxBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4rem;
  margin-right: 1rem;

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const RadioBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.7rem;
  border-radius: 0.4rem;
  margin-right: 1rem;

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const DuplicateOrRemoveQuestionBlockContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  @media (max-width: 1100px) {
    height: 90px;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 0px;
  }
`;
