import React, { Suspense, lazy } from "react";
import { createBrowserHistory } from "history";
import { Router as ReactRouter, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";

import { BreadcrumbsProvider } from "./BreadcrumbsProvider";
import { Route } from "../components/ui/Route";
import { SidebarMenu } from "../components/SidebarMenu";
import { SuperAdminProtector } from "./../components/SuperAdminProtector";
import { DefaultLoader } from "../components/ui";
import { BurgetMenu } from "../components/BurgetMenu";
import { useViewport } from "../hooks";
import { deviceSize } from "../consts";

import {
  Members,
  MemberProfile,
  Profile,
  RecommendTool,
  Handover,
  Editor,
  ThankYou,
  LoggedFAQ,
  UnloggedFAQ,
  Landing
} from "../pages";

const LoginPage = lazy(() => import("../pages/Login"));
const SignUpPage = lazy(() => import("../pages/SignUp"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacePolicy"));
const TermsService = lazy(() => import("../pages/TermsService"));
const UsefulDocumentsPage = lazy(() => import("../pages/UsefulDocuments"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const RestorePassword = lazy(() => import("../pages/RestorePassword"));
const Dictionary = lazy(() => import("../pages/Dictionary"));
const Story = lazy(() => import("../pages/Story"));
const Handovers = lazy(() => import("../pages/Handovers"));
const Blog = lazy(() => import("../pages/Blog"));
const BlogArticle = lazy(() => import("../pages/BlogArticle"));

export const MainLayout = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  max-width: 100vw;
`;

export const MainContainer = styled.main`
  width: 100%;
  max-width: 100%;
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const history = createBrowserHistory();

export enum Routes {
  Root = "/",
  LoggedFAQ = "/loggedFAQ",
  UnloggedFAQ = "/FAQ",
  Dictionary = "/ordbok",
  Login = "/logga-in",
  SignUp = "/skapa-konto",
  MemberProfile = "/members/:id",
  Profile = "/profil",
  Handovers = "/overlamning",
  Story = "/skickade",
  Handover = "/overlamning/overlamning",
  RecommendTool = "/overlamning/rekommendera-detta-verktyg",
  Members = "/användare",
  Editor = "/redaktör",
  ThankYou = "/tack",
  PrivacyPolicy = "/privat-policy",
  ForgotPassword = "/glömt-lösenord",
  TermsAndConditions = "/villkor",
  RestorePassword = "/restore-password/:user",
  NotFoundPage = "*",
  Landing = "/landing",
  UsefulDocuments = "/anvandbara-dokument",
  Blog = "/blogg"
}

export function Router() {
  const width = useViewport();

  return (
    <ReactRouter history={history}>
      <Suspense fallback={<DefaultLoader />}>
        <Switch>
          <Route exact path={Routes.Root} component={Landing} />
          <Route exact path={Routes.SignUp} component={SignUpPage} />
          <Route exact path={Routes.Login} component={LoginPage} />
          <Route exact path={Routes.UnloggedFAQ} component={UnloggedFAQ} />
          <Route exact path={Routes.PrivacyPolicy} component={PrivacyPolicy} />

          <Route
            exact
            path={Routes.ForgotPassword}
            component={ForgotPassword}
          />
          <Route
            exact
            path={Routes.RestorePassword}
            component={RestorePassword}
          />
          <Route
            exact
            path={Routes.TermsAndConditions}
            component={TermsService}
          />
          <Route
            exact
            path={Routes.UsefulDocuments}
            component={UsefulDocumentsPage}
          />
          <Route exact path={Routes.Dictionary} component={Dictionary} />
          <Route exact path={Routes.Blog} component={Blog} />
          <Route path={Routes.Blog + `/:url`} component={BlogArticle} />

          <MainLayout>
            {width > parseInt(deviceSize.mobile.max) ? (
              <SidebarMenu />
            ) : (
              <BurgetMenu />
            )}
            <BreadcrumbsProvider>
              <MainContainer>
                <Route
                  exact
                  guarded
                  path={Routes.Handovers}
                  component={Handovers}
                />
                <Route
                  exact
                  guarded
                  path={Routes.LoggedFAQ}
                  component={LoggedFAQ}
                />

                <Route
                  exact
                  guarded
                  path={Routes.RecommendTool}
                  component={RecommendTool}
                />
                <Route
                  exact
                  guarded
                  path={Routes.Handover}
                  component={Handover}
                />
                <Route exact guarded path={Routes.Story} component={Story} />
                <Route
                  exact
                  guarded
                  path={Routes.ThankYou}
                  component={ThankYou}
                />
                <Route
                  exact
                  guarded
                  path={Routes.Members}
                  component={() => (
                    <SuperAdminProtector redirectTo={Routes.Handovers}>
                      <Members />
                    </SuperAdminProtector>
                  )}
                />
                <Route
                  exact
                  guarded
                  path={Routes.Profile}
                  component={Profile}
                />
                <Route
                  exact
                  guarded
                  path={Routes.MemberProfile}
                  component={MemberProfile}
                />

                <Route
                  exact
                  guarded
                  path={Routes.Editor}
                  component={() => (
                    <SuperAdminProtector redirectTo={Routes.Handovers}>
                      <Editor />
                    </SuperAdminProtector>
                  )}
                />
                <Route
                  exact
                  guarded
                  path={Routes.Root}
                  component={() => <Redirect to={Routes.Handovers} />}
                />
              </MainContainer>
            </BreadcrumbsProvider>
          </MainLayout>
        </Switch>
      </Suspense>
    </ReactRouter>
  );
}
