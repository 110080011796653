import React from "react";

import { Apollo } from "./Apollo";
import { ErrorBoundary } from "./ErrorBoundary";
import { Language } from "./Language";
import { Router } from "./Router";

export function App() {
  return (
    <ErrorBoundary>
      <Apollo>
        <Language>
          <Router />
        </Language>
      </Apollo>
    </ErrorBoundary>
  );
}
