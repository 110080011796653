import React from "react";
import { VideoPopUpWrapp, VideoPopupContent, IntroVideo } from "./Modals.style";
import { Videos } from "../../../environment";

export const ModalContent18 = () => {
  return (
    <>
      <VideoPopUpWrapp>
        <VideoPopupContent>
          <IntroVideo controls>
            <source src={Videos.howItWorks} type="video/mp4" />
          </IntroVideo>
        </VideoPopupContent>
      </VideoPopUpWrapp>
    </>
  );
};
