import { deviceRange } from "./../../consts";
import styled from "styled-components";
import { Colors } from "../../environment/theme/Colors";
import { Fonts } from "../../environment/theme/Fonts";

export const HeaderTextWrapper = styled.div``;

export const TextWrapper = styled.div`
  margin-top: 10px;
  width: 86%;
  justify-content: center;
  display: flex;
  align-items: center;

  @media ${deviceRange.mobile} {
    width: 100%;
  }
`;

export const PageMainContent = styled.main`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

export const HeaderText = styled.p`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  color: #6bc9db;
  width: 100%;
`;

export const HeaderTextMain = styled.p`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  color: #6bc9db;
  width: 100%;

  @media ${deviceRange.mobile} {
    font-size: 28px;
  }
`;

export const BlockText = styled.p`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #425262;
`;

export const UsuallyText = styled.p`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #425262;

  @media ${deviceRange.mobile} {
    font-size: 16px;
  }
`;

export const ForgotPasswordResult = styled.div`
  display: flex;
  flex-direction: column;
  width: 42rem;
`;

export const ForgotPasswordLeftBlockTitle = styled.h1`
  font-size: 3.2rem;
  font-weight: 800;
  font-family: ${Fonts.landingConcertOneRegular};
  color: #6bc9db;
  margin-bottom: 0;
`;

export const ForgotPasswordLeftBlockText = styled.p`
  font-size: 1.8rem;
  color: ${Colors.gray[100]};
  margin-bottom: 2.1em;
`;
