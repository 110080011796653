import styled from "styled-components";
import { deviceRange } from "../../consts";
import { Fonts } from "../../environment/theme/Fonts";

interface ContentCardProps {
  contentNumber: number;
}

export const ContentCard = styled.div<ContentCardProps>`
  width: ${props =>
    props.contentNumber === 3
      ? 377 + "px"
      : props.contentNumber === 4
      ? 377 + "px"
      : props.contentNumber === 5
      ? 275 + "px"
      : 345 + "px"};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 25px rgba(200, 233, 239, 0.65);
  border-radius: 15px;
  margin: 15px;

  @media ${deviceRange.laptop} {
    width: 255px;
  }

  @media ${deviceRange.mobile} {
    width: 100%;
    margin: 15px 0;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  align-self: center;
`;

export const ImageCard = styled.img`
  width: 260px;
  height: 250px;

  @media ${deviceRange.laptop} {
    width: 190px;
    height: 180px;
  }
`;

// interface TextWrapperProps {
//   customHeight?: number;
// }

export const TextWrapper = styled.div<ContentCardProps>`
  width: 100%;
  ${props => (props.contentNumber === 5 ? `height: 90px;` : `height: 88px;`)}
  margin-bottom: 25px;
  padding-left: 10px;
  padding-right: 10px;

  @media ${deviceRange.laptop} {
  }
  @media ${deviceRange.mobile} {
    height: 100%;
  }
`;

export const SecondTextWrapper = styled.div<ContentCardProps>`
  ${props => props.contentNumber === 5 && `display: none;`}
  width: 100%;
  height: 25px;
  margin-bottom: 25px;
  padding-left: 10px;
  padding-right: 10px;

  @media ${deviceRange.laptop} {
  }
  @media ${deviceRange.mobile} {
    height: 100%;
  }
`;

export const SecondTitleTextCard = styled.h4`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #008c82;

  @media ${deviceRange.laptop} {
    font-size: 18px;
    line-height: 24px;
  }

  @media ${deviceRange.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const SecondTextCard = styled.p`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #425262;

  @media ${deviceRange.laptop} {
    font-size: 14px;
    line-height: 18px;
  }
  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 25px;
  display: flex;
  align-self: center;
`;

export const ButtonCard = styled.button`
  padding: 10px;
  min-width: 120px;
  background: none;
  border-radius: 80px;
  cursor: pointer;
  border: 1px solid #ffa100;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: calc(5px + 0.5vw);
  line-height: 24px;
  color: #425262;
  &:hover {
    border: 1px solid rgba(252, 163, 17, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(255, 161, 0, 0.5);
  }
  &:active {
    background: #fca311;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.laptop} {
    font-size: 12px;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
  }
`;
