import styled from "styled-components";
import { Fonts } from "../../../environment/theme/Fonts";
import { Input as DefaultInput } from "../../ui/Input";

export const Form = styled.form`
  width: 100%;
  max-width: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
`;

export const Input = styled(DefaultInput)`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 30px;

  input {
    padding-left: 20px;
  }
`;

interface TargetBlockBtnRekommenderaProps {
  onClick?: any;
}
export const TargetBlockBtnRekommendera = styled.button<
  TargetBlockBtnRekommenderaProps
>`
  width: 42rem;
  padding: 1rem;
  background: #fca311;
  box-shadow: 3px 4px rgba(255, 161, 0, 62%);
  border-radius: 80px;
  color: white;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px rgba(255, 161, 0, 62%);
  }
  &:active {
    box-shadow: -3px -5px rgba(255, 161, 0, 62%);
  }
`;
