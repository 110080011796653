import React, { useState } from "react";
import {
  ButtonPDF,
  GoogleButton,
  ButtonStep,
  Instruction
} from "./BaseButtonModal";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  ContentBlockWrapper
} from "./Modals.style";

export const ModalContent4 = () => {
  const [step, setStep] = useState<number>(0);
  const changeStep = (id: number) => setStep(id);

  return (
    <>
      {step === 0 && (
        <ContentBlockWrapper>
          <ContentBlock>
            <LeftBlockContent>
              <TextTitleWrapper>
                <TextTitle>För vem:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>Processledare</SecondText>
              </TextWrapper>
              <TextTitleWrapper>
                <TextTitle>Hur dokumentet kan användas:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>
                  Kan användas som en gruppövning. Låt de som varit
                  förtroendevalda diskutera och lista vilka dokument varje ny
                  förtroendevald bör ta del av.
                </SecondText>
              </TextWrapper>
            </LeftBlockContent>
            <RightBlockContent>
              <TextTitleWrapper>
                <TextTitle>Beskrivning:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>
                  Öppna dokumentet i Google Docs och redigera utifrån er
                  organisations behov. Skapa därefter en digital mapp eller
                  fysisk pärm med dokumenten för att dela med varje ny
                  förtroendevald. Att ha dokumenten samlade underlättare varje
                  gång de ska delas med en ny person.
                </SecondText>
              </TextWrapper>
            </RightBlockContent>
          </ContentBlock>
        </ContentBlockWrapper>
      )}
      {step === 1 && <Instruction />}
      <FooterModal notAliginSelf={step ? 0 : 1}>
        {step === 0 && (
          <>
            <LeftBlockContent>
              <ButtonStep changeStep={changeStep} />
            </LeftBlockContent>
            <RightBlockContent>
              <ButtonPDF
                downLink={
                  "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Dokument som är nyttiga att se på för dig som är ny i organisationen_fyllbar PDF.pdf"
                }
              />
            </RightBlockContent>
          </>
        )}
        {step === 1 && (
          <GoogleButton
            downLink={
              "https://docs.google.com/document/d/1bMUipeKqLHRXlmo7n9dH_X9E6taogH9kK6JD5AGEYus/edit"
            }
          />
        )}
      </FooterModal>
    </>
  );
};
