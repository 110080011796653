//@ts-nocheck
import React from "react";
import Faq from "react-faq-component";

import { PageWrapper, PageMainContent } from "./FAQ.style";
import { AuthHeader } from "../../components/auth";
import { data } from "./FAQ.data";
import { Colors } from "../../environment";
import { ContactUsBlock } from "../../components/userProfile/UserProfile.style";
import { Images } from "../../environment/theme/";
import { BurgetMenu } from "../../components/BurgetMenu";
import { useViewport, useNavigation } from "../../hooks";
import { deviceSize } from "../../consts";
import { GlobalStyle } from "../../pages/Landing/Landing.style";

const styles = {
  bgColor: `white`,
  titleTextColor: `${Colors.black[100]}`,
  rowContentColor: `${Colors.gray[100]}`
};

export default function UnloggedFAQ() {
  const width = useViewport();
  const { routes, history } = useNavigation();

  const handleRouteAndScroll = () => {
    history.push(routes.root);
    setTimeout(() => {
      const targetBlock = document.getElementById("link2");
      targetBlock?.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }, 500);
  };

  return (
    <PageWrapper>
      {width > parseInt(deviceSize.mobile.max) ? (
        <AuthHeader activelink={"faq"} />
      ) : (
        <BurgetMenu activelink={"faq"} unLogged={true} />
      )}

      <PageMainContent>
        <GlobalStyle />
        <Faq data={data} styles={styles} />
      </PageMainContent>
      <ContactUsBlock onClick={() => handleRouteAndScroll("link2")}>
        <img
          src={Images.landingCommentUs}
          style={{ cursor: "pointer" }}
          alt=""
        />
        Kontakta oss
      </ContactUsBlock>
    </PageWrapper>
  );
}
