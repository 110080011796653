import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  HandoverData,
  SendHandoverDocumentData,
  SendPdfPreviewData
} from "@spridakunskap/api";

import gql from "graphql-tag";

import { HandoverFragment } from "./../fragments";

const HANDOVER = gql`
  query Handover {
    handover {
      ...HandoverFragment
    }
  }
  ${HandoverFragment}
`;

const SEND_HANDOVER_DOCUMENT = gql`
  query SendHandoverDocument($data: SendHandoverDocumentInput!) {
    sendHandoverDocument(data: $data)
  }
`;
const SEND_PDF_PREVIEW = gql`
  query SendPdfPreview($data: SendPdfPreviewInput!) {
    sendPdfPreview(data: $data)
  }
`;
const GET_IMAGE = gql`
  query getImage($data: UploadedFileName!) {
    getImage(data: $data)
  }
`;

export function useGetImageQuery() {
  return useLazyQuery<any>(GET_IMAGE);
}

export function useSendHandoverDocumentQuery() {
  return useLazyQuery<SendHandoverDocumentData>(SEND_HANDOVER_DOCUMENT);
}

export function useSendPdfPreview() {
  return useLazyQuery<SendPdfPreviewData>(SEND_PDF_PREVIEW, {
    fetchPolicy: "network-only"
  });
}

export function useHandoverQuery() {
  return useQuery<HandoverData>(HANDOVER);
}
