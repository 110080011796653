import styled from "styled-components";

export const PageContainer = styled.div`
  width: 103%;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  background: white;
  margin-left: -30px;
  padding-left: 100px;
  padding-top: 100px;
`;

export const MembersList = styled.div`
  overflow-y: scroll;
`;

export const PageTopBarItem = styled.div`
  display: flex;
  align-items: center;
`;

export const PageTopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 2.4rem;
`;
