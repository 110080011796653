import styled from "styled-components";
import { deviceRange } from "../../../consts";

import { Fonts } from "../../../environment";
import { DefaultButton } from "../../ui/Button/DefaultButton";

export const PageNavigation = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 10px 10px;
  height: 14%;
  max-height: 83px;
  margin-left: 80px;

  @media ${deviceRange.tablet} {
    margin-left: 10px;
  }

  @media ${deviceRange.laptop} {
    margin-left: 40px;
  }

  @media ${deviceRange.laptopM} {
    margin-left: 40px;
  }

  @media ${deviceRange.mobile} {
    margin-top: 66px;
    margin-left: 0;
  }
`;

interface PageNavigationItemProps {
  active: boolean;
}

export const PageNavigationItem = styled.button<PageNavigationItemProps>`
  cursor: pointer;
  list-style-position: inside;
  font-family: ${Fonts.landingConcertOneRegular};
  font-size: 18px;
  color: ${props => (props.active ? "#6bc9db" : "rgba(66,82,98,0.5)")};
  z-index: 90;
  padding: 21px 0px 12px 0px;
  box-shadow: 2px 10px 18px rgb(107 201 219 / 30%);
  border-radius: 0px 0px 25px 25px;
  outline: 0;
  border: none;
  background: white;
  font-style: normal;
  font-weight: normal;
  width: 100%;
  margin: auto;
  position: relative;

  @media ${deviceRange.mobile} {
    font-size: 14px;
    width: 90%;
    min-width: 100px;
    display: ${props => (props.active ? "block" : "none")};
  }

  @media ${deviceRange.tablet} {
    white-space: nowrap;
  }
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 150px;
`;
export const PageNavigationItemIndex = styled.div<PageNavigationItemProps>`
  margin: auto;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  font-family: ${Fonts.landingConcertOneRegular};
  font-size: 18px;
  color: ${props => (props.active ? "#6bc9db" : "rgba(66,82,98,0.5)")};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 10px 18px rgb(107 201 219 / 30%);
  background: white;
  z-index: 100;
  margin-top: -10px;
  font-style: normal;
  font-weight: normal;
  position: relative;

  @media ${deviceRange.mobile} {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }
`;

export const ModalContainer = styled.div`
  padding: 2.4rem;
`;

export const AddChapterButton = styled(DefaultButton)`
  margin-top: 10px;
  position: sticky;
  bottom: 0;
  background: #f2f3f5;

  button {
    background: #4f45f5;
    color: #fff;

    :hover {
      background: #342acd;
      color: #fff;
    }

    :disabled :hover {
      background: #4f45f5;
      cursor: not-allowed;
    }
  }

  @media ${deviceRange.mobile} {
    display: none;
  }
`;
