import React, { useEffect, useState, useContext } from "react";
import { FormikProps } from "formik";
import { useFormik } from "formik";
import * as yup from "yup";
import { SuperAdminProtector } from "./../../SuperAdminProtector";
import { GhostButton } from "../../ui";
import { IconType } from "../../../consts";
import { Images } from "../../../environment";
import { useAddOrRemoveOrgainsotionLogo, useNavigation } from "../../../hooks";

import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../UserPofile";
import { getTokenData } from "../../../helpers";
import { UserInfoButton } from "../UserGeneralInfo/UserGeneralInfo.style";
import { CropModalOrganisation } from "./CropModalOrganisation";
import { LinkWrapper } from "./UserOrganisationLogo.style";
import {
  PageContainer,
  ImageContent,
  LogoImage,
  BlockButtonsImage,
  Form,
  BlockInputs,
  AddColorInput,
  BlockButtonsWrapper,
  TopBlock,
  Label,
  AddColorDescription
} from "./UserOrganisationLogo.style";

export interface UserAccountFormValues {
  firstName: string;
  lastName: string;
  email: string;
  organisationColor: string;
}
interface UserOrganisationLogoProps {
  formik: FormikProps<UserAccountFormValues>;
}

export function UserOrganisationLogo({
  formik: { handleChange, handleBlur, handleSubmit, values, touched, errors }
}: UserOrganisationLogoProps) {
  const { user, setUser } = useContext(UserContext);
  const [
    showOrganisationLogoCropModal,
    setShowOrganisationLogoCropModal
  ] = useState(false);
  const [
    removeOrganisationLogo,
    { data: removeOrganisationLogoData }
  ] = useAddOrRemoveOrgainsotionLogo();

  function handleRemoveOrganisationLogo() {
    if (user) {
      removeOrganisationLogo({
        variables: {
          data: {
            userAccountWhereUniqueInput: { id: user.id },
            isRemove: true
          }
        }
      });
    }
  }
  const { routes, navigate, history } = useNavigation();
  useEffect(() => {
    if (removeOrganisationLogoData) {
      setUser(removeOrganisationLogoData.addOrRemoveOrganisationLogo);
    }
  }, [removeOrganisationLogoData]);

  function handleOpenModal() {
    setShowOrganisationLogoCropModal(true);
  }

  function handleCloseModal() {
    setShowOrganisationLogoCropModal(false);
  }

  return (
    <PageContainer>
      <CropModalOrganisation
        showLogoCropModal={showOrganisationLogoCropModal}
        handleCloseModal={handleCloseModal}
      />

      <TopBlock>
        <ImageContent>
          <LogoImage
            src={
              user && user.organisationLogo
                ? user.organisationLogo.url
                : Images.logoImagePlaceholder
            }
          />
        </ImageContent>

        <BlockButtonsImage>
          {/* Add Avatar */}
          <GhostButton
            leftIcon={IconType.EpArrowUp}
            title="Lägg in logga för aktuell organisation"
            onClick={handleOpenModal}
          />
          <GhostButton
            leftIcon={IconType.EpTrash}
            title="Ta bort logga"
            onClick={handleRemoveOrganisationLogo}
          />
        </BlockButtonsImage>
      </TopBlock>
      <Label>Färgen måste skrivas i HEX-format, exempel: #000000.</Label>
      <Form onSubmit={handleSubmit}>
        <BlockInputs>
          <AddColorInput
            padding={"20px 18px"}
            placeholder="#"
            name="organisationColor"
            onChange={handleChange}
            value={values.organisationColor}
            error={
              touched.organisationColor ? errors.organisationColor : undefined
            }
            onBlur={handleBlur}
          />
        </BlockInputs>
        <BlockButtonsWrapper>
          <UserInfoButton
            onClick={handleSubmit}
            paddingTop={18}
            paddingBottom={18}
            shadowLeft={3}
            shadowTop={4}
            marginTop={1}
            width={320}
            type="submit"
          >
            Lägg till färg
          </UserInfoButton>
        </BlockButtonsWrapper>
      </Form>
      <SuperAdminProtector>
        <LinkWrapper>
          <Link to={routes.members}>
            <img
              src={Images.landingMenuMedlemmar}
              style={{
                marginLeft: "-1.7px",
                marginTop: "-1.7px",
                maxWidth: "32px",
                maxHeight: "32px",
                borderRadius: "50%",
                marginRight: "10px"
              }}
            />
            Medlemmar
          </Link>
        </LinkWrapper>
      </SuperAdminProtector>
      <AddColorDescription>
        *Välj någon av färgerna som finns i din organisations grafiska profil
        (förslagsvis "primärfärgen"). Färgen kommer att synas på framsidan i din
        överlämning ihop med loggan som du lagt in ovan
      </AddColorDescription>
    </PageContainer>
  );
}
