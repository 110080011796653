export const Images = {
  googleDocPreview: require("./images/GoogleDocPreview.png"),
  preview: require("./images/Handovers/Preview.png"),
  manSeatWithLaptop: require("./images/Landing/ManSeatWithLaptop.png"),
  manSeatWithPhone: require("./images/Landing/ManSeatWithPhone.png"),
  manOnBike: require("./images/Landing/ManOnBike.png"),
  manOnBooks: require("./images/Landing/ManOnBooks.png"),
  howItWorks: require("./images/Landing/HowItWorks.png"),
  YoutubeLogo: require("./images/Landing/YoutubeLogo.png"),
  brush: require("./images/Handovers/Brush.png"),
  floppy: require("./images/Handovers/Floppy.png"),
  backgroundBlur: require("./images/Handovers/Background.png"),
  avatar: require("./images/Landing/UserFoto.png"),
  assistant: require("./images/Landing/assistant.png"),
  assistantWithoutDot: require("./images/Landing/assistantWithoutDot.png"),
  authLogo: require("./images/Logo.svg"),
  test: require("./images/test.svg"),
  logoImagePlaceholder: require("./images/logo-image-placeholder.png"),
  signUpImg: require("./images/SignUpPage/signup_left_side_img.svg"),
  verifyCodeImg: require("./images/SignUpPage/signup_verifycode_left_img.svg"),
  loginImg: require("./images/LoginPage/loginpage_left_side_img.svg"),
  avatarBadgeSuccess: require("./images/BadgeSuccess.svg"),
  avatarBadgeDefault: require("./images/BadgeDefault.svg"),
  avatarBadgeIgnored: require("./images/BadgeIgnored.svg"),
  spridakuskapLogoWithName: require("./images/spridakunskap_logo_with_name.svg"),
  spridakuskapLogo: require("./images/spridakunskap_logo.svg"),
  intercomLogo: require("./images/intercom.svg"),
  spridakuskapLogoFooter: require("./images/spridakunskap_logo_footer.svg"),
  spridakunskapFirstHandover: require("./images/Handovers/handovers_right_img.svg"),
  spridakunskapPartnersAllmana: require("./images/Sidebar/Med_stod_fran_Allmanna_Arvsfonden_SV_pos.png"),
  spridakunskapPartnersViunga: require("./images/Sidebar/transparent_viunga_grey.png"),
  landingLogo: require("./images/Landing/SKlogo.svg"),
  landingBurgerMenu: require("./images/Landing/BurgerMenu.png"),
  landingArrow1: require("./images/Landing/Arrow1.svg"),
  landingArrow2: require("./images/Landing/Arrow2.png"),
  landingArrow3: require("./images/Landing/Arrow3.svg"),
  landingArrow3Mobile: require("./images/Landing/Arrow1_mobile.svg"),
  landingArrow4: require("./images/Landing/Arrow4.svg"),
  landingArrow4Mobile: require("./images/Landing/Arrow4_mobile.svg"),
  landingCardImage: require("./images/Landing/CardImage.png"),
  landingGirlAvatar: require("./images/Landing/GirlAvatar.png"),
  landingBoyAvatar: require("./images/Landing/BoyAvatar.png"),
  landingImageWeb: require("./images/Landing/ImageWeb.svg"),
  landingImageLike: require("./images/Landing/ImageLike.svg"),
  landingImageLike2: require("./images/Landing/ImageLike2.png"),
  landingMean: require("./images/Landing/Mean.png"),
  landingPen: require("./images/Landing/Pen.svg"),
  landingGroupFace: require("./images/Landing/GroupFace.png"),
  landingEllipseChem: require("./images/Landing/EllipseChem.svg"),
  landingChem: require("./images/Landing/Chem.png"),
  landingIconWoker: require("./images/Landing/IconWoker.png"),
  landingIconGroup: require("./images/Landing/IconGroup.png"),
  landingIconMark: require("./images/Landing/IconMark.png"),
  landingIconQuestion: require("./images/Landing/IconQuestion.png"),
  landingImageComments: require("./images/Landing/ImageComments.svg"),
  landingImageTarget: require("./images/Landing/ImageTarget.png"),
  landingImagePoint: require("./images/Landing/ImagePoint.svg"),
  landingImagePlus: require("./images/Landing/ImagePlus.png"),
  landingImageClose: require("./images/Landing/ImageClose.png"),
  landingMainPeopleImage: require("./images/Landing/MainPeopleImage.png"),
  landingPeopleSkate: require("./images/Landing/PeopleSkate.png"),
  landingPeopleQuestions: require("./images/Landing/PeopleQuestions.png"),
  landingPeopleComputer: require("./images/Landing/PeopleComputer.png"),
  landingIconGirl: require("./images/Landing/IconGirl.png"),
  landingIconMans: require("./images/Landing/IconMans.png"),
  landingIcon65: require("./images/Landing/Icon65.png"),
  landingImageCode: require("./images/Landing/ImageCode.png"),
  landingImageLightBulb: require("./images/Landing/ImageLightBulb.png"),
  landingImageWifi: require("./images/Landing/ImageWifi.png"),
  landingFinanceLogo: require("./images/Landing/FinanceLogo.png"),
  landingImageArrowUp: require("./images/Landing/ImageArrowUp.png"),
  landingImageArrowDown: require("./images/Landing/ImageArrowDown.png"),
  landingYellowPoint: require("./images/Landing/YellowPoint.svg"),
  landingFooterlogo: require("./images/Landing/Footerlogo.svg"),
  landingVector: require("./images/Landing/Vector.svg"),
  landingYoutube: require("./images/Landing/youtube.svg"),
  landingFacebook: require("./images/Landing/Facebook.svg"),
  landingInsta: require("./images/Landing/insta.svg"),
  landingPeopleCall: require("./images/Landing/PeopleCall.png"),
  landingManForTable: require("./images/Landing/picture2.png"),
  landingMenuHandover: require("./images/Landing/MenuHandover.png"),
  landingMenuProfile: require("./images/Landing/ProfilePicture.png"),
  landingMenuFaq: require("./images/Landing/MenuFaq.png"),
  landingMenuLogout: require("./images/Landing/MenuLogout.png"),
  landingMenuSponsors: require("./images/Landing/MenuSponsors.svg"),
  landingMenuPartner1: require("./images/Landing/Partner1.png"),
  landingMenuPartner2: require("./images/Landing/Partner2.png"),
  landingVerifyPic: require("./images/Landing/VerifyPic.svg"),
  landingSkapaKontaPic: require("./images/Landing/SkapaKontaPic.jpg"),
  landingMenuRedactor: require("./images/Landing/Redactor.png"),
  landingMenuMedlemmar: require("./images/Landing/Medlemmar.png"),
  landingMenuSkickade: require("./images/Landing/Skickade.png"),
  landingLoginPic: require("./images/Landing/LoginPic.jpg"),
  landingLoginPicture: require("./images/Landing/LoginPicture.png"),

  landingProgressBarArrow: require("./images/Landing/ProgressBarArrow.svg"),
  landingProgressBarArrowRight: require("./images/Landing/ProgressBarArrowRight.png"),
  landingArrowLeftBlue: require("./images/Landing/ArrowLeftBlue.png"),
  landingArrowRightPink: require("./images/Landing/ArrowRightPink.png"),
  landingLogoSidebar: require("./images/Landing/LogoSidebar.svg"),
  landingScrollArrow: require("./images/Landing/ScrollArrow.svg"),
  landingMaskGroup: require("./images/Landing/MaskGroup.svg"),
  landingEllipseMaskGroup: require("./images/Landing/EllipseMaskGroup.svg"),
  landingExportLogo: require("./images/Landing/ExportLogo.svg"),
  landingTrashIcon: require("./images/Landing/TrashIcon.svg"),
  landingPlusIcon: require("./images/Landing/PlusIcon.svg"),
  landingBackgroundBlur: require("./images/Landing/BackgroundBlur.png"),
  landingCheckIcon: require("./images/Landing/CheckIcon.svg"),
  landingDateIcon: require("./images/Landing/DateIcon.svg"),
  landingInformationIcon: require("./images/Landing/InformationIcon.svg"),
  landingCommentUs: require("./images/Landing/CommentUs.png"),
  landingCloseIcon: require("./images/Landing/CloseIcon.svg"),
  landingLogoName: require("./images/Landing/LogoName.svg"),
  landingCheckboxIcon: require("./images/Landing/CheckboxIcon.svg"),
  landingMottagarePeop: require("./images/Landing/MottagarePeop.svg"),
  landingThankYouMan: require("./images/Landing/ThankYouMan.png"),
  landingEllipseSidebar: require("./images/Landing/EllipseSidebar.svg"),
  imageFirstPage: require("./images/Landing/ImageFirstPage.png"),
  handoversIntroVideo: require("./videos/SPRIDAKUNSKAP.SE.v3.Intro.mp4"),
  transmitInfoVideo: require("./videos/SPRIDAKUNSKAP.TransmitInfo.mp4"),
  developKnowledgeTransfer: require("./videos/DevelopKnowledgeTransfer.mp4"),
  implicitAndExplicitKnowledge: require("./videos/ImplicitAndExplicitKnowledge.mp4"),
  whatIsKnowledge: require("./videos/WhatIsKnoweledge.mp4"),

  landingFileHands: require("./images/Landing/SecondPageFileHands.png"),
  landingHeadMen: require("./images/Landing/SecondPageMan3.png"),
  landingHeadWoman: require("./images/Landing/SecondPageWoman61.png"),
  landingThirdContentImg1: require("./images/Landing/ThridContentImge1.png"),
  landingThirdContentImg2: require("./images/Landing/ThridContentImge2.png"),
  landingThirdContentImg3: require("./images/Landing/ThridContentImge3.png"),
  landingThirdContentImg4: require("./images/Landing/ThridContentImge4.png"),
  landingThirdContentImg5: require("./images/Landing/ThridContentImge5.png"),
  landingThirdContentImg6: require("./images/Landing/ThridContentImge6.png"),
  landingThirdContentImg7: require("./images/Landing/ThridContentImge7.png"),
  landingThirdContentImg8: require("./images/Landing/ThridContentImge8.png"),
  landingThirdContentImg9: require("./images/Landing/ThridContentImge9.png"),
  landingFourthContentImg1: require("./images/Landing/FourthContentImge1.png"),
  landingFourthContentImg2: require("./images/Landing/FourthContentImge2.png"),
  landingFourthContentImg3: require("./images/Landing/FourthContentImge3.png"),
  landingFourthContentImg4: require("./images/Landing/FourthContentImge4.png"),
  landingModalContentCheckMark: require("./images/Landing/checkmark.png"),
  landingModalpdfIcon: require("./images/Landing/pdfIcon.svg"),
  landingModalImageStep1: require("./images/Landing/Step1.png"),
  landingModalImageStep2: require("./images/Landing/Step2.png"),

  landingBlogImg1: require("./images/Landing/Blog/Picture1.png"),
  landingBlogImg2: require("./images/Landing/Blog/Picture2.png"),
  landingBlogImg3: require("./images/Landing/Blog/Picture3.png"),
  landingBlogImg4: require("./images/Landing/Blog/Picture4.png"),
  landingBlogImg5: require("./images/Landing/Blog/Picture5.png"),
  landingBlogImg6: require("./images/Landing/Blog/Picture6.png"),
  landingBlogImg7: require("./images/Landing/Blog/Picture7.png"),
  landingBlogImg8: require("./images/Landing/Blog/Picture8.png"),

  landingThumbnail1: require("./images/Landing/Blog/Thumbnail1.jpg"),
  landingThumbnail2: require("./images/Landing/Blog/Thumbnail2.jpg"),
  landingThumbnail3: require("./images/Landing/Blog/Thumbnail3.jpg"),
  landingThumbnail4: require("./images/Landing/Blog/Thumbnail4.jpg"),
  landingThumbnail5: require("./images/Landing/Blog/Thumbnail5.jpg"),
  landingThumbnail6: require("./images/Landing/Blog/Thumbnail6.jpg"),
  landingThumbnail7: require("./images/Landing/Blog/Thumbnail7.jpg"),
  landingThumbnail8: require("./images/Landing/Blog/Thumbnail8.jpg")
};
