import { deviceRange } from "./../../../consts";
import styled from "styled-components";

import { Colors, Fonts } from "../../../environment";

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const Label = styled.label`
  font-family: ${Fonts.heading};
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: ${Colors.typoPlaceholder};
  position: absolute;
  top: 1.2rem;
  left: 1.2rem;
  pointer-events: none;
`;

interface TextareaProps {
  invalid: boolean;
  focus: boolean;
  withLabel: boolean;
}

export const BaseTextarea = styled.textarea<TextareaProps>`
  resize: none;
  width: 100%;
  min-width: 28rem;
  color: black;
  background-color: white;
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-align: left;
  padding: 1.4rem 1.2rem;
  border: 1px solid ${Colors.inputBorderColor};
  border-radius: 8px;
  outline: 0;
  box-shadow: ${props =>
    props.focus ? `0px 0px 5px 2px ${Colors.inputFocusColor}` : "none"};

  @media ${deviceRange.mobile} {
    min-width: 1px;
    font-size: 12px;
  }
`;

export const ErrorLabel = styled.span`
  display: flex;
  max-width: fit-content;
  font-family: ${Fonts.heading};
  font-size: 1.4rem;
  color: ${Colors.red[100]};
  padding: 1rem 1.2rem 0;
`;
export const Information = styled.div`
  border: 1px solid #425262;
  border-radius: 8px;
  background: #6bc9db;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #425262;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 20px;
  margin-right: 15px;
  z-index: 100;
`;
