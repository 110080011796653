import React, { useState, useEffect } from "react";
import { Chapter, Handover as HandoverModel } from "../../../../../api";

import { ModalWindow } from "./ModalWindow";
import { SuperAdminProtector } from "./../../SuperAdminProtector";
import { ChapterTypes } from "../../../consts";
import {
  useAlerts,
  useAddChapterToHandoverMutation,
  useTranslation
} from "./../../../hooks";

import {
  PageNavigation,
  PageNavigationItem,
  AddChapterButton,
  PageNavigationItemIndex,
  ButtonWrapper
} from "./Navigation.style";

interface NavigationProps {
  chapters: Chapter[];
  handoverId: string;
  activeChapter: Chapter | null;
  setActiveChapter: (chapter: Chapter) => void;
  updateHandover: (handover: HandoverModel) => void;
}

export function Navigation({
  handoverId,
  chapters,
  activeChapter,
  setActiveChapter,
  updateHandover
}: NavigationProps) {
  const translate = useTranslation();
  const { setNotification } = useAlerts();
  const [showModal, setShowModal] = useState(false);
  const [chapterType, setChapterType] = useState(ChapterTypes.Regular);

  const [isLoading, setIsloading] = useState<boolean>(false);
  const [
    addChapterToHandover,
    { data: updatedHandoverData }
  ] = useAddChapterToHandoverMutation();
  const onClickAddButton = (newChapterName: string) => {
    setIsloading(true);
    addChapterToHandover({
      variables: {
        data: {
          handoverWhereUniqueInput: { id: handoverId },
          handoverUpdateInput: {
            chapters: {
              create: { name: newChapterName, chapterType: chapterType }
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    if (updatedHandoverData) {
      setIsloading(false);
      setShowModal(false);
      updateHandover(updatedHandoverData.updateHandover);
      window.location.reload();
      setNotification({
        message: translate(({ messages }) => messages.createdChapter)
      });
    }
  }, [updatedHandoverData]);

  return (
    <PageNavigation>
      {chapters.map((chapter, index) => (
        <ButtonWrapper key={`bw${index}`}>
          <PageNavigationItem
            key={`${chapter.id}${index}`}
            active={activeChapter?.id === chapter.id}
            onClick={() => setActiveChapter(chapter)}
          >
            {chapter.name}
          </PageNavigationItem>
          <PageNavigationItemIndex
            key={`pnii${index}`}
            active={activeChapter?.id === chapter.id}
            onClick={() => setActiveChapter(chapter)}
          >
            {index + 1}
          </PageNavigationItemIndex>
        </ButtonWrapper>
      ))}

      <SuperAdminProtector>
        <>
          <AddChapterButton
            title={translate(
              ({ handoversPage }) => handoversPage.addNewChapter
            )}
            onClick={() => setShowModal(!showModal)}
          />

          {showModal && (
            <ModalWindow
              setChapterType={setChapterType}
              onClickAddButton={onClickAddButton}
              isLoading={isLoading}
              setShowModal={setShowModal}
              showModal={showModal}
            />
          )}
        </>
      </SuperAdminProtector>
    </PageNavigation>
  );
}
