import React from "react";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  ContentBlockWrapper
} from "./Modals.style";
import { ButtonPDF } from "./BaseButtonModal";

export const ModalContent10 = () => {
  return (
    <>
      <ContentBlockWrapper>
        <ContentBlock>
          <LeftBlockContent>
            <TextTitleWrapper>
              <TextTitle>För vem:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>Överlämnare</SecondText>
            </TextWrapper>
            <TextTitleWrapper>
              <TextTitle>Hur dokumentet kan användas:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Tips på vad du kan göra under din mandatperiod för att skapa
                goda förutsättningar när det är din tur att lämna över uppdraget
                till någon annan.
              </SecondText>
            </TextWrapper>
          </LeftBlockContent>
          <RightBlockContent>
            <TextTitleWrapper>
              <TextTitle>Beskrivning:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Använd dig av listan för att få tips eller använd den som en
                checklista där du checkar av vilka saker du gjort.
              </SecondText>
            </TextWrapper>
          </RightBlockContent>
        </ContentBlock>
      </ContentBlockWrapper>
      <FooterModal>
        <ButtonPDF
          downLink={
            "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Tips på hur du kan skapa goda förutsättningar för överlämningar.pdf"
          }
        />
      </FooterModal>
    </>
  );
};
