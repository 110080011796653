export const DEFAULT_ACTIVITY_TIMEOUT = 3000;

export enum AlertType {
  Notification = "notification",
  Error = "error"
}

export enum ChapterTypes {
  Regular = "REGULAR",
  Business = "BUSINESS"
}

export enum UserTypes {
  Regular = "REGULAR",
  Super = "SUPER"
}

export enum EventType {
  Resize = "resize",
  Scroll = "scroll",
  Click = "mousedown",
  Initialized = "initialized",
  KeyDown = "keydown"
}

export enum InputType {
  Text = "text",
  Textarea = "textarea",
  Email = "email",
  Password = "password",
  Date = "date",
  Number = "number"
}

export enum IconType {
  SendForm = "SendForm",
  SaveAndContinue = "SaveAndContinue",
  IconPreview = "IconPreview",
  ClearBrush = "ClearBrush",
  ArrowRight = "ArrowRight",
  ArrowLeft = "ArrowLeft",
  Menu = "Menu",
  EpGlobe = "EpGlobe",
  EpMore = "EPMore",
  EpSearch = "EpSearch",
  EpPlus = "EpPlus",
  EpMinus = "EpMinus",
  EpChevronDown = "EpChevronDown",
  EpChevronUp = "EpChevronUp",
  EpChevronRight = "EpChevronRight",
  EpChevronLeft = "EpChevronLeft",
  EpCalendar = "EpCalendar",
  EpEdit = "EpEdit",
  EpRegComment = "EpRegComment",
  EpMail = "EpMail",
  EpTimes = "EpTimes",
  EpTimesRound = "EpTimesRound",
  EpAttendance = "EpAttendance",
  EpMembers = "EpMembers",
  EpAddMember = "EpAddMember",
  EpCommittees = "EpCommittees",
  EpDocument = "EpDocument",
  EpDoc = "EpDoc",
  EpUploadDocument = "EpUploadDocument",
  EpDocumentFilled = "EpDocumentFilled",
  EpMegaphone = "EpMegaphone",
  EpPolls = "EpPolls",
  EpSettings = "EpSettings",
  EpEye = "EpEye",
  EpEyeSlash = "EpEyeSlash",
  EpArrowUp = "EpArrowUp",
  EpArrowDown = "EpArrowDown",
  EpArrowRight = "EpArrowRight",
  EpArrowLeft = "EpArrowLeft",
  EpTrash = "EpTrash",
  EpCheck = "EpCheck",
  EpCheckRound = "EpCheckRound",
  EpInfo = "EpInfo",
  EpExciamation = "EpExciamation",
  EpCSVIcon = "EpCSVIcon",
  EpUploadFile = "EpUploadFile",
  EpNewDocument = "EpNewDocument",
  EpPage = "EpPage",
  EpFolder = "EpFolder",
  EpFolderDefault = "EpFolderDefault",
  EpMoveFolder = "EpMoveFolder",
  EpEvent = "EpEvent",
  EpPollFilled = "EpPollFilled",
  EpPreferences = "EpPreferences",
  EpZoomIn = "EpZoomIn",
  EpZoomOut = "EpZoomOut",
  EpHand = "EpHand",
  EpTextTool = "EpTextTool",
  EpText = "EpText",
  EpCornerDown = "EpCornerDown",
  EpCornerRight = "EpCornerRight",
  EpClock = "EpClock",
  EpClockArrow = "EpClockArrow",
  EpAttachment = "EpAttachment",
  EpOpen = "EpOpen",
  EpDevice = "EpDevice",
  EpBell = "EpBell",
  EpExternalLink = "EpExternalLink",
  EpStatus = "EpStatus",
  EpRelation = "EpRelation",
  EpSubItem = "EpSubItem",
  EpDragHandle = "EpDragHandle",
  EpAvatarIcon = "EpAvatarIcon",
  LogOut = "LogOut",
  Handover = "Handover",
  Profile = "Profile",
  PreviewDocument = "PreviewDocument",
  SaveFloppy = "SaveFloppy",
  PaperAirplane = "PaperAirplane"
}

export enum StorageKey {
  LanguageCode = "spridakunskap-language-code",
  Token = "spridakunskap-auth-token",
  ActiveWorkspaceId = "spridakunskap-active-workspace-id"
}

export const FieldTypes = {
  checkboxGroup: "checkbox-group",
  startQuestionBlock: "startQuestionBlock",
  endQuestionBlock: "endQuestionBlock",
  duplicatePrevField: "duplicatePrevField",
  select: "select",
  radioGroup: "radio-group",
  textarea: "textarea",
  text: "text",
  paragraph: "paragraph",
  header: "header",
  date: "date",
  file: "file",
  image: "image"
};

export const processedFields = [
  "checkbox-group",
  "date",
  "file",
  "textarea",
  "text",
  "radio-group",
  "select"
];

export const deviceSize = {
  mobile: {
    min: "300px",
    max: "833px"
  },
  tablet: {
    min: "834px",
    max: "1023px"
  },
  laptop: {
    min: "1024px",
    max: "1279px"
  },
  laptopM: {
    min: "1280px",
    max: "1365px"
  },
  laptopL: {
    min: "1366px",
    max: "1439px"
  },
  desktop: {
    min: "1440px",
    max: "1920px"
  },
  fourK: {
    min: "2560px",
    max: ""
  }
};

export const deviceRange = {
  mobile: `(min-width : ${deviceSize.mobile.min}) and (max-width : ${deviceSize.mobile.max})`,
  tablet: `(min-width : ${deviceSize.tablet.min}) and (max-width : ${deviceSize.tablet.max})`,
  laptop: `(min-width : ${deviceSize.laptop.min}) and (max-width : ${deviceSize.laptop.max})`,
  laptopM: `(min-width : ${deviceSize.laptopM.min}) and (max-width : ${deviceSize.laptopM.max})`,
  laptopL: `(min-width : ${deviceSize.laptopL.min}) and (max-width : ${deviceSize.laptopL.max})`,
  desktop: `(min-width : ${deviceSize.desktop.min}) and (max-width : ${deviceSize.desktop.max})`,
  fourK: `(min-width : ${deviceSize.fourK.min})`
};
