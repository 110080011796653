import React, { useEffect, useState } from "react";
import data from "../Blog/Blog.data";
import { useParams } from "react-router-dom";
import {
  BlogContainer,
  ArticleTitle,
  ArticleText,
  ArticleWrapper,
  ArticleImage,
  ImageWrapper,
  Date,
  Line,
  DateBlock
} from "./BlogArticle.style";

function ArticleItem() {
  const { url }: any = useParams();
  const [articleData, setArticleData] = useState(data);

  useEffect(() => {
    if (url) {
      const article: any = data.filter(post => post.url === url);
      setArticleData(article);
    }
  }, [url]);

  return (
    <BlogContainer>
      {articleData &&
        articleData.map(post => {
          return (
            <ArticleWrapper key={post.id}>
              <ArticleTitle>{post.title}</ArticleTitle>
              <ImageWrapper>
                <ArticleImage src={post.image} alt="" />
              </ImageWrapper>
              <DateBlock>
                <Line />
                <Date>{post.date}</Date>
              </DateBlock>
              <ArticleText>{post.text}</ArticleText>
            </ArticleWrapper>
          );
        })}
    </BlogContainer>
  );
}

export default ArticleItem;
