import React, { useState } from "react";
import { deviceSize, IconType } from "../../consts";
import {
  useDevice,
  useNavigation,
  useTranslation,
  useViewport
} from "../../hooks";

import { Chapter } from "../../../../api";
import { Link } from "react-router-dom";
import { Images } from "../../environment";
import {
  LinkIcon,
  LogOutBtn,
  LogOutBtnText
} from "../SidebarMenu/SidebarMenu.style";
import {
  ArrowLeftItem,
  ArrowRightItem,
  ItemText,
  MainBlock,
  MenuItem,
  Wrapper,
  HelpManWrapp,
  HelpManContent,
  HelpManImgWrap,
  HelpManText,
  HelpManTextWrapper,
  AssistantBlockContent,
  AssistantBlockWrapper,
  AssistantTextBlock,
  TextUnderMascot,
  MascotImg,
  PopupBurgerMenu,
  ImageCloseButtonWrapper,
  ImageCloseButton,
  HeaderBlockLinks,
  LinkBurgetMenu,
  LinkText,
  HelpManBackground,
  MenuText,
  BackgroundBlur,
  HelpManTextParagraph
} from "./MobileMenu.style";
import { Icon } from "../ui/Icon";

export enum StorageKey {
  LanguageCode = "spridakunskap-language-code",
  Token = "spridakunskap-auth-token",
  ActiveWorkspaceId = "spridakunskap-active-workspace-id"
}

interface MobileMenuProps {
  chapter?: Chapter | null;
  step?: number;
  prevButtonDisabled?: boolean;
  onPrevButtonClick?: () => void;
  onNextButtonClick?: () => void;
  isLastChapter?: boolean;
  ShowPreview?: () => void;
  ShowConfirmPopup?: () => void;
  ShowConfirmationPopup?: () => void;
  SendAnswerHandler?: () => void;
}

export function MobileMenu({
  chapter,
  ShowConfirmPopup,
  step,
  prevButtonDisabled,
  onPrevButtonClick,
  onNextButtonClick,
  isLastChapter,
  ShowPreview,
  ShowConfirmationPopup,
  SendAnswerHandler
}: MobileMenuProps) {
  const device = useDevice();
  const [sideMenuShow, setSideMenuShow] = useState<boolean>(false);
  const [burgetMenuShow, setBurgetMenuShow] = useState<boolean>(false);
  const width = useViewport();
  const { routes, navigate, history } = useNavigation();
  const translate = useTranslation();
  const [showAssistant, setShowAssistant] = useState<boolean>(true);
  const getHelpManText = (chapter: Chapter) => {
    if (chapter?.helpmanText) {
      const helpManTextLine = [
        {
          text: JSON.parse(chapter.helpmanText).map((item: any) => item.value),
          currentStepForText: JSON.parse(chapter.helpmanText).map(
            (item: any) => item.currentStep
          )
        }
      ];
      return helpManTextLine;
    }
    return null;
  };
  const helpText = chapter ? getHelpManText(chapter) : null;

  const showPreview = () => {
    if (ShowPreview) {
      ShowPreview();
      setSideMenuShow(false);
    }
  };
  const showConfirmPopup = () => {
    if (ShowConfirmPopup) {
      ShowConfirmPopup();
      setSideMenuShow(false);
    }
  };
  const showConfirmationPopup = () => {
    if (ShowConfirmationPopup) {
      ShowConfirmationPopup();
      setSideMenuShow(false);
    }
  };

  const sendAnswerHandler = () => {
    if (SendAnswerHandler) {
      SendAnswerHandler();
      setSideMenuShow(false);
    }
  };
  return (
    <Wrapper>
      <MainBlock>
        <PopupBurgerMenu isOpen={sideMenuShow}>
          <ImageCloseButtonWrapper onClick={() => setSideMenuShow(false)}>
            <ImageCloseButton src={Images.landingCloseIcon} />
          </ImageCloseButtonWrapper>
          <HeaderBlockLinks>
            <LinkBurgetMenu onClick={showPreview}>
              <img
                style={{ width: "24px", height: "24px" }}
                src={Images.preview}
              ></img>
              <MenuText>Förhandsvisning</MenuText>
            </LinkBurgetMenu>

            <LinkBurgetMenu onClick={showConfirmPopup}>
              <img
                style={{ width: "24px", height: "24px" }}
                src={Images.brush}
              ></img>

              <MenuText>Nollställ formulärssvar</MenuText>
            </LinkBurgetMenu>

            <LinkBurgetMenu onClick={showConfirmationPopup}>
              <img
                style={{ width: "24px", height: "24px" }}
                src={Images.floppy}
              ></img>
              <MenuText>Spara och fortsätt senare</MenuText>
            </LinkBurgetMenu>

            <LinkBurgetMenu onClick={sendAnswerHandler}>
              <Icon name={IconType.PaperAirplane} />
              <MenuText>Färdig för att skicka in</MenuText>
            </LinkBurgetMenu>
          </HeaderBlockLinks>
        </PopupBurgerMenu>

        <MenuItem onClick={() => setSideMenuShow(true)}>
          <Icon name={IconType.Menu} />
          <ItemText>Meny</ItemText>
        </MenuItem>

        <ArrowLeftItem
          disabled={prevButtonDisabled}
          onClick={onPrevButtonClick}
        >
          <Icon name={IconType.ArrowLeft} />
          <ItemText>Föregående</ItemText>
        </ArrowLeftItem>
        <ArrowRightItem onClick={onNextButtonClick}>
          <Icon name={IconType.ArrowRight} />
          <ItemText>{isLastChapter ? "Färdig" : "Nästa"}</ItemText>
        </ArrowRightItem>
        <ArrowRightItem>
          {showAssistant && chapter && (
            <a
              onTouchStart={() => setShowAssistant(false)}
              onMouseDown={() => setShowAssistant(false)}
            >
              <BackgroundBlur
                style={{
                  backgroundImage: showAssistant
                    ? `url(${Images.landingBackgroundBlur})`
                    : "none",
                  display: showAssistant ? "block" : "none",
                  backgroundSize: showAssistant ? "cover" : "none"
                }}
              ></BackgroundBlur>
            </a>
          )}

          {showAssistant && chapter ? (
            <HelpManWrapp>
              <HelpManContent>
                <HelpManImgWrap>
                  <HelpManText
                    isShow={
                      helpText
                        ? helpText[0].currentStepForText.includes(step)
                        : false
                    }
                    isMacintosh={device}
                  >
                    <HelpManTextWrapper>
                      {helpText &&
                        helpText.map(helpManTextLineForPartChapter => {
                          return helpManTextLineForPartChapter.text.map(
                            (item: any, idx: number) => {
                              if (
                                helpManTextLineForPartChapter
                                  .currentStepForText[idx] === step
                              ) {
                                return (
                                  <HelpManTextParagraph key={`${idx}textline`}>
                                    {item}
                                    <br />
                                  </HelpManTextParagraph>
                                );
                              }
                            }
                          );
                        })}
                    </HelpManTextWrapper>
                  </HelpManText>

                  <AssistantBlockContent>
                    {(helpText ? (
                      helpText[0].currentStepForText.includes(step)
                    ) : (
                      false
                    )) ? (
                      <img
                        style={{
                          marginLeft: "-32px",
                          width: "157px",
                          height: "213"
                        }}
                        src={Images.assistant}
                        onClick={() => setShowAssistant(false)}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{ marginLeft: "-32px" }}
                        src={Images.assistant}
                        onClick={() => setShowAssistant(false)}
                        alt=""
                      />
                    )}
                  </AssistantBlockContent>
                </HelpManImgWrap>
              </HelpManContent>
            </HelpManWrapp>
          ) : (
            <AssistantBlockWrapper>
              <AssistantBlockContent onClick={() => setShowAssistant(true)}>
                <AssistantTextBlock>
                  <img
                    src={Images.landingImageArrowUp}
                    style={{
                      cursor: "pointer",
                      width: "35px",
                      height: "35px"
                    }}
                    alt=""
                  />
                </AssistantTextBlock>

                <HelpManBackground />
                <img
                  src={Images.landingEllipseMaskGroup}
                  style={{ position: "relative" }}
                  alt=""
                />

                <MascotImg src={Images.landingMaskGroup} alt=""></MascotImg>

                <TextUnderMascot>Behövs hjälp?</TextUnderMascot>
              </AssistantBlockContent>
            </AssistantBlockWrapper>
          )}
        </ArrowRightItem>
      </MainBlock>
    </Wrapper>
  );
}
