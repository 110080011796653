import { useState, useEffect } from "react";

function useDeviceUser() {
  const [device, setDevice] = useState(
    window.navigator.userAgent.includes("Macintosh")
  );

  useEffect(() => {
    const isAppleBook = window.navigator.userAgent.includes("Macintosh");
    setDevice(isAppleBook);
  }, []);
  return device;
}

export const useDevice = () => {
  return useDeviceUser();
};
