import { deviceRange } from "./../../consts";
import styled, { createGlobalStyle } from "styled-components";
import { Fonts, Colors } from "../../environment";
import { PropertySafetyTwoTone } from "@ant-design/icons";

export const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: auto;
    background-color: white;
    overflow-x: hidden;
    
  }
`;
interface HeaderContainerProps {
  scroll?: boolean;
}

//#region Header

export const HeaderContainer = styled.div<HeaderContainerProps>`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 26px;
  padding-left: 75px;
  padding-right: 75px;
  padding-bottom: 26px;
  background-color: white;
  position: fixed;
  box-shadow: ${props =>
    props.scroll ? "4px 4px 25px rgba(107, 201, 219, 0.25)" : "none"};
  border-radius: ${props =>
    props.scroll ? "0px 0px 35px 35px" : "0px 0px 0px 0px"};
  z-index: 100;

  @media ${deviceRange.laptop} {
    justify-content: space-between;
    padding-left: 35px;
    padding-right: 35px;
  }

  @media ${deviceRange.tablet} {
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media ${deviceRange.mobile} {
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
    overflow: hidden;
  }
  @media ${deviceRange.desktop} {
    //zoom: 1.2;
  }
  @media ${deviceRange.fourK} {
    zoom: 1.2;
  }
`;
export const HeaderBlockLogo = styled.div`
  display: flex;
  align-items: center;

  @media ${deviceRange.mobile} {
    margin-left: -6px;
  }
`;
export const HeaderBlockLinks = styled.div`
  display: flex;
  align-self: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;

  @media ${deviceRange.tablet} {
    flex-direction: column;
    align-items: center;
  }

  @media ${deviceRange.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;
interface HeaderLinkProps {
  fontSize?: number;
  onClick?: any;
  backlight?: number;
}

export const HeaderLink = styled.a<HeaderLinkProps>`
  margin-left: 10px;
  margin-right: 10px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize + "px" : "20px")};
  line-height: 32px;
  color: #425262;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #cc00a0;
  }
  ${props => (props.backlight ? "color: #cc00a0;" : null)}


  @media ${deviceRange.laptopM} {
    font-size: 16px;
  }

  @media ${deviceRange.laptopL} {
    font-size: 16px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
  }
  @media ${deviceRange.desktop} {
    font-size: 16px;
  }
  @media ${deviceRange.fourK} {
    zoom: 1.22;
  }
`;

export const HeaderBlockButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeadetButtonReg = styled.button`
  min-width: 151px;
  background: #fca311;
  border-radius: 80px;
  cursor: pointer;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 35px;
  padding-right: 35px;
  border: none;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #425262;
  &:focus {
    outline: none;
  }

  @media ${deviceRange.mobile} {
    padding: 0px;
    font-size: 12px;
    width: 120px;
    height: 40px;
    min-width: 0px;
  }
`;
interface HeadetButtonLogProps {
  marginLeft?: number;
  marginTop?: number;
  padding?: string;
  customWidth?: number;
  fontSize?: number;
  widthVW?: number;
}
export const HeadetButtonLog = styled.button<HeadetButtonLogProps>`
  min-width: 120px;
  background: none;
  ${props => (props.customWidth ? "width:" + props.customWidth + "px;" : null)}
  ${props =>
    props.widthVW ? `width: ${props.widthVW}vw;` : null}
border-radius: 80px;
  cursor: pointer;
  padding: ${props => (props.padding ? props.padding : "13px 35px")};
  border: 1px solid #ffa100;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #425262;
  margin-left: ${props =>
    props.marginLeft ? props.marginLeft + "px" : "37px"};
  ${props => (props.marginTop ? `margin-top: ${props.marginTop}px` : null)};
  &:hover {
    border: 1px solid rgba(252, 163, 17, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(255, 161, 0, 0.5);
  }
  &:active {
    background: #fca311;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.mobile} {
    width: 48%;
    font-size: 10px;
    height: 40px;
    padding: 0;
  }
`;

export const LogIn = styled.button`
  width: 128px;
  background: none;

  border-radius: 80px;
  cursor: pointer;
  padding: 13px 35px;
  border: 1px solid #ffa100;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #425262;
  margin-left: 37px;
  &:hover {
    border: 1px solid rgba(252, 163, 17, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(255, 161, 0, 0.5);
  }
  &:active {
    background: #fca311;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.mobile} {
    width: 120px;
    font-size: 12px;
    padding: 0px;
    height: 40px;
  }
`;

export const HeaderBurgerMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
`;

export const ImageBurgerMenu = styled.img`
  width: 60px;
  height: 60px;

  @media ${deviceRange.mobile} {
    width: 40px;
    height: 40px;
  }
`;

interface PopupBurgerMenuProps {
  isOpen: boolean;
}

export const PopupBurgerMenu = styled.div<PopupBurgerMenuProps>`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(107, 201, 219, 0.15);
  border-radius: 0px 0px 15px 15px;
  z-index: 101;
  transform: translateY(${props => (props.isOpen ? "0px" : "-300px")});
  transition: 1.3s, ease-in-out;
  transition-duration: 0.8s;

  @media ${deviceRange.tablet} {
    flex-direction: column;
    height: 240px;
  }

  @media ${deviceRange.mobile} {
    flex-direction: column;
    height: 220px;
  }
`;

export const ImageCloseButton = styled.img`
  width: 16px;
  height: 16px;
`;

export const ImageCloseButtonWrapper = styled.div`
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  @media ${deviceRange.tablet} {
    margin-left: auto;
  }

  @media ${deviceRange.mobile} {
    margin-left: auto;
    margin-bottom: -28px;
    margin-right: 50px;
  }
`;

export const HeaderMobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;

//#endregion

export const Wrapper = styled.div`
  overflow: hidden;
`;
export const LasMerButton = styled.button<HeadetButtonLogProps>`
  background: none;
  border-radius: 80px;
  cursor: pointer;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 95px;
  padding-right: 95px;
  border: 1px solid #ffa100;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #fca311;
  margin-left: ${props =>
    props.marginLeft ? props.marginLeft + "px;" : "0px;"};
  margin-top: 35px;
  &:hover {
    border: 1px solid rgba(252, 163, 17, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(255, 161, 0, 0.5);
  }
  &:active {
    background: #fca311;
    color: white;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.laptopL} {
    padding: 2vh 5vw;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
    padding: 20px 77px;
  }
`;

export const LasMerButtonServiceBlock = styled.button<HeadetButtonLogProps>`
  background: none;
  border-radius: 80px;
  cursor: pointer;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 95px;
  padding-right: 95px;
  border: 1px solid #ffa100;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #fca311;
  margin-left: ${props =>
    props.marginLeft ? props.marginLeft + "px;" : "0px;"};
  margin-top: 35px;
  &:hover {
    border: 1px solid rgba(252, 163, 17, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(255, 161, 0, 0.5);
  }
  &:active {
    background: #fca311;
    color: white;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.laptopL} {
    padding: 2vh 5vw;
  }

  @media ${deviceRange.tablet} {
    width: 210px;
    height: 56.54px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 0;
  }

  @media ${deviceRange.laptop} {
    width: 210px;
    height: 56.54px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 0;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
    padding: 20px 77px;
  }
`;

export const PromoBlockContaner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  background-color: white;
  padding-top: 150px;

  @media ${deviceRange.desktop} {
    padding-top: 75px;
  }

  @media ${deviceRange.tablet} {
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media ${deviceRange.mobile} {
    flex-direction: column;
    padding-right: 20px;
    max-width: 410px;
    margin: auto;
  }
`;
interface PromoBlockLeftPartRrops {
  maxWidth?: number;
}
export const PromoBlockLeftPart = styled.div<PromoBlockLeftPartRrops>`
  display: flex;
  flex-direction: column;
  max-width: ${props => (props.maxWidth ? props.maxWidth + "px;" : "720px;")};
  margin-top: 0px;
  margin-left: 150px;

  @media ${deviceRange.laptop} {
    margin-left: 50px;
  }

  @media ${deviceRange.tablet} {
    z-index: 2;
    margin-left: 30px;
  }

  @media ${deviceRange.mobile} {
    margin-left: 0px;
  }
`;
interface PromoBlockMainTextProps {
  fontSize?: number;
  textAlign?: string;
}
export const PromoBlockMainText = styled.div<PromoBlockMainTextProps>`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize + "px" : "55px")};
  line-height: 60px;
  color: #6bc9db;
  width: 100%;
  text-align: ${props => (props.textAlign ? props.textAlign : "none")};

  @media ${deviceRange.mobile} {
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 28px;
  }
`;

export const LandingPromoBlockMainText = styled.div<PromoBlockMainTextProps>`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize + "px" : "55px")};
  line-height: 60px;
  color: #6bc9db;
  width: 100%;

  @media ${deviceRange.laptop} {
    font-family: ${Fonts.landingConcertOneRegular};
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    line-height: 50px;
  }

  @media ${deviceRange.tablet} {
    font-size: 42px;
    line-height: 50px;
    width: 500px;
  }

  @media ${deviceRange.mobile} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const PromoBlockText = styled.p`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #425262;
  max-width: 585px;
  margin-top: 35px;

  @media ${deviceRange.laptop} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
    max-width: 320px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;
interface PromoTextBeforeButtonProps {
  marginLeft?: number;
}
export const PromoTextBeforeButton = styled.div<PromoTextBeforeButtonProps>`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #425262;
  margin-top: 55px;
  margin-left: ${props =>
    props.marginLeft ? props.marginLeft + "px;" : "20px;"};
  display: flex;
  width: 100%;

  @media ${deviceRange.mobile} {
    width: 240px;
    margin-left: 0px;
    margin-top: 35px;
    margin-bottom: 0px;
  }
`;
export const PromoTextBeforeButtonFirstPage = styled.div`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #425262;
  margin-top: 55px;
  margin-left: 20px;
  display: flex;
  width: auto;

  @media ${deviceRange.mobile} {
    margin-left: 0px;
    margin-top: 25px;
    font-size: 12px;
    width: auto;
  }
`;
interface PromoButtonStartprops {
  marginTop?: number;
  width?: number;
  onClick?: any;
  paddingTop?: number;
  paddingBottom?: number;
  shadowLeft?: number;
  shadowTop?: number;
  marginLeft?: number;
  marginBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  height?: number;
  fontSize?: number;
  widthVW?: number;
  padding?: string;
  background?: string;
  borderColor?: string;
  boxShadowColor?: string;
}
export const PromoButtonStart = styled.button<PromoButtonStartprops>`
  min-width:120px;
  background: ${props => (props.background ? props.background : "#cc00a0")};
  box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
    ${props =>
      props.shadowTop ? props.shadowTop + "px" : "6px"} 0px ${props =>
  props.boxShadowColor ? props.boxShadowColor : "#b2028c"};
  border-radius: 80px;
  padding-top: ${props =>
    props.paddingTop ? props.paddingTop + "px" : "34px"};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom + "px" : "34px"};
  padding-left: ${props =>
    props.paddingLeft ? props.paddingLeft + "px" : "41px"};
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight + "px" : "41px"};
  border: ${props => (props.background ? props.background : "#cc00a0")};
  color: #ffffff;
  width: ${props => (props.width ? props.width + "px" : "100%")};
  ${props => (props.widthVW ? `width: ${props.widthVW}vw;` : null)}
  padding: ${props => (props.padding ? props.padding : "13px 35px")};
  font-family: ${Fonts.landingOpenSansSemibold};
  font-style: normal;
  font-size: 1.6rem;
  margin-top: ${props => (props.marginTop ? props.marginTop + "px" : "-40px")};
  ${props =>
    props.marginLeft ? "margin-left:" + props.marginLeft + "px" : null};
  ${props =>
    props.marginBottom ? "margin-bottom:" + props.marginBottom + "px" : null};
  cursor: pointer;
  ${props => (props.height ? "height:" + props.height + "px;" : null)}
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px ${props =>
      props.boxShadowColor ? props.boxShadowColor : "#b2028c"};;
  }
  &:active {
    box-shadow: -5px -5px 0px ${props =>
      props.boxShadowColor ? props.boxShadowColor : "#b2028c"};;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
      ${props =>
        props.shadowTop ? props.shadowTop + "px" : "6px"} 0px #b2028c;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
    width: 200px;
  }
`;

export const ButtonRegister = styled.button<PromoButtonStartprops>`
  min-width:120px;
  background: #cc00a0;
  box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
    ${props => (props.shadowTop ? props.shadowTop + "px" : "6px")} 0px #b2028c;
  border-radius: 80px;
  padding-top: ${props =>
    props.paddingTop ? props.paddingTop + "px" : "34px"};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom + "px" : "34px"};
  padding-left: ${props =>
    props.paddingLeft ? props.paddingLeft + "px" : "41px"};
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight + "px" : "41px"};
  border: #cc00a0;
  color: #ffffff;
  width: ${props => (props.width ? props.width + "px" : "100%")};
  ${props => (props.widthVW ? `width: ${props.widthVW}vw;` : null)}
  padding: ${props => (props.padding ? props.padding : "13px 35px")};
  font-family: ${Fonts.landingOpenSansSemibold};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: ${props => (props.marginTop ? props.marginTop + "px" : "-40px")};
  ${props =>
    props.marginLeft ? "margin-left:" + props.marginLeft + "px" : null};
  ${props =>
    props.marginBottom ? "margin-bottom:" + props.marginBottom + "px" : null};
  cursor: pointer;
  ${props => (props.height ? "height:" + props.height + "px;" : null)}
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #b2028c;
  }
  &:active {
    box-shadow: -5px -5px 0px #b2028c;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
      ${props =>
        props.shadowTop ? props.shadowTop + "px" : "6px"} 0px #b2028c;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
    //width: 200px;
  }
`;

export const ButtonLogin = styled.button<PromoButtonStartprops>`
  min-width:120px;
  background: #cc00a0;
  box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
    ${props => (props.shadowTop ? props.shadowTop + "px" : "6px")} 0px #b2028c;
  border-radius: 80px;
  padding-top: ${props =>
    props.paddingTop ? props.paddingTop + "px" : "34px"};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom + "px" : "34px"};
  padding-left: ${props =>
    props.paddingLeft ? props.paddingLeft + "px" : "41px"};
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight + "px" : "41px"};
  border: #cc00a0;
  color: #ffffff;
  width: ${props => (props.width ? props.width + "px" : "100%")};
  ${props => (props.widthVW ? `width: ${props.widthVW}vw;` : null)}
  padding: ${props => (props.padding ? props.padding : "13px 35px")};
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  font-size: calc(5px + 0.5vw);
  line-height: 24px;
  margin-top: ${props => (props.marginTop ? props.marginTop + "px" : "-40px")};
  ${props =>
    props.marginLeft ? "margin-left:" + props.marginLeft + "px" : null};
  ${props =>
    props.marginBottom ? "margin-bottom:" + props.marginBottom + "px" : null};
  cursor: pointer;
  ${props => (props.height ? "height:" + props.height + "px;" : null)}
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #b2028c;
  }
  &:active {
    box-shadow: -5px -5px 0px #b2028c;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
      ${props =>
        props.shadowTop ? props.shadowTop + "px" : "6px"} 0px #b2028c;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
  }
`;

export const PromoButtonStartServiceBlock = styled.button`
  min-width: 120px;
  background: #cc00a0;
  box-shadow: 3px 6px 0px #b2028c;
  border-radius: 80px;
  padding-top: 34px;
  padding-bottom: 34px;
  padding-left: 41px;
  padding-right: 41px;
  border: #cc00a0;
  color: #ffffff;
  width: 208px;
  padding: 13px 35px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  font-size: calc(5px + 0.5vw);
  line-height: 24px;
  margin-top: -50px;
  height: 58px;
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #b2028c;
  }
  &:active {
    box-shadow: -5px -5px 0px #b2028c;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: 3px 6px 0px #b2028c;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
    margin-left: 28px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
    margin-left: 36px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
    width: 200px;
  }
`;

export const PromoButtonEmail = styled.button<PromoButtonStartprops>`
  margin-left: 2rem;
  background: #fca311;
  box-shadow: 3px 4px 0px #f09800;
  border-radius: 80px;
  padding-top: ${props =>
    props.paddingTop ? props.paddingTop + "px" : "34px"};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom + "px" : "34px"};
  padding-left: ${props =>
    props.paddingLeft ? props.paddingLeft + "px" : "41px"};
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight + "px" : "41px"};
  border: #fca311;
  color: #ffffff;
  width: ${props => (props.width ? props.width + "px" : "100%")};
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : "18px")};
  line-height: 24px;
  margin-top: ${props => (props.marginTop ? props.marginTop + "px" : "-40px")};
  ${props =>
    props.marginLeft ? "margin-left:" + props.marginLeft + "px" : null};
  ${props =>
    props.marginBottom ? "margin-bottom:" + props.marginBottom + "px" : null};
  cursor: pointer;
  ${props => (props.height ? "height:" + props.height + "px;" : null)}
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #f09800;
  }
  &:active {
    box-shadow: -5px -5px 0px #f09800;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
      ${props => (props.shadowTop ? props.shadowTop + "px" : "6px")} 0px
      rgba(255, 161, 0, 0.5);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PromoBlockRightPart = styled.div``;
export const PromoBlockSecondLeftPart = styled.div`
  display: flex;
  align-self: start;
  justify-self: start;
`;
export const BlockExchangeContainer = styled.div`
  width: 100%;
  background-color: #6bc9db;
  margin-top: 100px;
  padding-top: 75px;
  padding-bottom: 66px;

  @media ${deviceRange.mobile} {
    margin-top: 60px;
  }
`;

export const VideoBlockTitle = styled.div`
  width: fit-content;
  margin: 0 auto 45px;
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  line-height: 60px;
  color: #6bc9db;

  @media ${deviceRange.laptop} {
    font-size: 42px;
    line-height: 50px;
  }

  @media ${deviceRange.tablet} {
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 35px;
  }

  @media ${deviceRange.mobile} {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 35px;
  }
`;

export const BlockVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${Colors.white};

  @media ${deviceRange.laptopL} {
    margin-top: 50px;
  }
  @media ${deviceRange.laptopM} {
    margin-top: 70px;
  }
  @media ${deviceRange.laptop} {
    margin-top: 70px;
  }
  @media ${deviceRange.tablet} {
    margin-top: 70px;
  }
  @media ${deviceRange.mobile} {
    margin-top: 70px;
  }
`;

export const BlockVideoContentWrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 0px 20px ${Colors.inputFocusColor};
  border-radius: 20px;

  @media ${deviceRange.laptopL} {
    max-width: none;
    margin: 0 115px;
  }

  @media ${deviceRange.laptopM} {
    max-width: none;
    margin: 0 70px;
  }
  @media ${deviceRange.laptop} {
    max-width: none;
    margin: 0 70px;
  }
  @media ${deviceRange.tablet} {
    max-width: none;
    margin: 0 50px;
  }
  @media ${deviceRange.mobile} {
    max-width: none;
    margin: 0 20px;
  }
`;

//TODO:
export const VideoPopUpWrapp = styled.div`
  display: flex;
  position: relative;
`;

export const VideoPopupContent = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 50px 0px;

  @media ${deviceRange.mobile} {
    padding: 40px 0px;
  }
`;

export const IntroVideo = styled.video`
  width: 100%;
  height: fit-content;
  padding: 0 100px;

  @media ${deviceRange.laptopM} {
    padding: 0 80px;
  }
  @media ${deviceRange.laptop} {
    padding: 0 80px;
  }
  @media ${deviceRange.tablet} {
    padding: 0 60px;
  }
  @media ${deviceRange.mobile} {
    padding: 0 20px;
  }
`;

export const BlockExchangeContentWrapper = styled.div`
  max-width: 1400px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media ${deviceRange.laptopL} {
    margin-left: 100px;
  }

  @media ${deviceRange.mobile} {
    max-width: 410px;
  }
`;
export const BlockExchangeMainText1 = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 55px;
  height: 115px;
  text-align: center;
  color: white;
  font-family: ${Fonts.landingConcertOneRegular};
  margin: auto;
  max-width: 640px;
`;
export const BlockExchangeLeftPart = styled.div`
  max-width: 490px;
  display: flex;
  flex-direction: column;
  margin-top: 35px;

  @media ${deviceRange.laptopL} {
    margin-left: 50px;
  }

  @media ${deviceRange.laptopM} {
    margin-left: 50px;
  }

  @media ${deviceRange.desktop} {
    margin-left: 80px;
  }

  @media ${deviceRange.laptop} {
    width: 450px;
  }
`;
interface BlockExchangeLeftPartTextProps {
  maxWidth?: number;
  marginTop?: number;
  color?: string;
  fontSize?: number;
  lineHeight?: number;
}
export const BlockExchangeLeftPartText = styled.div<
  BlockExchangeLeftPartTextProps
>`
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize + "px" : "20px")};
  line-height: ${props =>
    props.lineHeight ? props.lineHeight + "px" : "32px"};
  color: ${props => (props.color ? props.color : "#425262")};
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: ${props => (props.marginTop ? props.marginTop + "px" : "0px")};
  max-width: ${props => (props.maxWidth ? props.maxWidth + "px" : "100%")};
`;

export const ReadMoreBlockLeftPartText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #425262;
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 35px;
  max-width: 290px;

  @media ${deviceRange.laptop} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
  }
`;

export const BlockFinanceText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #425262;
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 35px;
  max-width: 380px;

  @media ${deviceRange.laptop} {
    font-size: 14px;
    line-height: 18px;
  }

  @media ${deviceRange.tablet} {
    font-size: 14px;
    line-height: 18px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const BlockFinanceMainText = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  color: #6bc9db;
  max-width: 835px;
  margin: auto;

  @media ${deviceRange.laptop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.tablet} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  }
`;
export const BlockWithArrowsTextInfo = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #425262;
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 35px;
  max-width: 380px;
`;

export const PromoBlockSecondContainerMainText = styled.div`
  color: #6bc9db;
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  line-height: 60px;
  width: 100%;

  @media ${deviceRange.laptop} {
    font-size: 36px;
    line-height: 45px;
  }

  @media ${deviceRange.tablet} {
    font-size: 36px;
    line-height: 45px;
    margin-top: 30px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  }
`;

export const BlockIdeasMainText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 32px;
  color: white;
  font-family: ${Fonts.landingOpenSansRegular};
  margin: auto;
  text-align: center;
  max-width: 100px;

  @media ${deviceRange.tablet} {
    font-size: 36px;
    line-height: 45px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const BlockWithArrowsText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #425262;
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 35px;
  max-width: 635px;

  @media ${deviceRange.laptop} {
    font-size: 16px;
    line-height: 22px;
  }
  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const LandingImageWithState = styled.img`
  cursor: pointer;
  width: 100px;
  height: 100px;

  @media ${deviceRange.laptop} {
  }

  @media ${deviceRange.tablet} {
  }

  @media ${deviceRange.laptopL} {
  }

  @media ${deviceRange.laptopM} {
  }

  @media ${deviceRange.mobile} {
    width: 50px;
    height: 50px;
  }
`;

export const QuestionServiceLeftPartText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #425262;
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 35px;
  max-width: 650px;

  @media ${deviceRange.laptopL} {
    max-width: 650px;
  }

  @media ${deviceRange.laptop} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;
export const IdeasConteinerText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #425262;
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 35px;
  max-width: 490px;

  @media ${deviceRange.laptop} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
    width: 270px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const BlockExchangeLeftPartTextOnLeft = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: "#425262";
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 35px;
  max-width: 100%;

  @media ${deviceRange.laptop} {
    width: 450px;
    margin-left: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
    max-width: 330px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const BlockExchangeLeftPartTextSecondage = styled.div<
  BlockExchangeLeftPartTextProps
>`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: "#425262";
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 35px;
  max-width: 100%;
`;

export const BlockExchangeLeftPartTextSecondPage = styled.div<
  BlockExchangeLeftPartTextProps
>`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: "#425262";
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 70px;
  max-width: 100%;

  @media ${deviceRange.laptop} {
    font-size: 16px;
    line-height: 22px;
    margin-top: 60px;
  }

  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
    max-width: 330px;
    margin-top: 40px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
  }
`;

export const BlockExchangeRightPartTextSecondPage = styled.div<
  BlockExchangeLeftPartTextProps
>`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: "#425262";
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 70px;
  max-width: 100%;

  @media ${deviceRange.laptop} {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
    margin-top: 30px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
  }
`;
export const BlockExchangeRightPartTextSecondPageDown = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 68px;
  color: white;
  max-width: 100%;

  @media ${deviceRange.laptop} {
    color: white;
    font-size: 14px;
    line-height: 18px;
  }

  @media ${deviceRange.tablet} {
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
    line-height: 16px;
    margin-top: 20px;
  }
`;

export const BlockExchangeRightPartText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: "#425262";
  font-family: ${Fonts.landingOpenSansRegular};
  margin-top: 70px;
  max-width: 100%;

  @media ${deviceRange.laptop} {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-top: 50px;
    font-family: ${Fonts.landingOpenSansRegular};
  }

  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
    margin-top: 42px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
    margin-top: 22px;
  }
`;

export const BlockExchangeRightPart = styled.div`
  max-width: 490px;
  display: flex;
  flex-direction: column;
  margin-top: 66px;

  @media ${deviceRange.laptopL} {
    margin-right: 60px;
  }

  @media ${deviceRange.laptopM} {
    margin-right: 60px;
  }

  @media ${deviceRange.desktop} {
    margin-right: 70px;
  }

  @media ${deviceRange.laptop} {
    width: 370px;
  }
  @media ${deviceRange.tablet} {
    width: 330px;
  }
`;
export const BlockExchangeRightPartAvatars = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const BlockExchangeWrapParts = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 755px;

  @media ${deviceRange.laptop} {
    min-height: 650px;
  }

  @media ${deviceRange.tablet} {
    min-height: 580px;
  }

  @media ${deviceRange.mobile} {
    min-height: 480px;
  }
`;

export const BlockExchangeBoyAvatar = styled.img`
  width: 190px;
  height: 230px;
  @media ${deviceRange.laptop} {
    width: 123px;
    height: 154px;
  }

  @media ${deviceRange.tablet} {
    width: 89.43px;
    height: 112.08px;
  }

  @media ${deviceRange.mobile} {
    width: 89.43px;
    height: 112.08px;
  }
`;
export const BlockExchangeArrow = styled.img`
  width: 86px;
  height: 72px;
  @media ${deviceRange.laptop} {
    width: 66px;
    height: 55px;
  }

  @media ${deviceRange.tablet} {
    width: 48.11px;
    height: 40.19px;
  }

  @media ${deviceRange.mobile} {
    width: 48px;
  }
`;
export const BlockExchangeButtons = styled.div`
  margin: auto;
  width: 65px;
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${deviceRange.mobile} {
    width: 120px;
  }
`;
interface BlockExchangeButtonprops {
  check: number;
}

export const BlockExchangeButton1 = styled.button<BlockExchangeButtonprops>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  box-shadow: 2px 4px 4px #55838b, inset -2px -2px 4px #474747;
  cursor: pointer;
  background-color: ${props => (props.check === 0 ? "#929496" : "white")};
  &:focus {
    outline: none;
  }
`;

export const BlockExchangeButton2 = styled.button<BlockExchangeButtonprops>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  box-shadow: 2px 4px 4px #55838b, inset -2px -2px 4px #474747;
  cursor: pointer;
  background-color: ${props => (props.check === 1 ? "#929496" : "white")};
  &:focus {
    outline: none;
  }
`;

export const BlockExchangeButton3 = styled.button<BlockExchangeButtonprops>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  box-shadow: 2px 4px 4px #55838b, inset -2px -2px 4px #474747;
  cursor: pointer;
  background-color: ${props => (props.check === 2 ? "#929496" : "white")};
  &:focus {
    outline: none;
  }
`;

export const BlockExchangeButton4 = styled.button<BlockExchangeButtonprops>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  box-shadow: 2px 4px 4px #55838b, inset -2px -2px 4px #474747;
  cursor: pointer;
  background-color: ${props => (props.check === 3 ? "#929496" : "white")};
  &:focus {
    outline: none;
  }
`;

export const VemBlockPinkText = styled.div`
  width: 100%;
  color: #cc00a0;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  font-family: ${Fonts.landingConcertOneRegular};
  margin-top: 35px;

  @media ${deviceRange.laptop} {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.tablet} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;
export const BlockWithArrowsContainer = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${deviceRange.mobile} {
    max-width: 330px;
    margin: auto;
    margin-top: 50px;
  }
`;
interface BlockWithArrowsMainTextprops {
  fontSize?: number;
  margin?: string;
  marginTop?: number;
  textAlign?: string;
  maxWidth?: number;
  color?: string;
  ref?: any;
}
export const BlockWithArrowsMainText = styled.div<BlockWithArrowsMainTextprops>`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize + "px" : "48px")};
  line-height: 55px;
  color: ${props => (props.color ? props.color : "#6bc9db")};
  max-width: ${props => (props.maxWidth ? props.maxWidth + "px" : "750px")};
  ${props => (props.margin ? `margin: ${props.margin};` : null)}
  margin-top: ${props => (props.marginTop ? props.marginTop + "px" : "0px")};
  ${props => (props.textAlign ? `text-align: ${props.textAlign};` : null)}
`;

export const ReadMoreBlockMainText = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 55px;
  color: #6bc9db;
  max-width: 750px;
  margin-top: 0px;

  @media ${deviceRange.laptop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.tablet} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const QuestionBlockLeftPartMainTextBottom = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 55px;
  color: #6bc9db;
  max-width: 750px;
  margin-top: 35px;

  @media ${deviceRange.laptop} {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.tablet} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const QuestionBlockLeftPartMainText = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 55px;
  color: #6bc9db;
  max-width: 750px;

  @media ${deviceRange.laptop} {
    font-size: 36px;
    line-height: 45px;
  }

  @media ${deviceRange.tablet} {
    font-size: 36px;
    line-height: 45px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const BlockServiceMainText = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 55px;
  color: #6bc9db;
  max-width: 750px;

  @media ${deviceRange.laptop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.tablet} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;
export const BlockWithArrow1MainText = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 55px;
  color: #6bc9db;
  max-width: 750px;

  @media ${deviceRange.laptop} {
    font-size: 36px;
    line-height: 45px;
  }

  @media ${deviceRange.tablet} {
    font-size: 36px;
    line-height: 45px;
    margin-left: 20px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const BlockExchangeMainText = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 55px;
  color: white;
  max-width: 750px;
  margin: auto;
  text-align: center;

  @media ${deviceRange.laptop} {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 45px;
  }

  @media ${deviceRange.tablet} {
    font-size: 36px;
    line-height: 45px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const BlockAchievementsMainText = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 55px;
  color: #6bc9db;
  max-width: 835px;
  margin: auto;
  margin-top: 75px;
  text-align: center;

  @media ${deviceRange.laptop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.tablet} {
    font-size: 24px;
    line-height: 32px;
    max-width: 70%;
  }

  @media ${deviceRange.mobile} {
    font-size: 18px;
    line-height: 24px;
    margin: auto;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;

    max-width: 410px;
  }
`;

export const BlockWithArrows2MainText = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 55px;
  color: #6bc9db;
  max-width: 363px;
  margin: 0;
  margin-top: 0px;
  text-align: 0;

  @media ${deviceRange.tablet} {
    font-size: 36px;
    line-height: 45px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 28px;
  }
`;
export const BlockWithArrow1 = styled.div`
  display: flex;
`;

interface BlockWithArrowCardProps {
  width?: number;
  height?: number;
  marginTop?: number;
}
export const BlockWithArrowCard = styled.div<BlockWithArrowCardProps>`
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 54px;
  padding-top: 110px;
  background: #ffffff;
  box-shadow: 4px 4px 25px rgba(107, 201, 219, 0.25);
  border-radius: 25px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #425262;
  margin-top: ${props => (props.marginTop ? props.marginTop + "px" : "0px")};
  width: ${props => (props.width ? props.width + "px" : "390px")};
  height: ${props => (props.height ? props.height + "px" : "250px")};

  @media ${deviceRange.tablet} {
    padding-left: 26px;
    padding-right: 26px;
    padding-bottom: 24px;
    padding-top: 75px;
    height: 190px;
  }
`;

export const BlockWithArrowCardBottom = styled.div`
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 54px;
  padding-top: 110px;
  background: #ffffff;
  box-shadow: 4px 4px 25px rgba(107, 201, 219, 0.25);
  border-radius: 25px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #425262;
  margin-top: 0px;
  width: 390px;
  height: 250px;

  @media ${deviceRange.tablet} {
    padding-left: 26px;
    padding-right: 26px;
    padding-bottom: 24px;
    padding-top: 75px;
    height: 190px;
  }
`;
interface BlockWithArrowBlockCardProps {
  marginTop?: number;
  marginLeft?: number;
  width?: string;
}
export const BlockWithArrowBlockCard = styled.div<BlockWithArrowBlockCardProps>`
  ${props => (props.width ? `width: ${props.width};` : null)}
  ${props =>
    props.marginLeft ? `margin-left: ${props.marginLeft + "px;"}` : null}
  ${props =>
    props.marginTop ? `margin-top: ${props.marginTop + "px;"}` : null}
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${deviceRange.tablet} {
    margin-bottom: 10px;
  }
`;

export const BottomCardsLanding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${deviceRange.tablet} {
    margin-bottom: 50px;
  }
`;

export const BlockWithArrow2 = styled.div`
  width: 365px;
  margin-left: 70rem;

  @media ${deviceRange.laptop} {
    width: 410px;
  }

  @media ${deviceRange.tablet} {
    width: 410px;
    margin-left: 50rem;
  }

  @media ${deviceRange.mobile} {
    width: auto;
    margin-left: 0px;
    margin-top: 20px;
  }
`;
export const TargetBlockContainer = styled.div`
  width: 100%;
  margin-top: 135px;
  background-color: #6bc9db;

  @media ${deviceRange.mobile} {
    margin-top: 55px;
  }
`;
export const TargetBlockContentWrapper = styled.div`
  margin: auto;
  max-width: 1700px;
  padding-bottom: 60px;

  @media ${deviceRange.mobile} {
    max-width: 410px;
  }
`;
export const TargetBlockContentLine1 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media ${deviceRange.mobile} {
    display: flex;
    flex-direction: column-reverse;
  }
`;
interface TargetBlockMaintextProps {
  marginTop?: number;
}
export const TargetBlockMaintext = styled.div<TargetBlockMaintextProps>`
  max-width: 980px;
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;
  ${props =>
    props.marginTop ? `margin-top: ${props.marginTop + "px;"}` : null}
`;

export const MainTextBlockTarget = styled.div`
  max-width: 980px;
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;
  margin-top: 70px;

  @media ${deviceRange.laptop} {
    font-size: 36px;
    line-height: 45px;
    margin-top: 30px;
  }

  @media ${deviceRange.tablet} {
    font-size: 36px;
    line-height: 45px;
    margin-top: 20px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 28px;
    margin-top: 20px;
  }
`;

export const TargetBlockMainText2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 75px;

  @media ${deviceRange.laptopM} {
    font-size: 36px;
    line-height: 45px;
    width: 80%;
    margin-top: 20px;
  }

  @media ${deviceRange.laptop} {
    font-size: 36px;
    line-height: 45px;
    width: 80%;
    margin-top: 20px;
  }

  @media ${deviceRange.tablet} {
    width: 80%;
    margin-top: 20px;
  }

  @media ${deviceRange.mobile} {
    margin-top: 0px;
  }
`;
export const TargetBlockContentLine2 = styled.div`
  max-width: 1320px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 50px;
  flex-wrap: wrap;

  @media ${deviceRange.mobile} {
    padding-right: 0px;
    justify-content: center;
  }
`;
export const TargetBlockContentLine2Text = styled.div`
  max-width: 380px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #425262;

  @media ${deviceRange.tablet} {
    font-size: 14px;
    line-height: 18px;
    max-width: 270px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
    line-height: 18px;
  }

  @media ${deviceRange.laptopM} {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${deviceRange.laptopL} {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-top: 30px;
  }
`;
interface TargetBlockBtnRekommenderaProps {
  margin?: string;
  marginTop?: number;
  width?: number;
  paddingTop?: number;
  paddingBottom?: number;
  shadowLeft?: number;
  shadowTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  fontSize?: number;
  onClick?: any;
  marginLeft?: number;
  widthVW?: number;
  padding?: string;
}

export const IdeasBlockBtnRekommendera = styled.button<
  TargetBlockBtnRekommenderaProps
>`
  font-size: calc(5px + 0.5vw);
  min-width: 120px;
  margin: auto;
  width: 350px;
  margin-top:-50px;
  background: #fca311;
  box-shadow: ${props =>
    props.shadowLeft ? props.shadowLeft + "px" : "3px"} ${props =>
  props.shadowTop ? props.shadowTop + "px" : "6px"} rgba(255, 161, 0, 62%);
  border-radius: 80px;
  padding-top: ${props =>
    props.paddingTop ? props.paddingTop + "px" : "35px"};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom + "px" : "35px"};
  padding-left: ${props =>
    props.paddingLeft ? props.paddingLeft + "px" : "50px"};
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight + "px" : "50px"};
  color: white;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  border: none;
  ${props => (props.marginLeft ? `margin-left: ${props.marginLeft}px;` : null)}
  ${props => (props.widthVW ? `width: ${props.widthVW}vw;` : null)}
  padding: ${props => (props.padding ? props.padding : "13px 35px")};
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px rgba(255, 161, 0, 62%);
  }
  &:active {
    box-shadow: -3px -5px rgba(255, 161, 0, 62%);
  }

  @media ${deviceRange.laptopL}{
    width: 25vw;
    margin-left:15px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;}
  
`;

export const BeforeIdeasBlockBtnRekommendera = styled.button`
  font-size: calc(5px + 0.5vw);
  min-width: 120px;
  margin: auto;
  width: 350px;
  margin-top: -50px;
  background: #fca311;
  box-shadow: 3px 4px 0px #f09800;
  border: #fca311;
  border-radius: 80px;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 50px;
  padding-right: 50px;
  color: white;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  border: none;
  padding: 13px 35px;
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px rgba(255, 161, 0, 62%);
  }
  &:active {
    box-shadow: -3px -5px rgba(255, 161, 0, 62%);
  }

  @media ${deviceRange.laptopL} {
    width: 25vw;
    margin-left: 15px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
    width: 287.31px;
    height: 64.19px;
    margin-left: 40px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
    width: 280px;
    margin-top: 0px;
  }
`;

export const TargetBlockBtnRekommendera = styled.button<
  TargetBlockBtnRekommenderaProps
>`

  ${props => (props.margin ? `margin: ${props.margin};` : null)}
  
  ${props => (props.marginTop ? `margin-top: ${props.marginTop}px;` : null)}
  background: #fca311;
  box-shadow: 3px 4px 0px #f09800;
  border-radius: 80px;
  padding-top: ${props =>
    props.paddingTop ? props.paddingTop + "px" : "35px"};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom + "px" : "35px"};
  padding-left: ${props =>
    props.paddingLeft ? props.paddingLeft + "px" : "50px"};
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight + "px" : "50px"};
  color: white;
  font-family: ${Fonts.landingOpenSansSemibold};
  font-style: normal;
  font-size: 1.6rem;
  line-height: 24px;
  cursor: pointer;
  border: none;
  ${props => (props.marginLeft ? `margin-left: ${props.marginLeft}px;` : null)}
  ${props => (props.widthVW ? `width: ${props.widthVW}vw;` : null)}
  padding: ${props => (props.padding ? props.padding : "13px 35px")};

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #ffc561;
  }
  &:active {
    box-shadow: -3px -5px rgba(255, 161, 0, 62%);
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;
export const QuestionBlockContaner = styled.div`
  display: flex;
  margin-top: 135px;
  justify-content: space-around;
  width: 100%;

  @media ${deviceRange.mobile} {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 410px;
    margin: auto;
    margin-top: 50px;
  }
`;
export const QuestionBlockLeftPart = styled.div`
  display: flex;
  max-width: 570px;
  flex-direction: column;
  width: 100%;
  margin-left: 100px;

  @media ${deviceRange.mobile} {
    margin-left: 0px;
  }
`;

export const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const QuestionBlockQuestions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;

  @media ${deviceRange.laptop} {
    width: 400px;
  }

  @media ${deviceRange.laptopM} {
    width: 570px;
  }

  @media ${deviceRange.laptopL} {
    width: 520px;
  }

  @media ${deviceRange.mobile} {
    max-width: 330px;
  }
`;
export const QuestionBlockQuestionsText = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 33px;
  line-height: 45px;
  color: rgba(66, 82, 98, 0.5);
  margin-left: 28px;

  @media ${deviceRange.laptopL} {
    font-size: 25px;
    max-width: 399px;
  }

  @media ${deviceRange.laptopM} {
    font-size: 24px;
    max-width: 370px;
  }

  @media ${deviceRange.laptop} {
    font-size: 24px;
  }

  @media ${deviceRange.tablet} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;
export const ServicesBlock = styled.div`
  margin-top: 135px;
  padding-bottom: 140px;

  @media ${deviceRange.mobile} {
    padding-bottom: 40px;
  }
`;
export const ServicesBlockTitle = styled.div`
  margin: auto;
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  line-height: 60px;
  color: #6bc9db;
  width: fit-content;

  @media ${deviceRange.laptop} {
    font-size: 42px;
    line-height: 50px;
  }

  @media ${deviceRange.tablet} {
    font-size: 42px;
    line-height: 50px;
  }

  @media ${deviceRange.mobile} {
    font-size: 28px;
    line-height: 32px;
  }
`;
export const ServicesBlockWrapParts = styled.div`
  justify-content: space-around;
  width: 100%;
  display: flex;
  margin-top: 75px;

  @media ${deviceRange.mobile} {
    flex-direction: column;
    margin-top: 30px;
    max-width: 410px;
    margin: auto;
    margin-top: 5px;
  }
`;
export const AchievementsBlock = styled.div`
  justify-content: space-around;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 130px;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-right: 50px;

  @media ${deviceRange.desktop} {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media ${deviceRange.laptopM} {
    margin-left: 0px;
    margin-right: 0px;
  }
  @media ${deviceRange.tablet} {
    margin-top: 0px;
  }

  @media ${deviceRange.mobile} {
    flex-wrap: nowrap;
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 5px;
    width: 98%;
    overflow-x: scroll;
    justify-content: unset;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const BlockBeforeIdeasBlock = styled.div`
  width: fit-content;
  margin: auto;

  @media ${deviceRange.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 410px;
  }
`;
export const IdeasBlock = styled.div`
  width: 100%;
  margin-top: 140px;
  background-color: #6bc9db;
  padding-left: 43px;
  padding-right: 96px;
  padding-bottom: 33px;
  padding-top: 75px;

  @media ${deviceRange.tablet} {
    padding-left: 13px;
    padding-right: 6px;
    padding-bottom: 23px;
    padding-top: 25px;
  }

  @media ${deviceRange.mobile} {
    margin-top: 50px;
    padding-left: 13px;
    padding-right: 6px;
    padding-bottom: 23px;
    padding-top: 25px;
  }
`;
export const IdeasBlockContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media ${deviceRange.mobile} {
    flex-direction: column;
    margin-top: 0px;
    align-items: center;
  }
`;
export const FinanceBlockContainer = styled.div`
  display: flex;
  margin-top: 140px;
  align-content: center;
  justify-content: space-evenly;

  @media ${deviceRange.mobile} {
    margin: auto;
    margin-top: 60px;
    max-width: 410px;
  }
`;
export const FaqItemsContaner = styled.div`
  width: 100%;
  margin-top: 35px;
  box-shadow: 4px 4px 17px rgba(107, 201, 219, 0.25);
  border-radius: 35px;
`;
export const FaqItemsContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(66, 82, 98, 0.5);
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const FaqItemsText = styled.div`
  margin-left: 70px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #425262;
  max-width: 1011px;
  margin-right: 5px;
  padding-bottom: 10px;

  @media ${deviceRange.laptop} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;
export const FooterLine = styled.div`
  margin: auto;
  max-width: 1170px;
  height: 1px;
  background: rgba(66, 82, 98, 0.5);
  margin-top: 133px;

  @media ${deviceRange.mobile} {
    margin-top: 50px;
  }
`;
export const BlockButtonFooter = styled.div`
  margin: auto;
  margin-top: 50px;
  width: fit-content;
`;
export const FooterContactsBlock = styled.div`
  margin: auto;
  max-width: 900px;
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
  padding-bottom: 20px;

  @media ${deviceRange.tablet} {
    max-width: 700px;
  }

  @media ${deviceRange.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;
export const SocialBlock = styled.div`
  max-width: 200px;
  display: flex;
  flex-direction: column;

  @media ${deviceRange.mobile} {
    margin-top: 10px;
  }
`;
export const SocialBlockTextBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;

  @media ${deviceRange.mobile} {
    padding-bottom: 20px;
  }
`;
export const SocialBlockText = styled.div`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(66, 82, 98, 0.5);

  @media ${deviceRange.mobile} {
    align-self: center;
  }
`;
export const DesignByBlock = styled.div`
  width: 100%;
  background: rgba(66, 82, 98, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  margin-top: 50px;
`;
export const SocialBlockTextBefSoc = styled.div`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(66, 82, 98, 0.5);
  margin-left: 7px;
`;
export const FooterLink = styled.a`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #425262;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #cc00a0;
  }
`;
export const FooterBlockLinks = styled.div`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 14px;
  line-height: 24px;

  @media ${deviceRange.mobile} {
    align-items: center;
  }
`;
export const FaqTitle = styled.div`
  margin: auto;
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 55px;
  color: #6bc9db;
  max-width: 100px;
  text-align: center;
  margin-top: 115px;

  @media ${deviceRange.tablet} {
    font-size: 42px;
    line-height: 50px;
  }

  @media ${deviceRange.mobile} {
    font-size: 28px;
    line-height: 32px;
  }
`;
interface ImageWrapperProps {
  marginLeft?: number;
  marginTop?: number;
  width?: number;
  zIndex?: number;
  position?: string;
  transform?: string;
  marginRight?: number;
}
export const ImageWrapper = styled.div<ImageWrapperProps>`
  width: ${props => (props.width ? props.width + "px" : "fit-content")};
  height: fit-content;
  margin-left: ${props =>
    props.marginLeft ? props.marginLeft + "px;" : "0px;"};
  margin-top: ${props => (props.marginTop ? props.marginTop + "px;" : "0px;")};
    ${props => (props.position ? `position: ${props.position};` : null)}
    ${props => (props.zIndex ? `z-index: ${props.zIndex};` : null)}
    ${props => (props.transform ? `transform: ${props.transform};` : null)}
      margin-right: ${props =>
        props.marginRight ? props.marginRight + "px;" : "0px;"};
`;
//template
export const TEMPLATE = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 0px;
  margin-top: 0px;
  position: null;
  z-index: null;
  transform: null;
  margin-right: 0px;
`;
export const LandingFinanceLogoWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 0px;
  margin-top: 0px;
  position: null;
  z-index: null;
  transform: null;
  margin-right: 0px;

  @media ${deviceRange.mobile} {
    margin: auto;
    margin-top: 10px;
  }
`;
export const LandingImageCodeWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 0px;
  margin-top: -50px;
  position: null;
  z-index: null;
  transform: null;
  margin-right: 0px;
`;

export const LandingImagePoint = styled.img``;
export const LandingImageLightBulbWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 0px;
  margin-top: 110px;
  margin-right: 0px;

  @media ${deviceRange.mobile} {
    margin-top: 0px;
  }
`;
export const LandingImageWifiWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: -30px;
  margin-top: 250px;
  margin-right: 40px;

  @media ${deviceRange.laptopL} {
    margin-top: 340px;
  }
`;
export const LandingArrow1PromoBlockWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 6px;
  margin-top: 15px;
  transform: rotate(20deg);
  margin-right: 0px;
`;
export const LandingIconGirlWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: -65px;
  position: absolute;
  z-index: 50;
  transform: null;
  background-color: white;
  box-shadow: 2px 2px 25px rgb(107 201 219 / 20%);

  @media ${deviceRange.mobile} {
    position: unset;
    margin-top: 0px;
  }
`;
export const LandingIconMansWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: -65px;
  position: absolute;
  z-index: 50;
  transform: null;
  background-color: white;
  box-shadow: 2px 2px 25px rgb(107 201 219 / 20%);

  @media ${deviceRange.mobile} {
    position: unset;
    margin-top: 0px;
  }
`;
export const LandingIcon65Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: -65px;
  position: absolute;
  z-index: 50;
  transform: null;
  background-color: white;
  box-shadow: 2px 2px 25px rgb(107 201 219 / 20%);

  @media ${deviceRange.mobile} {
    position: unset;
    margin-top: 0px;
  }
`;

export const LandingArrow1Wrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 12px;
  margin-top: 15px;
  transform: rotate(25deg);
  margin-right: 0px;
`;
export const LandingPeopleQuestionsWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 0px;
  margin-top: 50px;
  position: null;
  z-index: null;
  transform: null;
  margin-right: 0px;

  @media ${deviceRange.mobile} {
    margin-top: 0px;
    mask-image: radial-gradient(rgb(0 0 0) 50%, rgba(0, 0, 0, 0) 70%);
    background: radial-gradient(rgb(255, 255, 255) 60%, rgb(242, 243, 245) 70%);
  }
`;
export const LandingPeopleComputerWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 0px;
  margin-top: 60px;
  position: null;
  z-index: null;
  transform: null;
  margin-right: 0px;

  @media ${deviceRange.mobile} {
    margin: auto;
    margin-top: 10px;
  }
`;
export const LandingMeanWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 0px;
  margin-top: 70px;
  position: null;
  z-index: null;
  margin-right: 0px;
  @media ${deviceRange.laptop} {
    margin-top: 40px;
  }
  @media ${deviceRange.laptopL} {
    margin-top: 40px;
  }
  @media ${deviceRange.laptopM} {
    margin-top: 40px;
  }
  @media ${deviceRange.tablet} {
    margin-top: 40px;
  }
  @media ${deviceRange.mobile} {
    margin-top: 40px;
    margin-left: auto;
    margin-right: 10px;
  }
`;
export const LandingImageTargetWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 0px;
  margin-top: 0px;
  position: null;
  z-index: null;
  transform: null;
  margin-right: 0px;

  @media ${deviceRange.laptop} {
    width: 107px;
    height: 107px;
  }
`;
export const LandingIconMarkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: -65px;
  position: absolute;
  z-index: 50;
  transform: null;
  background-color: white;
  box-shadow: 2px 2px 25px rgb(107 201 219 / 20%);
`;
export const LandingIconQuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: -65px;
  position: absolute;
  z-index: 50;
  transform: null;
  background-color: white;
  box-shadow: 2px 2px 25px rgb(107 201 219 / 20%);
`;
export const LandingIconGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: -65px;
  position: absolute;
  z-index: 50;
  transform: null;
  background-color: white;
  box-shadow: 2px 2px 25px rgb(107 201 219 / 20%);
`;
export const LandingIconWokerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: -65px;
  position: absolute;
  z-index: 50;
  transform: null;
  background-color: white;
  box-shadow: 2px 2px 25px rgb(107 201 219 / 20%);
`;

export const LandingChemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: -65px;
  position: absolute;
  z-index: 50;
  transform: null;
  background-color: white;
  box-shadow: 2px 2px 25px rgb(107 201 219 / 20%);
`;
export const LandingEllipseChemWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 0px;
  margin-top: -65px;
  position: absolute;
  z-index: 50;
  transform: null;
  margin-right: 0px;
`;
export const LandingArrow4Wrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: -442px;
  margin-top: 10px;
  position: null;
  z-index: null;
  transform: null;
  margin-right: 0px;

  @media ${deviceRange.mobile} {
    width: 210px;
    margin-left: 70px;
    margin-top: -10px;
  }
`;

export const MainPeopleImageWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 1px;
  margin-top: 0px;
  position: null;
  z-index: null;
  transform: null;
  margin-right: 0px;

  @media ${deviceRange.laptopL} {
    width: 538px;
    height: 595px;
  }

  @media ${deviceRange.mobile} {
    margin-top: 5px;
    mask-image: radial-gradient(rgb(0 0 0) 50%, rgba(0, 0, 0, 0) 70%);
    background: radial-gradient(rgb(255, 255, 255) 60%, rgb(242, 243, 245) 70%);
  }
`;

export const LandingGroupFaceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: -65px;
  position: absolute;
  z-index: 50;
  transform: null;
  background-color: white;
  box-shadow: 2px 2px 25px rgb(107 201 219 / 20%);
`;

export const ImageExchangeBlockContanerWrapper = styled.div`
  margin-top: 52px;
  margin-right: 0px;
  width: "fit-content";
  height: fit-content;

  @media ${deviceRange.mobile} {
    margin-top: 16px;
    align-self: center;
  }
`;
export const LongHorizontalArrowImageWrapper = styled.div`
  margin-top: 38px;
  margin-left: -105px;
  margin-right: 0px;
  width: "fit-content";
  height: fit-content;

  @media ${deviceRange.mobile} {
    margin-right: 75px;
    margin-left: -170px;
  }
`;

export const LandingPenImageWrapper = styled.div`
  margin-top: -150px;
  margin-left: 10px;
  margin-right: 0px;
  width: "fit-content";
  height: fit-content;

  @media ${deviceRange.laptop} {
  }
`;
export const LandingPenImageLikeWrapper = styled.div`
  margin-top: 50px;
  margin-left: -30px;
  margin-right: 0px;
  width: "fit-content";
  height: fit-content;
`;
export const ImageWebPageExchangeBlockContanerWrapper = styled.div`
  margin-top: 52px;
  margin-left: 13px;
  margin-right: 0px;
  width: "fit-content";
  height: fit-content;

  @media ${deviceRange.mobile} {
    margin-left: 0px;
    align-self: center;
  }
`;
export const ImagePromoBlockSecondContanerWrapper = styled.div`
  margin-left: -278px;
  margin-top: -60px;
  margin-top: 52px;
  margin-left: 13px;
  margin-right: 0px;
  width: "fit-content";
  height: fit-content;
`;
export const ImagePeopleWithSkateWrapper = styled.div`
  margin-right: 0px;
  width: "fit-content";
  height: fit-content;

  @media ${deviceRange.tablet} {
  }

  @media ${deviceRange.mobile} {
    mask-image: radial-gradient(rgb(0 0 0) 50%, rgba(0, 0, 0, 0) 70%);
    background: radial-gradient(rgb(255, 255, 255) 60%, rgb(242, 243, 245) 70%);
  }
`;

export const ImageExchangeContanerLandingWrapper = styled.div`
  margin-top: 60px;
  margin-left: 13px;
  margin-right: 0px;
  width: "fit-content";
  height: fit-content;

  @media ${deviceRange.mobile} {
    margin: 0px;
  }
`;
export const ImagePromoBlockContanerWrapper = styled.div`
  margin-top: 15px;
  margin-left: 13px;
  margin-right: 0px;
  width: 90px;
  height: 100px;
  transform: rotate(25deg);

  @media ${deviceRange.mobile} {
    transform: rotate(0deg);
    margin: 0px;
    width: auto;
    height: auto;
  }
`;

export const QuestionItem = styled.div`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #425262;
  padding-left: 43px;
  padding-right: 90px;
  margin-top: 35px;

  @media ${deviceRange.laptopL} {
    font-size: 17px;
  }

  @media ${deviceRange.laptopM} {
    font-size: 16px;
  }

  @media ${deviceRange.laptop} {
    font-size: 15px;
  }

  @media ${deviceRange.tablet} {
    padding: 0;
    margin-left: 20px;
    font-size: 14px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const FaqItemsWrapper = styled.div`
  width: 100%;
`;
export const FaqItemText = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 33px;
  line-height: 45px;
  color: rgba(66, 82, 98, 0.5);
  font-size: "36px";
  width: 100%;
  margin-right: 5px;
  margin-left: 0px;
  text-align: center;

  @media ${deviceRange.laptop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.tablet} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 28px;
    text-align: left;
    overflow-wrap: anywhere;
  }
`;
interface FaqItemsBlockProps {
  isFull?: boolean;
}
export const FaqItemsBlock = styled.div<FaqItemsBlockProps>`
  margin: auto;
  width: 100%;
  max-width: 1166px;
`;
export const LandingCardImage = styled.img`
  width: 486px;
  height: 232px;
  @media ${deviceRange.laptop} {
    width: 448px;
    height: 213px;
  }

  @media ${deviceRange.tablet} {
    width: 330px;
    height: 156.94px;
  }

  @media ${deviceRange.mobile} {
    width: 288px;
    height: 138px;
  }
`;
export const LandingImageGirl = styled.img`
  width: 190px;
  height: 230px;
  @media ${deviceRange.laptop} {
    width: 123px;
    height: 153px;
  }

  @media ${deviceRange.tablet} {
    width: 89.43px;
    height: 111.51px;
  }

  @media ${deviceRange.mobile} {
    width: 89px;
  }
`;
export const LandingImageTarget = styled.img`
  @media ${deviceRange.laptopM} {
    width: 200px;
    height: 200px;
  }

  @media ${deviceRange.laptopL} {
    width: 200px;
    height: 200px;
  }

  @media ${deviceRange.laptop} {
    width: 150px;
    height: 150px;
  }

  @media ${deviceRange.tablet} {
    width: 115px;
    height: 115px;
    margin-top: 30px;
  }
`;
export const LandingIconQuestion = styled.img`
  width: 58%;
  height: 58%;
  display: block;
`;
export const LandingPenImage = styled.img`
  @media ${deviceRange.laptop} {
    width: 190px;
    height: 190px;
  }

  @media ${deviceRange.tablet} {
    width: 130px;
    height: 130px;
  }

  @media ${deviceRange.mobile} {
    width: 109px;
  }
`;
export const LandingIconMark = styled.img`
  width: 58%;
  height: 58%;
  display: block;
`;
export const LandingMeanImage = styled.img`
  @media ${deviceRange.laptop} {
    width: 64px;
    height: 76px;
  }

  @media ${deviceRange.tablet} {
    width: 75px;
    height: 100px;
  }
  @media ${deviceRange.mobile} {
    width: 50px;
  }
`;
export const LandingMean = styled.img`
  @media ${deviceRange.laptopM} {
    width: 120px;
    height: 100px;
  }

  @media ${deviceRange.laptopL} {
    width: 120px;
    height: 100px;
  }

  @media ${deviceRange.laptop} {
    width: 100px;
    height: 90px;
  }

  @media ${deviceRange.tablet} {
    width: 75px;
    height: 60px;
  }

  @media ${deviceRange.mobile} {
    width: 60px;
  }
`;
export const LandingImageLike2 = styled.img`
  @media ${deviceRange.laptopM} {
  }
  @media ${deviceRange.laptopL} {
  }
  @media ${deviceRange.laptop} {
    width: 80px;
    height: 90px;
  }
  @media ${deviceRange.desktop} {
    margin-left: 40px;
  }
  @media ${deviceRange.tablet} {
    width: 91px;
    height: 108px;
  }
`;
export const LandingLikeImage = styled.img`
  @media ${deviceRange.laptopM} {
  }

  @media ${deviceRange.laptopL} {
  }

  @media ${deviceRange.laptop} {
    width: 104px;
    height: 123px;
  }

  @media ${deviceRange.tablet} {
    width: 78px;
    height: 90px;
  }

  @media ${deviceRange.mobile} {
    width: 60px;
  }
`;
export const LandingIconWoker = styled.img`
  width: 58%;
  height: 58%;
  display: block;
`;
export const LandingChem = styled.img`
  width: 58%;
  height: 58%;
  display: block;
`;
export const LandingEllipseChem = styled.img``;
export const LandingIconGroup = styled.img`
  width: 66%;
  height: 56%;
  display: block;
`;
export const LandingGroupFace = styled.img`
  width: 58%;
  height: 58%;
  display: block;
`;
export const LandingLogo = styled.img``;
export const LandingLogoHeader = styled.img`
  @media ${deviceRange.mobile} {
    width: 140px;
    height: 18px;
  }
`;
export const LandingArrow1 = styled.img``;
export const LandingIcon65 = styled.img`
  width: 60%;
  height: 60%;
  display: block;
  @media ${deviceRange.mobile} {
  }
`;
export const LandingIconMans = styled.img`
  width: 58%;
  height: 58%;
  display: block;
  @media ${deviceRange.mobile} {
  }
`;
export const LandingIconGirl = styled.img`
  width: 60%;
  height: 60%;
  display: block;
  @media ${deviceRange.mobile} {
  }
`;
export const LandingIconMans2 = styled.img``;
export const LandingIconGirl2 = styled.img``;
export const PromoLandingArrow1 = styled.img``;
export const LandingImageWifi = styled.img`
  width: 90px;
  height: 90px;
`;
export const LandingImageLightBulb = styled.img`
  height: 200px;
  width: 200px;
  @media ${deviceRange.tablet} {
    height: 90px;
    width: 90px;
  }
`;
export const LandingImageCode = styled.img`
  width: 100px;
  height: 80px;
`;
export const LandingFooterlogo = styled.img`
  cursor: pointer;

  @media ${deviceRange.mobile} {
    width: 131px;
  }
`;
export const LandingInsta = styled.img``;
export const LandingFacebook = styled.img``;
export const LandingYoutube = styled.img``;
export const LandingVector = styled.img``;

export const LandingFinanceLogo = styled.img`
  display: block;
  width: 300px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  @media ${deviceRange.laptop} {
    width: 60%;
  }

  @media ${deviceRange.tablet} {
    width: 60%;
  }

  @media ${deviceRange.mobile} {
    width: 140px;
  }
`;

export const FirstImage = styled.img`
  width: 100%;
  height: 100%;

  @media ${deviceRange.tablet} {
    position: absolute;
    right: -60px;
    z-index: 1;
    top: 100px;
    width: 530.55px;
    height: 585px;
  }
`;
export const LandingArrow4 = styled.img`
  width: 70%;
  height: 100%;

  @media ${deviceRange.tablet} {
    width: 60%;
    height: 100%;
    margin-left: 120px;
  }
`;
export const ImageWebPage = styled.img`
  width: 100%;
  height: 100%;

  @media ${deviceRange.tablet} {
    width: 330px;
    height: 193px;
  }

  @media ${deviceRange.mobile} {
    width: 290px;
  }
`;
export const ArrowPromoBlockContaner = styled.img`
  width: 100%;
  height: 100%;

  @media ${deviceRange.tablet} {
    width: 80%;
    height: 80%;
  }

  @media ${deviceRange.mobile} {
    width: auto;
    height: auto;
  }
`;
export const ImagePeopleWithSkate = styled.img`
  width: 100%;
  height: 100%;
`;
export const LandingArrowImage = styled.img`
  width: 100%;
  height: 100%;

  @media ${deviceRange.tablet} {
    width: 80%;
    height: 80%;
  }
`;
export const PromoBlockSecondContaner = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  background-color: white;

  @media ${deviceRange.mobile} {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 410px;
  }
`;

interface PromoBlockForSecondContainerProps {
  maxWidth?: number;
}

export const PromoBlockForSecondContainer = styled.div<
  PromoBlockForSecondContainerProps
>`
  display: flex;
  flex-direction: column;
  max-width: ${props => (props.maxWidth ? props.maxWidth + "px;" : "720px;")};
  margin-top: 0px;
  margin-right: 100px;
`;
export const PromoBlockSecondContainer = styled.div<
  PromoBlockForSecondContainerProps
>`
  display: flex;
  flex-direction: column;
  max-width: 490px;
  margin-top: 0px;
  margin-right: 100px;

  @media ${deviceRange.mobile} {
    margin: auto;
  }
`;
export const QuestionManImage = styled.img`
  width: 100%;
  height: 100%;
`;
export const ManWithComputerImage = styled.img`
  width: 360px;
  height: 620px;

  @media ${deviceRange.laptop} {
    width: 300px;
    height: 500px;
  }

  @media ${deviceRange.tablet} {
    display: none;
  }

  @media ${deviceRange.mobile} {
    width: 162px;
    height: 280px;
  }
`;
export const SimpleImageWrapper = styled.div`
  width: 100%;
  margin-top: -135px;
`;

export const ServicesBlockText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 20px;
  max-width: 345px;

  @media ${deviceRange.desktop} {
    margin-left: 40px;
    margin-right: 40px;
  }

  @media ${deviceRange.laptopL} {
    margin-left: 40px;
    margin-right: 40px;
  }

  @media ${deviceRange.mobile} {
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 410px;
  }
`;

export const FinanceBlockTextWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;

  @media ${deviceRange.laptop} {
    width: 40vw;
  }
`;

export const PromoBlockContanerWrapper = styled.div`
  height: 100px;
  width: 300px;

  @media ${deviceRange.mobile} {
    width: auto;
    max-width: 250px;
    height: auto;
  }
`;
export const PromoTextBeforeButtonText = styled.div`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @media ${deviceRange.mobile} {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 30px;
  }
`;

export const PromoButtonStartWrapper = styled.div`
  @media ${deviceRange.mobile} {
    margin-right: 80px;
  }
`;

export const BlockCardAchievements = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${deviceRange.tablet} {
    margin-bottom: 10px;
  }

  @media ${deviceRange.mobile} {
    margin-right: 10px;
    margin-left: 10px;
  }
`;

export const BlockWithCardAchievements = styled.div<BlockWithArrowCardProps>`
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 54px;
  padding-top: 110px;
  background: #ffffff;
  box-shadow: 4px 4px 25px rgba(107, 201, 219, 0.25);
  border-radius: 25px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #425262;
  margin-top: ${props => (props.marginTop ? props.marginTop + "px" : "0px")};
  width: ${props => (props.width ? props.width + "px" : "390px")};
  height: ${props => (props.height ? props.height + "px" : "250px")};

  @media ${deviceRange.tablet} {
    padding-left: 26px;
    padding-right: 26px;
    padding-bottom: 24px;
    padding-top: 75px;
    height: 190px;
  }

  @media ${deviceRange.mobile} {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0px;
    padding-top: 70px;
    width: 250px;
    height: 150px;
    margin-top: -80px;
    margin-bottom: 30px;
  }
`;
