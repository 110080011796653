import styled from "styled-components";
import { Fonts } from "../../../environment";

export const ProgressBarContainer = styled.div`
  width: 100%;
  max-width: 260px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  height: 70px;
`;

export const ProgressBarScaleContainerTitle = styled.p`
  font-family: ${Fonts.SFPTextRegular};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 8px;
`;

export const ProgressBarScaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 25px;
`;

export const ProgressBarButtonsContainer = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
interface ProgressBarBaseButtonProps {
  marginTop?: boolean;
}

export const ProgressBarBaseButton = styled.button<ProgressBarBaseButtonProps>`
  width: 85px;
  height: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  ${props => (props.marginTop ? "margin-top: 30px;" : null)}
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export const EllipseContainer = styled.div`
  width: 72px;
  height: 72px;
  padding: 12px;
  box-shadow: 0px 0px 10px rgba(107, 201, 219, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PagesSpan = styled.span`
  position: absolute;
  font-size: 14px;
`;
