import React from "react";

import { DefaultLoader } from "./";
interface Props {
  children: React.ReactNode;
  isLoading: boolean;
}

export function WrapperLoader({ children, isLoading }: Props) {
  return <>{isLoading ? <DefaultLoader /> : children}</>;
}
