import gql from "graphql-tag";

export const HandoverFragment = gql`
  fragment HandoverFragment on Handover {
    id
    docTemplate
    chapters {
      id
      template
      name
      helpmanText
      chapterType
      helpmanIconUrl
    }
    docIndents
  }
`;
