import React from "react";
import { ButtonPDF, ButtonPDFWithoutBackground } from "./BaseButtonModal";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  ContentBlockWrapper
} from "./Modals.style";

export const ModalContent5 = () => {
  return (
    <>
      <ContentBlockWrapper>
        <ContentBlock>
          <LeftBlockContent>
            <TextTitleWrapper>
              <TextTitle>För vem:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>Processledare</SecondText>
            </TextWrapper>
            <TextTitleWrapper>
              <TextTitle>Hur dokumentet kan användas:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Efter att en ny överlämningsrutin tagits fram behöver den
                utvärderas. Lämna detta utvärderingsformulär de till se som
                genomgått en muntlig överlämning.
              </SecondText>
            </TextWrapper>
          </LeftBlockContent>
          <RightBlockContent>
            <TextTitleWrapper>
              <TextTitle>Beskrivning:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Dokumentet innehåller en färdig utvärdering för organisationens
                överlämningsrutin.
              </SecondText>
            </TextWrapper>
          </RightBlockContent>
        </ContentBlock>
      </ContentBlockWrapper>
      <FooterModal notAliginSelf={1}>
        <LeftBlockContent>
          <ButtonPDFWithoutBackground
            downLink={
              "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Utvärdering av överlämningsrutin ifyllbar PDF.pdf"
            }
          />
        </LeftBlockContent>
        <RightBlockContent>
          <ButtonPDF
            downLink={
              "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Utvärdering av överlämning.pdf"
            }
          />
        </RightBlockContent>
      </FooterModal>
    </>
  );
};
