import styled from "styled-components";
import { deviceRange } from "../../consts";
import { Fonts } from "../../environment/theme/Fonts";

export const FirstPage = styled.div`
  display: flex;
  margin: auto;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  padding-top: 60px;
  flex-direction: column;
`;

export const MainTextWrapper = styled.div`
  display: flex;
  margin-top: 100px;
  width: 90%;
`;

export const MainText = styled.h1`
  font-family: ${Fonts.landingOpenSansRegular};
  font-family: "Concert One";
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  line-height: 60px;
  text-align: center;
  color: #6bc9db;

  @media ${deviceRange.laptop} {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${deviceRange.tablet} {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${deviceRange.mobile} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const MainTextBeforeCard = styled.h1`
  font-family: ${Fonts.landingOpenSansRegular};
  font-family: "Concert One";
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  line-height: 60px;
  text-align: center;
  color: #6bc9db;

  @media ${deviceRange.laptop} {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${deviceRange.tablet} {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 32px;
  }
`;

export const MainTextThirdPage = styled.h2`
  font-family: ${Fonts.landingOpenSansRegular};
  font-family: "Concert One";
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  line-height: 60px;
  text-align: center;
  color: #6bc9db;

  @media ${deviceRange.laptop} {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${deviceRange.tablet} {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 32px;
    width: 70%;
    text-align: center;
  }
`;

export const MormaTextWrapper = styled.div`
  width: 90%;
  display: flex;
  margin-top: 40px;
`;
interface NormalTextProps {
  textAlign: string;
}
export const NormalText = styled.p<NormalTextProps>`
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: ${props => props.textAlign};

  @media ${deviceRange.laptop} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ImageFirstPage = styled.img`
  position: absolute;
  top: 13%;

  @media ${deviceRange.tablet} {
    width: 100%;
  }
  @media ${deviceRange.mobile} {
    position: relative;
    max-width: 100vw;
  }
`;

export const SecondPage = styled.div`
  width: 100%;
  background-color: #6bc9db;
  display: flex;
  margin: auto;
  margin-top: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 60px;
  flex-direction: column;

  @media ${deviceRange.mobile} {
    margin-top: 0px;
    justify-content: flex-start;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 45px;
  }
`;

export const SecondTextWrapper = styled.div`
  display: flex;
  width: 870px;

  @media ${deviceRange.tablet} {
    max-width: 627px;
  }

  @media ${deviceRange.mobile} {
    width: 90%;
  }
`;

export const BlackMainText = styled.h2`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  color: #425262;

  @media ${deviceRange.laptop} {
    font-size: 36px;
    line-height: 45px;
  }

  @media ${deviceRange.tablet} {
    font-size: 36px;
    line-height: 45px;
  }

  @media ${deviceRange.mobile} {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const SecondContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media ${deviceRange.mobile} {
    margin-top: 32px;
    min-height: 360px;
    overflow: hidden;
  }
`;

export const SecondLeftBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media ${deviceRange.mobile} {
    width: 84%;
  }
`;

export const SecondLeftContentWrapper = styled.div`
  margin-top: 55px;
  margin-right: 75px;

  @media ${deviceRange.laptop} {
    margin-right: 40px;
  }

  @media ${deviceRange.mobile} {
    margin-top: 0px;
    margin-right: 0px;
  }
`;

export const SecondHat = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  margin-left: 14px;
  @media ${deviceRange.mobile} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-left: 0px;
    width: 85%;
  }
`;

export const WrapperForTextElement = styled.div`
  @media ${deviceRange.mobile} {
    justify-content: center;
    display: flex;
  }
`;

export const SecondTextMainWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  margin-right: 15px;
`;

export const SecondImageWrapper = styled.div`
  display: flex;
`;

export const ImageSecondPage = styled.img`
  width: 135px;

  @media ${deviceRange.laptop} {
    width: 95px;
  }

  @media ${deviceRange.tablet} {
    width: 75px;
  }

  @media ${deviceRange.mobile} {
    width: 65px;
    height: 65px;
  }
`;

export const SecondWhitePlaceForTextLeft = styled.div`
  padding: 10px;
  width: 462px;
  background: #ffffff;
  border: 5px solid #c8e9ef;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.96);
  border-radius: 20px;

  @media ${deviceRange.laptop} {
    width: 370px;
  }

  @media ${deviceRange.tablet} {
    width: 332px;
  }

  @media ${deviceRange.mobile} {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
    padding: 16px;
    width: 90%;
  }
`;

export const SecondWhitePlaceForTextRightOne = styled.div`
  padding: 10px;
  width: 456px;
  background: #ffffff;
  border: 5px solid #c8e9ef;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.96);
  border-radius: 20px;

  @media ${deviceRange.laptop} {
    width: 335px;
  }

  @media ${deviceRange.tablet} {
    width: 330px;
  }

  @media ${deviceRange.mobile} {
    width: 90%;
    margin-top: 20px;
  }
`;

export const SecondWhitePlaceForTextRightTwo = styled.div`
  padding: 10px;
  width: 356px;
  background: #ffffff;
  border: 5px solid #c8e9ef;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.96);
  border-radius: 20px;

  @media ${deviceRange.laptop} {
    width: 290px;
  }

  @media ${deviceRange.tablet} {
    width: 270px;
  }
`;

export const SecondRightBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BlockExchangeButtons = styled.div`
  margin: auto;
  width: 65px;
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${deviceRange.mobile} {
    width: 120px;
    overflow: hidden;
    padding-bottom: 50px;
  }
`;

export const SecondRightContentWrapperOne = styled.div`
  margin-top: 87px;
  margin-left: 115px;

  @media ${deviceRange.laptop} {
    margin-left: 55px;
  }

  @media ${deviceRange.tablet} {
    margin-top: 40px;
    margin-left: 0px;
  }

  @media ${deviceRange.mobile} {
    margin-top: 0px;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const SecondRightContentWrapperTwo = styled.div`
  margin-top: 71px;
  margin-left: 35px;
  margin-bottom: 85px;

  @media ${deviceRange.laptop} {
    margin-top: 51px;
    margin-left: 15px;
    margin-bottom: 55px;
  }

  @media ${deviceRange.tablet} {
    margin-top: 45px;
    margin-left: 0px;
    margin-bottom: 45px;
  }
`;

export const ThirdPage = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: 135px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media ${deviceRange.tablet} {
    margin-top: 75px;
    overflow: hidden;
  }
`;

export const ThirdMainTextWrapper = styled.div`
  margin-bottom: 45px;

  @media ${deviceRange.mobile} {
    display: flex;
    justify-content: center;
  }
`;

export const ThirdContentWrapper = styled.div`
  width: 100vw;
`;

export const FourthPage = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: 135px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media ${deviceRange.mobile} {
    margin-top: 50px;
    overflow: hidden;
  }
`;

export const FourthMainTextWrapper = styled.div`
  margin-bottom: 45px;
`;

export const FourthContentWrapper = styled.div``;
