import React from "react";

import {
  PageWrapper,
  PageMainContent,
  TermsServiceHeader,
  TermsServiceText,
  TermsServiceTitle,
  TermsServiceTextSub,
  TermsServiceMainTitle
} from "./TermsService.style";
import { useTranslation } from "../../hooks/i18n";
import { AuthHeader } from "../../components/auth";
import { BurgetMenu } from "../../components/BurgetMenu";
import { useViewport } from "../../hooks";
import { deviceSize } from "../../consts";

export const TermsService = () => {
  const translate = useTranslation();
  const width = useViewport();
  return (
    <PageWrapper>
      {width > parseInt(deviceSize.mobile.max) ? (
        <AuthHeader />
      ) : (
        <BurgetMenu unLogged={true} />
      )}

      <PageMainContent>
        <TermsServiceHeader>
          <TermsServiceMainTitle>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.ICMASTermsOfService
            )}
          </TermsServiceMainTitle>
          <TermsServiceTitle>
            1. {translate(({ privacyAndTerms }) => privacyAndTerms.Terms)}
          </TermsServiceTitle>
          <TermsServiceText
            dangerouslySetInnerHTML={{
              __html: translate(
                ({ privacyAndTerms }) => privacyAndTerms.TermsText
              )
            }}
          ></TermsServiceText>

          <TermsServiceTitle>
            2. {translate(({ privacyAndTerms }) => privacyAndTerms.UseLicence)}
          </TermsServiceTitle>
          <TermsServiceTextSub>
            a.{" "}
            {translate(({ privacyAndTerms }) => privacyAndTerms.UseLicenceText)}
          </TermsServiceTextSub>
          <TermsServiceTextSub>
            I.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.UseLicenceText2
            )}
          </TermsServiceTextSub>
          <TermsServiceTextSub>
            II.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.UseLicenceText3
            )}
          </TermsServiceTextSub>
          <TermsServiceTextSub>
            III.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.UseLicenceText4
            )}
          </TermsServiceTextSub>
          <TermsServiceTextSub>
            IV.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.UseLicenceText5
            )}
          </TermsServiceTextSub>
          <TermsServiceTextSub>
            V.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.UseLicenceText6
            )}
          </TermsServiceTextSub>
          <TermsServiceTextSub>
            b.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.UseLicenceTextSub
            )}
          </TermsServiceTextSub>

          <TermsServiceTitle>
            3. {translate(({ privacyAndTerms }) => privacyAndTerms.Disclaimer)}
          </TermsServiceTitle>
          <TermsServiceTextSub>
            a.{" "}
            {translate(({ privacyAndTerms }) => privacyAndTerms.DisclaimerText)}
          </TermsServiceTextSub>
          <TermsServiceTextSub>
            b.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.DisclaimerText2
            )}
          </TermsServiceTextSub>

          <TermsServiceTitle>
            4. {translate(({ privacyAndTerms }) => privacyAndTerms.Limitations)}
          </TermsServiceTitle>
          <TermsServiceTextSub>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.LimitationsText
            )}
          </TermsServiceTextSub>

          <TermsServiceTitle>
            5.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.AccuracyOfMaterials
            )}
          </TermsServiceTitle>
          <TermsServiceTextSub>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.AccuracyOfMaterialsText
            )}
          </TermsServiceTextSub>

          <TermsServiceTitle>
            6. {translate(({ privacyAndTerms }) => privacyAndTerms.Links)}
          </TermsServiceTitle>
          <TermsServiceTextSub>
            {translate(({ privacyAndTerms }) => privacyAndTerms.LinksText)}
          </TermsServiceTextSub>

          <TermsServiceTitle>
            7.{" "}
            {translate(({ privacyAndTerms }) => privacyAndTerms.Modifications)}
          </TermsServiceTitle>
          <TermsServiceTextSub>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.ModificationsText
            )}
          </TermsServiceTextSub>

          <TermsServiceTitle>
            8.{" "}
            {translate(({ privacyAndTerms }) => privacyAndTerms.GoverningLaw)}
          </TermsServiceTitle>
          <TermsServiceTextSub>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.GoverningLawText
            )}
          </TermsServiceTextSub>
        </TermsServiceHeader>
      </PageMainContent>
    </PageWrapper>
  );
};
