import { Chapter, FormFieldData } from "@spridakunskap/api";
import { processedFields } from "./../consts";
export const getfieldsValuesFromChapters = (
  chapters: Chapter[],
  prevfieldsValues: FormFieldData[] = []
): Array<FormFieldData> => {
  const fieldsValues: Array<FormFieldData> = [];

  for (let { template } of chapters) {
    if (!template) continue;

    const templateDataArr = JSON.parse(template);

    for (let {
      name,
      label,
      required = false,
      type,
      value = []
    } of templateDataArr) {
      if (processedFields.indexOf(type) < 0) continue;
      const prevFieldData = prevfieldsValues.find(({ name: fieldName }) => {
        return name === fieldName;
      });

      if (prevFieldData) {
        fieldsValues.push(prevFieldData);
      } else {
        fieldsValues.push({ name, value, label, required, type });
      }
    }
  }

  return fieldsValues;
};
