import React from "react";
import { VideoPopUpWrapp, VideoPopupContent, IntroVideo } from "./Modals.style";
import { ButtonGoogleDocumentWithoutBackground } from "./BaseButtonModal";
import { Videos } from "../../../environment";

export const ModalContent15 = () => {
  return (
    <>
      <VideoPopUpWrapp>
        <VideoPopupContent>
          <IntroVideo controls>
            <source
              src={Videos.implicitAndExplicitKnowledge}
              type="video/mp4"
            />
          </IntroVideo>
        </VideoPopupContent>
      </VideoPopUpWrapp>{" "}
    </>
  );
};
