import React, { useState, useEffect, useRef } from "react";
import {
  BackgroundPopupPdf,
  ContentPopupPdf,
  BlockPreviewButtons,
  TestBlock,
  PopupPdfBody,
  PdfWrapper,
  PdfPageWrapper
} from "./QuillContainer.style";
import { DefaultButton } from "../../components/ui";
import { Document, Page, pdfjs } from "react-pdf";
import { useSendPdfPreview, useMeQuery } from "../../hooks";
import { HandoverFile } from "../../pages";
https: pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PopupPdfProps {
  setShow: () => void;
  source: any;
  indents: string;
  user?: any;
  values?: any;
  preview?: boolean;
  handoverFiles: Array<HandoverFile>;
}

export const PopupPdf = ({
  setShow,
  user,
  source,
  indents,
  values,
  preview = false,
  handoverFiles
}: PopupPdfProps) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [sendPdfFront, { data: sendPdfToConvert }] = useSendPdfPreview();
  const popupRef = useRef<React.ElementRef<"div"> | null>(null);

  const userData = useMeQuery();

  useEffect(() => {
    let value;
    if (values) {
      value = values;
    } else if (!values) {
      value = JSON.parse(user?.draftForm);
    } else {
      value = "[]";
    }

    if (userData.data) {
      const newFieldsValues =
        JSON.parse(userData.data.me.draftForm || "") || value;
      sendPdfFront({
        variables: {
          data: {
            fieldsValues: newFieldsValues,
            template: source,
            indents,
            preview,
            handoverFiles,
            draft: !preview
          }
        }
      });
    }
  }, [userData.data?.me.draftForm]);

  const onDocumentLoadSuccess = ({
    numPages: pagesCount
  }: {
    numPages: number;
  }) => {
    setNumPages(pagesCount);
  };

  const convert = () => {
    if (sendPdfToConvert?.sendPdfPreview) {
      const byteCharacters = atob(`${sendPdfToConvert?.sendPdfPreview}`);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "" });

      return blob;
    }
    return "";
  };

  return (
    <BackgroundPopupPdf>
      <TestBlock onClick={setShow} />
      <ContentPopupPdf ref={popupRef}>
        {sendPdfToConvert && (
          <PdfWrapper>
            <PopupPdfBody>
              <Document
                loading=""
                file={convert()}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <PdfPageWrapper>
                  <Page
                    loading=""
                    pageNumber={pageNumber}
                    width={popupRef.current?.clientWidth! - 90}
                  />
                </PdfPageWrapper>
              </Document>
            </PopupPdfBody>
            <p>
              Sida {pageNumber} av {numPages}
            </p>
            <BlockPreviewButtons>
              <DefaultButton
                title="Föregående"
                onClick={() =>
                  setPageNumber(prev => (prev > 1 ? prev - 1 : prev))
                }
              />
              <DefaultButton title="Stäng" onClick={setShow} />
              <DefaultButton
                title="Nästa"
                onClick={() =>
                  setPageNumber(prev => (prev < numPages ? prev + 1 : prev))
                }
              />
            </BlockPreviewButtons>
          </PdfWrapper>
        )}
      </ContentPopupPdf>
    </BackgroundPopupPdf>
  );
};
