import React from "react";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  ContentBlockWrapper,
  VideoPopUpWrapp,
  VideoPopupContent,
  IntroVideo
} from "./Modals.style";
import { ButtonGoogleDocumentWithoutBackground } from "./BaseButtonModal";
import { Videos } from "../../../environment";

export const ModalContent16 = () => {
  return (
    <>
      <VideoPopUpWrapp>
        <VideoPopupContent>
          <IntroVideo controls>
            <source src={Videos.handoversIntroVideo} type="video/mp4" />
          </IntroVideo>
        </VideoPopupContent>
      </VideoPopUpWrapp>
    </>
  );
};
