import styled from "styled-components";
import { Colors, Fonts } from "../../../environment";
import { SelectSize } from "./Select";

export const SelectContainer = styled.div`
  position: relative;
`;

interface SelectButtonProps {
  open: boolean;
  disabled: boolean;
  size: SelectSize;
}

export const SelectButton = styled.button<SelectButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #6bc9db;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  transition: all 0.2s;
  width: 100%;
  white-space: nowrap;
  opacity: ${props => props.disabled && 0.3};
  padding: ${props =>
    (props.size === SelectSize.SM && ".1rem .8rem;") ||
    (props.size === SelectSize.MD && ".3rem .4rem;") ||
    (props.size === SelectSize.LG && ".7rem .4rem;") ||
    (props.size === SelectSize.XL && "1rem .4rem;")};
  margin-bottom: 16px;
  margin-top: 3px;
  :focus {
    box-shadow: 0px 0px 5px 2px ${Colors.inputFocusColor};
  }
`;

export const SelectButtonTitle = styled.p`
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: black;
  padding: 0 0.8rem;
`;

interface SelectMenuProps {
  open: boolean;
}

export const SelectMenu = styled.div<SelectMenuProps>`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  overflow-y: overlay;
  border-radius: 0.5rem;
  margin: 1rem 0 0 0;
  padding: 0.8rem;
  z-index: 10;
  background-color: ${Colors.white};
  box-shadow: 4px 4px 25px rgb(107 201 219 / 25%);
`;

interface SelectItemProps {
  disabled?: boolean;
}

export const SelectItem = styled.div<SelectItemProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.8rem;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #a1a9b1;
  pointer-events: ${props => props.disabled && "none"};
  opacity: ${props => props.disabled && 0.3};

  &:hover {
    color: #425262;
  }
`;
