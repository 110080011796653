export const Fonts = {
  title: "Kanit Bold",
  heading: "Kanit Regular",
  paragraph: "Kanit Light",
  SFPDisplayBold: "SF Pro Display Bold",
  SFPDisplayRegular: "SF Pro Display Regular",
  SFPTextBold: "SF Pro Text Bold",
  SFPTextRegular: "SF Pro Text Regular",
  landingConcertOneRegular: "Concert One",
  landingOpenSansRegular: "Open Sans",
  landingOpenSansSemibold: "Open Sans Semibold"
};
