import React, {
  useEffect,
  useMemo,
  useReducer,
  createContext,
  Dispatch,
  ReducerAction,
  Reducer,
  useState,
  useRef
} from "react";
import {
  Chapter,
  Handover as HandoverModel,
  FormFieldData,
  MeData,
  FileOutput,
  HandoverData
} from "../../../../api";

import { PageWrapper, HandoverPageContainer } from "./Handover.style";
import { WrapperLoader } from "../../components/ui";
import {
  useBreadcrumbs,
  useNavigation,
  useTranslation,
  useHandoverQuery,
  useUpdateChapterMutation,
  useAlerts,
  useSendHandoverDocumentQuery,
  useMeQuery,
  useUpdateUserAccountDetailsMutation,
  useGetEmbeddedFilesListMutation,
  useCreateDraftChapter
} from "../../hooks";
import {
  Body,
  Navigation,
  SendFormDataModal
} from "./../../components/Handover";
import { v4 as uuidv4 } from "uuid";
import { ChapterTypes, processedFields } from "./../../consts";
import {
  handoverInitialState,
  handoverReducer,
  setActiveChapter,
  setIsLoading,
  setHandover,
  updateHandoverChapter,
  setfieldsValues,
  setChapters,
  setShowSendFormDataModal,
  setCurrentStep,
  setHandoverFile
} from "./Handover.reducer";
import { UserAccount } from "../../../../api";
import { UserAccountDraftForm } from "../../components/userProfile/UserGeneralInfo";
import _debounce from "lodash/debounce";

export const HandoverDispatchContext = createContext<
  Dispatch<ReducerAction<Reducer<any, any>>>
>(() => {});
export function Handover() {
  const [
    {
      activeChapter,
      isLoading,
      handover,
      fieldsValues,
      showSendFormDataModal,
      currentStep,
      handoverFiles
    },
    dispatch
  ] = useReducer(handoverReducer, handoverInitialState);

  const [getEmbeddedFiles] = useGetEmbeddedFilesListMutation();
  const translate = useTranslation();
  const [
    sendHandoverDoc,
    { loading: sendHandoverDocLoading, data: sendHandoverDocData }
  ] = useSendHandoverDocumentQuery();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { routes, history } = useNavigation();
  const { loading, data: handoverData } = useHandoverQuery();
  const [updateChapterData, { data: chapterData }] = useUpdateChapterMutation();
  const { setNotification } = useAlerts();
  const { data: userData } = useMeQuery();
  const [user, setUser] = useState<UserAccount | null>(null);
  const timer = useRef(0);
  const [handoverDocUrl, setHandoverDocUrl] = useState<string>("");

  const inited = useRef(false);
  const [
    updateUserAccountDetails,
    { data: updateUserAccountDetailsData }
  ] = useUpdateUserAccountDetailsMutation();
  const [
    draftChapter,
    { data: draftChapterData, loading: draftChapterloading }
  ] = useCreateDraftChapter();

  useEffect(() => {
    if (updateUserAccountDetailsData) {
      const { user } = updateUserAccountDetailsData.updateUserAccountDetails;
      setUser(user);
    }
  }, [updateUserAccountDetailsData]);

  const getHandoverFilesFromDraft = async (data: MeData) => {
    const embeddedFiles = await getEmbeddedFiles({
      variables: {
        data: {
          id: data.me.id
        }
      }
    });

    if (embeddedFiles.data) {
      embeddedFiles?.data?.getEmbeddedFilesList?.map(
        (embeddedFile: FileOutput) => {
          dispatch(
            setHandoverFile(embeddedFile.fileName, embeddedFile.fieldName)
          );
        }
      );
    }
  };

  useEffect(() => {
    if (userData?.me) getHandoverFilesFromDraft(userData);
  }, [userData]);

  useEffect(() => {
    if (userData?.me) {
      setUser(userData?.me);
    }
  }, [userData]);

  useEffect(() => {
    if (inited.current && handover) {
      timer.current = window.setTimeout(() => {
        const draftChapters = user?.draftChapters || [];
        const formattedChapters = [activeChapter].reduce(
          (res: any, chapter: Chapter) => {
            const draftChapter = (draftChapters as []).find((draft: any) => {
              if (draft && chapter && chapter.id && draft.chapterId)
                return draft.chapterId === chapter.id;
            });

            if (draftChapter) {
              res.update = [
                ...(res.update || []),
                {
                  where: { id: (draftChapter as { id: string }).id },
                  data: {
                    template: chapter.template
                  }
                }
              ];
              return res;
            }
            if (chapter) {
              res.create = [
                ...(res.create || []),
                {
                  chapterId: chapter.id,
                  template: chapter.template
                }
              ];
            }
            return res;
          },
          {}
        );

        handleUpdateUserAccountDetails({
          draftForm: JSON.stringify(fieldsValues),
          draftChapters: {
            create:
              formattedChapters.create && formattedChapters.create.length
                ? formattedChapters.create
                : undefined,
            update:
              formattedChapters.update && formattedChapters.update.length
                ? formattedChapters.update
                : undefined
          }
        });
      }, 700);

      return () => {
        clearTimeout(timer.current);
      };
    }
  }, [fieldsValues, activeChapter]);

  const setfieldsValuesFront = (fieldValues: Array<FormFieldData> = []) => {
    dispatch(setfieldsValues(fieldValues));
  };

  const setChaptersFront = (chapters: any) => {
    dispatch(setChapters(chapters));
  };

  useEffect(() => {
    if (handover && user && !inited.current) {
      const values = JSON.parse(user.draftForm || "[]" || fieldsValues);
      const updatedChapters = (handover.chapters || []).map(
        (chapter: Chapter) => {
          const draftChapter = ((user.draftChapters || []) as []).find(
            (draft: any) => draft.chapterId === chapter.id
          );
          if (draftChapter) {
            return {
              ...chapter,
              template: (draftChapter as { template: string }).template
            };
          }
          return chapter;
        }
      );
      setfieldsValuesFront(
        getfieldsValuesFromChapters(updatedChapters, values)
      );
      setChaptersFront(updatedChapters);
      dispatch(setActiveChapter(updatedChapters[0]));

      inited.current = true;
    }
  }, [handover, user]);

  const handleUpdateUserAccountDetails = (
    userAccountDetails: UserAccountDraftForm
  ) => {
    if (user) {
      updateUserAccountDetails({
        variables: {
          data: {
            userAccountWhereUniqueInput: { id: user.id },
            draftForm: userAccountDetails.draftForm,
            draftChapters: userAccountDetails.draftChapters
          }
        }
      });
    }
  };

  const receiverName = useMemo(() => {
    const foundField = fieldsValues.find((field: any) => {
      return ["recciver_full_name"].includes(field.name);
    });

    return foundField || { value: [] };
  }, [fieldsValues]);

  const receiverEmail = useMemo(() => {
    const foundField = fieldsValues.find((field: any) => {
      return ["recciver_email", "Epost-mottagare", "epost-mottagare"].includes(
        field.name
      );
    });

    return foundField || { value: [] };
  }, [fieldsValues]);

  // useEffectOnce(() => {
  //   const breadcrumbsData = [
  //     {
  //       label: translate(({ handoversPage }) => handoversPage.handovers),
  //       to: routes.handovers
  //     },
  //     {
  //       label: translate(({ handoverPage }) => handoverPage.handover),
  //       to: routes.handover
  //     }
  //   ];

  //   setBreadcrumbs(breadcrumbsData);
  // });

  useEffect(() => {
    if (chapterData) {
      updateChapter(chapterData.updateChapter);
    }
  }, [chapterData]);

  useEffect(() => {
    if (handoverData) {
      const isExist =
        localStorage.getItem("ChapterType") !== undefined
          ? localStorage.getItem("ChapterType")
          : ChapterTypes.Regular;

      const typeFromLocalStorage = JSON.parse(
        isExist !== null ? isExist : ChapterTypes.Regular
      );

      const FilterHandover: HandoverData = JSON.parse(
        JSON.stringify(handoverData)
      );

      FilterHandover.handover.chapters = FilterHandover.handover.chapters.filter(
        chapter => chapter.chapterType === typeFromLocalStorage
      );

      dispatch(setHandover(FilterHandover.handover));

      const chapters = FilterHandover.handover.chapters;

      if (chapters.length) {
        dispatch(setActiveChapter(chapters[0]));
      }

      dispatch(setIsLoading(false));
    }
  }, [handoverData]);

  const saveDraftChapter = async (handoverUrl: string) => {
    await draftChapter({
      variables: {
        data: {
          id: uuidv4().slice(0, 25),
          recipientName: receiverName.value[0] || "",
          recipientEmail: receiverEmail.value[0] || "",
          template: JSON.stringify(handover.chapters),
          savedForm: JSON.stringify(fieldsValues),
          chapterId: user?.id,
          dowloadUrl: handoverUrl
        }
      }
    });
  };

  useEffect(() => {
    if (sendHandoverDocData) {
      setNotification({
        message: translate(({ messages }) => messages.documentSent)
      });
      dispatch(setfieldsValues(getfieldsValuesFromChapters(handover.chapters)));
      dispatch(setShowSendFormDataModal(false));

      saveDraftChapter(sendHandoverDocData.sendHandoverDocument);
      // handleUpdateUserAccountDetails({
      //   draftForm: ""
      // });
      history.push(routes.thankYou);
    }
  }, [sendHandoverDocData]);

  const updateChapter = (newChapter: Chapter) => {
    dispatch(updateHandoverChapter(newChapter));
    dispatch(setActiveChapter(newChapter));
  };

  const updateChapterTemplate = async (
    chapter: Chapter | null,
    template: string,
    chapterType: string,
    cb?: () => void
  ) => {
    if (!chapter) return;

    await updateChapterData({
      variables: {
        data: {
          chapterWhereUniqueInput: { id: chapter.id },
          chapterUpdateInput: { template },
          userAccountWhereUniqueInput: { id: userData?.me.id },
          currentStep: 0,
          chapterType: chapterType
        }
      }
    });
    if (cb) {
      cb();
    }
  };

  const chapterTemplateSteps = useMemo(() => {
    if (!activeChapter) {
      return activeChapter;
    }

    const template = JSON.parse(activeChapter.template || "[]");
    const step = [];
    const steps = [];

    for (let i = 0; i < template.length; i++) {
      const isLastElement = i === template.length - 1;

      if (template[i].type === "endOfStep" || isLastElement) {
        step.push(template[i]);
        steps.push([...step]);
        step.length = 0;
        continue;
      }

      step.push(template[i]);
    }

    return steps;
  }, [activeChapter?.template]);

  const onButtonSendClick = async (emails: Array<any>) => {
    const allEmails = [];
    for (const email in emails) {
      allEmails.push(emails[email].email);
    }
    allEmails.push(userData?.me.email);

    await sendHandoverDoc({
      variables: {
        data: {
          recievers: allEmails,
          fieldsValues: fieldsValues,
          handoverFiles: handoverFiles
        }
      }
    });
  };

  return (
    <WrapperLoader isLoading={isLoading}>
      <PageWrapper>
        {/* <PageTopBarContainer>
          <PageTopBarItem>
            <Breadcrumbs />
          </PageTopBarItem>
        </PageTopBarContainer> */}
        <HandoverPageContainer>
          <Navigation
            chapters={handover?.chapters || []}
            handoverId={handover?.id || ""}
            activeChapter={activeChapter}
            updateHandover={(handover: HandoverModel | null) =>
              dispatch(setHandover(handover))
            }
            setActiveChapter={(chapter: Chapter) => {
              dispatch(setCurrentStep(0));
              dispatch(setActiveChapter(chapter));
            }}
          />
          <HandoverDispatchContext.Provider value={dispatch}>
            <Body
              user={userData?.me}
              fieldsValues={fieldsValues}
              chapter={activeChapter}
              handoverFiles={handoverFiles}
              chapterTemplateSteps={chapterTemplateSteps}
              step={currentStep}
              handover={handover}
              updateChapterTemplate={(chapterTemplate, chapterType) => {
                updateChapterTemplate(
                  activeChapter,
                  chapterTemplate,
                  chapterType
                );
              }}
              onUpdateChapter={(chapter, chapterType, cb?: () => void) => {
                if (chapter?.template) {
                  updateChapterTemplate(
                    chapter,
                    chapter.template,
                    chapterType,
                    cb
                  );
                }
              }}
              setfieldsValuesFront={setfieldsValuesFront}
            />
          </HandoverDispatchContext.Provider>
        </HandoverPageContainer>
      </PageWrapper>

      {showSendFormDataModal && (
        <SendFormDataModal
          receiverEmail={receiverEmail.value[0] || ""}
          showModal={showSendFormDataModal}
          isLoading={sendHandoverDocLoading}
          setShowModal={showModal =>
            dispatch(setShowSendFormDataModal(showModal))
          }
          onClickSendButton={onButtonSendClick}
        />
      )}
    </WrapperLoader>
  );
}

export const getfieldsValuesFromChapters = (
  chapters: Chapter[],
  prevfieldsValues: FormFieldData[] = []
): Array<FormFieldData> => {
  const fieldsValues: Array<FormFieldData> = [];

  for (let { template } of chapters) {
    if (!template) continue;

    const templateDataArr = JSON.parse(template);

    for (let {
      name,
      label,
      required = false,
      type,
      value = []
    } of templateDataArr) {
      if (processedFields.indexOf(type) < 0) continue;
      const prevFieldData = prevfieldsValues.find(
        ({ name: fieldName }) => name === fieldName
      );

      if (prevFieldData) {
        fieldsValues.push(prevFieldData);
      } else {
        fieldsValues.push({ name, value, label, required, type });
      }
    }
  }

  return fieldsValues;
};
