import { String } from "./../../../../../api/src/generated/prisma-client/index";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  MeData,
  PasswordIsSetData,
  PasswordIsSetVariables,
  VerifyEmailInputData,
  SendVerificationCodeData,
  ForgotPasswordData
} from "../../../../../api";
import gql from "graphql-tag";

const ME_QUERY = gql`
  {
    me {
      id
      email
      firstName
      lastName
      updatedAt
      createdAt
      role
      organisationColor
      organisationColor
      avatar {
        id
        name
        url
      }
      organisationLogo {
        id
        name
        url
      }
      draftForm
      draftChapters {
        id
        chapterId
        template
      }
    }
  }
`;

const WORKSPACES_QUERY = gql`
  query workspacesQuery {
    me {
      preferences {
        activeWorkspace {
          ...ActiveWorkspace
        }
      }
      workspaces {
        ...Workspaces
      }
    }
  }
`;

const IS_PASSWORD_SET_QUERY = gql`
  query PasswordIsSet($email: String!) {
    passwordIsSet(email: $email) {
      isSet
    }
  }
`;
const GET_DRAFT_CHAPTER_QUERY = gql`
  query GetHistory($chapterId: String) {
    getTransmitionHistory(chapterId: $chapterId) {
      id
      updatedAt
      recipientEmail
      recipientName
      dowloadUrl
    }
  }
`;
const SEND_VERIFICATION_CODE_QUERY = gql`
  query SendVerificationCode($email: String!) {
    sendVerificationCode(email: $email) {
      token
    }
  }
`;

const VERIFY_EMAIL_QUERY = gql`
  query VerifyEmail($data: VerifyEmailInput!) {
    verifyEmail(data: $data)
  }
`;

const FORGOT_PASSWORD_QUERY = gql`
  query forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export function useMeQuery() {
  return useQuery<MeData>(ME_QUERY, {
    fetchPolicy: "no-cache"
  });
}

export function useTransmitionHistory() {
  return useLazyQuery<any>(GET_DRAFT_CHAPTER_QUERY, {
    fetchPolicy: "no-cache"
  });
}

export function useGetMe() {
  return useLazyQuery<MeData>(ME_QUERY, {
    fetchPolicy: "no-cache"
  });
}

export function useWorkspacesQuery() {
  return useQuery<MeData>(WORKSPACES_QUERY);
}

export function useIsPasswordSetQuery() {
  return useLazyQuery<PasswordIsSetData, PasswordIsSetVariables>(
    IS_PASSWORD_SET_QUERY,
    {
      fetchPolicy: "no-cache"
    }
  );
}

export function useVerifyEmail() {
  return useLazyQuery<VerifyEmailInputData>(VERIFY_EMAIL_QUERY, {
    onError: () => {}
  });
}

export function useSendVerificationCode() {
  return useLazyQuery<SendVerificationCodeData>(SEND_VERIFICATION_CODE_QUERY, {
    onError: () => {}
  });
}

export function useForgotPasswordQuery() {
  return useLazyQuery<ForgotPasswordData>(FORGOT_PASSWORD_QUERY, {
    onError: () => {}
  });
}
