import React, { useState } from "react";
import {
  ButtonPDF,
  ButtonStep,
  GoogleButton,
  Instruction
} from "./BaseButtonModal";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  Highlighter,
  ContentBlockWrapper
} from "./Modals.style";

export const ModalContent3 = () => {
  const [step, setStep] = useState<number>(0);
  const changeStep = (id: number) => setStep(id);

  return (
    <>
      {step === 0 && (
        <ContentBlockWrapper>
          <ContentBlock>
            <LeftBlockContent>
              <TextTitleWrapper>
                <TextTitle>För vem:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>Processledare, överlämnare</SecondText>
              </TextWrapper>
              <TextTitleWrapper>
                <TextTitle>Hur dokumentet kan användas:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>
                  Använd i samband med att ni tar fram en överlämningsrutin och
                  gärna som en bilaga till
                  <Highlighter>
                    {" "}
                    En steg-för steg-metod på hur en muntlig överlämningsrutin
                    kan tas fram.{" "}
                  </Highlighter>
                  Använd dokumentet som det är eller redigera det utifrån vad
                  som passar er organisation.
                </SecondText>
              </TextWrapper>
            </LeftBlockContent>
            <RightBlockContent>
              <TextTitleWrapper>
                <TextTitle>Beskrivning:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>
                  Använd dokumentet för att få inspiration till vilka ämnen ni
                  kan ta upp vid muntliga överlämningar. Använd dokumentet som
                  det är eller öppna det i Google Docs för att redigera det.
                </SecondText>
              </TextWrapper>
            </RightBlockContent>
          </ContentBlock>
        </ContentBlockWrapper>
      )}
      {step === 1 && <Instruction />}
      <FooterModal notAliginSelf={step ? 0 : 1}>
        {step === 0 && (
          <>
            <LeftBlockContent>
              <ButtonStep changeStep={changeStep} />
            </LeftBlockContent>
            <RightBlockContent>
              <ButtonPDF
                downLink={
                  "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Ämnen att gå igenom vid muntlig överlämning ifyllbar PDF.pdf"
                }
              />
            </RightBlockContent>
          </>
        )}
        {step === 1 && (
          <GoogleButton
            downLink={
              "https://docs.google.com/document/d/19kYu3f-56fzcERenTadZaY4lOxQluFtT-cWmyDB0mN8/edit"
            }
          />
        )}
      </FooterModal>
    </>
  );
};
