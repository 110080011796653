import React from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGb from "date-fns/locale/en-GB";
import { LandingDateIcon } from "./DefaultDatePicker.style";
import "./DefaultDatePicker.scss";
import { ToggleInput } from "./ToggleInput";
import { Images } from "../../../environment";

registerLocale("en-gb", enGb);

interface DefaultDatePickerProps {
  startDate: Date;
  setStartDate: (date: Date) => void;
  inputLabel?: string;
  maxDate?: Date;
}

export function DefaultDatePicker({
  startDate,
  setStartDate,
  inputLabel,
  maxDate
}: DefaultDatePickerProps) {
  function handlerWtiteDate(date: Date) {
    const maxPossibleYear = 2500;
    if (date.getFullYear() < maxPossibleYear) {
      setStartDate(date);
    }
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReactDatePicker
        locale="en-gb"
        dateFormat="dd/M/yyyy"
        dateFormatCalendar="MMM yyyy"
        selected={startDate}
        onChange={(date: Date) => {
          handlerWtiteDate(date);
        }}
        calendarClassName={"default-deatepicker-calendar-classname"}
        showPopperArrow={false}
        dayClassName={() => "DayClassName"}
        customInput={
          <ToggleInput
            label={inputLabel}
            onChange={(date: Date) => setStartDate(date)}
          />
        }
        showDisabledMonthNavigation
        maxDate={maxDate}
      />
      <LandingDateIcon src={Images.landingDateIcon} alt="" />
    </div>
  );
}
