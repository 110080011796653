import styled from "styled-components";
import { deviceRange } from "../../../../consts";
import { CustomInput } from "../../../ui/Input";
import { Fonts } from "./../../../../environment/theme/Fonts";

export const Form = styled.form`
  max-width: 662px;
  width: 100%;
  margin: 0 auto;
`;
export const BlockInputs = styled.div`
  display: flex;
  margin-bottom: 18px;

  @media ${deviceRange.mobile} {
    flex-direction: column;
  }
`;
interface InputWrappProps {
  marginRight?: number;
}
export const InputWrapp = styled.div<InputWrappProps>`
  width: 320px;
  ${props =>
    props.marginRight ? "margin-right:" + props.marginRight + "px;" : null}

@media ${deviceRange.mobile}{
    width:100%;
    display:flex;
    justify-content: center
  }
`;

export const ResetPasswordInput = styled(CustomInput)`
  width: 100%;
  max-width: 320px;
  margin-bottom: 0px;

  @media ${deviceRange.mobile} {
    width: 100%;
  }
`;

export const BlockButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${deviceRange.mobile} {
    flex-direction: column;
  }
`;
export const DeleteButtonWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  margin-right: 20px;
  height: fit-content;
  button {
    padding: 10px 0;
  }
`;
interface PromoButtonStartprops {
  marginTop?: number;
  width?: number;
  onClick?: any;
  paddingTop?: number;
  paddingBottom?: number;
  shadowLeft?: number;
  shadowTop?: number;
  marginLeft?: number;
  marginBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  height?: number;
  fontSize?: number;
  widthVW?: number;
  padding?: string;
}

export const UserInfoButton = styled.button<PromoButtonStartprops>`
  min-width:120px;
  background: #cc00a0;
  box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
    ${props => (props.shadowTop ? props.shadowTop + "px" : "6px")} 0px #b2028c;
  border-radius: 80px;
  border: #cc00a0;
  color: #ffffff;
  width: ${props => (props.width ? props.width + "px" : "100%")};
  ${props => (props.widthVW ? `width: ${props.widthVW}vw;` : null)}
  padding: ${props => (props.padding ? props.padding : "10px 35px")};
  font-family: ${Fonts.landingOpenSansSemibold};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 10px;
  margin-right: 40px;
  max-width: 320px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #b2028c;
  }
  &:active {
    box-shadow: -5px -5px 0px #b2028c;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
      ${props =>
        props.shadowTop ? props.shadowTop + "px" : "6px"} 0px #b2028c;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }

  @media ${deviceRange.mobile} {
    max-width: 320px;
    font-size: 11px;
    width: 100%;
    margin-top: 10px;
    margin-right: 0;
  }
`;
