import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef
} from "react";
import { Chapter, FormFieldData, Handover } from "../../../../../api";
import { useGetMe } from "../../../hooks";
import {
  BodyContainer,
  Title,
  FormContainer,
  BodyContent,
  BodyLeftPart,
  BodyRightPart,
  RightBlockButtonsContainer,
  BlockStegWrapper,
  BlockSteg,
  AssistantTextBlock,
  AssistantBlockWrapper,
  AssistantBlockContent,
  BodyLeftPartWrapper,
  ProgresBarAndSendBtnWrapp,
  SendPopupWrapp,
  SendPopupContent,
  ButtonCloseSendPopup,
  ButtonsSendPopupWrapp,
  HelpManWrapp,
  HelpManContent,
  HelpManText,
  HelpManImgWrap,
  ChapterClickBodyButton,
  SuperUserButton,
  ScrollAndFormWrapper,
  ChapterButtonsWrapper,
  MandantoryFields,
  BodyTabletWrapp,
  SendAnswer,
  PreviewButton,
  DropSaveButton,
  SaveAndContinueLater,
  HelpManTextWrapper,
  PopupButtonConfirm,
  DropPopupButton,
  BodyBackgroundBlurPurple,
  BodyBackgroundBlurBlue,
  HelpmanWrapper,
  DiscardButton
} from "./Body.style";
import {
  HandoverDispatchContext,
  setActiveChapter,
  setShowSendFormDataModal,
  removeAllHandoverFiles,
  setCurrentStep,
  HandoverFile
} from "../../../pages/Handover";
import { Drawer, Typography, BlueButton, Icon } from "./../../../components/ui";
import {
  useAlerts,
  useTranslation,
  useUpdateUserAccountDetailsMutation,
  useDeleteEmbeddedFileMutation,
  useViewport,
  useDevice,
  useNavigation
} from "../../../hooks";
import { SuperAdminProtector } from "./../../../components/SuperAdminProtector";
import { FormBuilder } from "./../FormBuilder";
import { GeneratedField } from "./../GeneratedField";
import {
  processedFields,
  FieldTypes,
  deviceSize,
  IconType
} from "../../../consts";
import { Scroll } from "../../../components/ui/AnimatedScroll/Scroll";
import { PopupPdf } from "../../../components/QuillContainer/PopupPdf";
import { UserAccountDraftForm } from "../../../components/userProfile/UserGeneralInfo";
import { UserAccount } from "../../../../../api";
import { ConfirmValuesPopup } from "./ConfirmValuesPopup";
import { Images } from "../../../environment";
import { MobileMenu } from "../../MobileMenu";
import { isUndefined } from "lodash";
import { ConsoleSqlOutlined } from "@ant-design/icons";

interface BodyProps {
  user: UserAccount | undefined;
  fieldsValues: Array<FormFieldData>;
  chapter: Chapter | null;
  handover: Handover | null;
  step: number;
  chapterTemplateSteps: Array<Array<FormFieldData>>;
  handoverFiles: Array<HandoverFile>;
  updateChapterTemplate: (template: string, chapterType: string) => void;
  onUpdateChapter?: (
    chapter: Chapter,
    chapterType: string,
    cb?: () => void
  ) => any;
  setfieldsValuesFront: any;
}

interface FieldsStatistic {
  totalSteps: number;
  finishedSteps: number;
  percentOfFinishedSteps: number;
}

let currentScroll: number;

export function Body({
  chapter,
  chapterTemplateSteps,
  handover,
  step,
  fieldsValues,
  handoverFiles,
  user,
  updateChapterTemplate,
  onUpdateChapter,
  setfieldsValuesFront
}: BodyProps) {
  const [chapterClickButton, setChapterClickButton] = useState<boolean>(false);
  const bodyContentRef = useRef<React.ElementRef<"div"> | null>(null);
  const handoverDispatch = useContext(HandoverDispatchContext);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const { setError } = useAlerts();
  const translate = useTranslation();
  const isFirstChapter = chapter?.id === handover?.chapters[0]?.id;
  const isLastChapter =
    chapter?.id === handover?.chapters[handover?.chapters.length - 1]?.id;
  const [position, setPosition] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showAssistant, setShowAssistant] = useState<boolean>(true);
  const [lastStepNewChapter, setLastStepNewChapter] = useState<number>(0);
  const [clickPrevProgressbar, setClickPrevProgressbar] = useState<boolean>(
    false
  );
  const width = useViewport();
  const device = useDevice();
  const [showConfirmSend, setShowConfirmSend] = useState<boolean>(false);
  const needScroll = useRef(false);
  const { routes, history } = useNavigation();
  const [isEmptyFields, setIsEmptyFields] = useState(true);
  if (needScroll.current && bodyContentRef.current?.scrollTop) {
    currentScroll = bodyContentRef.current?.scrollTop;
  }

  const [updateUserAccountDetails] = useUpdateUserAccountDetailsMutation();

  const [deleteEmbeddedFile] = useDeleteEmbeddedFileMutation();
  const [showConfirmValuesPopup, setShowConfirmValuesPopup] = useState<boolean>(
    false
  );
  const [isSave, setIsSave] = useState<boolean>(false);

  const [getMe, { data: meData }] = useGetMe();

  useEffect(() => {
    if (!meData?.me.draftForm) {
      getMe();
    }
  }, [meData?.me.draftForm]);

  const onScrollClickHandler = () => {
    bodyContentRef.current?.scrollTo({
      top: 999999,
      behavior: "smooth"
    });
  };

  const onScrollClickHandlerBack = () => {
    bodyContentRef.current?.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    setPosition(false);
  }, [step]);

  useEffect(() => {
    if (clickPrevProgressbar) {
      setLastStepNewChapter(chapterTemplateSteps.length - 1);
    }
    setClickPrevProgressbar(false);
  }, [chapter]);

  useEffect(() => {
    const scrollHandler: EventListenerOrEventListenerObject = (event: any) => {
      const targetElHeight = event.target.getBoundingClientRect().height;
      const targetScrollHeight = event.target.scrollHeight;

      let booleanPos = true;

      if (targetScrollHeight - targetElHeight === 0) booleanPos = true;
      else if (
        targetScrollHeight - targetElHeight - event.target.scrollTop <
        15
      )
        booleanPos = true;
      else booleanPos = false;

      setPosition(booleanPos);
    };

    bodyContentRef.current &&
      bodyContentRef.current.addEventListener("scroll", scrollHandler);

    return () => {
      bodyContentRef.current &&
        bodyContentRef.current.removeEventListener("scroll", scrollHandler);
    };
  }, [bodyContentRef.current]);

  useEffect(() => {
    let result = fieldsValues.find((item: any, index: number) => {
      return item.value[0] !== "";
    });

    if (result === undefined) {
      setIsEmptyFields(true);
      localStorage.setItem("ChapterStatus", "ENABLED");
    }
    if (result) {
      setIsEmptyFields(false);
      localStorage.setItem("ChapterStatus", "DISABLED");
    }
  }, [fieldsValues]);

  useEffect(() => {
    setShowScrollButton(
      bodyContentRef?.current?.scrollHeight! -
        bodyContentRef?.current?.clientHeight! >
        0
    );
  }, [chapter, step]);

  useEffect(() => {
    changeStep(lastStepNewChapter);
  }, [lastStepNewChapter]);

  const getActiveChapterPosition = useCallback(() => {
    if (!chapter || !handover) return 0;

    return handover.chapters.findIndex(
      (handoverChapter: Chapter) => handoverChapter.id === chapter.id
    );
  }, [chapter, handover?.chapters]);

  const onNextChapterClick = () => {
    if (handover) {
      const activeChapterPosition = getActiveChapterPosition();

      handoverDispatch(
        setActiveChapter(handover.chapters[activeChapterPosition + 1])
      );
    }
  };

  const changeStep = (step: number) => {
    handoverDispatch(setCurrentStep(step));
  };
  const showConfirmationPopup = () => {
    setShowConfirmValuesPopup(true);
    setIsSave(true);
  };
  const sendAnswersHandler = () => {
    if (checkIfRequiredFieldsFilled()) {
      setShowConfirmSend(true);
    } else {
      setError({
        message: translate(({ errors }) => errors.missedRequiredFields)
      });
    }
    bodyContentRef.current?.scroll({ top: -999999 });
  };

  const showConfirmPopup = () => {
    setShowConfirmValuesPopup(true);
    setIsSave(false);
    localStorage.setItem("ChapterStatus", "ENABLED");
  };
  const onPrevChapterClick = () => {
    if (handover) {
      const activeChapterPosition = getActiveChapterPosition();

      handoverDispatch(
        setActiveChapter(handover.chapters[activeChapterPosition - 1])
      );
    }
  };

  const onSendButtonClick = () => {
    for (let { value, required } of fieldsValues) {
      if (required && !value.length) {
        return setError({
          message: translate(({ errors }) => errors.missedRequiredFields)
        });
      }
    }

    handoverDispatch(setShowSendFormDataModal(true));
    localStorage.setItem("ChapterStatus", "ENABLED");
  };

  const getFieldsStatistic = useCallback(
    (currentStep): FieldsStatistic => {
      const fieldStatistic = {
        totalSteps: 0,
        finishedSteps: 0,
        percentOfFinishedSteps: 0
      };

      if (
        !chapterTemplateSteps ||
        chapterTemplateSteps.length === 1 ||
        chapterTemplateSteps.length === 0
      )
        return {
          totalSteps: 1,
          finishedSteps: 0,
          percentOfFinishedSteps: 0
        };

      fieldStatistic.totalSteps = chapterTemplateSteps.length;
      fieldStatistic.finishedSteps = currentStep;

      fieldStatistic.percentOfFinishedSteps = Math.round(
        (100 * fieldStatistic.finishedSteps) / fieldStatistic.totalSteps
      );

      return fieldStatistic;
    },
    [fieldsValues, chapterTemplateSteps, step]
  );
  const onPrevButtonClickHandler = () => {
    setChapterClickButton(prev => !prev);
    if (fieldsStatistic?.finishedSteps === 0 && !isFirstChapter) {
      clickPrevChapter();
    } else {
      prevButtonHandler();
      onScrollClickHandlerBack();
    }
  };

  const onNextButtonClickHandler = () => {
    setChapterClickButton(prev => !prev);
    if (fieldsStatistic?.totalSteps - 1 === fieldsStatistic?.finishedSteps) {
      changeStep(0);
      onNextChapterClick();
    } else {
      nextButtonHandler();
      bodyContentRef.current?.scroll({ top: 0 });
    }
  };

  const onPrevButtonClickHandlerSecond = () => {
    setChapterClickButton(prev => !prev);

    if (fieldsStatistic?.finishedSteps === 0 && !isFirstChapter) {
      clickPrevChapter();
    } else {
      prevButtonHandler();
      onScrollClickHandlerBack();
    }
  };

  const fieldsStatistic = getFieldsStatistic(step);
  const prevButtonDisabled =
    isFirstChapter && fieldsStatistic?.finishedSteps === 0;
  const nextButtonDisabled =
    isLastChapter &&
    fieldsStatistic?.finishedSteps + 1 === fieldsStatistic?.totalSteps;

  if (!chapterTemplateSteps || !chapter) {
    return (
      <BodyContainer>
        <Typography tag="h3">
          You don't have any chapters, please create one.
        </Typography>
      </BodyContainer>
    );
  }

  const handleUpdateUserAccountDetails = async (
    userAccountDetails: UserAccountDraftForm
  ) => {
    if (user) {
      await updateUserAccountDetails({
        variables: {
          data: {
            userAccountWhereUniqueInput: { id: user.id },
            draftForm: userAccountDetails.draftForm,
            draftChapters: userAccountDetails.draftChapters
          }
        }
      });
    }
  };

  const nextButtonHandler = () => {
    changeStep(step + 1);
  };

  const prevButtonHandler = () => changeStep(step - 1);

  const checkIfRequiredFieldsFilled = () => {
    for (const step of chapterTemplateSteps) {
      for (const { type, name, required } of step) {
        const isProcessedFields = ~processedFields.indexOf(type);

        const shouldCheckField = isFirstChapter
          ? !isProcessedFields
          : !isProcessedFields || !required;

        if (shouldCheckField) continue;

        const fieldValue = fieldsValues.find(
          ({ name: fieldName }) => name === fieldName
        );

        if (type === FieldTypes.file) {
          const fileUploaded = handoverFiles.find(
            ({ fieldName }) => fieldName === name
          );

          if (fileUploaded) continue;
        }

        if (fieldValue && !fieldValue.value[0]) {
          return false;
        }
      }
    }

    return true;
  };

  const SendAnswerHandler = () => {
    if (checkIfRequiredFieldsFilled()) {
      setShowConfirmSend(true);
    } else {
      setError({
        message: translate(({ errors }) => errors.missedRequiredFields)
      });
    }
    bodyContentRef.current?.scroll({ top: -999999 });
  };

  const deleteValues = (draftForm: any) => {
    const resArray = draftForm.map((el: any) => {
      el.value = [""];
      return el;
    });

    handleUpdateUserAccountDetails({
      draftForm: JSON.stringify(resArray)
    });

    setfieldsValuesFront(resArray);
    setShowConfirmValuesPopup(false);

    handoverDispatch(removeAllHandoverFiles());
  };

  const clickPrevChapter = () => {
    setClickPrevProgressbar(true);
    onPrevChapterClick();
  };

  const getHelpManText = (chapter: Chapter) => {
    if (chapter?.helpmanText) {
      const helpManTextLine = [
        {
          text: JSON.parse(chapter.helpmanText).map((item: any) => item.value),
          currentStepForText: JSON.parse(chapter.helpmanText).map(
            (item: any) => item.currentStep
          )
        }
      ];
      return helpManTextLine;
    }
    return null;
  };

  const helpText = getHelpManText(chapter);

  const discard = () => {
    if (meData?.me.draftForm) {
      const form = JSON.parse(meData?.me.draftForm);
      deleteValues(form);
    } else {
      throw Error("Det går inte att nollställa formuläret");
    }
    localStorage.setItem("ChapterStatus", "ENABLED");
    history.push(routes.handovers);
  };

  return (
    <BodyContainer>
      {width >= parseInt(deviceSize.laptop.min) ? (
        <>
          <BodyLeftPartWrapper
            isMacintosh={device}
            marginLeft={step === 0 ? "60px" : "0"}
          >
            <ScrollAndFormWrapper>
              {fieldsStatistic.finishedSteps + 1 > 1 && (
                <Scroll
                  position={position}
                  onClick={onScrollClickHandler}
                  showArrow={showScrollButton}
                />
              )}
              <BodyLeftPart
                marginTop={
                  fieldsStatistic?.finishedSteps === 0 ? "16%" : "20px"
                }
                width={fieldsStatistic.finishedSteps + 1 > 1 ? 727 : 800}
                height={step === 0 ? 1 : 0}
              >
                <Title>{chapter.name}</Title>
                {fieldsStatistic.finishedSteps > 0 && (
                  <BlockStegWrapper>
                    <BlockSteg>
                      {"Fråga "}
                      {fieldsStatistic?.finishedSteps +
                        "/" +
                        (fieldsStatistic?.totalSteps - 1)}
                    </BlockSteg>
                  </BlockStegWrapper>
                )}
                <BodyContent
                  paddingAndShadow={
                    fieldsStatistic?.finishedSteps === 0 ? false : true
                  }
                >
                  <SuperAdminProtector>
                    <Drawer
                      open={showDrawer}
                      onClose={() => setShowDrawer(false)}
                      title="Form builder"
                    >
                      <FormBuilder
                        openDrawer={setShowDrawer}
                        updateFormData={updateChapterTemplate}
                        initialFormData={chapter.template || "[]"}
                      />
                    </Drawer>
                  </SuperAdminProtector>
                  <FormContainer
                    ref={bodyContentRef}
                    paddingLeft={step === 0 ? 0 : 36}
                  >
                    {isFirstChapter && step === 1 && (
                      <MandantoryFields>
                        Markeade fält är obligatoriska
                      </MandantoryFields>
                    )}
                    <div style={{ direction: "ltr" }}>
                      {chapterTemplateSteps[step] &&
                        chapterTemplateSteps[step].map(
                          (field: FormFieldData, idx: number) => (
                            <GeneratedField
                              chapterClickButton={chapterClickButton}
                              key={idx}
                              chapter={chapter}
                              fieldData={field}
                              isFirstChapter={isFirstChapter}
                              handoverFiles={handoverFiles}
                              value={
                                fieldsValues.find(
                                  ({ name }) => field.name === name
                                )?.value || []
                              }
                              onUpdateChapter={(chapter, chapterType) => {
                                const updateScroll = () => {
                                  if (bodyContentRef.current) {
                                    needScroll.current = false;
                                    bodyContentRef.current.scrollTop = currentScroll;
                                  }
                                };
                                if (onUpdateChapter) {
                                  needScroll.current = true;
                                  onUpdateChapter(
                                    chapter,
                                    chapterType,
                                    updateScroll
                                  );
                                }
                              }}
                            />
                          )
                        )}
                    </div>
                  </FormContainer>
                  {step === 0 && (
                    <ChapterButtonsWrapper
                      maxWidth={500}
                      marginTop={"40px"}
                      marginLeft={"0"}
                    >
                      <ChapterClickBodyButton
                        borderColor={"#6bc9db"}
                        disabled={prevButtonDisabled}
                        onClick={() => {
                          setChapterClickButton(prev => !prev);
                          if (
                            fieldsStatistic?.finishedSteps === 0 &&
                            !isFirstChapter
                          ) {
                            clickPrevChapter();
                          } else {
                            prevButtonHandler();
                            onScrollClickHandlerBack();
                          }
                        }}
                      >
                        <img
                          src={Images.landingArrowLeftBlue}
                          style={{ marginRight: "10px" }}
                          alt=""
                        />
                        {fieldsStatistic?.finishedSteps === 0 && !isFirstChapter
                          ? "Föregående kapitel"
                          : translate(({ buttons }) => buttons.prev)}
                      </ChapterClickBodyButton>
                      {isLastChapter &&
                      fieldsStatistic?.totalSteps - 1 ===
                        fieldsStatistic?.finishedSteps ? (
                        <SendAnswer onClick={SendAnswerHandler}>
                          {translate(({ handoversPage }) => handoversPage.send)}
                        </SendAnswer>
                      ) : (
                        <ChapterClickBodyButton
                          borderColor={"#cc00a0"}
                          disabled={nextButtonDisabled}
                          onClick={() => {
                            setChapterClickButton(prev => !prev);

                            if (
                              fieldsStatistic?.totalSteps - 1 ===
                              fieldsStatistic?.finishedSteps
                            ) {
                              changeStep(0);
                              onNextChapterClick();
                            } else {
                              nextButtonHandler();
                              onScrollClickHandler();
                            }
                          }}
                        >
                          {fieldsStatistic?.totalSteps - 1 ===
                          fieldsStatistic?.finishedSteps
                            ? "Nästa kapitel"
                            : translate(
                                ({ handoversPage }) => handoversPage.nextChapter
                              )}
                          <img
                            src={Images.landingArrowRightPink}
                            style={{ marginLeft: "10px" }}
                            alt=""
                          />
                        </ChapterClickBodyButton>
                      )}
                    </ChapterButtonsWrapper>
                  )}
                  {/* {getNextButton()} */}
                </BodyContent>
              </BodyLeftPart>
            </ScrollAndFormWrapper>
            {step !== 0 && (
              <ChapterButtonsWrapper
                maxWidth={550}
                marginTop={"40px"}
                marginLeft={"54px"}
              >
                <ChapterClickBodyButton
                  borderColor={"#6bc9db"}
                  disabled={prevButtonDisabled}
                  onClick={() => {
                    setChapterClickButton(prev => !prev);
                    if (
                      fieldsStatistic?.finishedSteps === 0 &&
                      !isFirstChapter
                    ) {
                      clickPrevChapter();
                    } else {
                      prevButtonHandler();
                      onScrollClickHandlerBack();
                    }
                  }}
                >
                  <img
                    src={Images.landingArrowLeftBlue}
                    style={{ marginRight: "10px" }}
                    alt=""
                  />
                  {fieldsStatistic?.finishedSteps === 0 && !isFirstChapter
                    ? "Föregående kapitel"
                    : translate(({ buttons }) => buttons.prev)}
                </ChapterClickBodyButton>
                {isLastChapter &&
                fieldsStatistic?.totalSteps - 1 ===
                  fieldsStatistic?.finishedSteps ? (
                  <SendAnswer onClick={SendAnswerHandler}>
                    {translate(({ handoversPage }) => handoversPage.send)}
                  </SendAnswer>
                ) : (
                  <ChapterClickBodyButton
                    borderColor={"#cc00a0"}
                    disabled={nextButtonDisabled}
                    onClick={() => {
                      setChapterClickButton(prev => !prev);
                      if (
                        fieldsStatistic?.totalSteps - 1 ===
                        fieldsStatistic?.finishedSteps
                      ) {
                        changeStep(0);
                        onNextChapterClick();
                      } else {
                        nextButtonHandler();
                        bodyContentRef.current?.scroll({ top: 0 });
                      }
                    }}
                  >
                    {fieldsStatistic?.totalSteps - 1 ===
                    fieldsStatistic?.finishedSteps
                      ? "Nästa kapitel"
                      : translate(
                          ({ handoversPage }) => handoversPage.nextChapter
                        )}
                    <img
                      src={Images.landingArrowRightPink}
                      style={{ marginLeft: "10px" }}
                      alt=""
                    />
                  </ChapterClickBodyButton>
                )}
              </ChapterButtonsWrapper>
            )}
          </BodyLeftPartWrapper>
          <BodyRightPart>
            {showAssistant && <BodyBackgroundBlurPurple />}
            {showAssistant && <BodyBackgroundBlurBlue />}
            {user?.role === "SUPER" ? (
              <SuperUserButton>
                <BlueButton
                  title={translate(
                    ({ handoversPage }) => handoversPage.updateForm
                  )}
                  onClick={() => setShowDrawer(true)}
                />
              </SuperUserButton>
            ) : null}
            <RightBlockButtonsContainer>
              <PreviewButton onClick={() => setShowPreview(!showPreview)}>
                Förhandsvisning
              </PreviewButton>
              <DropSaveButton onClick={showConfirmPopup}>
                Nollställ formulärsvar
              </DropSaveButton>
              <SaveAndContinueLater onClick={showConfirmationPopup}>
                {translate(({ buttons }) => buttons.saveAndContinueLater)}
              </SaveAndContinueLater>
              <SendAnswer onClick={SendAnswerHandler}>
                {translate(({ handoversPage }) => handoversPage.send)}
              </SendAnswer>
              {/* <DiscardButton onClick={discard}>Avfärda</DiscardButton> */}
            </RightBlockButtonsContainer>
            {showConfirmValuesPopup && (
              <ConfirmValuesPopup
                setShow={() => setShowConfirmValuesPopup(false)}
                deleteClick={deleteValues}
                isSave={isSave}
              />
            )}
            {showPreview && (
              <PopupPdf
                user={user}
                source={handover?.docTemplate}
                values={fieldsValues}
                indents={
                  handover && handover.docIndents
                    ? JSON.stringify(handover.docIndents)
                    : '{ "left": "2", "top": "2", "right": "2", "bottom": "2" }'
                }
                setShow={() => setShowPreview(false)}
                handoverFiles={handoverFiles}
              />
            )}
            <ProgresBarAndSendBtnWrapp>
              {showConfirmSend && (
                <SendPopupWrapp>
                  <SendPopupContent>
                    <ButtonCloseSendPopup
                      onClick={() => setShowConfirmSend(false)}
                    >
                      <img src={Images.landingCloseIcon} alt="" />
                    </ButtonCloseSendPopup>
                    Är du säker på att du är redo för att skicka in formuläret?
                    <ButtonsSendPopupWrapp>
                      <PreviewButton onClick={onSendButtonClick}>
                        Ja, skicka
                      </PreviewButton>
                      <DropSaveButton onClick={() => setShowConfirmSend(false)}>
                        Avbryt
                      </DropSaveButton>
                    </ButtonsSendPopupWrapp>
                  </SendPopupContent>
                </SendPopupWrapp>
              )}
            </ProgresBarAndSendBtnWrapp>

            {showAssistant ? (
              <HelpManWrapp>
                <HelpManContent>
                  <HelpManImgWrap>
                    <HelpManText
                      isShow={
                        helpText
                          ? helpText[0].currentStepForText.includes(step)
                          : false
                      }
                      isMacintosh={device}
                    >
                      <HelpManTextWrapper>
                        {helpText &&
                          helpText.map(helpManTextLineForPartChapter => {
                            return helpManTextLineForPartChapter.text.map(
                              (item: any, idx: number) => {
                                if (
                                  helpManTextLineForPartChapter
                                    .currentStepForText[idx] === step
                                ) {
                                  return (
                                    <p
                                      style={{ paddingRight: "10px" }}
                                      key={`${idx}textline`}
                                    >
                                      {item}
                                      <br />
                                    </p>
                                  );
                                }
                              }
                            );
                          })}
                      </HelpManTextWrapper>
                    </HelpManText>

                    <AssistantBlockContent>
                      {(helpText ? (
                        helpText[0].currentStepForText.includes(step)
                      ) : (
                        false
                      )) ? (
                        <img
                          src={Images.assistant}
                          style={{ width: "157px", height: "213" }}
                          onClick={() => setShowAssistant(false)}
                          alt=""
                        />
                      ) : (
                        <img
                          src={Images.assistantWithoutDot}
                          style={{ width: "157px", height: "213" }}
                          onClick={() => setShowAssistant(false)}
                          alt=""
                        />
                      )}
                    </AssistantBlockContent>
                  </HelpManImgWrap>
                </HelpManContent>
              </HelpManWrapp>
            ) : (
              <AssistantBlockWrapper>
                <AssistantBlockContent onClick={() => setShowAssistant(true)}>
                  <AssistantTextBlock>
                    <img
                      src={Images.landingImageArrowUp}
                      style={{
                        cursor: "pointer",
                        width: "35px",
                        height: "35px"
                      }}
                      alt=""
                    />
                    Behövs hjälp?
                  </AssistantTextBlock>
                  <img
                    src={Images.landingEllipseMaskGroup}
                    style={{ position: "relative" }}
                    alt=""
                  />
                  <img
                    src={Images.landingMaskGroup}
                    style={{
                      position: "relative",
                      marginLeft: "-100px",
                      zIndex: 99
                    }}
                    alt=""
                  />
                </AssistantBlockContent>
              </AssistantBlockWrapper>
            )}
          </BodyRightPart>
        </>
      ) : (
        <BodyTabletWrapp>
          {/* TABLET VERSION */}
          {showConfirmValuesPopup && (
            <ConfirmValuesPopup
              setShow={() => setShowConfirmValuesPopup(false)}
              deleteClick={deleteValues}
              isSave={isSave}
            />
          )}
          {/* Popup for preview button*/}
          {showPreview && (
            <PopupPdf
              user={user}
              source={handover?.docTemplate}
              values={fieldsValues}
              indents={
                handover && handover.docIndents
                  ? JSON.stringify(handover.docIndents)
                  : '{ "left": "2", "top": "2", "right": "2", "bottom": "2" }'
              }
              setShow={() => setShowPreview(false)}
              handoverFiles={handoverFiles}
            />
          )}
          {/* Popup for send button*/}
          <ProgresBarAndSendBtnWrapp>
            {showConfirmSend && (
              <SendPopupWrapp>
                <SendPopupContent>
                  <ButtonCloseSendPopup
                    onClick={() => setShowConfirmSend(false)}
                  >
                    <img src={Images.landingCloseIcon} alt="" />
                  </ButtonCloseSendPopup>
                  Är du säker på att du är redo för att skicka in formuläret?
                  <ButtonsSendPopupWrapp>
                    <PreviewButton onClick={onSendButtonClick}>
                      Ja, skicka
                    </PreviewButton>
                    <DropSaveButton onClick={() => setShowConfirmSend(false)}>
                      Avbryt
                    </DropSaveButton>
                  </ButtonsSendPopupWrapp>
                </SendPopupContent>
              </SendPopupWrapp>
            )}
          </ProgresBarAndSendBtnWrapp>
          <BodyRightPart>
            {showAssistant && <BodyBackgroundBlurPurple />}
            {showAssistant && <BodyBackgroundBlurBlue />}
            {user?.role === "SUPER" ? (
              <SuperUserButton>
                <BlueButton
                  title={translate(
                    ({ handoversPage }) => handoversPage.updateForm
                  )}
                  onClick={() => setShowDrawer(true)}
                />
              </SuperUserButton>
            ) : null}
            <RightBlockButtonsContainer>
              <PreviewButton onClick={() => setShowPreview(!showPreview)}>
                Förhandsvisning
              </PreviewButton>
              <DropSaveButton onClick={showConfirmPopup}>
                Nollställ formulärsvar
              </DropSaveButton>
              <SaveAndContinueLater onClick={showConfirmationPopup}>
                {translate(({ buttons }) => buttons.saveAndContinueLater)}
              </SaveAndContinueLater>
              <SendAnswer onClick={SendAnswerHandler}>
                {translate(({ handoversPage }) => handoversPage.send)}
              </SendAnswer>
              {/* <DiscardButton onClick={discard}>Avfärda</DiscardButton> */}
            </RightBlockButtonsContainer>
            {showConfirmValuesPopup && (
              <ConfirmValuesPopup
                setShow={() => setShowConfirmValuesPopup(false)}
                deleteClick={deleteValues}
                isSave={isSave}
              />
            )}
          </BodyRightPart>
          <BodyLeftPartWrapper>
            {width <= parseInt(deviceSize.mobile.max) && (
              <MobileMenu
                chapter={chapter}
                step={step}
                onPrevButtonClick={onPrevButtonClickHandler}
                onNextButtonClick={
                  isLastChapter &&
                  fieldsStatistic?.totalSteps - 1 ===
                    fieldsStatistic?.finishedSteps
                    ? sendAnswersHandler
                    : onNextButtonClickHandler
                }
                isLastChapter={
                  isLastChapter &&
                  fieldsStatistic?.totalSteps - 1 ===
                    fieldsStatistic?.finishedSteps
                }
                prevButtonDisabled={prevButtonDisabled}
                ShowPreview={() => setShowPreview(!showPreview)}
                ShowConfirmPopup={showConfirmPopup}
                ShowConfirmationPopup={showConfirmationPopup}
                SendAnswerHandler={SendAnswerHandler}
              ></MobileMenu>
            )}
            <ScrollAndFormWrapper>
              {fieldsStatistic.finishedSteps > 0 && (
                <Scroll
                  position={position}
                  onClick={onScrollClickHandler}
                  showArrow={showScrollButton}
                />
              )}
              <BodyLeftPart
                marginTop="25px"
                width={1000}
                height={step === 0 ? 1 : 0}
              >
                <Title
                  marginLeft={fieldsStatistic?.finishedSteps === 0 ? 20 : 0}
                >
                  {chapter.name}
                </Title>
                {fieldsStatistic.finishedSteps + 1 > 1 && (
                  <BlockStegWrapper>
                    <BlockSteg>
                      {"Fråga "}
                      {fieldsStatistic?.finishedSteps +
                        "/" +
                        (fieldsStatistic?.totalSteps - 1)}
                    </BlockSteg>
                  </BlockStegWrapper>
                )}
                <BodyContent
                  ref={bodyContentRef}
                  paddingAndShadow={
                    fieldsStatistic?.finishedSteps === 0 ? false : true
                  }
                >
                  <SuperAdminProtector>
                    <Drawer
                      open={showDrawer}
                      onClose={() => setShowDrawer(false)}
                      title="Form builder"
                    >
                      <FormBuilder
                        openDrawer={setShowDrawer}
                        updateFormData={updateChapterTemplate}
                        initialFormData={chapter.template || "[]"}
                      />
                    </Drawer>
                  </SuperAdminProtector>
                  <FormContainer>
                    {isFirstChapter && step === 1 && (
                      <MandantoryFields>
                        Markerade fält är obligatoriska
                      </MandantoryFields>
                    )}
                    {chapterTemplateSteps[step] &&
                      chapterTemplateSteps[step].map(
                        (field: FormFieldData, idx: number) => (
                          <GeneratedField
                            chapterClickButton={chapterClickButton}
                            key={idx}
                            chapter={chapter}
                            fieldData={field}
                            isFirstChapter={isFirstChapter}
                            handoverFiles={handoverFiles}
                            value={
                              fieldsValues.find(
                                ({ name }) => field.name === name
                              )?.value || []
                            }
                            onUpdateChapter={(chapter, chapterType) => {
                              const updateScroll = () => {
                                if (bodyContentRef.current) {
                                  needScroll.current = false;
                                  bodyContentRef.current.scrollTop = currentScroll;
                                }
                              };
                              if (onUpdateChapter) {
                                needScroll.current = true;
                                onUpdateChapter(
                                  chapter,
                                  chapterType,
                                  updateScroll
                                );
                              }
                            }}
                          />
                        )
                      )}
                  </FormContainer>
                  {step === 0 && (
                    <ChapterButtonsWrapper
                      maxWidth={500}
                      marginTop={"40px"}
                      marginLeft={"54px"}
                    >
                      <ChapterClickBodyButton
                        borderColor={"#6bc9db"}
                        disabled={prevButtonDisabled}
                        onClick={onPrevButtonClickHandler}
                      >
                        <img
                          src={Images.landingArrowLeftBlue}
                          style={{ marginRight: "10px" }}
                          alt=""
                        />
                        {fieldsStatistic?.finishedSteps === 0 && !isFirstChapter
                          ? "Föregående kapitel"
                          : translate(({ buttons }) => buttons.prev)}
                      </ChapterClickBodyButton>
                      {isLastChapter &&
                      fieldsStatistic?.totalSteps - 1 ===
                        fieldsStatistic?.finishedSteps ? (
                        <SendAnswer onClick={SendAnswerHandler}>
                          {translate(({ handoversPage }) => handoversPage.send)}
                        </SendAnswer>
                      ) : (
                        <ChapterClickBodyButton
                          borderColor={"#cc00a0"}
                          disabled={nextButtonDisabled}
                          onClick={() => {
                            setChapterClickButton(prev => !prev);
                            if (
                              fieldsStatistic?.totalSteps - 1 ===
                              fieldsStatistic?.finishedSteps
                            ) {
                              changeStep(0);
                              onNextChapterClick();
                            } else {
                              nextButtonHandler();
                              onScrollClickHandler();
                            }
                          }}
                        >
                          {fieldsStatistic?.totalSteps - 1 ===
                          fieldsStatistic?.finishedSteps
                            ? "Nästa kapitel"
                            : translate(
                                ({ handoversPage }) => handoversPage.nextChapter
                              )}
                          <img
                            src={Images.landingArrowRightPink}
                            style={{ marginLeft: "10px" }}
                            alt=""
                          />
                        </ChapterClickBodyButton>
                      )}
                    </ChapterButtonsWrapper>
                  )}
                  {/* {getNextButton()} */}
                </BodyContent>
              </BodyLeftPart>
            </ScrollAndFormWrapper>
            {step !== 0 && (
              <ChapterButtonsWrapper
                maxWidth={550}
                marginTop={"30px"}
                marginLeft={"54px"}
              >
                <ChapterClickBodyButton
                  borderColor={"#6bc9db"}
                  disabled={prevButtonDisabled}
                  onClick={onPrevButtonClickHandlerSecond}
                >
                  <img
                    src={Images.landingArrowLeftBlue}
                    style={{ marginRight: "10px" }}
                    alt=""
                  />
                  {fieldsStatistic?.finishedSteps === 0 && !isFirstChapter
                    ? "Föregående kapitel"
                    : translate(({ buttons }) => buttons.prev)}
                </ChapterClickBodyButton>
                {isLastChapter &&
                fieldsStatistic?.totalSteps - 1 ===
                  fieldsStatistic?.finishedSteps ? (
                  <SendAnswer onClick={sendAnswersHandler}>
                    {translate(({ handoversPage }) => handoversPage.send)}
                  </SendAnswer>
                ) : (
                  <ChapterClickBodyButton
                    borderColor={"#cc00a0"}
                    disabled={nextButtonDisabled}
                    onClick={onNextButtonClickHandler}
                  >
                    {fieldsStatistic?.totalSteps - 1 ===
                    fieldsStatistic?.finishedSteps
                      ? "Nästa kapitel"
                      : translate(
                          ({ handoversPage }) => handoversPage.nextChapter
                        )}
                    <img
                      src={Images.landingArrowRightPink}
                      style={{ marginLeft: "10px" }}
                      alt=""
                    />
                  </ChapterClickBodyButton>
                )}
              </ChapterButtonsWrapper>
            )}
          </BodyLeftPartWrapper>
          <HelpmanWrapper>
            {showAssistant ? (
              <HelpManWrapp>
                <HelpManContent>
                  <HelpManImgWrap>
                    <HelpManText
                      style={{ position: "fixed" }}
                      isShow={
                        helpText
                          ? helpText[0].currentStepForText.includes(step)
                          : false
                      }
                      isMacintosh={device}
                    >
                      <HelpManTextWrapper>
                        {helpText &&
                          helpText.map(helpManTextLineForPartChapter => {
                            return helpManTextLineForPartChapter.text.map(
                              (item: any, idx: number) => {
                                if (
                                  helpManTextLineForPartChapter
                                    .currentStepForText[idx] === step
                                ) {
                                  return (
                                    <p
                                      style={{ paddingRight: "10px" }}
                                      key={`${idx}textline`}
                                    >
                                      {item}
                                      <br />
                                    </p>
                                  );
                                }
                              }
                            );
                          })}
                      </HelpManTextWrapper>
                    </HelpManText>

                    <AssistantBlockContent style={{ position: "fixed" }}>
                      {(helpText ? (
                        helpText[0].currentStepForText.includes(step)
                      ) : (
                        false
                      )) ? (
                        <img
                          src={Images.assistant}
                          onClick={() => setShowAssistant(false)}
                          alt=""
                        />
                      ) : (
                        <img
                          src={Images.assistantWithoutDot}
                          onClick={() => setShowAssistant(false)}
                          alt=""
                        />
                      )}
                    </AssistantBlockContent>
                  </HelpManImgWrap>
                </HelpManContent>
              </HelpManWrapp>
            ) : (
              <AssistantBlockWrapper>
                <AssistantBlockContent onClick={() => setShowAssistant(true)}>
                  <AssistantTextBlock>
                    <img
                      src={Images.landingImageArrowUp}
                      style={{
                        cursor: "pointer",
                        width: "35px",
                        height: "35px"
                      }}
                      alt=""
                    />
                    Behövs hjälp?
                  </AssistantTextBlock>
                  <img
                    src={Images.landingEllipseMaskGroup}
                    style={{ position: "relative" }}
                    alt=""
                  />
                  <img
                    src={Images.landingMaskGroup}
                    style={{
                      position: "relative",
                      marginLeft: "-100px",
                      zIndex: 99
                    }}
                    alt=""
                  />
                </AssistantBlockContent>
              </AssistantBlockWrapper>
            )}
          </HelpmanWrapper>
          {width <= parseInt(deviceSize.mobile.max) && (
            <ProgresBarAndSendBtnWrapp>
              {showConfirmSend && (
                <SendPopupWrapp>
                  <SendPopupContent>
                    <ButtonCloseSendPopup
                      onClick={() => setShowConfirmSend(false)}
                    >
                      <img src={Images.landingCloseIcon} alt="" />
                    </ButtonCloseSendPopup>
                    Är du säker på att du är redo för att skicka in formuläret?
                    <ButtonsSendPopupWrapp>
                      <PopupButtonConfirm onClick={onSendButtonClick}>
                        Ja, skicka
                      </PopupButtonConfirm>
                      <DropPopupButton
                        onClick={() => setShowConfirmSend(false)}
                      >
                        Avbryt
                      </DropPopupButton>
                    </ButtonsSendPopupWrapp>
                  </SendPopupContent>
                </SendPopupWrapp>
              )}
            </ProgresBarAndSendBtnWrapp>
          )}
        </BodyTabletWrapp>
      )}
    </BodyContainer>
  );
}
