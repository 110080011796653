import React, { useContext, useState, useEffect } from "react";

import { PageContainer } from "./UserSettings.style";
import { StorageKey } from "../../../consts";
import {
  useTranslation,
  useDeleteUserAccountMutation,
  useAlerts,
  useMeQuery,
  useNavigation
} from "../../../hooks";
import { UserContext } from "../UserPofile";
import { ConfirmModal } from "../../ui";
import { ResetPasswordModal } from "./ResetPasswordModal";

export function UserSettings() {
  const { user } = useContext(UserContext);
  const translate = useTranslation();
  const { history, routes } = useNavigation();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(
    false
  );
  const { data: meData } = useMeQuery();
  const [
    deleteUserAccount,
    { data: deleteUserAccountData, loading: deleteUserAccountLoading }
  ] = useDeleteUserAccountMutation();
  const { setNotification } = useAlerts();

  useEffect(() => {
    if (deleteUserAccountData) {
      setNotification({
        message: translate(({ messages }) => messages.accountDeleted)
      });
      setShowConfirmDeleteModal(false);

      if (meData?.me.id === deleteUserAccountData.deleteUserAccount) {
        localStorage.removeItem(StorageKey.Token);
        history.push(routes.signup);
      } else {
        history.push(routes.members);
      }
    }
  }, [deleteUserAccountData]);

  const onDeleteUserAccountClick = () => {
    if (user && meData) {
      deleteUserAccount({
        variables: {
          userAccountWhereUniqueInput: { id: user.id }
        }
      });
    }
  };

  return (
    <PageContainer>
      <ResetPasswordModal
        setShowConfirmDeleteModal={() => setShowConfirmDeleteModal(true)}
      />
      {/* <PageHeaderBlock>
        <PageTitle>
          {translate(({ titles }) => titles.accountSettings)}
        </PageTitle>
      </PageHeaderBlock>
      <PageSettingsBlock>
        <PageSettingsItem>
          <PageSubTitle>
            {translate(({ titles }) => titles.password)}
          </PageSubTitle>
          <PageTypoText>
            {translate(({ titles }) => titles.changePassword)}
          </PageTypoText>
          <LightButton
            title={translate(({ buttons }) => buttons.resetPassword)}
            onClick={() => setShowResetPasswordModal(true)}
          />
        </PageSettingsItem>
      </PageSettingsBlock>
      <PageActionsBlock>
        <PageActionsItem>
          <DangerButton
            title={translate(({ buttons }) => buttons.delete)}
            leftIcon={IconType.EpTrash}
            onClick={() => setShowConfirmDeleteModal(true)}
          />
          <PageTypoText>
            {translate(({ titles }) => titles.deleteUserAccountAndAllUserInfo)}
          </PageTypoText>
        </PageActionsItem>
      </PageActionsBlock> */}

      {/* {showResetPasswordModal && (
        <ResetPasswordModal
          open={showResetPasswordModal}
          onClose={() => setShowResetPasswordModal(false)}
          title={translate(({ buttons }) => buttons.resetPassword)}
        />
      )} */}

      {showConfirmDeleteModal && (
        <ConfirmModal
          title={translate(({ titles }) => titles.confirmChangePassword)}
          onClickYes={() => {
            onDeleteUserAccountClick();
          }}
          onCloseModal={() => {
            setShowConfirmDeleteModal(false);
          }}
          showModal={showConfirmDeleteModal}
          loading={deleteUserAccountLoading}
        />
      )}
    </PageContainer>
  );
}
