import styled from "styled-components";
import { NavLink as BaseNavLink } from "react-router-dom";

import { Input as DefaultInput } from "../../ui/Input";
import { CustomInput } from "../../ui/Input";

import { Colors, Fonts } from "../../../environment";
import { deviceRange } from "../../../consts";

export const Form = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  min-width: 350px;
`;
interface PromoButtonStartprops {
  marginTop?: number;
  width?: number;
  onClick?: any;
  paddingTop?: number;
  paddingBottom?: number;
  shadowLeft?: number;
  shadowTop?: number;
  marginLeft?: number;
  marginBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  height?: number;
  fontSize?: number;
  widthVW?: number;
  padding?: string;
}

export const ButtonLogin = styled.button<PromoButtonStartprops>`
  min-width:120px;
  background: #cc00a0;
  box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
    ${props => (props.shadowTop ? props.shadowTop + "px" : "6px")} 0px #b2028c;
  border-radius: 80px;
  padding-top: ${props =>
    props.paddingTop ? props.paddingTop + "px" : "34px"};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom + "px" : "34px"};
  padding-left: ${props =>
    props.paddingLeft ? props.paddingLeft + "px" : "41px"};
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight + "px" : "41px"};
  border: #cc00a0;
  color: #ffffff;
  width: ${props => (props.width ? props.width + "px" : "100%")};
  ${props => (props.widthVW ? `width: ${props.widthVW}vw;` : null)}
  padding: ${props => (props.padding ? props.padding : "13px 35px")};
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  font-size: calc(5px + 0.5vw);
  line-height: 24px;
  margin-top: ${props => (props.marginTop ? props.marginTop + "px" : "-40px")};
  ${props =>
    props.marginLeft ? "margin-left:" + props.marginLeft + "px" : null};
  ${props =>
    props.marginBottom ? "margin-bottom:" + props.marginBottom + "px" : null};
  cursor: pointer;
  ${props => (props.height ? "height:" + props.height + "px;" : null)}
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #b2028c;
  }
  &:active {
    box-shadow: -5px -5px 0px #b2028c;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
      ${props =>
        props.shadowTop ? props.shadowTop + "px" : "6px"} 0px #b2028c;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    margin-top:12px;
    
  }
`;
interface PromoBlockMainTextProps {
  fontSize?: number;
}
export const LoginMainText = styled.div<PromoBlockMainTextProps>`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize + "px" : "55px")};
  line-height: 60px;
  color: #6bc9db;
  width: 100%;

  @media ${deviceRange.mobile} {
    justify-content: center;
    display: flex;
    align-items: center;
  }
`;

export const FormMobile = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  min-width: 350px;

  @media ${deviceRange.mobile} {
    justify-content: center;
    display: flex;
    align-items: center;
    min-width: 0px;
    margin-top: 25px;
  }
`;

export const Input = styled(DefaultInput)`
  margin-bottom: 4rem;
`;

export const SignUpInput = styled(CustomInput)`
  width: 100%;
  //filter: drop-shadow(0px 4px 4px rgba(66, 82, 98, 0.5));
`;

export const LoginInput = styled(CustomInput)`
  width: 100%;
  //filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const ForgotPasswordBlock = styled.div`
  margin: 2.4rem 0;
  text-align: center;
`;

export const FormInfoBlock = styled.div``;

export const FormInfoText = styled.p`
  color: ${Colors.typoPlaceholder};
  font-size: 1.4rem;
  line-height: 150%;
  width: 100%;

  @media ${deviceRange.mobile} {
  }
`;

export const NavLink = styled(BaseNavLink)`
  text-decoration: none;
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 1.5rem;
  color: ${Colors.black[100]};
`;
export const LeftBlockForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media ${deviceRange.mobile} {
    align-items: center;
  }
`;
export const RightBlockForm = styled.div`
  width: 100%;
  margin-left: 20px;
  text-align: center;
`;
export const LoginFormWrapper = styled.div`
  width: 50%;
  max-width: 650px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  @media ${deviceRange.tablet} {
    width: 70%;
    z-index: 1;
  }

  @media ${deviceRange.mobile} {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const NavLinkForgotPassword = styled(BaseNavLink)`
  text-decoration: none;
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 1.5rem;
  color: ${Colors.black[100]};

  @media ${deviceRange.mobile} {
    margin-top: 10px;
    text-decoration-line: underline;
  }
`;

export const ForgotPasswordWrapper = styled.div`
  margin-top: 10px;
`;

export const CreateAccountButton = styled.button`
  width: 100%;
  background: #fca311;
  box-shadow: 3px 4px 0px #f09800;
  border-radius: 80px;
  padding: 13px 35px;
  margin-top: 30px;
  color: white;
  font-family: ${Fonts.landingOpenSansSemibold};
  font-style: normal;
  font-size: 1.6rem;
  cursor: pointer;
  border: #fca311;

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px rgba(255, 161, 0, 62%);
  }
  &:active {
    box-shadow: -3px -5px rgba(255, 161, 0, 62%);
  }

  @media ${deviceRange.mobile} {
    margin-top: 10px;
  }
`;
