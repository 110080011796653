import styled from "styled-components";
import { Fonts } from "../../../environment";

export const PageTitlePanelContainer = styled.div`
  display: flex;
  height: 4.8rem;
  padding: 1.4rem 2rem 0.6rem 2rem;
  margin-bottom: 50px;
`;

export const PageTitle = styled.h4`
  margin-right: 1rem;
  white-space: nowrap;
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 60px;
  color: #6bc9db;
`;

export const PageTitlePanelActionsBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
