import styled from "styled-components";
import { deviceRange } from "../../../consts";

import { Colors, Fonts } from "../../../environment";

export const Container = styled.div`
  position: relative;
  margin-bottom: 1rem;
  @media ${deviceRange.mobile} {
    width: 100%;
  }
`;

export const Label = styled.label`
  text-transform: uppercase;
  color: ${Colors.typoPlaceholder};
  position: absolute;
  top: 12px;
  left: 12px;
  pointer-events: none;
  font-family: ${Fonts.paragraph};
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 1px;
  text-align: left;
`;

interface InputProps {
  invalid?: boolean;
  focus?: boolean;
  withLabel?: boolean;
  borderAndShadow?: boolean;
  borderRadius?: number;
  borderColor: string;
  padding?: string;
  isFirstChapter?: boolean;
  fontSize?: string;
}

export const HandoverFormInput = styled.input<InputProps>`
  width: 100%;
  color: black;
  background: white;
  margin-bottom: 2px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  height:50px;

  letter-spacing: 0.2px;
  text-align: left;
  ${props =>
    props.isFirstChapter
      ? "border-left-color: #cc00a0; border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
      : ""};
  border: ${props =>
    props.borderAndShadow
      ? "none"
      : `1px solid ${props.borderColor ? props.borderColor : "#6bc9db"}`};
  ${props =>
    props.borderColor ? "border-color:" + props.borderColor + ";" : null}
  -webkit-box-shadow: ${props =>
    props.borderAndShadow
      ? `inset
      0 0 0 50px #fff,
      0px 0px 10px 0px rgb(107 25 219/ 20%);`
      : "none"};

  padding: ${props => (props.padding ? props.padding : "1vw 0.5vw")};
  border-radius: ${props =>
    props.borderRadius ? props.borderRadius + "px" : "80px"};
  outline: 0;
  ::placeholder {
    font-size: 1.4rem;
    color: ${Colors.overlay[40]};
  }
  :focus {
    box-shadow:0px 0px 5px 2px ${Colors.inputFocusColor};
}


  @media ${deviceRange.laptop} {
    margin-bottom: 0px;
    height:50px;

  }

  @media ${deviceRange.laptopL} {
    margin-bottom: 0px;
  }
  @media ${deviceRange.laptopM} {
    margin-bottom: 0px;
  }

  @media ${deviceRange.tablet} {   
    padding: 18px;
  } 
  @media ${deviceRange.desktop} {
    padding: 18px;

  } 
  @media ${deviceRange.mobile} {
    font-size:12px;
  }
`;

export const BaseInput = styled.input<InputProps>`
  width: 100%;
  color: black;
  background: white;
  margin-bottom: 2px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  height:50px;
  letter-spacing: 0.2px;
  text-align: left;
  border: ${props =>
    props.borderAndShadow
      ? "none"
      : `1px solid ${props.borderColor ? props.borderColor : "lightgray"}`};
  ${props =>
    props.borderColor ? "border-color:" + props.borderColor + ";" : null}
  -webkit-box-shadow: ${props =>
    props.borderAndShadow ? `0px 0px 10px 0px rgb(107 25 219/ 20%)` : "none"};

  padding: ${props => (props.padding ? props.padding : "1vw 0.5vw")};
  border-radius: ${props =>
    props.borderRadius ? props.borderRadius + "px" : "80px"};
  outline: 0;
  ::placeholder {
    font-size: 1.4rem;
    color: ${Colors.overlay[40]};
  }
  ${props =>
    props.isFirstChapter
      ? "border-left-color: #cc00a0; border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
      : ""};



  @media ${deviceRange.laptop} {
    margin-bottom: 0px;
    height:50px;

  }

  @media ${deviceRange.laptopL} {
    margin-bottom: 0px;
  }
  @media ${deviceRange.laptopM} {
    margin-bottom: 0px;
  }

  @media ${deviceRange.tablet} {
   
    padding: 20px;
  } 
  @media ${deviceRange.desktop} {
    padding: 20px;

  } 
  @media ${deviceRange.mobile} {
    font-size:12px;
  }
`;

export const BaseInputMobile = styled.input<InputProps>`
  width: 100%;
  color: black;
  background: white;
  margin-bottom: 2px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-align: left;
  border: ${props =>
    props.borderAndShadow
      ? "none"
      : `1px solid ${props.borderColor ? props.borderColor : "lightgray"}`};
  ${props =>
    props.borderColor ? "border-color:" + props.borderColor + ";" : null}
  -webkit-box-shadow: ${props =>
    props.borderAndShadow
      ? `inset
      0 0 0 50px #fff,
    4px 4px 25px rgb(107 201 219 / 25%)`
      : "none"};

  padding: 1vw 0.5vw;
  border-radius: ${props =>
    props.borderRadius ? props.borderRadius + "px" : "80px"};
  outline: 0;
  ::placeholder {
    font-size: 1.4rem;
    color: ${Colors.overlay[40]};
  }
  ${props =>
    props.isFirstChapter
      ? "border-left-color: #cc00a0; border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
      : ""};


  @media ${deviceRange.laptop} {
    margin-bottom: 0px;
  }

  @media ${deviceRange.laptopM} {
    margin-bottom: 0px;
  }

  @media ${deviceRange.desktop} {
    padding: 20px 10px;
  }
`;

export const ErrorLabel = styled.span`
  display: flex;
  max-width: 100%;
  font-family: ${Fonts.heading};
  font-size: 1.4rem;
  color: ${Colors.red[100]};
  padding: 0.5rem 0 0 1rem;
`;

export const HelpText = styled.span`
  display: flex;
  max-width: 100%;
  font-family: ${Fonts.paragraph};
  font-size: 1.3rem;
  color: ${Colors.typoPlaceholder};
  padding: 0.5rem 0 0 1rem;
`;

export const IconView = styled.span`
  position: absolute;
  top: 25px;
  right: 1.2rem;
  cursor: pointer;
  transform: translateY(-1.2rem);
`;

export const IconViewLogin = styled.span`
  position: absolute;
  top: 46%;
  right: 5%;
  cursor: pointer;
  transform: translateY(-1.2rem);

  @media ${deviceRange.desktop} {
  }

  @media ${deviceRange.mobile} {
  }
`;

export const IconViewLetter = styled.span`
  position: absolute;
  top: 46%;
  right: 5%;
  margin: auto;
  border-radius: 3px;
  transform: translateY(-1.2rem);

  @media ${deviceRange.desktop} {
  }

  @media ${deviceRange.mobile} {
  }
`;
