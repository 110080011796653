import React, { useEffect, useState } from "react";
import { useNavigation, useViewport } from "../../hooks";
import data from "../Blog/Blog.data";
import { deviceSize } from "../../consts";
import { Link, useParams } from "react-router-dom";
import { Images } from "../../environment/theme/Images";
import ArticleItem from "./ArticleItem";

import {
  DesignByBlock,
  FooterBlockLinks,
  FooterContactsBlock,
  FooterLine,
  FooterLink,
  GlobalStyle,
  HeaderBlockButtons,
  HeaderBlockLinks,
  HeaderBlockLogo,
  HeaderBurgerMenu,
  HeaderContainer,
  HeaderLink,
  HeaderMobileWrapper,
  HeadetButtonLog,
  HeadetButtonReg,
  ImageBurgerMenu,
  ImageCloseButton,
  ImageCloseButtonWrapper,
  LandingLogoHeader,
  LogIn,
  PopupBurgerMenu,
  SocialBlock,
  SocialBlockText,
  SocialBlockTextBefSoc,
  SocialBlockTextBlock
} from "../Landing/Landing.style";

export function BlogArticle() {
  const width = useViewport();
  const { routes, history } = useNavigation();
  const [scroll, setScroll] = useState<boolean>(false);
  const [burgetMenuShow, setBurgetMenuShow] = useState<boolean>(false);

  const { url }: any = useParams();

  useEffect(() => {
    function scrolling() {
      if (window.pageYOffset > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    }
    window.addEventListener("scroll", scrolling);
    return () => {
      window.removeEventListener("scroll", scrolling);
    };
  }, []);

  const scrollToElement = (el: any, a?: any) => {
    const y = el?.current?.getBoundingClientRect().top + window.scrollY;

    window.scroll({
      top: y - (a === "a" ? 0 : 130),
      behavior: "smooth"
    });
  };
  return (
    <>
      <GlobalStyle />
      {width >= parseInt(deviceSize.laptopM.min) ? (
        <HeaderContainer>
          <HeaderBlockLogo>
            <Link to="/">
              <LandingLogoHeader src={Images.landingLogo} alt="" />
            </Link>
          </HeaderBlockLogo>
          <HeaderBlockLinks>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Varför kunskapsöverföring?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              För vem?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Om oss
            </HeaderLink>

            <HeaderLink onClick={() => history.push(routes.usefuldocuments)}>
              Användbart material
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.dictionary)}>
              Ordbok
            </HeaderLink>
            <HeaderLink backlight={1} onClick={() => history.push(routes.blog)}>
              Blogg
            </HeaderLink>
          </HeaderBlockLinks>
          <HeaderBlockButtons>
            <HeadetButtonReg onClick={() => history.push(routes.signup)}>
              Skapa konto
            </HeadetButtonReg>
            <HeadetButtonLog
              customWidth={128}
              onClick={() => history.push(routes.login)}
            >
              Logga in
            </HeadetButtonLog>
          </HeaderBlockButtons>
        </HeaderContainer>
      ) : width >= parseInt(deviceSize.tablet.min) ? (
        <HeaderContainer>
          <HeaderBlockLogo>
            <Link to="/">
              <LandingLogoHeader src={Images.landingLogo} alt="" />
            </Link>
          </HeaderBlockLogo>
          <HeaderBlockButtons>
            <HeadetButtonReg onClick={() => history.push(routes.signup)}>
              Skapa konto
            </HeadetButtonReg>
            <HeadetButtonLog
              customWidth={128}
              onClick={() => history.push(routes.login)}
            >
              Logga in
            </HeadetButtonLog>
            <HeaderBurgerMenu onClick={() => setBurgetMenuShow(true)}>
              <ImageBurgerMenu src={Images.landingBurgerMenu} alt="" />
            </HeaderBurgerMenu>
          </HeaderBlockButtons>
        </HeaderContainer>
      ) : (
        <HeaderContainer>
          <HeaderMobileWrapper>
            <HeaderBlockLogo>
              <Link to="/">
                <LandingLogoHeader src={Images.landingLogo} alt="" />
              </Link>
            </HeaderBlockLogo>
            <HeaderBurgerMenu onClick={() => setBurgetMenuShow(true)}>
              <ImageBurgerMenu src={Images.landingBurgerMenu} alt="" />
            </HeaderBurgerMenu>
          </HeaderMobileWrapper>
          <HeaderMobileWrapper>
            <HeadetButtonReg onClick={() => history.push(routes.signup)}>
              Skapa konto
            </HeadetButtonReg>
            <LogIn onClick={() => history.push(routes.login)}>Logga in</LogIn>
          </HeaderMobileWrapper>
        </HeaderContainer>
      )}
      {width >= parseInt(deviceSize.laptop.min) ? (
        <PopupBurgerMenu isOpen={burgetMenuShow}>
          <HeaderBlockLinks>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Varför kunskapsöverföring?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              För vem?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Om oss
            </HeaderLink>

            <HeaderLink onClick={() => history.push(routes.usefuldocuments)}>
              Användbart material
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.dictionary)}>
              Ordbok
            </HeaderLink>
            <HeaderLink backlight={1} onClick={() => history.push(routes.root)}>
              Blogg
            </HeaderLink>
          </HeaderBlockLinks>
          <ImageCloseButtonWrapper onClick={() => setBurgetMenuShow(false)}>
            <ImageCloseButton src={Images.landingCloseIcon} />
          </ImageCloseButtonWrapper>
        </PopupBurgerMenu>
      ) : (
        <PopupBurgerMenu isOpen={burgetMenuShow}>
          <ImageCloseButtonWrapper onClick={() => setBurgetMenuShow(false)}>
            <ImageCloseButton src={Images.landingCloseIcon} />
          </ImageCloseButtonWrapper>
          <HeaderBlockLinks>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Varför kunskapsöverföring?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              För vem?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Om oss
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.usefuldocuments)}>
              Användbart material
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.dictionary)}>
              Ordbok
            </HeaderLink>
            <HeaderLink backlight={1} onClick={() => history.push(routes.root)}>
              Blogg
            </HeaderLink>
          </HeaderBlockLinks>
        </PopupBurgerMenu>
      )}

      <ArticleItem />

      <FooterLine />

      <FooterContactsBlock>
        <FooterBlockLinks>
          <FooterLink onClick={() => history.push(routes.root)}>
            Varför kunskapsöverföring?
          </FooterLink>
          <FooterLink onClick={() => history.push(routes.root)}>
            För vem?
          </FooterLink>
          <FooterLink onClick={() => history.push(routes.root)}>
            Om oss
          </FooterLink>
          <FooterLink onClick={() => history.push(routes.root)}>
            Kontakta
          </FooterLink>
        </FooterBlockLinks>
        <img
          src={Images.landingFooterlogo}
          onClick={() => scrollToElement(0)}
          style={{ cursor: "pointer" }}
          alt=""
        />
        <SocialBlock>
          <SocialBlockText>Följ oss i sociala medier</SocialBlockText>
          <SocialBlockTextBlock>
            <a href="https://www.instagram.com/forbundetviunga/" target="blank">
              <img src={Images.landingInsta} alt="" />
            </a>
            <a href="https://www.facebook.com/pg/Digitalt-verktyg-f%C3%B6r-kunskaps%C3%B6verf%C3%B6ring-110620453810556/">
              <img src={Images.landingFacebook} alt="" />
            </a>
            <a
              href="https://www.youtube.com/user/ForbundetViUnga"
              target="blank"
            >
              <img src={Images.landingYoutube} alt="" />
            </a>
          </SocialBlockTextBlock>
          <SocialBlockTextBlock>
            <img src={Images.landingVector} alt="" />
            <SocialBlockTextBefSoc>
              Alla rättigheter förbehållna
            </SocialBlockTextBefSoc>
          </SocialBlockTextBlock>
        </SocialBlock>
      </FooterContactsBlock>
      <DesignByBlock></DesignByBlock>
    </>
  );
}
