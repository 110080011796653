//@ts-nocheck
import React, { useState, useEffect, useCallback, useReducer } from "react";
import { FormFieldData } from "@spridakunskap/api";
import {
  HintsContainer,
  BlockEditor,
  BlockA4,
  BlockInterface,
  MaketContainer,
  MaketMenu,
  MaketToolbar,
  MaketWrapper,
  IndentsBlock,
  MenuBarWrapper
} from "./QuillContainer.style";
import { LightButton, ButtonSize, DefaultButton } from "../../components/ui";
import tinymce from "tinymce";
import { v4 as uuidv4 } from "uuid";
import { DropdownIndents } from "./DropdownIndents";
import { PopupPdf } from "./PopupPdf";
import {
  setDocumentEditor,
  handoverReducer,
  handoverInitialState
} from "../../pages/Handover/Handover.reducer";

function insertEl(newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}
interface QuillProps {
  hints: Array<FormFieldData>;
  initialDocTemplate?: string;
  loading: boolean;
  onSaveTemplate: (temlate: string, indents: string) => void;
  indents: string;
  user?: any;
}
export interface Indents {
  left: string;
  top: string;
  right: string;
  bottom: string;
}

const blockTypes = [
  {
    label: "Start block",
    name: "start"
  },
  {
    label: "End block",
    name: "end"
  },
  {
    label: "Page break",
    name: "page-break"
  },
  {
    label: "Title block",
    name: "title"
  },
  {
    label: "Description block",
    name: "description"
  },
  {
    label: "Value block",
    name: "value"
  },
  {
    label: "Start question block",
    name: "start-question"
  },
  {
    label: "End question block",
    name: "end-question"
  }
];

function generateQuickPanel(blocks = [], onClick = () => {}, position) {
  const container = document.createElement("div");
  container.style.cssText = `
      display: none;
      position: absolute;
      justify-content: center;
      ${position === "top" ? `top: -30px;` : `bottom: -30px;`}
      left: 50%;
      right: 50%;
      z-index: 10;
      white-space: nowrap;
    `;
  const wrapper = document.createElement("div");
  wrapper.style.cssText = `
      display: flex;
      align-items: center;
      padding: 5px 0 5px 5px;
      background: #F2F3F5;
      ${
        position === "top"
          ? `border-radius: 10px 10px 0 0;`
          : `border-radius: 0 0 10px 10px;`
      }
    `;

  blocks.forEach(({ label, name }) => {
    const button = document.createElement("button");
    button.style.cssText = `
      border: 0;
      background: #fff;
      color: #5A5A5A;
      font-family: Kanit Regular;
      padding: 0 3px;
      margin-right: 5px;
      cursor: pointer;
      border-radius: 3px;
    `;
    button.innerHTML = label;
    button.onclick = () => onClick(name);
    wrapper.appendChild(button);
  });

  container.appendChild(wrapper);

  return container;
}

export const QuillContainer = ({
  hints,
  onSaveTemplate,
  initialDocTemplate,
  loading,
  indents,
  user
}: QuillProps) => {
  const [editorsOrder, setEditorsOrder] = useState([]);
  const [showIndents, setShowIndents] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [{}, dispatch] = useReducer(handoverReducer, handoverInitialState);

  const getNormalizedContent = useCallback(() => {
    const str = editorsOrder.map(editorId => {
      const item = tinymce.editors.find(editor => editor.id === editorId);

      if (item.id.includes("value")) {
        return `<div data-block-type="value" style="page-break-inside: avoid;">${item.getContent()}</div>`;
      } else if (item.id.includes("description")) {
        return `<div data-block-type="description" style="page-break-inside: avoid;">${item.getContent()}</div>`;
      } else if (item.id.includes("title")) {
        return `<div data-block-type="title" style="page-break-inside: avoid;">${item.getContent()}</div>`;
      } else if (item.id.includes("page-break")) {
        return `<div data-block-type="pageBreak" style="page-break-after: always;"></div>`;
      } else if (item.id.includes("end-question")) {
        return `<div data-block-type="endQuestionBlock"></div>`;
      } else if (item.id.includes("start-question")) {
        return `<div data-block-type="startQuestionBlock"></div>`;
      } else if (item.id.includes("start")) {
        return `<div data-block-type="startBlock"></div>`;
      } else if (item.id.includes("end")) {
        return `<div data-block-type="endBlock"></div>`;
      }
    });
    const finishData = str.join("");
    return finishData;
  }, [editorsOrder]);

  const [indentsValues, setIndentsValues] = useState<Indents>(
    JSON.parse(indents)
  );

  const saveFunc = async () => {
    dispatch(setDocumentEditor(getNormalizedContent()));
    onSaveTemplate(getNormalizedContent(), JSON.stringify(indentsValues));
  };

  const updateEditorOrder = (from, to) => {
    setEditorsOrder(editorIds =>
      editorIds.map(id => {
        if (id === from) {
          return to;
        } else if (id === to) {
          return from;
        }
        return id;
      })
    );
  };

  useEffect(() => {
    setIndentsValues(JSON.parse(indents));
  }, [indents]);

  useEffect(() => {
    const lengthTemplate = getNormalizedContent();
    const seconds = Math.round(lengthTemplate.length / 4);
    setTimeout(() => setDisabled(false), seconds);
  }, [getNormalizedContent]);

  useEffect(() => {
    tinymce.remove();
    const docContainer = document.getElementById("editor");
    docContainer.innerHTML = "";
    if (initialDocTemplate) {
      initialDocTemplate.match(
        /<div data-block-type="startQuestionBlock">(.|\n|\r)*?<div data-block-type="endQuestionBlock"><\/div>/g
      );
      const blocksArray = initialDocTemplate.match(
        /<div data-block-type="[a-zA-Z]*"(| style="page-break-after: always;"| style="page-break-inside: avoid;")>(.|\n|\r)*?(?=(<div data-block-type="[a-zA-Z]*"(| style="page-break-after: always;"| style="page-break-inside: avoid;")>|$))/g
      );

      if (blocksArray) {
        let ids = [];
        blocksArray.forEach(el => {
          const blockType = el.match(/data-block-type="([a-zA-Z]*)"/)[1];
          const initialContent = String(el.match(/>(?:.|\n|\r)+(<\/div>$)/g));
          const newInitialContent = initialContent.substr(
            1,
            initialContent.length - 7
          );

          switch (blockType) {
            case "title": {
              const id = createNewEditorBlock("title", newInitialContent);
              return ids.push(id);
            }
            case "description": {
              const id = createNewEditorBlock("description", newInitialContent);
              return ids.push(id);
            }
            case "value": {
              const id = createNewEditorBlock("value", newInitialContent);
              return ids.push(id);
            }
            case "pageBreak": {
              const id = addPageBreakBlock();
              return ids.push(id);
            }
            case "startBlock": {
              const id = addStartBlock();
              return ids.push(id);
            }
            case "startQuestionBlock": {
              const id = addStartQuestionBlock();
              return ids.push(id);
            }
            case "endBlock": {
              const id = addEndBlock();
              return ids.push(id);
            }
            case "endQuestionBlock": {
              const id = addEndQuestionBlock();
              return ids.push(id);
            }
            default:
              return null;
          }
        });
        setEditorsOrder(ids);
      }
    }
  }, [initialDocTemplate]);

  const createNewEditorBlock = (
    typeBlock,
    initialContent,
    target,
    position
  ) => {
    const divWrapper = document.createElement("div");
    const id = uuidv4();
    divWrapper.id = `wrapper${id}`;
    divWrapper.dataset.id = id;
    divWrapper.dataset.typeBlock = typeBlock;
    divWrapper.style.cssText = `
      position: relative;
      border: 1px dashed lightgray;
    `;
    const divBlockType = document.createElement("div");
    divBlockType.innerHTML = `<h2>${typeBlock.toUpperCase()} BLOCK</h2>`;
    divBlockType.style.cssText = `
      position: absolute;
      letter-spacing: 0.3em;
      color: lightgray;
      text-align: right;
      width: 100%;
    `;
    const btnDelete = document.createElement("BUTTON");
    btnDelete.innerHTML = "X";
    btnDelete.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-radius: 3px;
      padding: 4px;
      position: absolute;
      right: -18px;
      top: -1px;
    `;
    const btnUpBlock = document.createElement("BUTTON");
    btnUpBlock.dataset.id = id;
    btnUpBlock.dataset.typeBlock = typeBlock;
    btnUpBlock.dataset.wrapper = `wrapper${id}`;
    btnUpBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 6L15 9.5L14.3 10.2L12 7.9L12 19H11L11 7.9L8.7 10.2L8 9.5L11.5 6Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnUpBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-right: 1px solid #ddd;
    `;
    const btnDownBlock = document.createElement("BUTTON");
    btnDownBlock.dataset.id = id;
    btnDownBlock.dataset.typeBlock = typeBlock;
    btnDownBlock.dataset.wrapper = `wrapper${id}`;
    btnDownBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 19L9 15.5L9.7 14.8L12 17.1V6H13V17.1L15.3 14.8L16 15.5L12.5 19Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnDownBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
    `;
    const divBtn = document.createElement("div");
    divBtn.style.cssText = `
      display: flex;
      align-items: center;
      position: absolute;
      flex-direction: row;
      left: -37px;
      top: -15px;
      visibility: hidden;
      padding: 15px 3px;
    `;
    divBtn?.appendChild(btnUpBlock);
    divBtn?.appendChild(btnDownBlock);

    const divEditor = document.createElement("div");
    divEditor.id = `${typeBlock}-block-${id}`;
    const editor = document.getElementById("editor");
    if (target) {
      if (position === "prev") {
        editor?.insertBefore(divWrapper, target);
      } else {
        insertEl(divWrapper, target);
      }
    } else {
      editor?.appendChild(divWrapper);
    }
    divWrapper?.appendChild(divBtn);
    divWrapper?.appendChild(divBlockType);
    divWrapper?.appendChild(divEditor);
    divWrapper?.appendChild(btnDelete);

    const quickPanelTop = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "top"
    );
    const quickPanelBottom = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex + 1, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "bottom"
    );
    divWrapper?.appendChild(quickPanelTop);
    divWrapper?.appendChild(quickPanelBottom);

    btnDelete.addEventListener("click", () => {
      tinymce.execCommand("mceRemoveEditor", true, `${typeBlock}-block-${id}`);
      divWrapper.remove();
      setEditorsOrder(prev => {
        return prev.filter(item => item !== `${typeBlock}-block-${id}`);
      });
    });
    divWrapper.onmouseover = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: visible;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "flex";
      quickPanelBottom.style.display = "flex";
    };
    divWrapper.onmouseout = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: hidden;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "none";
      quickPanelBottom.style.display = "none";
    };

    btnUpBlock.addEventListener("click", () => {
      const buttonData = btnUpBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const prevEditorEl = currentEditor?.previousElementSibling;
      if (!prevEditorEl) {
        return false;
      }
      const prevEditorData = {
        id: prevEditorEl.dataset.id,
        typeBlock: prevEditorEl.dataset.typeBlock,
        wrapperId: `${prevEditorEl.dataset.typeBlock}-block-${prevEditorEl.dataset.id}`
      };

      insertEl(prevEditorEl, currentEditor);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${prevEditorData.typeBlock}-block-${prevEditorData.id}`;
      updateEditorOrder(from, to);
    });
    btnDownBlock.addEventListener("click", () => {
      const buttonData = btnDownBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const nextEditorEl = currentEditor?.nextElementSibling;

      if (!nextEditorEl) {
        return false;
      }
      const nextEditorData = {
        id: nextEditorEl.dataset.id,
        typeBlock: nextEditorEl.dataset.typeBlock,
        wrapperId: `${nextEditorEl.dataset.typeBlock}-block-${nextEditorEl.dataset.id}`
      };
      insertEl(currentEditor, nextEditorEl);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${nextEditorData.typeBlock}-block-${nextEditorData.id}`;
      updateEditorOrder(from, to);
    });
    switch (typeBlock) {
      case "title":
        titleInstance({ id, initialContent });
        break;
      case "description":
        descriptionInstance({ id, initialContent });
        break;

      case "value":
        valueInstance({ id, initialContent });
        break;
    }
    return `${typeBlock}-block-${id}`;
  };

  const addTitleBlock = () => {
    const id = createNewEditorBlock("title");
    return id;
  };

  const addDescriptionBlock = () => {
    const id = createNewEditorBlock("description");
    return id;
  };

  const addValuesBlock = () => {
    const id = createNewEditorBlock("value");
    return id;
  };

  const addPageBreakBlock = (target, position) => {
    const divWrapper = document.createElement("div");
    const id = uuidv4();
    const typeBlock = "page-break";
    divWrapper.id = `wrapper${id}`;
    divWrapper.dataset.id = id;
    divWrapper.dataset.typeBlock = typeBlock;
    divWrapper.style.cssText = `
      position: relative;
      border: 1px dashed lightgray;
    `;
    const btnUpBlock = document.createElement("BUTTON");
    btnUpBlock.dataset.id = id;
    btnUpBlock.dataset.typeBlock = typeBlock;
    btnUpBlock.dataset.wrapper = `wrapper${id}`;
    btnUpBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 6L15 9.5L14.3 10.2L12 7.9L12 19H11L11 7.9L8.7 10.2L8 9.5L11.5 6Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnUpBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-right: 1px solid #ddd;
    `;
    const btnDownBlock = document.createElement("BUTTON");
    btnDownBlock.dataset.id = id;
    btnDownBlock.dataset.typeBlock = typeBlock;
    btnDownBlock.dataset.wrapper = `wrapper${id}`;
    btnDownBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 19L9 15.5L9.7 14.8L12 17.1V6H13V17.1L15.3 14.8L16 15.5L12.5 19Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnDownBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
    `;
    const divBtn = document.createElement("div");
    divBtn.style.cssText = `
      display: flex;
      align-items: center;
      position: absolute;
      flex-direction: row;
      left: -37px;
      top: -15px;
      visibility: hidden;
      padding: 15px 3px;
    `;
    divBtn?.appendChild(btnUpBlock);
    divBtn?.appendChild(btnDownBlock);
    const quickPanelTop = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "top"
    );
    const quickPanelBottom = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex + 1, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "bottom"
    );
    divWrapper?.appendChild(quickPanelTop);
    divWrapper?.appendChild(quickPanelBottom);
    const btnDelete = document.createElement("BUTTON");
    btnDelete.innerHTML = "X";
    btnDelete.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-radius: 3px;
      padding: 4px;
      position: absolute;
      right: -18px;
      top: -1px;
    `;
    divWrapper.onmouseover = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: visible;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "flex";
      quickPanelBottom.style.display = "flex";
    };
    divWrapper.onmouseout = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: hidden;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "none";
      quickPanelBottom.style.display = "none";
    };
    btnUpBlock.addEventListener("click", () => {
      const buttonData = btnUpBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const prevEditorEl = currentEditor?.previousElementSibling;
      if (!prevEditorEl) {
        return false;
      }
      const prevEditorData = {
        id: prevEditorEl.dataset.id,
        typeBlock: prevEditorEl.dataset.typeBlock,
        wrapperId: `${prevEditorEl.dataset.typeBlock}-block-${prevEditorEl.dataset.id}`
      };

      insertEl(prevEditorEl, currentEditor);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${prevEditorData.typeBlock}-block-${prevEditorData.id}`;
      updateEditorOrder(from, to);
    });
    btnDownBlock.addEventListener("click", () => {
      const buttonData = btnDownBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const nextEditorEl = currentEditor?.nextElementSibling;

      if (!nextEditorEl) {
        return false;
      }
      const nextEditorData = {
        id: nextEditorEl.dataset.id,
        typeBlock: nextEditorEl.dataset.typeBlock,
        wrapperId: `${nextEditorEl.dataset.typeBlock}-block-${nextEditorEl.dataset.id}`
      };
      insertEl(currentEditor, nextEditorEl);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${nextEditorData.typeBlock}-block-${nextEditorData.id}`;
      updateEditorOrder(from, to);
    });

    const divEditor = document.createElement("div");
    divEditor.id = `page-break-block-${id}`;
    const editor = document.getElementById("editor");
    if (target) {
      if (position === "prev") {
        editor?.insertBefore(divWrapper, target);
      } else {
        insertEl(divWrapper, target);
      }
    } else {
      editor?.appendChild(divWrapper);
    }
    divWrapper?.appendChild(divEditor);
    divWrapper?.appendChild(btnDelete);
    divWrapper?.appendChild(divBtn);

    btnDelete.addEventListener("click", () => {
      tinymce.execCommand("mceRemoveEditor", true, `page-break-block-${id}`);
      divWrapper.remove();
      setEditorsOrder(prev => {
        return prev.filter(item => item !== `${typeBlock}-block-${id}`);
      });
    });
    tinymce
      .init({
        selector: `#page-break-block-${id}`,
        inline: true,
        height: 23,
        readonly: 1,
        fixed_toolbar_container: "#menubar",
        setup: function(editor) {
          editor.on("init", function(e) {
            editor.setContent("<--Page break-->" || "");
          });
        }
      })
      .then(test => {})
      .catch(error => {
        console.log(error);
      });
    return `${typeBlock}-block-${id}`;
  };

  const addStartBlock = (target, position) => {
    const divWrapper = document.createElement("div");
    const id = uuidv4();
    const typeBlock = "start";
    divWrapper.id = `wrapper${id}`;
    divWrapper.dataset.id = id;
    divWrapper.dataset.typeBlock = typeBlock;
    divWrapper.style.cssText = `
      position: relative;
      border: 1px dashed lightgray;
    `;
    const btnUpBlock = document.createElement("BUTTON");
    btnUpBlock.dataset.id = id;
    btnUpBlock.dataset.typeBlock = typeBlock;
    btnUpBlock.dataset.wrapper = `wrapper${id}`;
    btnUpBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 6L15 9.5L14.3 10.2L12 7.9L12 19H11L11 7.9L8.7 10.2L8 9.5L11.5 6Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnUpBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-right: 1px solid #ddd;
    `;
    const btnDownBlock = document.createElement("BUTTON");
    btnDownBlock.dataset.id = id;
    btnDownBlock.dataset.typeBlock = typeBlock;
    btnDownBlock.dataset.wrapper = `wrapper${id}`;
    btnDownBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 19L9 15.5L9.7 14.8L12 17.1V6H13V17.1L15.3 14.8L16 15.5L12.5 19Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnDownBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
    `;
    const divBtn = document.createElement("div");
    divBtn.style.cssText = `
      display: flex;
      align-items: center;
      position: absolute;
      flex-direction: row;
      left: -37px;
      top: -15px;
      visibility: hidden;
      padding: 15px 3px;
    `;
    divBtn?.appendChild(btnUpBlock);
    divBtn?.appendChild(btnDownBlock);
    const quickPanelTop = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "top"
    );
    const quickPanelBottom = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex + 1, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "bottom"
    );
    divWrapper?.appendChild(quickPanelTop);
    divWrapper?.appendChild(quickPanelBottom);
    divWrapper.onmouseover = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: visible;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "flex";
      quickPanelBottom.style.display = "flex";
    };
    divWrapper.onmouseout = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: hidden;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "none";
      quickPanelBottom.style.display = "none";
    };
    btnUpBlock.addEventListener("click", () => {
      const buttonData = btnUpBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const prevEditorEl = currentEditor?.previousElementSibling;

      if (!prevEditorEl) {
        return false;
      }
      const prevEditorData = {
        id: prevEditorEl.dataset.id,
        typeBlock: prevEditorEl.dataset.typeBlock,
        wrapperId: `${prevEditorEl.dataset.typeBlock}-block-${prevEditorEl.dataset.id}`
      };

      insertEl(prevEditorEl, currentEditor);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${prevEditorData.typeBlock}-block-${prevEditorData.id}`;
      updateEditorOrder(from, to);
    });
    btnDownBlock.addEventListener("click", () => {
      const buttonData = btnDownBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const nextEditorEl = currentEditor?.nextElementSibling;

      if (!nextEditorEl) {
        return false;
      }
      const nextEditorData = {
        id: nextEditorEl.dataset.id,
        typeBlock: nextEditorEl.dataset.typeBlock,
        wrapperId: `${nextEditorEl.dataset.typeBlock}-block-${nextEditorEl.dataset.id}`
      };
      insertEl(currentEditor, nextEditorEl);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${nextEditorData.typeBlock}-block-${nextEditorData.id}`;
      updateEditorOrder(from, to);
    });
    const btnDelete = document.createElement("BUTTON");
    btnDelete.innerHTML = "X";
    btnDelete.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-radius: 3px;
      padding: 4px;
      position: absolute;
      right: -18px;
      top: -1px;
    `;
    const divEditor = document.createElement("div");
    divEditor.id = `start-block-${id}`;
    const editor = document.getElementById("editor");
    if (target) {
      if (position === "prev") {
        editor?.insertBefore(divWrapper, target);
      } else {
        insertEl(divWrapper, target);
      }
    } else {
      editor?.appendChild(divWrapper);
    }
    divWrapper?.appendChild(divEditor);
    divWrapper?.appendChild(btnDelete);
    divWrapper?.appendChild(divBtn);

    btnDelete.addEventListener("click", () => {
      tinymce.execCommand("mceRemoveEditor", true, `start-block-${id}`);
      divWrapper.remove();
      setEditorsOrder(prev => {
        return prev.filter(item => item !== `${typeBlock}-block-${id}`);
      });
    });
    tinymce
      .init({
        selector: `#start-block-${id}`,
        inline: true,
        height: 23,
        readonly: 1,
        fixed_toolbar_container: "#menubar",
        setup: function(editor) {
          editor.on("init", function(e) {
            editor.setContent("<--Start block-->" || "");
          });
        }
      })
      .then(test => {})
      .catch(error => {
        console.log(error);
      });
    return `${typeBlock}-block-${id}`;
  };
  const addEndBlock = (target, position) => {
    const divWrapper = document.createElement("div");
    const id = uuidv4();
    const typeBlock = "end";
    divWrapper.id = `wrapper${id}`;
    divWrapper.dataset.id = id;
    divWrapper.dataset.typeBlock = typeBlock;
    divWrapper.style.cssText = `
      position: relative;
      border: 1px dashed lightgray;
    `;
    const btnDelete = document.createElement("BUTTON");
    btnDelete.innerHTML = "X";
    btnDelete.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-radius: 3px;
      padding: 4px;
      position: absolute;
      right: -18px;
      top: -1px;
    `;
    const btnUpBlock = document.createElement("BUTTON");
    btnUpBlock.dataset.id = id;
    btnUpBlock.dataset.typeBlock = typeBlock;
    btnUpBlock.dataset.wrapper = `wrapper${id}`;
    btnUpBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 6L15 9.5L14.3 10.2L12 7.9L12 19H11L11 7.9L8.7 10.2L8 9.5L11.5 6Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnUpBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-right: 1px solid #ddd;
    `;
    const btnDownBlock = document.createElement("BUTTON");
    btnDownBlock.dataset.id = id;
    btnDownBlock.dataset.typeBlock = typeBlock;
    btnDownBlock.dataset.wrapper = `wrapper${id}`;
    btnDownBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 19L9 15.5L9.7 14.8L12 17.1V6H13V17.1L15.3 14.8L16 15.5L12.5 19Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnDownBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
    `;
    const divBtn = document.createElement("div");
    divBtn.style.cssText = `
      display: flex;
      align-items: center;
      position: absolute;
      flex-direction: row;
      left: -37px;
      top: -15px;
      visibility: hidden;
      padding: 15px 3px;
    `;
    divBtn?.appendChild(btnUpBlock);
    divBtn?.appendChild(btnDownBlock);
    const quickPanelTop = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "top"
    );
    const quickPanelBottom = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex + 1, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "bottom"
    );
    divWrapper?.appendChild(quickPanelTop);
    divWrapper?.appendChild(quickPanelBottom);
    divWrapper.onmouseover = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: visible;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "flex";
      quickPanelBottom.style.display = "flex";
    };
    divWrapper.onmouseout = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: hidden;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "none";
      quickPanelBottom.style.display = "none";
    };
    btnUpBlock.addEventListener("click", () => {
      const buttonData = btnUpBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const prevEditorEl = currentEditor?.previousElementSibling;

      if (!prevEditorEl) {
        return false;
      }
      const prevEditorData = {
        id: prevEditorEl.dataset.id,
        typeBlock: prevEditorEl.dataset.typeBlock,
        wrapperId: `${prevEditorEl.dataset.typeBlock}-block-${prevEditorEl.dataset.id}`
      };

      insertEl(prevEditorEl, currentEditor);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${prevEditorData.typeBlock}-block-${prevEditorData.id}`;
      updateEditorOrder(from, to);
    });
    btnDownBlock.addEventListener("click", () => {
      const buttonData = btnDownBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const nextEditorEl = currentEditor?.nextElementSibling;

      if (!nextEditorEl) {
        return false;
      }
      const nextEditorData = {
        id: nextEditorEl.dataset.id,
        typeBlock: nextEditorEl.dataset.typeBlock,
        wrapperId: `${nextEditorEl.dataset.typeBlock}-block-${nextEditorEl.dataset.id}`
      };
      insertEl(currentEditor, nextEditorEl);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${nextEditorData.typeBlock}-block-${nextEditorData.id}`;
      updateEditorOrder(from, to);
    });
    const divEditor = document.createElement("div");
    divEditor.id = `end-block-${id}`;
    const editor = document.getElementById("editor");
    if (target) {
      if (position === "prev") {
        editor?.insertBefore(divWrapper, target);
      } else {
        insertEl(divWrapper, target);
      }
    } else {
      editor?.appendChild(divWrapper);
    }
    divWrapper?.appendChild(divEditor);
    divWrapper?.appendChild(btnDelete);
    divWrapper?.appendChild(divBtn);

    btnDelete.addEventListener("click", () => {
      tinymce.execCommand("mceRemoveEditor", true, `end-block-${id}`);
      divWrapper.remove();
      setEditorsOrder(prev => {
        return prev.filter(item => item !== `${typeBlock}-block-${id}`);
      });
    });
    tinymce
      .init({
        selector: `#end-block-${id}`,
        inline: true,
        height: 23,
        readonly: 1,
        fixed_toolbar_container: "#menubar",
        setup: function(editor) {
          editor.on("init", function(e) {
            editor.setContent("<--End block-->" || "");
          });
        }
      })
      .then(test => {})
      .catch(error => {
        console.log(error);
      });
    return `${typeBlock}-block-${id}`;
  };
  const addStartQuestionBlock = (target, position) => {
    const divWrapper = document.createElement("div");
    const id = uuidv4();
    const typeBlock = "start-question";
    divWrapper.id = `wrapper${id}`;
    divWrapper.dataset.id = id;
    divWrapper.dataset.typeBlock = typeBlock;
    divWrapper.style.cssText = `
      position: relative;
      border: 1px dashed lightgray;
    `;
    const btnUpBlock = document.createElement("BUTTON");
    btnUpBlock.dataset.id = id;
    btnUpBlock.dataset.typeBlock = typeBlock;
    btnUpBlock.dataset.wrapper = `wrapper${id}`;
    btnUpBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 6L15 9.5L14.3 10.2L12 7.9L12 19H11L11 7.9L8.7 10.2L8 9.5L11.5 6Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnUpBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-right: 1px solid #ddd;
    `;
    const btnDownBlock = document.createElement("BUTTON");
    btnDownBlock.dataset.id = id;
    btnDownBlock.dataset.typeBlock = typeBlock;
    btnDownBlock.dataset.wrapper = `wrapper${id}`;
    btnDownBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 19L9 15.5L9.7 14.8L12 17.1V6H13V17.1L15.3 14.8L16 15.5L12.5 19Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnDownBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
    `;
    const divBtn = document.createElement("div");
    divBtn.style.cssText = `
      display: flex;
      align-items: center;
      position: absolute;
      flex-direction: row;
      left: -37px;
      top: -15px;
      visibility: hidden;
      padding: 15px 3px;
    `;
    divBtn?.appendChild(btnUpBlock);
    divBtn?.appendChild(btnDownBlock);
    const quickPanelTop = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "top"
    );
    const quickPanelBottom = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex + 1, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "bottom"
    );
    divWrapper?.appendChild(quickPanelTop);
    divWrapper?.appendChild(quickPanelBottom);
    divWrapper.onmouseover = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: visible;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "flex";
      quickPanelBottom.style.display = "flex";
    };
    divWrapper.onmouseout = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: hidden;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "none";
      quickPanelBottom.style.display = "none";
    };
    btnUpBlock.addEventListener("click", () => {
      const buttonData = btnUpBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const prevEditorEl = currentEditor?.previousElementSibling;

      if (!prevEditorEl) {
        return false;
      }
      const prevEditorData = {
        id: prevEditorEl.dataset.id,
        typeBlock: prevEditorEl.dataset.typeBlock,
        wrapperId: `${prevEditorEl.dataset.typeBlock}-block-${prevEditorEl.dataset.id}`
      };

      insertEl(prevEditorEl, currentEditor);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${prevEditorData.typeBlock}-block-${prevEditorData.id}`;
      updateEditorOrder(from, to);
    });
    btnDownBlock.addEventListener("click", () => {
      const buttonData = btnDownBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const nextEditorEl = currentEditor?.nextElementSibling;

      if (!nextEditorEl) {
        return false;
      }
      const nextEditorData = {
        id: nextEditorEl.dataset.id,
        typeBlock: nextEditorEl.dataset.typeBlock,
        wrapperId: `${nextEditorEl.dataset.typeBlock}-block-${nextEditorEl.dataset.id}`
      };
      insertEl(currentEditor, nextEditorEl);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${nextEditorData.typeBlock}-block-${nextEditorData.id}`;
      updateEditorOrder(from, to);
    });
    const btnDelete = document.createElement("BUTTON");
    btnDelete.innerHTML = "X";
    btnDelete.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-radius: 3px;
      padding: 4px;
      position: absolute;
      right: -18px;
      top: -1px;
    `;
    const divEditor = document.createElement("div");
    divEditor.id = `start-question-block-${id}`;
    const editor = document.getElementById("editor");
    if (target) {
      if (position === "prev") {
        editor?.insertBefore(divWrapper, target);
      } else {
        insertEl(divWrapper, target);
      }
    } else {
      editor?.appendChild(divWrapper);
    }
    divWrapper?.appendChild(divEditor);
    divWrapper?.appendChild(btnDelete);
    divWrapper?.appendChild(divBtn);

    btnDelete.addEventListener("click", () => {
      tinymce.execCommand(
        "mceRemoveEditor",
        true,
        `start-question-block-${id}`
      );
      divWrapper.remove();
      setEditorsOrder(prev => {
        return prev.filter(item => item !== `${typeBlock}-block-${id}`);
      });
    });
    tinymce
      .init({
        selector: `#start-question-block-${id}`,
        inline: true,
        height: 23,
        readonly: 1,
        fixed_toolbar_container: "#menubar",
        setup: function(editor) {
          editor.on("init", function(e) {
            editor.setContent("<--Start question block-->" || "");
          });
        }
      })
      .then(test => {})
      .catch(error => {
        console.log(error);
      });
    return `${typeBlock}-block-${id}`;
  };
  const addEndQuestionBlock = (target, position) => {
    const divWrapper = document.createElement("div");
    const id = uuidv4();
    const typeBlock = "end-question";
    divWrapper.id = `wrapper${id}`;
    divWrapper.dataset.id = id;
    divWrapper.dataset.typeBlock = typeBlock;
    divWrapper.style.cssText = `
      position: relative;
      border: 1px dashed lightgray;
    `;
    const btnDelete = document.createElement("BUTTON");
    btnDelete.innerHTML = "X";
    btnDelete.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-radius: 3px;
      padding: 4px;
      position: absolute;
      right: -18px;
      top: -1px;
    `;
    const btnUpBlock = document.createElement("BUTTON");
    btnUpBlock.dataset.id = id;
    btnUpBlock.dataset.typeBlock = typeBlock;
    btnUpBlock.dataset.wrapper = `wrapper${id}`;
    btnUpBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 6L15 9.5L14.3 10.2L12 7.9L12 19H11L11 7.9L8.7 10.2L8 9.5L11.5 6Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnUpBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
      border-right: 1px solid #ddd;
    `;
    const btnDownBlock = document.createElement("BUTTON");
    btnDownBlock.dataset.id = id;
    btnDownBlock.dataset.typeBlock = typeBlock;
    btnDownBlock.dataset.wrapper = `wrapper${id}`;
    btnDownBlock.innerHTML = `    <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 19L9 15.5L9.7 14.8L12 17.1V6H13V17.1L15.3 14.8L16 15.5L12.5 19Z"
      fill={color || "currentColor"}
    />
  </svg>`;
    btnDownBlock.style.cssText = `
      border: 0; 
      cursor: pointer; 
    `;
    const divBtn = document.createElement("div");
    divBtn.style.cssText = `
      display: flex;
      align-items: center;
      position: absolute;
      flex-direction: row;
      left: -37px;
      top: -15px;
      visibility: hidden;
      padding: 15px 3px;
    `;
    divBtn?.appendChild(btnUpBlock);
    divBtn?.appendChild(btnDownBlock);
    const quickPanelTop = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "prev"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "prev");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "top"
    );
    const quickPanelBottom = generateQuickPanel(
      blockTypes,
      type => {
        function updateOrders(createdId, sourceId) {
          setEditorsOrder(editors => {
            const currentEditorIndex = editors.findIndex(val =>
              val.includes(sourceId)
            );
            const replacedIndex =
              currentEditorIndex < 0 ? 0 : currentEditorIndex;
            const newArray = [...editors];
            newArray.splice(replacedIndex + 1, 0, createdId);
            return newArray;
          });
        }

        switch (type) {
          case "title": {
            const createdId = createNewEditorBlock(
              "title",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "description": {
            const createdId = createNewEditorBlock(
              "description",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "value": {
            const createdId = createNewEditorBlock(
              "value",
              undefined,
              divWrapper,
              "next"
            );
            updateOrders(createdId, id);
            break;
          }
          case "page-break": {
            const createdId = addPageBreakBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start": {
            const createdId = addStartBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "start-question": {
            const createdId = addStartQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end": {
            const createdId = addEndBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          case "end-question": {
            const createdId = addEndQuestionBlock(divWrapper, "next");
            updateOrders(createdId, id);
            break;
          }
          default:
            return null;
        }
      },
      "bottom"
    );
    divWrapper?.appendChild(quickPanelTop);
    divWrapper?.appendChild(quickPanelBottom);
    divWrapper.onmouseover = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: visible;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "flex";
      quickPanelBottom.style.display = "flex";
    };
    divWrapper.onmouseout = () => {
      divBtn.style.cssText = `
        display: flex;
        align-items: center;
        position: absolute;
        flex-direction: row;
        left: -37px;
        top: -15px;
        visibility: hidden;
        padding: 15px 3px;
      `;
      quickPanelTop.style.display = "none";
      quickPanelBottom.style.display = "none";
    };
    btnUpBlock.addEventListener("click", () => {
      const buttonData = btnUpBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const prevEditorEl = currentEditor?.previousElementSibling;

      if (!prevEditorEl) {
        return false;
      }
      const prevEditorData = {
        id: prevEditorEl.dataset.id,
        typeBlock: prevEditorEl.dataset.typeBlock,
        wrapperId: `${prevEditorEl.dataset.typeBlock}-block-${prevEditorEl.dataset.id}`
      };

      insertEl(prevEditorEl, currentEditor);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${prevEditorData.typeBlock}-block-${prevEditorData.id}`;
      updateEditorOrder(from, to);
    });
    btnDownBlock.addEventListener("click", () => {
      const buttonData = btnDownBlock.dataset;
      const wrapperId = buttonData.wrapper;
      const currentEditor = document.getElementById(wrapperId);
      const nextEditorEl = currentEditor?.nextElementSibling;

      if (!nextEditorEl) {
        return false;
      }
      const nextEditorData = {
        id: nextEditorEl.dataset.id,
        typeBlock: nextEditorEl.dataset.typeBlock,
        wrapperId: `${nextEditorEl.dataset.typeBlock}-block-${nextEditorEl.dataset.id}`
      };
      insertEl(currentEditor, nextEditorEl);
      const from = `${buttonData.typeBlock}-block-${buttonData.id}`;
      const to = `${nextEditorData.typeBlock}-block-${nextEditorData.id}`;
      updateEditorOrder(from, to);
    });
    const divEditor = document.createElement("div");
    divEditor.id = `end-question-block-${id}`;
    const editor = document.getElementById("editor");
    if (target) {
      if (position === "prev") {
        editor?.insertBefore(divWrapper, target);
      } else {
        insertEl(divWrapper, target);
      }
    } else {
      editor?.appendChild(divWrapper);
    }
    divWrapper?.appendChild(divEditor);
    divWrapper?.appendChild(btnDelete);
    divWrapper?.appendChild(divBtn);

    btnDelete.addEventListener("click", () => {
      tinymce.execCommand("mceRemoveEditor", true, `end-question-block-${id}`);
      divWrapper.remove();
      setEditorsOrder(prev => {
        return prev.filter(item => item !== `${typeBlock}-block-${id}`);
      });
    });
    tinymce
      .init({
        selector: `#end-question-block-${id}`,
        inline: true,
        height: 23,
        readonly: 1,
        fixed_toolbar_container: "#menubar",
        setup: function(editor) {
          editor.on("init", function(e) {
            editor.setContent("<--End question block-->" || "");
          });
        }
      })
      .then(test => {})
      .catch(error => {
        console.log(error);
      });
    return `${typeBlock}-block-${id}`;
  };

  const onHintClick = (hintToAdd: string) => {
    if (!tinymce.activeEditor) return;
    tinymce.activeEditor.execCommand("mceInsertContent", false, hintToAdd);
  };

  return (
    <>
      {showPreview && (
        <PopupPdf
          user={user}
          source={getNormalizedContent()}
          indents={JSON.stringify(indentsValues)}
          setShow={() => setShowPreview(false)}
          preview
        />
      )}
      <Hints hintValues={hints} onHintClick={onHintClick} />

      <BlockInterface>
        <DefaultButton
          title="Start block"
          onClick={() => {
            const id = addStartBlock();
            setEditorsOrder(prev => [...prev, id]);
          }}
        />
        <DefaultButton
          title="End block"
          onClick={() => {
            const id = addEndBlock();
            setEditorsOrder(prev => [...prev, id]);
          }}
        />
        <DefaultButton
          title="Start question block"
          onClick={() => {
            const id = addStartQuestionBlock();
            setEditorsOrder(prev => [...prev, id]);
          }}
        />
        <DefaultButton
          title="End question block"
          onClick={() => {
            const id = addEndQuestionBlock();
            setEditorsOrder(prev => [...prev, id]);
          }}
        />
        <DefaultButton
          title="Page break"
          onClick={() => {
            const id = addPageBreakBlock();
            setEditorsOrder(prev => [...prev, id]);
          }}
        />
        <DefaultButton
          title="Add title block"
          onClick={() => {
            const id = addTitleBlock();
            setEditorsOrder(prev => [...prev, id]);
          }}
        />
        <DefaultButton
          title="Add description block"
          onClick={() => {
            const id = addDescriptionBlock();
            setEditorsOrder(prev => [...prev, id]);
          }}
        />
        <DefaultButton
          title="Add value block"
          onClick={() => {
            const id = addValuesBlock();
            setEditorsOrder(prev => [...prev, id]);
          }}
        />
        <IndentsBlock>
          <DefaultButton
            title="Indents"
            onClick={() => {
              setShowIndents(!showIndents);
            }}
          />
          {showIndents && (
            <DropdownIndents
              indentsValues={indentsValues}
              setIndentsValues={newState => setIndentsValues(newState)}
              setShowIndents={() => setShowIndents(false)}
            />
          )}
        </IndentsBlock>
        <DefaultButton
          title="Preview"
          onClick={() => setShowPreview(!showPreview)}
        />
      </BlockInterface>
      <MaketWrapper>
        <MaketContainer>
          <MaketMenu>
            Handover File Edit View Insert Format Tools Table Help
          </MaketMenu>
          <MaketToolbar></MaketToolbar>
        </MaketContainer>

        <MenuBarWrapper>
          <div id="menubar" style={{ marginTop: "-2px" }}></div>
        </MenuBarWrapper>
      </MaketWrapper>

      <BlockEditor>
        <BlockA4 indents={indentsValues}>
          <div id="editor"></div>
        </BlockA4>
      </BlockEditor>
      <DefaultButton
        loading={loading}
        title="save"
        onClick={() => saveFunc()}
        disabled={disabled}
      />
    </>
  );
};

interface VariablesContainerProps {
  hintValues: Array<FormFieldData>;
  onHintClick: (hint: string) => void;
}

const Hints = ({ hintValues, onHintClick }: VariablesContainerProps) => {
  const hints = [];

  for (let { name } of hintValues) {
    hints.push(
      <LightButton
        key={uuidv4()}
        size={ButtonSize.SM}
        title={name}
        onClick={() => onHintClick(`{{ ${name} }}`, hintValues, name)}
      />
    );
  }
  return <HintsContainer>{hints}</HintsContainer>;
};

interface InstanceProps {
  id: string;
  initialContent?: string;
}

const titleInstance = ({ id, initialContent }: InstanceProps) => {
  return tinymce
    .init({
      selector: `#title-block-${id}`,
      inline: true,
      fixed_toolbar_container: "#menubar",
      setup: function(editor) {
        editor.on("init", function(e) {
          editor.setContent(initialContent || "");
        });
      },
      plugins: [
        "advlist autolink link lists charmap hr anchor pagebreak",
        "searchreplace visualblocks visualchars code fullscreen  nonbreaking",
        "template paste help"
      ],
      toolbar:
        "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | " +
        "bullist numlist outdent indent | link image | print preview media fullpage | " +
        "forecolor backcolor | help",
      menu: {
        favs: {
          title: "Handover",
          items: "code visualaid | searchreplace | emoticons"
        }
      },
      menubar: "favs file edit view insert format tools table help"
    })
    .then(test => {})
    .catch(error => {
      console.log(error);
    });
};

const descriptionInstance = ({ id, initialContent }: InstanceProps) => {
  return tinymce
    .init({
      selector: `#description-block-${id}`,
      inline: true,
      fixed_toolbar_container: "#menubar",
      setup: function(editor) {
        editor.on("init", function(e) {
          editor.setContent(initialContent || "");
        });
      },
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak toc",
        "searchreplace visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "table emoticons template paste help"
      ],
      toolbar:
        "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | " +
        "bullist numlist outdent indent | link image | print preview media fullpage | " +
        "forecolor backcolor emoticons | help customInsertButton",
      menu: {
        favs: {
          title: "Handover",
          items: "code visualaid | searchreplace | emoticons"
        }
      },
      menubar: "favs file edit view insert format tools table help"
    })
    .then(test => {})
    .catch(error => {
      console.log(error);
    });
};

const valueInstance = ({ id, initialContent }: InstanceProps) => {
  return tinymce
    .init({
      selector: `#value-block-${id}`,
      inline: true,
      fixed_toolbar_container: "#menubar",
      setup: function(editor) {
        editor.on("init", function(e) {
          editor.setContent(initialContent || "");
        });
      },
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak toc",
        "searchreplace visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "table emoticons template paste help"
      ],
      toolbar:
        "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | " +
        "bullist numlist outdent indent | link image | print preview media fullpage | " +
        "forecolor backcolor emoticons | help customInsertButton",
      menu: {
        favs: {
          title: "Handover",
          items: "code visualaid | searchreplace | emoticons"
        }
      },
      menubar: "favs file edit view insert format tools table help"
    })
    .then(test => {})
    .catch(error => {
      console.log(error);
    });
};
