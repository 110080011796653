import styled from "styled-components";
import { Input } from "../../ui";
import { deviceRange } from "../../../consts";

export const DatePickerToggleInput = styled(Input)`
  width: 140px;
  min-width: 11rem;
  margin-right: 1rem;
  margin-bottom: 0;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  border: none;
  border-color: blue;
  direction: ltr;
`;

export const LandingDateIcon = styled.img`
  position: absolute;
  margin-left: 100px;
  margin-top: -21px;
`;
