export const Shadows = {
  lightest: `0 10px 20px 0 rgba(34,34,34,0.1)`,
  light: "0 10px 20px 0 rgba(34, 34, 34, 0.25)",
  medium: `0 10px 40px 0 rgba(34,34,34,0.6)`,
  button: `0 10px 60px 0 rgba(90, 90, 90, 0.8)`,
  scroll: `0 10px 20px 0 rgba(179, 179, 179, 0.25)`,
  dropDownMenu: `0px 24px 60px rgba(19, 16, 56, 0.16)`,
  meetingCard: `0 20px 40px rgba(33, 9, 9, 0.06)`,
  popovers: `0 20px 40px rgba(33, 9, 9, 0.12)`
};
