import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";

import {
  CropModalContentWrapper,
  FileInputContainer,
  FileInput,
  FileInputLabel,
  CropContainer,
  ImagePreviewContainer,
  ImagePreviewBlock,
  ImagePreview
} from "./CropModalContent.style";
import { useTranslation } from "../../../../hooks";
import { Images } from "../../../../environment";
interface CropModalContentProps {
  croppedImage: any | null;
  imageOptions: ImageOptions | null;
  setImageOptions: (imgOpt: ImageOptions | null) => void;
  setCroppedImage: (croppedImg: any) => void;
  setCroppedAreaPixels: any;
  showExample?: boolean;
}
interface ImageOptions {
  path: string;
  name: string;
  type: string;
}

export function CropModalContent({
  croppedImage,
  imageOptions,
  setImageOptions,
  setCroppedImage,
  setCroppedAreaPixels,
  showExample
}: CropModalContentProps) {
  const translate = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  function handleGetAvatar(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const imageOptions = {
        name: e.target.files[0].name,
        type: e.target.files[0].type,
        path: URL.createObjectURL(e.target.files[0])
      };

      setImageOptions(imageOptions);
      setCroppedImage(null);
    }
  }

  return (
    <CropModalContentWrapper>
      <FileInputContainer>
        <FileInput
          type="file"
          onChange={handleGetAvatar}
          accept="image/*"
          id="avatarUpload"
        />
        <FileInputLabel htmlFor="avatarUpload">
          <img src={Images.landingExportLogo} alt="" />
          {translate(({ buttons }) => buttons.uploadFileButton)}
        </FileInputLabel>
      </FileInputContainer>
      {imageOptions && (
        <>
          <CropContainer>
            <Cropper
              image={imageOptions.path}
              crop={crop}
              zoom={zoom}
              minZoom={0.5}
              maxZoom={8}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape="round"
              showGrid={false}
              cropSize={{ width: 150, height: 150 }}
            />
          </CropContainer>
          {showExample && (
            <ImagePreviewContainer>
              <ImagePreviewBlock>
                {croppedImage && (
                  <ImagePreview width={150} height={150} src={croppedImage} />
                )}
              </ImagePreviewBlock>
            </ImagePreviewContainer>
          )}
        </>
      )}
    </CropModalContentWrapper>
  );
}
