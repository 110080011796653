import React, { useState, useEffect } from "react";

import { ModalContainer } from "./SendFormDataModal.style";
import { Modal, ModalSizes } from "./../../ui";
import { useTranslation } from "./../../../hooks";
import {
  PromoButtonStart,
  PromoButtonEmail,
  ButtonWrapper
} from "../../../pages/Landing/Landing.style";
import { FieldEmail } from "./FieldEmail";

interface SendFormDataModalProps {
  showModal: boolean;
  isLoading: boolean;
  receiverEmail: string;
  setShowModal: (isShow: boolean) => void;
  onClickSendButton: (emails: Array<any>) => void;
}

export function SendFormDataModal({
  setShowModal,
  onClickSendButton,
  showModal,
  isLoading,
  receiverEmail
}: SendFormDataModalProps) {
  const [emails, setEmails] = useState<Array<any>>([]);
  const [currentIdEmail, setCurrentIdEmail] = useState<number>(1);

  const translate = useTranslation();

  const addEmail = (id: number, email: string) => {
    if (email) {
      if (id > emails.length) {
        const newEmail = {
          id: id,
          email: email
        };
        setCurrentIdEmail(id);
        setEmails([...emails, newEmail]);
      } else {
        const copyEmails = emails;
        copyEmails[id].email = email;
        setEmails([...copyEmails]);
      }
    }
  };

  useEffect(() => {
    addEmail(1, receiverEmail);
  }, []);

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      size={ModalSizes.XS}
      autoHeight={true}
      title={translate(
        ({ handoversPage }) => handoversPage.sendHandoverModal.sendFile
      )}
    >
      <ModalContainer>
        {emails.map((email, index) => (
          <FieldEmail
            key={index}
            addEmail={addEmail}
            id={index}
            receiverEmail={receiverEmail}
          />
        ))}
        <ButtonWrapper>
          <PromoButtonStart
            width={266}
            marginTop={1}
            paddingBottom={15}
            paddingTop={15}
            paddingLeft={15}
            paddingRight={15}
            shadowLeft={3}
            shadowTop={5}
            onClick={() => onClickSendButton(emails)}
          >
            {translate(({ handoversPage }) => handoversPage.send)}
          </PromoButtonStart>
          <PromoButtonEmail
            width={200}
            marginTop={1}
            paddingBottom={15}
            paddingTop={15}
            paddingLeft={15}
            paddingRight={15}
            shadowLeft={3}
            shadowTop={5}
            onClick={() => addEmail(currentIdEmail + 1, receiverEmail)}
          >
            Lägg till fler mottagare
          </PromoButtonEmail>
        </ButtonWrapper>
      </ModalContainer>
    </Modal>
  );
}
