import React, { useState } from "react";
import { useTranslation } from "./../../../hooks";
import { Input } from "../../ui";

interface FieldEmailProps {
  onClickSendButton?: (chapterName: string) => void;
  addEmail: (id: number, email: string) => void;
  id: number;
  receiverEmail: string;
}

export function FieldEmail({ addEmail, id, receiverEmail }: FieldEmailProps) {
  const translate = useTranslation();
  const [personEmail, setPersonEmail] = useState<string>(receiverEmail);

  const changeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const newEmailInput = e.currentTarget.value;
    setPersonEmail(newEmailInput);
    addEmail(id, newEmailInput);
  };

  return (
    <Input
      placeholder={translate(
        ({ handoversPage }) => handoversPage.sendHandoverModal.enterEmail
      )}
      value={personEmail}
      onChange={e => changeValue(e)}
    />
  );
}
