import React from "react";
import { Images } from "../../environment";

const data = [
  {
    id: 9,
    url: "Har du hört om SECI-modellen",
    date: "15 maj 2022",
    title: "Har du hört om SECI-modellen?",
    text: (
      <div>
        <p>
          Författarna Nonaka, I. & Takeuchi, H. beskriver i sin bok “The
          knowledge-creating company: how Japanese companies create the dynamics
          of innovation” från 1995, modellen som de kallar för SECI-modellen.{" "}
        </p>
        <p>
          Nu kanske du funderar på varför vi väljer att lyfta upp denna modellen
          här i bloggen. Jo, under tiden vi har arbetat med projektet
          Spridakunskap har denna modell återkommande dykt upp under våra
          research, såväl i böcker som beskrivningar där kunskapsöverföring
          nämns. Därmed känns det relevant att beskriva modellen även här, samt
          förklara hur vi ser på den.{" "}
        </p>
        <p style={{ display: "inline", width: "100%" }}>
          <b>Implicit och explicit</b>
        </p>
        <p>
          För att förstå SECI-modellen behöver du först hänga med på vad
          begreppen implicit och explicit kunskap betyder. Dessa begrepp
          förklaras tydligt i filmen “Implicit och explicit kunskap - vad är
          det?” som du når via denna länk;
          https://spridakunskap.se/anvandbara-dokument.{" "}
        </p>
        <p>Vill du hellre ha en skriftlig förklaring så kommer den här: </p>
        <p>
          Implicit kunskap: Kallas även för tyst kunskap. Är svår att beskriva i
          text och lärs enklast ut genom handling och social interaktion.
          Exempel på sådan kunskap är att simma eller cykla.
        </p>
        <p>
          Explicit kunskap: Är kunskap som kan skrivas ned eller kodifieras i en
          bok/dokument eller liknande och läsa sig till.
        </p>
        <p>
          <i>
            Det går att fördjupa sig mycket mer i implicit och explicit kunskap
            men i detta sammanhang nöjer vi oss med att beskriva dem enkelt och
            kort enligt ovan.{" "}
          </i>
        </p>
        <p>
          Nu när vi har kolla på implicit och explicit kunskap kan vi fördjupa
          oss i SECI-modellen.{" "}
        </p>
        <p style={{ display: "inline", width: "100%" }}>
          <b>SECI-modellen</b>
        </p>
        <p>
          Bokstäverna SECI står för Socialization, Externalization, Combination,
          och Internalization, vilket översatt på svenska blir:
        </p>

        <p style={{ marginBottom: "0", width: "100%" }}>
          Socialization = Socialisering (inlevelse)
        </p>
        <p style={{ marginBottom: "0", width: "100%" }}>
          Externalization = Externalisering (förtydligande)
        </p>
        <p style={{ marginBottom: "0", width: "100%" }}>
          Combination = Kombination (ihopkopplande)
        </p>
        <p style={{ marginBottom: "20px", width: "100%" }}>
          Internalization = Internationalisering (att ge konkret form åt)
        </p>

        <p>
          Översättningen i sig gör dig kanske inte så mycket klokare? Inte oss
          heller. Vi ska försöka förklara det vidare:
        </p>
        <p>
          Modellen utvecklades ursprungligen av professor Ikujiro Nonaka 1990,
          och förfinades senare av professor Hirotaka Takeuchi. Modellen togs
          fram för att ge en bild av hur kunskap skapas och hur den överförs.{" "}
        </p>
        <p>
          Nonaka och Takeuchi menar att det hela bygger på ett samspel mellan
          den implicita och den explicita kunskapen och att samspelet sker i
          olika processer. I modellen har de delat upp processen i fyra steg.
          Med dessa fyra steg vill de beskriva hur individuell kunskap bildas,
          delas och blir till organisatorisk kunskap.{" "}
        </p>
        <p>Nedan förklaras de fyra stegen:</p>
        <div style={{ marginLeft: "20px" }}>
          <p style={{ display: "inline", width: "100%" }}>
            <b>Socialization</b> (Implicit till implicit)
          </p>
          <p>
            Genom socialisering delas i detta steg den tysta kunskapen. Den
            delas genom personliga möten, social interaktion och handling. Det
            vill säga att individernas erfarenheter och kunskaper delas med
            andra genom dialog, observation och utövning och på så sätt skapas
            förståelse för den tysta kunskapen. Individen kan här förtydliga och
            uttrycka den tysta kunskapen i ord och handling till en annan
            individ, på så sätt blir en individs tysta kunskap överförd till en
            annan och blir den individens tysta kunskap.{" "}
          </p>
        </div>

        <div style={{ marginLeft: "20px" }}>
          <p style={{ display: "inline", width: "100%" }}>
            <b>Externalization</b> (Implicit till explicit)
          </p>
          <p>
            I detta steg görs den tysta kunskapen till explicit, det vill säga
            att den blir förtydligad genom att kodifieras eller förklaras med
            ord för andra.{" "}
          </p>
        </div>

        <div style={{ marginLeft: "20px" }}>
          <p style={{ display: "inline", width: "100%" }}>
            <b>Combination</b> (Explicit till explicit)
          </p>
          <p>
            Här görs explicit kunskap till ny explicit kunskap. Detta genom att
            kombinera en explicit kunskap med en annan, det kan till exempel
            vara en individs explicita kunskap som kombineras med en annan
            individs explicita kunskap. När dessa två kombineras skapas ny
            explicit kunskap. Genom att kombinera på detta sätt ökas tillgången
            på den explicita kunskapen i organisationen och fler kan ta del av
            den. I detta steg är databaser och fungerande IT-system en viktig
            pelare då det oftast är där den explicita kunskapen sparas och görs
            tillgänglig.
          </p>
        </div>

        <div style={{ marginLeft: "20px" }}>
          <p style={{ display: "inline", width: "100%" }}>
            <b>Internalization</b> (Explicit till implicit)
          </p>
          <p>
            Här handlar det om att göra explicit till implicit, det vill säga
            att göra kunskapen till en del av kulturen och arbetssättet i
            organisationen. I detta steg breddas kunskap genom att individen lär
            genom att göra och därmed gör den explicita kunskapen till
            individuell implicit.
          </p>
        </div>

        <p>
          Nonaka och Takeuchi menar att denna process är som en spiral av
          kunskapsskapande som gör att organisationens kunskap utökas varje gång
          processen sker. De menar att när det sista steget i processen skett,
          internaliseringen, börjar processen om på nytt igen, och så håller det
          på.
        </p>
        <p style={{ display: "inline", width: "100%" }}>
          <b>SECI-modellen kritiseras</b>
        </p>
        <p>
          Anna Jonsson skriver om SECI-modell i sin bok Kunskapsöverföring och
          Knowledge Management, från 2012. Hon nämner där att SECI-modellen är
          kritiserad av många, dels för att den utelämnar beskrivning av den
          implicita kunskapen men också för att den låter ledaren bestämma vad
          kunskap är. Anna refererar även till författare Stephen Gourlays text
          “Conceptualizing Knowledge Creation: A Critique of Nonaka's Theory”
          från 2006, där han menar att modellen har dåligt empiriskt bevis om
          att den fungerar.
        </p>

        <p>
          <b>Vad tycker vi?</b>
        </p>
        <p>
          Vi menar att varje enskild person och organisation är unik och att en
          och samma metod inte fungerar för allt och alla. Hur individuell
          kunskap bildas är till stor del individuellt, hur den delas och blir
          till organisatorisk kunskap avgörs nog många gånger av hur en
          organisation jobbar och hur personer i organisationen interagerar med
          varandra och dess kultur.
        </p>
        <p>
          Då vi har läst mycket om SECI-modellen så har den delvis funnits i
          bakhuvudet på oss när vi har tagit fram systemet och vi har tagit fram
          frågor som till viss del grundar sig i teorin. Som exempel kan nämnas
          att vi försökt att göra den implicita kunskapen till explicit genom
          att ställa frågor om oskrivna regler, personliga prioriteringar etc.
          Likaså har vi försökt att i systemet kombinera explicit kunskap med
          explicit kunskap genom att låta användaren bifoga befintliga mallar
          och dokument men samtidigt få chansen att beskriva och kommentera den
          explicita kunskapen med egna ord.{" "}
        </p>
        <p>
          Socialization och internalization har varit svårt att implementera
          rakt in i systemet men detta har vi nämnt i de dokument och mallar som
          finns under rubriken “Användbara dokument”, genom att uppmuntra till
          bredvidgång och fysiska upplärningsmöten, samt övning.
        </p>
        <p>
          Vi menar att det är många faktorer som ska fungera för att
          SECI-modellen ska fungera ultimat i en organisation. Med det menar vi
          att det är avgörande för SECI-modellen att organisationen har en
          rutiner för hur kunskapsöverföring ska gå till, samt om den fungerar,
          följs och regelbundet uppdateras. Likaså är det av betydelse vilka
          förväntningar som finns kring kunskapsöverföringen och att de
          förväntningarna är kända hos individerna. Vi menar också att kulturen
          spelar stor roll. Det är avgörande om det finns en genuin våga-fråga
          och dela-med-sig-kultur eller om organisationen har en kultur som gör
          att den med mest kunskap har högst status och att kunskapen därmed
          inte delas lika generöst. För att SECI-modellen ska fungera på alla
          plan är det också en förutsättning att den empiriska kunskapen finns,
          att den är uppdaterad och att det finns en fungerande och databas där
          den är tillgänglig när den väl ska delas.
        </p>
        <p>
          Även om metoden har blivit kritiserad har den ändå blivit mycket
          uppmärksammad i och med att den nämns i i princip alla sammanhang där
          kunskapsöverföring diskuteras, av det tycker vi att man kan dra en
          slutsats om att den ändå har fått stor betydelse för många. Och även
          om den inte stämmer in helt på alla organisationer och verksamheter
          tror vi att den till viss del ändå sker undermedvetet i
          organisationers kunskapsöverföring och kunskapsskapande.{" "}
        </p>
      </div>
    ),
    thumbnail: Images.landingThumbnail5,
    image: Images.landingBlogImg5
  },
  {
    id: 1,
    url: "Varför-kunskapsdelning-borde-vara-prioriterat",
    date: "14 maj 2022",
    title: "Varför kunskapsdelning borde vara prioriterat",
    text: (
      <div>
        <p>
          Att få organisationer har rutiner för kunskapsöverlämningar har vi
          skrivit om i ett tidigare inlägg. Det har blivit tydligt för oss i
          vårt arbete med projektet Sprida Kunskap att kunskapsdelning många
          gånger prioriteras ned pga av att andra uppgifter anses som viktigare.{" "}
        </p>
        <p>
          Vi tycker tvärtom, att kunskapsdelning borde prioriteras upp. Det
          finns nämligen många fördelar både för den enskilda individen och för
          organisationen att dela med sig av kunskap.{" "}
        </p>
        <p>
          Nedan har vi listat några exempel på fördelar som
          kunskapsdelning-/överföring för med sig:
        </p>
        <p>Fördelar för individen:</p>
        <ul>
          <li>Det ger en tryggare start i början av ett förtroendeuppdrag.</li>
          <li>
            Upplevelse av tillfredsställelse att ha delat med sig och hjälpt
            någon annan.
          </li>
          <li>Får en högre status då individen anses som en expert.</li>
          <li>Blir en förebild för andra.</li>
          <li>
            Får en känsla av vara betydelsefull och delaktig i organisationen.{" "}
          </li>
          <li>Får en känsla av samhörighet med organisationen.</li>
          <li>Det sker en utveckling av kunskapen när den delas med andra.</li>
          <li>
            Vardagen i organisationen underlättas när fler besitter viktig
            organisationskunskap.
          </li>
          <li>
            Det blir lättare för individen att lösa ett problem tillsammans med
            de andra i gruppen om de också har kunskap i den aktuella frågan.
          </li>
        </ul>
        <p>Fördelar för gruppen/organisationen: </p>
        <ul>
          <li>Organisationens resultat förstärks.</li>
          <li>
            Minimerar risken att erfarenhet och kunskap lämnar organisationer i
            takt med att personer lämnar.{" "}
          </li>
          <li>
            Ingen i organisationen behöver ”starta om från början” när och om
            kunskapen redan finns.
          </li>
          <li>Organisationen vinner tid i längden.</li>
          <li>
            Organisationen blir konkurrenskraftig och kan behålla
            konkurrenskraften på sikt.{" "}
          </li>
          <li>Individen känner samhörighet med organisationen. </li>
          <li>
            Vardagen i organisationen underlättas när fler besitter viktig
            organisationskunskap.
          </li>
          <li>Fler vågar ta sig an förtroendeuppdrag. </li>
          <li>Organisationen får ett gott rykte.</li>
          <li>Det blir lättare att attrahera framtida styrelsemedlemmar.</li>
        </ul>
        <p>
          Känner du till någon organisation som har goda rutiner för
          kunskapsöverlämning/överföring? Kommentera gärna nedan och låt fler ta
          del av goda exempel :)
        </p>
      </div>
    ),
    thumbnail: Images.landingThumbnail1,
    image: Images.landingBlogImg1
  },
  {
    id: 2,
    url: "Skriftliga-eller-muntliga-överlämningar",
    date: "13 maj 2022",
    title: "Skriftliga eller muntliga överlämningar?",
    text: (
      <div>
        <p>
          Enligt vad vi fått fram under vårt arbete i projektet är en
          kombination av skriftliga och muntliga överlämningar ett vinnande
          koncept för de allra flesta. Viss kunskap går nästan bara att lära sig
          genom handling och social interaktion ihop med någon som redan kan, så
          kallad implicit kunskap, medan annan kunskap är möjlig att kodifiera
          och skriva ner och sedan läsa sig till av den som ska lära sig, så
          kallad explicit kunskap.{" "}
        </p>
        <p>
          Den kunskap som går att dela både skriftligen och muntligen kan med
          fördel delas på båda sätt eftersom att repetition brukar vara givande
          för den som ska lära sig något nytt.
        </p>
        <p>
          Har du några sätt som du brukar använda dig av när du ska lära dig
          något nytt? Tipsa genom att kommentera nedan :D
        </p>
      </div>
    ),
    thumbnail: Images.landingThumbnail2,
    image: Images.landingBlogImg2
  },
  {
    id: 3,
    url:
      "Hur-kan-styrelsemedlemmarna-motiveras-till-att-genomföra-kunskapsöverlämningar",
    date: "12 maj 2022",
    title:
      "Hur kan styrelsemedlemmarna motiveras till att genomföra kunskapsöverlämningar?",
    text: (
      <div>
        <p>Har ni någon rutin för kunskapsöverlämning i er organisation?</p>
        <p>
          För att ha en lyckad sådan rutin verkar det som att avsatt tid och
          motivation hos styrelsemedlemmarna är avgörande faktorer.{" "}
        </p>
        <p>
          Men hur kan styrelsemedlemmarna motiveras till att genomföra
          kunskapsöverlämningar?{" "}
        </p>
        <p>
          Under arbetets gång i projektet Sprida Kunskap har vi listat några
          saker som verkar kunna ge styrelsemedlemmar motivation till att göra
          kunskapsöverlämningar.{" "}
        </p>
        <p>
          Nedan har vi listat några exempel på sådant som kan vara motiverande:{" "}
        </p>
        <ul>
          <li>
            Se till att det finns en rutin/policy för hur och att
            kunskapsöverlämningar ska ske i organisationen. Att det finns en
            sådan rutin verkar vara en motivation i sig.{" "}
          </li>
          <li>
            Se till att det finns en tydlig kultur och struktur för
            överlämningarna. (Läs om hur en sådan rutin kan införas i ett av
            våra tidigare inlägg.)
          </li>
          <li>
            Se till att ledningen i organisationen förstår vikten av
            kunskapsöverlämningar och att de är insatta i processen.
          </li>
          <li>
            Se till att det finns en våga-fråga-kultur i organisationen som
            främjar kunskapsdelning till vardags.{" "}
          </li>
          <li>
            Involvera styrelsemedlemmarna i att vara med och styra i vilka mål
            och strategier som ska finns vad gäller kunskapsöverlämning, och låt
            dem på så sätt ta ett större ansvar för detsamma.
          </li>
          <li>
            Se till att ansträngningar för kunskapsutbyte bidrar till en känsla
            av samhörighet i organisationen och med övriga styrelsemedlemmar.{" "}
          </li>
          <li>
            Att ledningen har klara argument för varför den enskilda individen
            ska bidra till organisationens interna kunskapsspridning.
          </li>
          <li>
            Att styrelsemedlemmen upplever sitt uppdrag som intressant och
            givande.{" "}
          </li>
          <li>Att rollen speglar styrelsemedlemmens förväntan på uppdraget.</li>
          <li>
            Att tempot för uppdraget speglar styrelsemedlemmens förväntan.
          </li>
          <li>
            Att ledaren uppmuntrar till kunskapsöverlämning och att
            styrelsemedlemmen bekräftas när överlämningen väl görs.
          </li>
          <li>
            Att ledaren ger individen stöd och coachning under
            överlämningsprocessen.
          </li>
          <li>
            Att ledaren ger en klar bild av vilken kunskap som ska delas med
            vem.{" "}
          </li>
          <li>
            Att ledaren avsatt tid och resurser för kunskapsöverlämningen.{" "}
          </li>
          <li>
            Att kunskapsöverlämningen är planerad och att den inte plötsligt
            dyker upp som en ny uppgift i uppdraget.
          </li>
          <p></p>
        </ul>
      </div>
    ),
    thumbnail: Images.landingThumbnail3,
    image: Images.landingBlogImg3
  },
  {
    id: 4,
    url: "Inför-en-rutin-med-kunskapsöverföring!",
    date: "11 maj 2022",
    title: "Inför en rutin med kunskapsöverföring!",
    text: (
      <div>
        <p>
          I ungdomsorganisationer sker vanligtvis skiften i styrelser oftare än
          i andra organisationer eftersom styrelsemedlemmarna “växer ur dem”.
          Många organisationer upplever att kunskap och erfarenhet försvinner i
          takt med att personer lämnar och att de som kommer in som nya får
          “börja om på nytt”. Känner ni igen er i denna beskrivning?
        </p>
        <p>
          Att ha en rutin och kultur i organisationen för kunskapsöverföring
          minimerar risken att kunskaps- och erfarenhetsförluster uppstår.
        </p>
        <p>
          Under projektets gång har vi fått en känsla av att det ofta finnas en
          diskussion i organisationerna som handlar om att organisationen ska ha
          en strategi för kunskapsöverföring men inte så ofta om hur det ska
          fungera i praktiken.{" "}
        </p>
        <p>
          Vi i projektet kan inte få organisationerna att införa en rutin för
          kunskapsöverföring men vi kan stolt erbjuda ett gratis verktyg som kan
          vara till god hjälp! Vi tror och hoppas att verktyget som finns här på{" "}
          <a href="https://spridakunskap.se/sign-up" target="blank">
            {" "}
            https://spridakunskap.se/sign-up{" "}
          </a>{" "}
          kan vara till stor nytta för nästan alla ungdomsorganisationer runt om
          i landet som vill införa en rutin för kunskapsöverföring!{" "}
        </p>
        <p>
          För dig som vill använda dig av verktyget kan det vara bra att veta
          att:
        </p>
        <ul>
          <li>Det är helt gratis. </li>
          <li>
            Organisationen binder inte upp sig på någonting alls vid användning.
          </li>
          <li>
            Om några i organisationen vill använda verktyget betyder det inte
            att alla måste göra det.{" "}
          </li>
          <li>
            Användandet är helt och hållet upp till varje enskild individ i
            organisationen.
          </li>
          <li>
            Verktyget är öppet för alla (inte bara ideella ungdomsorganisationer
            som är den primära målgruppen.)
          </li>
        </ul>
      </div>
    ),
    thumbnail: Images.landingThumbnail4,
    image: Images.landingBlogImg4
  },
  {
    id: 5,
    url: "Vad-är-kunskap",
    date: "10 maj 2022",
    title: "Vad är kunskap?",
    text: (
      <div>
        <p>
          När man pratar om kunskap kan det tolkas på många olika sätt, det har
          vi lärt oss under tiden vi jobbat i projektet.
        </p>
        <p>
          Vi har lagt märke till att det är vanligt att man väljer att dela in
          kunskap i två, explicit och implicit kunskap. Vad respektive står för
          är omdiskuterat men man kan förenklat säga att:
        </p>
        <p>
          - Explicit kunskap beskrivs som kunskap som kan skrivas ned och
          kodifieras och som därmed är enkel att dela med sig av.
        </p>
        <p>
          - Implicit kunskap, som även kallas är för tyst kunskap, är något som
          måste övas fram, och som är svår att läsa sig till. Den implicita
          kunskapen är personlig och erfarenhetsbaserad.
        </p>
        <p>
          Under projektets gång har vi även tagit del av en hel del andra
          beskrivningar av ordet kunskap, här kommer några exempel på vad
          kunskap beskrivs som:
        </p>
        <ul>
          <li>Information. </li>
          <li>Vetenskap.</li>
          <li>Kunnande. </li>
          <li>Förståelse.</li>
          <li>Praktiskt utförande.</li>
          <li>
            Något som är socialt uppbyggt och som delas vidare via interaktion
            med andra.
          </li>
          <li>Något som en individ besitter.</li>
          <li>
            Något som en individ gör i organiserad form men som den inte själv
            besitter.
          </li>
          <li>Något som skapas och utvecklas medan man utför det.</li>
        </ul>
        <p>Hur skulle du definiera order kunskap?</p>
        <p>
          Det verkar egentligen inte finns något rätt och fel gällande
          definitionen av kunskap. Vi tror att det bästa sättet är att definiera
          kunskap på är utifrån sammanhang och person.{" "}
        </p>
        <p>
          Vi tror dock att det är viktigt att definiera ordet i en organisation
          för att tydliggöra vad för typ av kunskap som förväntas överföras vid
          en kunskapsöverföring.{" "}
        </p>
        <p>Hur skulle du definiera ordet? Kommentera nedan! </p>
        <p>
          En intressant beskrivning av begreppet fann vi på Wikipedia,{" "}
          <a href="https://sv.wikipedia.org/wiki/Kunskap" target="blank">
            https://sv.wikipedia.org/wiki/Kunskap.
          </a>
        </p>
      </div>
    ),
    thumbnail: Images.landingThumbnail5,
    image: Images.landingBlogImg5
  },
  {
    id: 6,
    url:
      "Att-skapa-en-kultur-för-kunskapsöverlämningar-och-kunskapsdelning-i-organisationen",
    date: "09 maj 2022",
    title:
      "Att skapa en kultur för kunskapsöverlämningar och kunskapsdelning i organisationen",
    text: (
      <div>
        <p>
          Som vi nämnde i vårt senaste inlägg så tycks det vara avgörande att
          det finns en kultur och en struktur i organisationen när det kommer
          till att kunskapsöverföring och kunskapsdelning ska ske.{" "}
        </p>

        <p>
          Under arbetet med projektet har vi fått en bild av hur en sådan kultur
          kan skapas och hur den kan främjas över tid, ta del av våra skutsatser
          nedan:
        </p>

        <p>
          För at skapa en kultur i organisationen där kunskapsöverlämningar är
          lika givna som vilka andra rutiner som helst, verkar det ha stor
          betydelse att det finns ett förtroende inom organisationen. Det
          behöver finnas ett förtroende för ledningen, för styrelsemedlemmarna,
          samt mellan styrelsemedlemmarna. Ett sådant förtroende gynnas av ett
          positivt klimat där ledare och medlemmar har koll på och undviker
          beteenden som kan försvaga förtroendet.{" "}
        </p>

        <p>
          Ett delat ansvar och gemensamt ledarskaps tycks också vara givande för
          det generella förtroendet och därmed även för den generella viljan att
          dela med sig av kunskap.
        </p>

        <p>
          Att det också sker ett naturligt kunskapsutbyte till vardags mellan de
          förtroendevalda, där man delar med sig av ny och befintlig kunskap,
          verkar öka chanserna till en fungerande kultur för
          kunskapsöverlämningar.{" "}
        </p>

        <p>
          Vår uppfattning är också att det är viktigt att ledningen har en
          förståelse för själva kunskapsöverföringsprocessen, så att de kan
          uppmuntra styrelsemedlemmarna att göra kunskapsöverlämningar samt
          uppmuntra dem till att avsätta tid för detsamma.{" "}
        </p>

        <p>
          Att ha en stark berättarkultur kan också vara gynnande, det vill säga
          att ledare eller mer erfarna i organisationen berättar om fördelarna
          som de tidigare upplevt med en kunskapsdelningskultur.{" "}
        </p>

        <p>
          Utöver detta är det till stor hjälp att ha ett verktyg att använda för
          kunskapsöverföringen, såsom det som finns här på
          https://spridakunskap.se/sign-up . Med ett anpassat verktyg blir
          överlämningarna mer strukturerade och enklare att genomföra.{" "}
        </p>

        <p>
          För att bevara kulturen är det av vikt att organisationens
          lärandeprocesser regelbundet uppdateras för att förstärkas, förbättras
          och anpassas till nutid. Organisationskulturen behöver också justeras
          utifrån nya visioner som passar till organisationens aktuella behov
          och ge plats för kreativitet och innovation.{" "}
        </p>

        <p>
          Det kan även vara viktigt att definiera ordet kunskap, så att det är
          tydligt vad för typ av kunskap som ska förväntas överföras. Begreppet
          kunskap kan tolkas på många olika sätt och om ordet inte definieras
          finns risken att det är oklart vad som förväntas uppnås i kulturen.
          Läs mer om olika tolkningar av ordet kunskap i nästa blogginlägg.{" "}
        </p>
      </div>
    ),
    thumbnail: Images.landingThumbnail6,
    image: Images.landingBlogImg6
  },
  {
    id: 7,
    url: "Varför-har-så-få-organisationer-rutiner-för-kunskapsöverlämningar",
    date: "08 maj 2022",
    title: "Varför har så få organisationer rutiner för kunskapsöverlämningar?",
    text: (
      <div>
        <p>
          I början av projektet gjorde vi en enkätundersökning där vi bland
          annat ställde en fråga som handlade om befintliga
          kunskapsöverlämningsrutiner. I undersökningen ingick 52 ideella
          organisationer. Det visade sig att knappt en tredjedel av dem hade
          rutiner för kunskapsöverlämningar.
        </p>

        <p>
          Vi har försökt skaffa oss en bild av varför det är på detta sättet och
          kommit fram till att det många gånger kan bero på:
        </p>

        <ul>
          <li>Brist på tid.</li>
          <li>Brist på verktyg att använda.</li>
          <li>Brist på motivation.</li>
          <li>
            Prestige (att den som besitter viss kunskap vill behålla den för sig
            själv för att kunna känna sig unik.)
          </li>
          <li>
            Att det prioriteras ned med anledning av att andra uppgifter anses
            viktigare.{" "}
          </li>
          <li>
            Att det inte finns några förväntningar från organisationen om att en
            överlämning ska göras när man slutar.
          </li>
          <li>Brist på riktlinjer kring hur en överlämning ska göras.</li>
          <li>
            Att det är oklart vems ansvar det är att överlämningen görs, om det
            ledningens, eller om det ligger hos den som slutar och/eller den som
            börjar.
          </li>
          <li>
            Otydlighet kring vilken typ av kunskap som ska överlämnas vid
            styrelseskiften.
          </li>
        </ul>

        <p>Hur kan organisationerna ändra på detta?</p>

        <p>
          Sammanfattningsvis verkar det som att det behövs tydligare
          förväntningar och riktlinjer om när, hur och till vem kunskap ska
          överlämnas, samt vems ansvar det är att den genomförs.
        </p>

        <p>
          Utöver det behövs det verktyg för överlämningar, samt avsatt tid för
          att genomföra dem.
        </p>

        <p>
          Att det också finns en inarbetad kultur för strukturerade
          överlämningar tycks vara avgörande för att de ska bli av, men också
          att det finns en kultur till vardags som innefattar att man man delar
          med sig av sina erfarenheter och kunskaper helt utan prestige.
        </p>

        <p>
          Läs mer om hur man kan skapa en kultur för kunskapsöverföring i vårt
          kommande blogginlägg :)
        </p>
      </div>
    ),
    thumbnail: Images.landingThumbnail7,
    image: Images.landingBlogImg7
  },
  {
    id: 8,
    url:
      "Erfarenheter-som-vi-samlat-på-oss-under-arbetet-med-projektet-Sprida-Kunskap",
    date: "07 maj 2022",
    title:
      "Erfarenheter som vi samlat på oss under arbetet med projektet Sprida Kunskap",
    text: (
      <div>
        <p>
          Under tiden vi arbetat med projektet Sprida Kunskap har vi lärt oss en
          hel del om kunskap och kunskapsöverföring. Vi skulle vilja dela med
          oss av våra erfarenheter och vi kommer därför den närmaste tiden att
          publicera några inlägg i ämnet här på vår blogg!
        </p>
        <p>Stay tuned :) </p>
      </div>
    ),
    thumbnail: Images.landingThumbnail8,
    image: Images.landingBlogImg8
  }
];

export default data;
