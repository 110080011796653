import styled from "styled-components";
import { NavLink as BaseNavLink } from "react-router-dom";
import { Fonts, Colors } from "../../../environment";

export const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 2.5rem 3.2rem 8rem;
`;

export const FooterNavBlock = styled.nav`
  margin-left: auto;
`;

export const NavBar = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavBarItem = styled.li`
  float: left;
  list-style: none;
  margin-right: 4rem;
`;

export const NavLink = styled(BaseNavLink)`
  text-decoration: none;
  font-family: ${Fonts.heading};
  font-size: 13.5px;
  color: ${Colors.black[100]};
  letter-spacing: 0.3px;
`;

export const Logo = styled.img``;

export const FooterLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FooterLogoText = styled.span`
  font-family: ${Fonts.heading};
  margin-top: 10px;
  color: rgba(47, 42, 78, 0.6);
  font-size: 14px;
  letter-spacing: 0.2px;
`;
