import React, { useState, useEffect } from "react";
import { Handover, FormFieldData } from "../../../../api";
import {
  useNavigation,
  useBreadcrumbs,
  useEffectOnce,
  useUpdateHandoverMutation,
  useHandoverQuery,
  useAlerts,
  useTranslation,
  useMeQuery
} from "../../hooks";
import { WrapperLoader } from "./../../components/ui";
import { QuillContainer } from "./../../components/QuillContainer";
import { getfieldsValuesFromChapters } from "./../Handover";
import { PageWrapper } from "./Editor.style";

export function Editor() {
  const { setBreadcrumbs } = useBreadcrumbs();
  const translate = useTranslation();
  const { routes } = useNavigation();
  const [handover, setHandover] = useState<Handover | null>(null);
  const [fieldsValues, setFieldsValues] = useState<Array<FormFieldData>>([]);
  const {
    data: handoverData,
    loading: handoverDataLoading
  } = useHandoverQuery();
  const { data: userData } = useMeQuery();
  const { setNotification } = useAlerts();
  const [
    updateHandoverData,
    { data: updatedHandoverData, loading: updatedHandoverDataLoading }
  ] = useUpdateHandoverMutation();

  useEffectOnce(() => {
    const breadcrumbsData = [
      {
        label: translate(({ sideMenuLinks }) => sideMenuLinks.editor),
        to: routes.editor
      }
    ];

    setBreadcrumbs(breadcrumbsData);
  });

  useEffect(() => {
    if (updatedHandoverData) {
      setNotification({
        message: translate(({ messages }) => messages.documentSaved)
      });
      setHandover(updatedHandoverData.updateHandover);
    }
  }, [updatedHandoverData]);

  useEffect(() => {
    if (handoverData) {
      setHandover(handoverData.handover);
      setFieldsValues(
        getfieldsValuesFromChapters(handoverData.handover.chapters)
      );
    }
  }, [handoverData]);

  const updateHandoverTemplate = (template: string, indents: string) => {
    if (handover) {
      updateHandoverData({
        variables: {
          data: {
            handoverUpdateInput: { docTemplate: template, docIndents: indents },
            handoverWhereUniqueInput: { id: handover.id }
          }
        }
      });
    }
  };

  return (
    <WrapperLoader isLoading={handoverDataLoading}>
      <PageWrapper>
        {/* <PageTopBarContainer>
          <PageTopBarItem>
            <Breadcrumbs />
          </PageTopBarItem>
        </PageTopBarContainer> */}
        <QuillContainer
          user={userData?.me}
          hints={fieldsValues}
          onSaveTemplate={updateHandoverTemplate}
          loading={updatedHandoverDataLoading}
          initialDocTemplate={handover ? handover.docTemplate : "[]"}
          indents={
            handover && handover.docIndents
              ? handover.docIndents
              : '{ "left": "2", "top": "2", "right": "2", "bottom": "2" }'
          }
        />
      </PageWrapper>
    </WrapperLoader>
  );
}
