import { Fonts } from "./../../../environment/theme/Fonts";
import { deviceRange } from "./../../../consts";
import styled from "styled-components";
import { Colors } from "../../../environment";
import {
  LightButton as BaseLightButton,
  Input as BaseInput,
  Textarea as BaseTextarea
} from "../../ui";
import { CustomInput } from "../../ui/Input";

interface PromoButtonStartprops {
  marginTop?: number;
  width?: number;
  onClick?: any;
  paddingTop?: number;
  paddingBottom?: number;
  shadowLeft?: number;
  shadowTop?: number;
  marginLeft?: number;
  marginBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  height?: number;
  fontSize?: number;
  widthVW?: number;
  padding?: string;
}

export const UserInfoButton = styled.button<PromoButtonStartprops>`
  min-width:120px;
  background: #cc00a0;
  box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
    ${props => (props.shadowTop ? props.shadowTop + "px" : "6px")} 0px #b2028c;
  border-radius: 80px;
  border: #cc00a0;
  color: #ffffff;
  width: ${props => (props.width ? props.width + "px" : "100%")};
  ${props => (props.widthVW ? `width: ${props.widthVW}vw;` : null)}
  padding: ${props => (props.padding ? props.padding : "10px 35px")};
  font-family: ${Fonts.landingOpenSansSemibold};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 10px;
  margin-right: 40px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #b2028c;
  }
  &:active {
    box-shadow: -5px -5px 0px #b2028c;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
      ${props =>
        props.shadowTop ? props.shadowTop + "px" : "6px"} 0px #b2028c;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }

  @media ${deviceRange.mobile} {
    font-size: 11px;
    width: 100%;
    margin-top: 10px;
    margin-right: 0;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media ${deviceRange.mobile} {
    padding-right: 0px;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  }
`;

export const PageTitle = styled.h5`
  color: ${Colors.black[100]};
  margin-bottom: 2.6rem;
`;

export const UploadImageBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 700px;
  margin-bottom: 44px;
  margin-top: 39px;

  @media ${deviceRange.mobile} {
    margin-bottom: 0rem;
  }
`;

export const ImageContent = styled.div`
  margin-right: 3.2rem;

  @media ${deviceRange.mobile} {
    margin-right: 0rem;
  }
`;

export const UserImage = styled.img`
  border-radius: 50%;
  width: 12rem;
  height: 12rem;

  @media ${deviceRange.mobile} {
    width: 84px;
    height: 84px;
    margin-bottom: 10px;
  }
`;

export const LightButton = styled(BaseLightButton)`
  margin-right: 0.8rem;
`;

export const ProfileInput = styled(CustomInput)`
  width: 100%;
  max-width: 320px;
  margin-bottom: 0px;
  margin-right: 20px;

  @media ${deviceRange.mobile} {
    width: 100%;
    font-size: 10px;
    margin-right: 0;
  }

  @media ${deviceRange.laptop} {
    //margin-left: 20px;
  }

  @media ${deviceRange.tablet} {
    //margin-left: 20px;
  }

  @media ${deviceRange.laptopM} {
    //margin-left: 20px;
  }

  @media ${deviceRange.laptopL} {
    //margin-left: 20px;
  }

  @media ${deviceRange.desktop} {
    //margin-left: 20px;
  }
`;

export const UserAccoutGeneralInfoForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 700px;
`;

export const FormItem = styled.div`
  display: flex;
  margin-bottom: 4rem;
`;

export const FormItemIconBlock = styled.div`
  min-width: 3.2rem;
  padding: 0.4rem;
`;

export const Input = styled(BaseInput)`
  width: 100%;
  margin-left: 2rem;
`;

export const Textarea = styled(BaseTextarea)`
  margin-left: 2rem;
`;

export const UpdateButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BlockButtonsImage = styled.div`
  display: flex;
  flex-direction: column;
`;
export const BlockInputs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  @media ${deviceRange.mobile} {
    flex-direction: column;
    margin-bottom: 0;
  }
`;
export const WrapperButtonUpdate = styled.div`
  //margin-bottom: 30px;
  width: 100%;
  max-width: 320px;

  @media ${deviceRange.mobile} {
    max-width: none;
    margin-left: 0px;
    margin-bottom: 0px;
    justify-content: center;
    display: flex;
  }
`;
