import { deviceRange } from "../../consts";
import { Fonts } from "../../environment/theme/Fonts";
import styled from "styled-components";

export const BlogContainer = styled.div`
  max-width: 900px;
  min-height: 65vh;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  padding-top: 150px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media ${deviceRange.laptopM} {
  }

  @media ${deviceRange.laptopL} {
  }

  @media ${deviceRange.mobile} {
  }
  @media ${deviceRange.desktop} {
  }
  @media ${deviceRange.fourK} {
  }
`;

export const ArticleWrapper = styled.div`
  width: 100%;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  @media ${deviceRange.tablet} {
    max-width: 400px;
  }

  @media ${deviceRange.mobile} {
    max-width: 300px;
  }
`;

export const ArticleImage = styled.img`
  display: block;
  max-width: 100%;
`;

export const ArticleTitle = styled.h1`
  width: 100%;
  font-family: ${Fonts.landingOpenSansRegular};
  font-family: "Concert One";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 55px;
  color: #6bc9db;
  text-decoration: none;
  text-align: center;

  @media ${deviceRange.laptop} {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${deviceRange.tablet} {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${deviceRange.mobile} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const Date = styled.div`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 20px;
  color: #a1a9b1;

  @media ${deviceRange.mobile} {
    font-size: 1.4rem;
  }
`;

export const Line = styled.div`
  width: 200px;
  height: 5px;
  border-radius: 3px;
  background: #fca311;

  @media ${deviceRange.tablet} {
    width: 140px;
  }

  @media ${deviceRange.mobile} {
    width: 130px;
  }
`;

export const DateBlock = styled.div`
  padding: 0 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media ${deviceRange.laptop} {
  }

  @media ${deviceRange.tablet} {
    padding: 0 20px;
  }

  @media ${deviceRange.mobile} {
    padding: 0;
  }
`;

export const ArticleText = styled.div`
  width: 100%;
  color: #425262;
  padding: 0 75px;

  p {
    font-family: ${Fonts.landingOpenSansRegular};
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 20px;
    font-size: 1.6rem;
  }
  a {
    font-family: ${Fonts.landingOpenSansRegular};
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    font-size: 1.6rem;
  }
  ul {
    margin-bottom: 30px;
    margin-left: 40px;
    li {
      font-family: ${Fonts.landingOpenSansRegular};
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      font-size: 1.6rem;
      margin-bottom: 5px;
    }
  }

  @media ${deviceRange.laptop} {
  }

  @media ${deviceRange.tablet} {
    padding: 0 20px;
  }

  @media ${deviceRange.mobile} {
    padding: 0;
    p,
    a {
      font-size: 1.4rem;
      line-height: 20px;
    }

    ul {
      margin-bottom: 20px;
      margin-left: 30px;
      li {
        font-size: 1.4rem;
        line-height: 20px;
        margin-bottom: 0;
      }
    }
  }
`;
