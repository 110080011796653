import styled from "styled-components";
import { deviceRange } from "../../consts";
import { Fonts, Colors } from "../../environment";

export const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 0px;
  background: white;

  header {
    position: sticky;
    top: 0;
    background-color: transparent;
    z-index: 2;
  }
`;

export const PageMainContent = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 70%;
  margin: 20px auto 20px 80px;

  .faq-title {
    font-family: ${Fonts.title};
    font-size: 25px;
    line-height: 51px;
    letter-spacing: 0px;
    margin-bottom: 8px;
  }

  .row-title-text {
    font-family: ${Fonts.heading};
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0px;
    margin-bottom: 8px;

    @media ${deviceRange.mobile} {
      font-size: 14px;
    }
  }

  .row-content-text {
    font-family: ${Fonts.paragraph};
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: ${Colors.gray[100]};
    margin-bottom: 30px;

    @media ${deviceRange.mobile} {
      font-size: 12px !important;
    }
  }

  @media ${deviceRange.mobile} {
    margin: 0;
    margin-top: 60px;
    max-width: 90%;
    align-self: center;
  }
`;

export const PageTopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.5px;
  padding: 19px 56px;
`;

export const PageTopBarItem = styled.div`
  display: flex;
  align-items: center;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  height: calc(100% - 59px);
  width: 100%;
  padding: 45px 59px;
`;

export const FormBuilderContainer = styled.div`
  padding: 2.4rem;
`;

export const ScaleLabel = styled.span`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-size: 1.4rem;
  font-family: ${Fonts.SFPDisplayRegular};
  color: ${Colors.black[100]};
`;
