import React, { useEffect, useState, useCallback, useContext } from "react";

import { Modal } from "../../../ui";
import { useAddOrRemoveUserAvatar } from "../../../../hooks";
import { UserContext } from "../../UserPofile";
import { ModalSizes } from "../../../ui/Modal/Modal";
import { CropModalContent } from "./../CropModalContent";

import { getCroppedImg } from "../../../../helpers";
import { useAlerts, useTranslation } from "../../../../hooks";
import {
  HeadetButtonLog,
  PromoButtonStart
} from "../../../../pages/Landing/Landing.style";

export interface UserAccountFormValues {
  firstName: string;
  lastName: string;
  email: string;
}

interface ImageOptions {
  path: string;
  name: string;
  type: string;
}

interface UserGeneralInfoProps {
  showAvatarCropModal: boolean;
  handleCloseModal: () => void;
}

export function CropModal({
  showAvatarCropModal,
  handleCloseModal
}: UserGeneralInfoProps) {
  const translate = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const { setError } = useAlerts();
  const [imageOptions, setImageOptions] = useState<ImageOptions | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [showExample, setShowExample] = useState<boolean>(false);
  const [
    addUserAvatar,
    { data: addUserAvatarData, loading: addUserAvatarLoading }
  ] = useAddOrRemoveUserAvatar();

  function handleAddUserAvatar() {
    if (avatarFile && user) {
      addUserAvatar({
        variables: {
          data: {
            userAccountWhereUniqueInput: { id: user.id },
            avatar: avatarFile,
            isRemove: false
          }
        }
      });
      setShowExample(false);
    }
  }

  useEffect(() => {
    if (addUserAvatarData) {
      setUser(addUserAvatarData.addOrRemoveUserAvatar);
      handleCloseModal();
    }
  }, [addUserAvatarData]);

  function handleGetAvatarFile(file: File) {
    setAvatarFile(file);
  }

  const showCroppedImage = useCallback(async () => {
    try {
      if (!imageOptions) throw new Error();

      const {
        imgPath: croppedImage,
        imgFile: blobFile
      }: any = await getCroppedImg(imageOptions.path, croppedAreaPixels);
      setCroppedImage(croppedImage);

      const croppedImgFile = new File([blobFile], imageOptions.name, {
        lastModified: Date.now(),
        type: imageOptions.type
      });

      handleGetAvatarFile(croppedImgFile);
      setShowExample(true);
    } catch (e) {
      setError({ message: translate(({ errors }) => errors.tryAgain) });
    }
  }, [croppedAreaPixels]);

  return (
    <Modal
      title={translate(({ buttons }) => buttons.uploadModalHeader)}
      open={showAvatarCropModal}
      onClose={handleCloseModal}
      size={ModalSizes.SM}
    >
      <Modal.Body>
        <CropModalContent
          croppedImage={croppedImage}
          imageOptions={imageOptions}
          setImageOptions={setImageOptions}
          setCroppedImage={setCroppedImage}
          setCroppedAreaPixels={setCroppedAreaPixels}
          showExample={showExample}
        />
      </Modal.Body>
      <Modal.Footer>
        {imageOptions && (
          <HeadetButtonLog onClick={showCroppedImage}>
            {translate(({ buttons }) => buttons.crop)}
          </HeadetButtonLog>
        )}

        {/* Add Avatar */}
        <PromoButtonStart
          disabled={!avatarFile || addUserAvatarLoading}
          onClick={handleAddUserAvatar}
          marginTop={1}
          width={150}
          paddingLeft={20}
          paddingRight={20}
          paddingTop={15}
          paddingBottom={15}
          shadowLeft={2}
          shadowTop={4}
        >
          {translate(({ buttons }) => buttons.uploadConfirmButton)}
        </PromoButtonStart>
      </Modal.Footer>
    </Modal>
  );
}
