import styled from "styled-components";
import { Colors, Fonts } from "../../../environment";

export const CropModalContentWrapper = styled.div``;

export const FileInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  direction: rlt;
`;

export const FileInput = styled.input`
  display: none;
`;

export const FileInputLabel = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 12rem;
  height: 4rem;
  border: 2px solid #6bc9db;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  font-family: ${Fonts.landingOpenSansRegular};
  color: #425262;
  background-color: ${Colors.transparent};
  padding: 0px 18px;
  margin-left: auto;
  direction: ltr;
`;

export const LabelText = styled.span`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconTrashContainer = styled.div`
  color: lightgray;
  cursor: pointer;
  &:hover {
    color: black;
  }
`;
export const FilesBlock = styled.div`
  width: 100%;
  background: #d4eff5;
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
`;
export const FileWrapper = styled.div`
  width: 100%;
  border: 1px solid #6bc9db;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 8px;
  padding: 5px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: black;
`;
