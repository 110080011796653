import React, { useState, useCallback } from "react";

import {
  CropModalContentWrapper,
  FileInputContainer,
  FileInput,
  FileInputLabel,
  CropContainer,
  ImagePreviewContainer,
  ImagePreviewBlock,
  ImagePreview
} from "./CropModalContent.style";
import { useTranslation } from "../../../../hooks";
import { Images } from "../../../../environment";
interface CropModalContentProps {
  imageOptions: ImageOptions | null;
  setImageOptions: (imgOpt: ImageOptions | null) => void;
  setCroppedImage: (croppedImg: any) => void;
  showCroppedImage: any;
}
interface ImageOptions {
  path: string;
  name: string;
  type: string;
}

export function CropModalContent({
  imageOptions,
  setImageOptions,
  setCroppedImage,
  showCroppedImage
}: CropModalContentProps) {
  const translate = useTranslation();

  function handleGetLogo(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const imageOptions = {
        name: e.target.files[0].name,
        type: e.target.files[0].type,
        path: URL.createObjectURL(e.target.files[0])
      };

      showCroppedImage(imageOptions);
      setImageOptions(imageOptions);
      setCroppedImage(null);
    }
  }

  return (
    <CropModalContentWrapper>
      <FileInputContainer>
        <FileInput
          type="file"
          onChange={handleGetLogo}
          accept="image/*"
          id="logoUpload"
        />
        <FileInputLabel htmlFor="logoUpload">
          <img src={Images.landingExportLogo} alt="" />
          {translate(({ buttons }) => buttons.uploadFileButton)}
        </FileInputLabel>
      </FileInputContainer>
      {imageOptions && (
        <>
          <CropContainer>
            <ImagePreview src={imageOptions.path} />
          </CropContainer>
        </>
      )}
    </CropModalContentWrapper>
  );
}
