import React, { useCallback } from "react";

import { Select } from "./../../../../components/ui";
import { useTranslation } from "../../../../hooks";

interface SelectFieldProps {
  fieldData: any;
  onFieldChange: (name: string, value: Array<string>) => void;
  fieldValue: any;
}

export const SelectField = ({
  fieldData,
  onFieldChange,
  fieldValue
}: SelectFieldProps) => {
  const translate = useTranslation();

  const getOptions = useCallback(() => {
    let activeOptionName = translate(
      ({ handoversPage }) => handoversPage.formBuilder.selectOption
    );

    const options = fieldData.values.map(({ label, value }: any) => {
      if (value === fieldValue) {
        activeOptionName = label;
      }

      return (
        <Select.Item
          onClick={() => onFieldChange(fieldData.name, [value])}
          key={value}
        >
          {label}
        </Select.Item>
      );
    });

    return { options, activeOptionName };
  }, [fieldData.values, fieldValue, onFieldChange]);

  const { options, activeOptionName } = getOptions();

  return <Select title={activeOptionName}>{options}</Select>;
};
