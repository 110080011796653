import React, { useState, useEffect } from "react";
import { UserAccount } from "../../../../api";

import { PageTitlePanel, MembersPageLoader } from "../../components/ui";
import { MemberCard } from "./../../components/cards";

import {
  useTranslation,
  useNavigation,
  useBreadcrumbs,
  useWorkspaceMembersQuery,
  useEffectOnce
} from "../../hooks";
import { PageContainer, MembersList } from "./Members.style";

export function Members() {
  const { routes, history } = useNavigation();
  const { setBreadcrumbs } = useBreadcrumbs();
  const translate = useTranslation();
  const [workspaceMembers, setWorkspaceMembers] = useState<Array<UserAccount>>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const { data: workspaceMembersData } = useWorkspaceMembersQuery();

  useEffectOnce(() => {
    const breadcrumbsData = [
      {
        label: translate(({ sideMenuLinks }) => sideMenuLinks.members),
        to: routes.members
      }
    ];

    setBreadcrumbs(breadcrumbsData);
  });

  useEffect(() => {
    if (workspaceMembersData?.currentWorkspace) {
      setWorkspaceMembers(workspaceMembersData.currentWorkspace.members);
      setLoading(false);
    }
  }, [workspaceMembersData]);

  return (
    <PageContainer>
      {/* <PageTopBarContainer>
        <PageTopBarItem>
          <Breadcrumbs />
        </PageTopBarItem>
      </PageTopBarContainer> */}
      <PageTitlePanel title={translate(({ titles }) => titles.members)} />

      <MembersList>
        {!loading ? (
          workspaceMembers.length > 0 &&
          workspaceMembers.map((member: UserAccount) => (
            <MemberCard
              key={member.id}
              member={member}
              onClick={() => history.push(routes.memberProfile(member.id))}
            />
          ))
        ) : (
          <MembersPageLoader />
        )}
      </MembersList>
    </PageContainer>
  );
}
