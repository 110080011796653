import styled from "styled-components";
import { DefaultButton } from "./../../components/ui";
import { Fonts, Colors } from "../../environment";
import { deviceRange } from "../../consts";

export const PageWrapper = styled.div`
  width: 100%;
  position: relative;
  background: white;
  height: 100vh;
`;

// Form for employees
// TODO: Enable 'display: none'
export const PreviewButton = styled.button`
  font-size: 16px;
  border-radius: 80px;
  margin-top: 30px;
  width: 320px;
  height: 50px;
  padding: 10px 25px;
  background: #fca311;
  box-shadow: 3px 4px 0px #f09800;
  color: white;
  font-family: ${Fonts.landingOpenSansSemibold};
  font-style: normal;
  font-size: 1.6rem;
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 3px 6px 10px rgba(255, 161, 0, 62%);
  }

  &:active {
    box-shadow: -3px -5px rgba(255, 161, 0, 62%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: none;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }

  @media ${deviceRange.mobile} {
    width: 48%;
    font-size: 14px;
    height: 40px;
    padding: 0;
  }
`;

interface HeadetButtonLogProps {
  marginLeft?: number;
  marginTop?: number;
  padding?: string;
  customWidth?: number;
  fontSize?: number;
  widthVW?: number;
}

export const HeadetButtonLog = styled.button<HeadetButtonLogProps>`
  min-width: 120px;
  background: none;
  height: 50px;
  ${props => (props.customWidth ? "width:" + props.customWidth + "px;" : null)}
  ${props =>
    props.widthVW ? `width: ${props.widthVW}vw;` : null}
  border-radius: 80px;
  cursor: pointer;
  padding: ${props => (props.padding ? props.padding : "13px 35px")};
  border: 1px solid #ffa100;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #425262;
  margin-left: ${props =>
    props.marginLeft ? props.marginLeft + "px" : "37px"};
  ${props => (props.marginTop ? `margin-top: ${props.marginTop}px` : null)};
  &:hover {
    border: 1px solid rgba(252, 163, 17, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(255, 161, 0, 0.5);
  }
  &:active {
    background: #fca311;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.mobile} {
    width: 48%;
    font-size: 10px;
    height: 40px;
    padding: 0;
  }
`;

export const PageTopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 2.4rem;
`;

export const PageTopBarItem = styled.div`
  display: flex;
  align-items: center;
`;

interface PromoButtonStartprops {
  marginTop?: number;
  width?: number;
  onClick?: any;
  paddingTop?: number;
  paddingBottom?: number;
  shadowLeft?: number;
  shadowTop?: number;
  marginLeft?: number;
  marginBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  height?: number;
  fontSize?: number;
  widthVW?: number;
  padding?: string;
}
export const HandoversButtonStart = styled.button<PromoButtonStartprops>`
  min-width:120px;
  background: #cc00a0;
  box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
    ${props => (props.shadowTop ? props.shadowTop + "px" : "6px")} 0px #b2028c;
  border-radius: 80px;
  padding-top: ${props =>
    props.paddingTop ? props.paddingTop + "px" : "34px"};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom + "px" : "34px"};
  padding-left: ${props =>
    props.paddingLeft ? props.paddingLeft + "px" : "41px"};
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight + "px" : "41px"};
  border: #cc00a0;
  color: #ffffff;
  width: ${props => (props.width ? props.width + "px" : "100%")};
  ${props => (props.widthVW ? `width: ${props.widthVW}vw;` : null)}
  padding: ${props => (props.padding ? props.padding : "13px 35px")};
  font-family: ${Fonts.landingOpenSansSemibold};
  font-style: normal;
  font-size: 1.6rem;
  line-height: 24px;
  margin-top: ${props => (props.marginTop ? props.marginTop + "px" : "-40px")};
  ${props =>
    props.marginLeft ? "margin-left:" + props.marginLeft + "px" : null};
  ${props =>
    props.marginBottom ? "margin-bottom:" + props.marginBottom + "px" : null};
  cursor: pointer;
  ${props => (props.height ? "height:" + props.height + "px;" : null)}
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #b2028c;
  }
  &:active {
    box-shadow: -5px -5px 0px #b2028c;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: ${props => (props.shadowLeft ? props.shadowLeft + "px" : "3px")}
      ${props =>
        props.shadowTop ? props.shadowTop + "px" : "6px"} 0px #b2028c;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }

  @media ${deviceRange.mobile} {
    width: 48%;
    font-size: 14px;
    height: 40px;
    padding: 0;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const LeftBlockContainer = styled.div`
  width: 520px;

  @media ${deviceRange.tablet} {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 130px;
  }
  @media ${deviceRange.mobile} {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 130px;
  }
`;

export const LeftBlockTitle = styled.h2`
  width: 100%;
  font-family: ${Fonts.heading};
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0.6px;
  line-height: 120%;
  margin-bottom: 25px;
`;

export const BaseButtonContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;

  @media ${deviceRange.mobile} {
    align-items: center;
  }
`;

export const BaseButton = styled.button`
  padding: 14px 5px;
  height: auto;
  border-radius: 17px;
  width: 100%;
  border: 0px;
  outline: none;
  cursor: pointer;
`;

export const BaseButtonTitle = styled.p`
  font-family: ${Fonts.heading};
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  margin: 0;
`;

export const HandoverButton = styled(BaseButton)`
  background-color: #4f45f5;
  color: rgba(253, 253, 253, 1);

  :hover {
    background: #342acd;
    color: ${Colors.white};
  }
`;

export const RecommendToolButton = styled(BaseButton)`
  background-color: ${Colors.backgroundColor};
  border: 1px solid ${Colors.purple[30]};
  color: ${Colors.purple[100]};
  display: flex;
  justify-content: center;
  padding: 11px 5px;
  align-items: center;

  :hover {
    background: ${Colors.white};
  }

  p {
    margin-right: 5px;
  }
`;

export const RightBlockContainer = styled.div`
  background-color: rgba(223, 224, 241, 1);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 582px;
  border-radius: 40px;
  padding: 5px;
  position: relative;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const RightBlockSign = styled.p`
  color: rgba(144, 147, 193, 1);
  font-size: 28px;
  font-family: ${Fonts.heading};
  max-width: 100px;
  line-height: 34px;
  z-index: 99;
  position: absolute;
  top: 48px;
  left: 60px;
`;

export const RightBlockImg = styled.img``;

export const PeopleCallImg = styled.img`
  @media ${deviceRange.mobile} {
    width: 60vh;
    height: 60vh;
  }
`;

export const Button = styled(DefaultButton)`
  width: 100%;
  :not(:last-child) {
    margin-bottom: 5px;
  }
`;
interface PromoBlockMainTextProps {
  fontSize?: number;
  textAlign?: string;
}
export const PromoBlockMainText = styled.div<PromoBlockMainTextProps>`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize + "px" : "55px")};
  line-height: 60px;
  color: #6bc9db;
  width: 100%;
  text-align: center;

  @media ${deviceRange.mobile} {
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 28px;
  }
`;

export const BlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 200px;
  min-height: 100vh;
  height: 100%;

  @media ${deviceRange.tablet} {
    padding-left: 50px;
    flex-direction: column;
  }

  @media ${deviceRange.laptop} {
    padding-left: 100px;
  }

  @media ${deviceRange.laptopM} {
    padding-left: 100px;
  }

  @media ${deviceRange.laptopL} {
    padding-left: 150px;
  }

  @media ${deviceRange.mobile} {
    padding-left: 0px;
    flex-direction: column;
  }
`;

export const ImageWrapperPeopleCall = styled.div`
  display: flex;
  height: 100%;

  @media ${deviceRange.tablet} {
    margin-left: 75px;
  }

  @media ${deviceRange.mobile} {
    margin-left: 64px;
  }
`;

export const VideoPopUpWrapp = styled.div`
  display: flex;
  margin-top: 0px;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 150;
  position: absolute;
  right: 0px;

  @media ${deviceRange.tablet} {
    top: 30%;
  }

  @media ${deviceRange.mobile} {
    top: 30%;
    width: 100%;
    padding-left: 2px;
    padding-right: 2px;
  }
`;
interface TargetBlockBtnRekommenderaProps {
  margin?: string;
  marginTop?: number;
  width?: number;
  paddingTop?: number;
  paddingBottom?: number;
  shadowLeft?: number;
  shadowTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  fontSize?: number;
  onClick?: any;
  marginLeft?: number;
  widthVW?: number;
  padding?: string;
  borderColor?: string;
  boxShadowColor?: string;
}
export const TargetBlockBtnRekommendera = styled.button<
  TargetBlockBtnRekommenderaProps
>`
  ${props => (props.margin ? `margin: ${props.margin};` : null)}
  
  ${props => (props.marginTop ? `margin-top: ${props.marginTop}px;` : null)}
  background: #fca311;
  box-shadow: ${props =>
    props.shadowLeft ? props.shadowLeft + "px" : "3px"} ${props =>
  props.shadowTop ? props.shadowTop + "px" : "4px"} ${props =>
  props.boxShadowColor ? props.boxShadowColor : "#f09800"};
  border-radius: 80px;
  padding-top: ${props =>
    props.paddingTop ? props.paddingTop + "px" : "35px"};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom + "px" : "35px"};
  padding-left: ${props =>
    props.paddingLeft ? props.paddingLeft + "px" : "50px"};
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight + "px" : "50px"};
  color: white;
  font-family: ${Fonts.landingOpenSansSemibold};
  font-style: normal;
  font-size: 1.6rem;
  cursor: pointer;
  border: #fca311;
  ${props => (props.marginLeft ? `margin-left: ${props.marginLeft}px;` : null)}
  ${props => (props.widthVW ? `width: ${props.widthVW}vw;` : null)}
  padding: ${props => (props.padding ? props.padding : "13px 35px")};

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px rgba(255, 161, 0, 62%);
  }
  &:active {
    box-shadow: -3px -5px rgba(255, 161, 0, 62%);
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
    max-width:270px;
  }
`;
export const VideoPopupContent = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #6bc9db;
  background: white;
  padding: 36px 28px 70px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(107, 201, 219, 0.3);
  border-radius: 16px;
  width: 100%;
  display: flex;

  @media ${deviceRange.mobile} {
    padding: 36px 28px 45px 28px;
    align-items: center;
  }
`;

export const ButtonCloseVideoPopup = styled.div`
  margin-left: auto;
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
  margin-right: -15px;
  margin-top: -18px;
`;

export const IntroVideo = styled.video`
  width: 38vw;
  height: 36vh;

  @media ${deviceRange.tablet} {
    width: 600px;
    height: 40vh;
  }

  @media ${deviceRange.mobile} {
    width: 98%;
    height: 28vh;
  }
`;
