import React from "react";
import { Link } from "react-router-dom";

import {
  HeaderContainer,
  HeaderNavBlock,
  NavBar,
  NavBarItem,
  NavLinkSignup,
  NavLinkLogin,
  NavLinkFaq,
  HeaderLogo
} from "./AuthHeader.style";
import { Images } from "./../../../environment";
import { useNavigation, useTranslation } from "../../../hooks";

interface AuthHeaderProps {
  activelink?: string;
}

export function AuthHeader({ activelink }: AuthHeaderProps) {
  const translate = useTranslation();
  const { routes } = useNavigation();

  return (
    <HeaderContainer>
      <Link to="/">
        <HeaderLogo src={Images.landingLogo} sidebar={true} />
      </Link>

      <HeaderNavBlock>
        <NavBar>
          {/* <NavBarItem>
            <Dropdown
              toggleComponent={() => (
                <>
                  <Dropdown.TitleBold>
                    {languagesList.find(l => l.value === language)?.label}
                  </Dropdown.TitleBold>
                  <Icon
                    name={IconType.EpCornerDown}
                    color={Colors.black[100]}
                  />
                </>
              )}
            >
              {languagesList.map((lang, i) => (
                <Dropdown.Item
                  key={`select-language-${i}`}
                  onClick={() => changeLanguage(lang.value)}
                >
                  {lang.label}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </NavBarItem> */}

          <NavBarItem>
            <NavLinkSignup to={routes.signup} activelink={activelink}>
              {translate(({ authNav }) => authNav.createAccount)}
            </NavLinkSignup>
          </NavBarItem>

          <NavBarItem>
            <NavLinkLogin to={routes.login} activelink={activelink}>
              {translate(({ authNav }) => authNav.logIn)}
            </NavLinkLogin>
          </NavBarItem>

          <NavBarItem>
            <NavLinkFaq to={routes.unlogfaq} activelink={activelink}>
              FAQ
            </NavLinkFaq>
          </NavBarItem>
          {/* 
          <PopupBurgerMenu isOpen={burgetMenuShow}>
          <HeaderBlockLinks>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Varför kunskapsöverföring?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              För vem?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Om oss
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Kontakta
            </HeaderLink>
            <HeaderLink
              backlight={1}
              onClick={() => history.push(routes.usefuldocuments)}
            >
              Användbart material
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.dictionary)}>
              Ordbok
            </HeaderLink>
          </HeaderBlockLinks>
          <ImageCloseButtonWrapper onClick={() => setBurgetMenuShow(false)}>
            <ImageCloseButton src={Images.landingCloseIcon} />
          </ImageCloseButtonWrapper>
        </PopupBurgerMenu> */}
        </NavBar>
      </HeaderNavBlock>
    </HeaderContainer>
  );
}
