import React, { useState } from "react";
import {
  ButtonPDF,
  ButtonStep,
  GoogleButton,
  Instruction
} from "./BaseButtonModal";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  ContentBlockWrapper
} from "./Modals.style";

export const ModalContent8 = () => {
  const [step, setStep] = useState<number>(0);
  const changeStep = (id: number) => setStep(id);

  return (
    <>
      {step === 0 && (
        <ContentBlockWrapper>
          <ContentBlock>
            <LeftBlockContent>
              <TextTitleWrapper>
                <TextTitle>För vem:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>Ersättare</SecondText>
              </TextWrapper>
              <TextTitleWrapper>
                <TextTitle>Hur dokumentet kan användas:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>
                  Fyll i dokumentet och lämna det till din överlämnare inför att
                  du ska börja ditt uppdrag.
                </SecondText>
              </TextWrapper>
            </LeftBlockContent>
            <RightBlockContent>
              <TextTitleWrapper>
                <TextTitle>Beskrivning:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>
                  Ett dokument där du som ersättare kan fylla i saker du önskar
                  få tillgång till inför ditt uppdrag.
                </SecondText>
              </TextWrapper>
            </RightBlockContent>
          </ContentBlock>
        </ContentBlockWrapper>
      )}
      {step === 1 && <Instruction />}
      <FooterModal notAliginSelf={step ? 0 : 1}>
        {step === 0 && (
          <>
            <LeftBlockContent>
              <ButtonStep changeStep={changeStep} />
            </LeftBlockContent>
            <RightBlockContent>
              <ButtonPDF
                downLink={
                  "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Vad jag önskar få tillgång till från tidigare förtroendevald inför och under mitt nya uppdrag_ifyllbar PDF.pdf"
                }
              />
            </RightBlockContent>
          </>
        )}
        {step === 1 && (
          <GoogleButton
            downLink={
              "https://docs.google.com/document/d/16Lto1AfTh0oTawRqL_yRh0j5xT5m-L3fSx2TiLczQDM/edit"
            }
          />
        )}
      </FooterModal>
    </>
  );
};
