import styled from "styled-components";
import { deviceRange } from "../../consts";
import { Colors, Fonts } from "../../environment";
import { Indents } from "./QuillContainer";

export const PdfPageWrapper = styled.div`
  max-height: 100%;
  overflow: scroll;
  padding-right: 5px;

  &::-webkit-scrollbar {
    /* WebKit */
    width: 4px;
    height: 90%;
  }
  ::-webkit-scrollbar-thumb {
    background: #6bc9db;
    opacity: 0.2;
    border-radius: 1rem;
  }
`;
interface BlockA4Props {
  indents: Indents;
}
export const HintsContainer = styled.div`
  display: flex;
  overflow: scroll;
  width: 100%;
  max-width: calc(100% - 4.8rem);

  & > div:not(:last-child) {
    margin-right: 5px;
  }
`;

export const QuillElement = styled.div`
  min-height: 300px;
  max-height: 700px;
  overflow: scroll;
`;
export const LoaderBackground = styled.div`
  z-index: 50;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f2f3f5;
  margin-left: 0 !important;
`;

export const BlockWithIndents = styled.div`
  padding: 30px;
  border: 1px solid ${Colors.primaryGray};
  border-radius: 5px;
  max-width: 300px;
  position: absolute;
  top: 150px;
  z-index: 100;
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const BlockWithButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 300px;
`;
export const BlockEditor = styled.div`
  width: 95%;
  height: 65%;
  border: 1px solid ${Colors.primaryGray};
  padding: 50px;
  background-color: lightgray;
  overflow-y: auto;
`;
export const BlockA4 = styled.div<BlockA4Props>`
  width: 21cm;
  min-height: 29.7cm;
  padding-left: ${props => props.indents.left + "cm"};
  padding-top: ${props => props.indents.top + "cm"};
  padding-right: ${props => props.indents.right + "cm"};
  padding-bottom: ${props => props.indents.bottom + "cm"};
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;
export const BlockInterface = styled.div`
  max-width: 1300px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const FinishEditor = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;
export const MaketContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid darkgray;
  cursor: not-allowed;
`;
export const MaketMenu = styled.div`
  display: flex;
  color: darkgray;
  font-size: 16px;
  word-spacing: 20px;
  padding: 10px;
  border-bottom: 1px solid darkgray;
  background-color: #e8e8e8;
  opacity: 0.5;
`;
export const MaketToolbar = styled.div`
  height: 36px;
  display: flex;
  flex-direction: column;
  background-color: #e8e8e8;
  opacity: 0.5;
`;
export const MaketWrapper = styled.div`
  position: relative;
  width: inherit;
  height: inherit;
  width: 95%;
`;
export const IndentsBlock = styled.div``;
export const BackgroundPopupPdf = styled.div`
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  margin-right: 2.4rem;
  margin: 0 !important;

  @media ${deviceRange.mobile} {
    padding: 0px;
    padding-bottom: 20px;
  }
`;
export const PopupPdfBody = styled.div`
  height: 86%;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  canvas {
    border: 1px solid black;
  }
`;
export const ContentPopupPdf = styled.div`
  background-color: #fefefe;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 10px;
  max-height: 870px;
  border: 1px solid #888;
  max-width: 620px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  position: relative;
`;
export const ConfirmPopupDeleteValues = styled.div`
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  position: relative;
  margin-top: 200px;
  padding: 30px;
  border-radius: 8px;
`;
export const ConfirmPopupDeleteValuesText = styled.p`
  font-size: 35px;
  font-weight: bold;
  font-family: ${Fonts.landingConcertOneRegular};
  text-align: center;
  color: #6bc9db;
`;
export const ConfirmPopupDeleteValuesBlockBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 30px;
`;
export const BlockPreviewButtons = styled.div`
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  max-width: 240px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const TestBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
`;
export const MenuBarWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
`;
export const PdfWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;
