import React, { useState } from "react";
import { Images } from "../../environment/theme/Images";
import {
  QuestionBlockQuestions,
  QuestionBlockQuestionsText,
  QuestionItem,
  LandingImageWithState,
  LandingImagePoint,
  QuestionWrapper
} from "./Landing.style";
interface ButtonPlusCloseProps {
  text?: string;
  title?: string;
}
export const QuestionElement = ({ title, text }: ButtonPlusCloseProps) => {
  const [questionShow, setQuestionShow] = useState<boolean>(false);
  return (
    <div>
      <QuestionBlockQuestions>
        <QuestionWrapper>
          <LandingImagePoint src={Images.landingImagePoint} alt="" />
          <QuestionBlockQuestionsText>{title}</QuestionBlockQuestionsText>
        </QuestionWrapper>
        {questionShow ? (
          <LandingImageWithState
            src={Images.landingImageClose}
            onClick={() => setQuestionShow(false)}
            alt=""
          />
        ) : (
          <LandingImageWithState
            src={Images.landingImagePlus}
            onClick={() => setQuestionShow(true)}
            alt=""
          />
        )}
      </QuestionBlockQuestions>
      {questionShow ? <QuestionItem>{text}</QuestionItem> : ""}
    </div>
  );
};
