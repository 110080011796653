import React from "react";
import { ButtonPDF, ButtonPDFWithoutBackground } from "./BaseButtonModal";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  ContentBlockWrapper
} from "./Modals.style";

export const ModalContent7 = () => {
  return (
    <>
      <ContentBlockWrapper>
        <ContentBlock>
          <LeftBlockContent>
            <TextTitleWrapper>
              <TextTitle>För vem:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>Processledare</SecondText>
            </TextWrapper>
            <TextTitleWrapper>
              <TextTitle>Hur dokumentet kan användas:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Du som processledare kan fylla i dokumentet och överlämna till
                de som är nya förtroendevalda i organisationen.
              </SecondText>
            </TextWrapper>
          </LeftBlockContent>
          <RightBlockContent>
            <TextTitleWrapper>
              <TextTitle>Beskrivning:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Mall för att fylla i praktisk information och detaljer om
                organisationen. Öppna dokumentet i Google Docs och redigera
                utifrån er organisations behov.
              </SecondText>
            </TextWrapper>
          </RightBlockContent>
        </ContentBlock>
      </ContentBlockWrapper>
      <FooterModal notAliginSelf={1}>
        <LeftBlockContent>
          <ButtonPDFWithoutBackground
            downLink={
              "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Praktisk information och detaljer_ifyllbar PDF.pdf"
            }
          />
        </LeftBlockContent>
        <RightBlockContent>
          <ButtonPDF
            downLink={
              "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Praktisk information och detaljer.pdf"
            }
          />
        </RightBlockContent>
      </FooterModal>
    </>
  );
};
