import React from "react";
import { Images } from "../../../environment";
import { ScrollWrapper, Text, ScrollLine } from "./Scroll.style";

interface ScrollProps {
  position: boolean;
  onClick: () => void;
  showArrow: boolean;
}

export function Scroll({ position, onClick, showArrow }: ScrollProps) {
  return (
    <ScrollWrapper>
      <Text
        href="#"
        position={position}
        onClick={onClick}
        showArrow={showArrow}
      >
        Scrol
        <img
          src={Images.landingScrollArrow}
          style={{ width: "20px", marginTop: "5px" }}
          alt=""
        />
      </Text>
      {/* <ScrollLine /> */}
    </ScrollWrapper>
  );
}
