import React, { useEffect, useState } from "react";
import { AuthHeader } from "../../components/auth/AuthHeader/AuthHeader";
import { ForgotPasswordForm } from "../../components/auth/ForgotPasswordForm";
import { PageWrapper } from "../Login/Login.style";
import {
  HeaderTextWrapper,
  TextWrapper,
  PageMainContent,
  HeaderText,
  BlockText,
  ForgotPasswordResult,
  HeaderTextMain,
  UsuallyText
} from "./ForgotPassword.style";
import * as yup from "yup";
import {
  useForgotPasswordQuery,
  useNavigation,
  useTranslation,
  useViewport
} from "../../hooks";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { deviceSize } from "../../consts";
import { Images } from "../../environment";
import {
  HeaderContainer,
  HeaderBlockLogo,
  LandingLogoHeader,
  HeaderBlockLinks,
  HeaderLink,
  HeaderBlockButtons,
  HeadetButtonReg,
  LogIn,
  HeaderBurgerMenu,
  ImageBurgerMenu,
  HeaderMobileWrapper,
  PopupBurgerMenu,
  ImageCloseButtonWrapper,
  ImageCloseButton
} from "../Landing/Landing.style";

export function ForgotPassword() {
  const translate = useTranslation();
  const { routes, history } = useNavigation();
  const [burgetMenuShow, setBurgetMenuShow] = useState<boolean>(false);
  const width = useViewport();

  const [mailIsSend, setMailIsSend] = useState(false);
  const [
    forgotPassword,
    { data: forgotPasswordData, loading: forgotPasswordLoading }
  ] = useForgotPasswordQuery();

  const LOGIN_VALIDATION_SCHEMA = yup.object().shape({
    email: yup
      .string()
      .required(translate(({ inputs }) => inputs.email.required))
      .email(translate(({ inputs }) => inputs.email.invalid))
      .trim()
  });

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: LOGIN_VALIDATION_SCHEMA,
    onSubmit: ({ email }) => handleForgotPassword(email)
  });

  async function handleForgotPassword(email: string) {
    if (email) {
      await forgotPassword({
        variables: {
          email: email
        }
      });
    }
  }

  useEffect(() => {
    if (forgotPasswordData?.forgotPassword) {
      setMailIsSend(!mailIsSend);
    }
  }, [forgotPasswordData]);

  return width <= parseInt(deviceSize.mobile.max) ? (
    //mobile
    <PageWrapper>
      {width >= parseInt(deviceSize.laptopM.min) ? (
        <HeaderContainer>
          <HeaderBlockLogo>
            <Link to="/">
              <LandingLogoHeader src={Images.landingLogo} alt="" />
            </Link>
          </HeaderBlockLogo>
          <HeaderBlockLinks>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Varför kunskapsöverföring?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              För vem?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Om oss
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Kontakta
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.usefuldocuments)}>
              Användbart material
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.dictionary)}>
              Ordbok
            </HeaderLink>
          </HeaderBlockLinks>
          <HeaderBlockButtons>
            <HeadetButtonReg onClick={() => history.push(routes.signup)}>
              Skapa konto
            </HeadetButtonReg>
            <LogIn onClick={() => history.push(routes.login)}>Logga in</LogIn>
          </HeaderBlockButtons>
        </HeaderContainer>
      ) : width >= parseInt(deviceSize.tablet.min) ? (
        <HeaderContainer>
          <HeaderBlockLogo>
            <Link to="/">
              <LandingLogoHeader src={Images.landingLogo} alt="" />
            </Link>
          </HeaderBlockLogo>
          <HeaderBlockButtons>
            <HeadetButtonReg onClick={() => history.push(routes.signup)}>
              Skapa konto
            </HeadetButtonReg>
            <LogIn onClick={() => history.push(routes.login)}>Logga in</LogIn>
            <HeaderBurgerMenu onClick={() => setBurgetMenuShow(true)}>
              <ImageBurgerMenu src={Images.landingBurgerMenu} alt="" />
            </HeaderBurgerMenu>
          </HeaderBlockButtons>
        </HeaderContainer>
      ) : (
        <HeaderContainer>
          <HeaderMobileWrapper>
            <HeaderBlockLogo>
              <Link to="/">
                <LandingLogoHeader src={Images.landingLogo} alt="" />
              </Link>
            </HeaderBlockLogo>
            <HeaderBurgerMenu onClick={() => setBurgetMenuShow(true)}>
              <ImageBurgerMenu src={Images.landingBurgerMenu} alt="" />
            </HeaderBurgerMenu>
          </HeaderMobileWrapper>
          <HeaderMobileWrapper></HeaderMobileWrapper>
        </HeaderContainer>
      )}
      {width >= parseInt(deviceSize.laptop.min) ? (
        <PopupBurgerMenu isOpen={burgetMenuShow}>
          <HeaderBlockLinks>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Varför kunskapsöverföring?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              För vem?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Om oss
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Kontakta
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.usefuldocuments)}>
              Användbart material
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.dictionary)}>
              Ordbok
            </HeaderLink>
          </HeaderBlockLinks>
          <ImageCloseButtonWrapper onClick={() => setBurgetMenuShow(false)}>
            <ImageCloseButton src={Images.landingCloseIcon} />
          </ImageCloseButtonWrapper>
        </PopupBurgerMenu>
      ) : (
        <PopupBurgerMenu isOpen={burgetMenuShow}>
          <ImageCloseButtonWrapper onClick={() => setBurgetMenuShow(false)}>
            <ImageCloseButton src={Images.landingCloseIcon} />
          </ImageCloseButtonWrapper>
          <HeaderBlockLinks>
            <HeaderLink
              backlight={1}
              onClick={() => history.push(routes.signup)}
            >
              {" "}
              Skapa konto
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.login)}>
              Logga in
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.unlogfaq)}>
              {" "}
              FAQ
            </HeaderLink>
          </HeaderBlockLinks>
        </PopupBurgerMenu>
      )}
      <PageMainContent>
        {mailIsSend ? (
          <ForgotPasswordResult>
            <HeaderText>
              Återställ lösenordslänken skickas nu till din e-post!
            </HeaderText>
            <UsuallyText>
              Följ stegen i e-postmeddelandet för att återställa ditt lösenord.
            </UsuallyText>
          </ForgotPasswordResult>
        ) : (
          <>
            <HeaderTextWrapper>
              <HeaderTextMain>Glömt lösenord</HeaderTextMain>
            </HeaderTextWrapper>
            <TextWrapper>
              <UsuallyText>
                Det är enkelt att återställa ditt lösenord!
              </UsuallyText>
            </TextWrapper>
            <ForgotPasswordForm
              formik={formik}
              loading={forgotPasswordLoading}
            />
          </>
        )}
      </PageMainContent>
    </PageWrapper>
  ) : (
    <PageWrapper>
      <AuthHeader />
      <PageMainContent>
        {mailIsSend ? (
          <ForgotPasswordResult>
            <HeaderText>
              Återställ lösenordslänken skickas nu till din e-post!
            </HeaderText>
            <BlockText>
              Följ stegen i e-postmeddelandet för att återställa ditt lösenord.
            </BlockText>
          </ForgotPasswordResult>
        ) : (
          <>
            <HeaderTextWrapper>
              <HeaderText>Glömt lösenord</HeaderText>
            </HeaderTextWrapper>
            <TextWrapper>
              <BlockText>Det är enkelt att återställa ditt lösenord!</BlockText>
            </TextWrapper>
            <ForgotPasswordForm
              formik={formik}
              loading={forgotPasswordLoading}
            />
          </>
        )}
      </PageMainContent>
    </PageWrapper>
  );
}
