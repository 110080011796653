import React, { ReactChild } from "react";

import { getTokenData } from "./../../helpers";
import { useNavigation } from "./../../hooks";
import { StorageKey } from "./../../consts";

interface SuperAdminProtectorProps {
  children: ReactChild;
  redirectTo?: string;
}

export function SuperAdminProtector({
  children,
  redirectTo
}: SuperAdminProtectorProps) {
  const { routes, history } = useNavigation();
  const tokenData = getTokenData();

  if (!tokenData) {
    localStorage.removeItem(StorageKey.Token);
    history.push(routes.login);
  }

  if (redirectTo && tokenData?.role !== "SUPER") {
    history.push(redirectTo);
  }

  if (tokenData?.role !== "SUPER") return null;

  return <>{children}</>;
}
