import React from "react";
import { useTranslation } from "../../../hooks";
import {
  Input,
  TargetBlockBtnRekommendera,
  Form
} from "./ForgotPasswordForm.style";
import { PromoButtonStart } from "../../../pages/Landing/Landing.style";
import { FormikProps } from "formik";

interface LoginFormValues {
  email: string;
}
interface LoginScreenViewProps {
  formik: FormikProps<LoginFormValues>;
  loading: boolean;
}

export function ForgotPasswordForm({
  formik: {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleBlur,
    isValid
  }
}: LoginScreenViewProps) {
  const translate = useTranslation();

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        placeholder={translate(({ inputs }) => inputs.email.placeholder)}
        name="email"
        onChange={handleChange}
        value={values.email}
        error={touched.email ? errors.email : undefined}
        borderAndShadow={true}
      />
      <PromoButtonStart
        onClick={handleSubmit}
        paddingTop={20}
        paddingBottom={20}
        shadowLeft={3}
        shadowTop={4}
        paddingLeft={1}
        marginTop={1}
        paddingRight={30}
        background={"#fca311"}
        boxShadowColor={"#f09800"}
      >
        {" "}
        Återställ lösenord
      </PromoButtonStart>
    </Form>
  );
}
