import styled from "styled-components";
import { deviceRange } from "../../../consts";
import { Fonts } from "../../../environment/theme/Fonts";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const TextTitleStep = styled.h4`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #425262;

  @media ${deviceRange.mobile} {
    padding-left: 10px;
  }
`;

export const ImagePDF = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 15px;
`;

export const ButtonPdf = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 240px;
  background: #6bc9db;
  border-radius: 80px;
  cursor: pointer;
  border: 1px solid #6bc9db;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #425262;
  &:hover {
    border: 1px solid rgba(0, 140, 130, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 140, 130, 0.5);
  }
  &:active {
    background: #6bc9db;
  }
  &:focus {
    outline: none;
  }
`;

export const TextButtonWrapper = styled.div``;

export const ButtonGoogle = styled.button`
  width: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #6bc9db;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 10px;
  font-family: ${Fonts.landingOpenSansRegular};

  @media ${deviceRange.mobile} {
    width: 240px;
    margin-top: 10px;
    height: 52px;
  }

  &:hover {
    border: 1px solid rgba(0, 140, 130, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 140, 130, 0.5);
  }
  &:active {
    //background: #6bc9db;
  }
  &:focus {
    outline: none;
  }
`;

export const ButtonPdfwb = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 294px;
  border-radius: 80px;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #6bc9db;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #425262;
  &:hover {
    border: 1px solid rgba(0, 140, 130, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 140, 130, 0.5);
  }
  &:active {
    background: #6bc9db;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.mobile} {
    width: 240px;
    margin-top: 10px;
    height: 52px;
  }
`;
