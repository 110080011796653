import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { UpdateChapterData } from "../../../../../api";

const UPDATE_CHAPTER = gql`
  mutation UpdateChapter($data: UpdateChapterInput!) {
    updateChapter(data: $data) {
      id
      template
      helpmanText
      name
      chapterType
    }
  }
`;

// const UPLOAD_HELPMAN_IMAGE = gql`
//   mutation UploadHelpManImage($data: uploadHelpManImageInput!) {
//     uploadHelpManImage(data: $data) {
//       id
//     }
//   }
// `;

export function useUpdateChapterMutation() {
  return useMutation<UpdateChapterData>(UPDATE_CHAPTER, { onError: () => {} });
}
