import styled from "styled-components";
import { deviceRange } from "../../consts";
import { Fonts } from "../../environment/theme/Fonts";

interface ContentCardProps {
  contentNumber: number;
}

export const ContentWrapper = styled.div<ContentCardProps>`
  width: 100%;
  max-width: 1222px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;

  @media ${deviceRange.laptopL} {
  }

  @media ${deviceRange.laptopM} {
    max-width: 1222px;
  }

  @media ${deviceRange.laptop} {
    max-width: 855px;
  }

  @media ${deviceRange.tablet} {
    max-width: 814px;
    justify-content: ${props =>
      props.contentNumber === 5 ? "center" : "flex-start"};
  }
  @media ${deviceRange.mobile} {
    padding: 0 15px;
  }
`;

export const CardWrapper = styled.div`
  @media ${deviceRange.mobile} {
    width: 100%;
  }
`;
