import styled from "styled-components";

export const PageWrapper = styled.div`
  width: 103%;
  position: relative;
  overflow: auto;
  padding-top: 30px;
  padding-left: 50px;
  background: white;
  margin-left: -30px;
  & > div {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
    content: "Small";
    font-size: 16px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: "Normal";
    font-size: 16px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
    content: "Large";
    font-size: 16px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"]::before {
    content: "Huge";
    font-size: 16px !important;
  }
`;

export const PageTopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 0;
`;

export const PageTopBarItem = styled.div`
  display: flex;
  align-items: center;
`;
