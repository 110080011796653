export const Colors = {
  blue: {
    100: "rgb(34, 95, 188)",
    80: "rgba(34, 95, 188, 0.8)",
    60: "rgba(34, 95, 188, 0.6)",
    40: "rgba(34, 95, 188, 0.4)",
    30: "rgba(34, 95, 188, 0.3)",
    20: "rgba(34, 95, 188, 0.2)",
    10: "rgba(34, 95, 188, 0.1)",
    8: "rgba(34, 95, 188, 0.08)",
    6: "rgba(34, 95, 188, 0.06)",
    5: "rgba(34, 95, 188, 0.05)",
    4: "rgba(34, 95, 188, 0.04)"
  },
  neutralBlue: {
    100: "#5D779D",
    80: "rgba(93, 119, 157, 0.8)",
    40: "rgba(93, 119, 157, 0.4)",
    20: "rgba(93, 119, 157, 0.2)",
    10: "rgba(93, 119, 157, 0.1)",
    8: "rgba(93, 119, 157, 0.08)",
    5: "rgba(93, 119, 157, 0.05)",
    2: "rgba(16, 115, 224, 0.02)"
  },
  black: {
    100: "#222222",
    5: "rgba(34, 34, 34, 0.05)"
  },
  gray: {
    100: "#5A5A5A",
    90: "rgba(90, 90, 90, 0.9)",
    80: "rgba(90, 90, 90, 0.8)",
    60: "rgba(90, 90, 90, 0.6)",
    50: "#B3B3B3",
    10: "#F0F0F0",
    8: "rgba(90, 90, 90, 0.08)",
    7: "rgba(34, 34, 34, 0.07)",
    5: "#F4F4F4"
  },
  red: {
    100: "#F83367",
    80: "rgba(223, 40, 84, 0.8)",
    6: "rgba(248, 51, 98, 0.06)",
    4: "rgba(248, 51, 98, 0.04);"
  },
  orange: {
    100: "#FB8E0E",
    10: "rgba(251, 142, 14, 0.1)",
    8: "rgba(251, 142, 14, 0.08)"
  },
  purple: {
    100: "rgba(90, 57, 220, 1)",
    80: "rgba(90, 57, 220, .8)",
    40: "rgba(90, 57, 220, .4)",
    30: "rgba(90, 57, 220, .3)",
    10: "rgba(90, 57, 220, .1)"
  },
  green: "#27AE60",
  white: "#FFF",
  midLight: "#F0F0F0",
  lightColorWhite: "#FDFDFD",
  lightColorLight: "#FAFAFB",
  transparent: "rgba(0, 0, 0, 0)",
  overlay: {
    40: "rgba(13, 25, 44, 0.4)",
    25: "rgba(0, 0, 0, 0.25)",
    3: "rgba(13, 25, 44, 0.03)"
  },
  typoPlaceholder: "#86888C",
  inputBorderColor: "#6bc9db",
  inputFocusColor: "rgba(107, 201, 219, 0.3)",
  accentBlue: "#1073E0",
  primaryBtnHover: "#266DD8",
  basePrmary: "#0A0E12",
  ghostBtnBgColor: "rgba(16, 90, 202, 0.08)",
  lightBtnHover: "#DFE6F2",
  modalBackgroundColor: "rgba(13, 25, 44, 0.5)",
  borderMidColor: "rgba(13, 25, 44, 0.08)",
  dividerLight: "rgba(20, 27, 36, 0.05)",
  primaryColor: "rgba(10, 14, 18, 0.2)",
  primaryDark: "#000000",
  primaryGray: "#333333",
  primaryPink: "#cc00a0",
  backgroundColor: "#F2F3F5"
};
