import styled from "styled-components";
import { deviceRange } from "../../../consts";
import { Fonts, Colors } from "../../../environment";

export const TypographyWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  direction: ltr;
  h1 {
    font-family: ${Fonts.landingConcertOneRegular};
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #425262;

    @media ${deviceRange.mobile} {
      font-size: 14px;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${Fonts.paragraph};
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    font-family: ${Fonts.landingConcertOneRegular};
    color: #425262;
    margin-bottom: 5px;

    @media ${deviceRange.mobile} {
      font-size: 14px;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    font-family: ${Fonts.landingConcertOneRegular};
    color: #425262;
    margin-bottom: 10px;

    @media ${deviceRange.mobile} {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
  h4 {
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    font-family: ${Fonts.landingConcertOneRegular};
    color: #425262;

    @media ${deviceRange.mobile} {
      font-size: 14px;
    }
  }
  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    font-family: ${Fonts.landingConcertOneRegular};
    color: #425262;
    margin-bottom: 10px;

    @media ${deviceRange.laptop} {
      margin-bottom: 0px;
    }

    @media ${deviceRange.laptopM} {
      margin-bottom: 0px;
    }
    @media ${deviceRange.mobile} {
      font-size: 14px;
    }
  }
  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    font-family: ${Fonts.landingConcertOneRegular};
    color: #425262;
    margin-bottom: 2px;

    @media ${deviceRange.mobile} {
      font-size: 13px;
    }
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    font-family: ${Fonts.landingConcertOneRegular};
    color: #425262;

    @media ${deviceRange.mobile} {
      font-size: 14px;
    }
  }

  @media ${deviceRange.laptop} {
    h2,
    h3,
    h5 {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }

  @media ${deviceRange.laptopM} {
    h2,
    h3,
    h5 {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }

  @media ${deviceRange.laptopL} {
    h2,
    h3,
    h5 {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }

  @media ${deviceRange.desktop} {
    h2,
    h3,
    h5 {
      font-size: 15px;
    }
    p {
      font-size: 15px;
    }
  }
`;

export const Paragraph = styled.p`
  font-family: ${Fonts.paragraph};
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.black[100]};
  margin-bottom: 30px;

  @media ${deviceRange.mobile} {
    font-size: 14px;
  }
`;

export const landingDateIcon = styled.img``;
export const TextareaWrapper = styled.div`
  label {
    font-family: ${Fonts.paragraph};
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 1px;
    text-align: left;
  }
`;

export const RequiredFieldWrapper = styled.div``;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const InputLabel = styled.p`
  font-size: 15px;
  margin-bottom: 10px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #425262;
  direction: ltr;

  @media ${deviceRange.laptop} {
    margin-bottom: 0px;
  }

  @media ${deviceRange.laptopM} {
    margin-bottom: 0px;
  }

  @media ${deviceRange.laptopL} {
    margin-bottom: 0px;
  }

  @media ${deviceRange.desktop} {
    margin-bottom: 0px;
  }
  @media ${deviceRange.mobile} {
    font-size: 10px;
    margin-bottom: 5px;
  }
`;
export const LineStartQuestionBlock = styled.div`
  margin-top: 20px;
  margin-bottom: 20px !important;
  width: 100%;
  height: 2px;
  border-top: 2px dashed #cc00a0;
`;
