import React, { useState } from "react";
import { Images } from "../../environment/theme/Images";
import {
  FaqItemsContaner,
  FaqItemText,
  FaqItemsContentWrapper,
  FaqItemsText,
  FaqItemsWrapper
} from "./Landing.style";

interface FaqItemProps {
  text: String;
  title: String;
}
export const FaqItem = ({ text, title }: FaqItemProps) => {
  const [faqShow, setFaqShow] = useState<boolean>(false);
  return (
    <FaqItemsWrapper>
      <FaqItemsContaner>
        <FaqItemsContentWrapper>
          <img
            src={Images.landingYellowPoint}
            style={{ marginRight: "25px", marginLeft: "30px" }}
            alt=""
          />

          <FaqItemText>{title}</FaqItemText>
          {faqShow ? (
            <img
              src={Images.landingImageArrowUp}
              onClick={() => setFaqShow(false)}
              style={{ cursor: "pointer", width: "50px" }}
              alt=""
            />
          ) : (
            <img
              src={Images.landingImageArrowDown}
              onClick={() => setFaqShow(true)}
              style={{ cursor: "pointer", width: "50px" }}
              alt=""
            />
          )}
        </FaqItemsContentWrapper>
        {faqShow && (
          <FaqItemsText
            dangerouslySetInnerHTML={{
              __html: `${text}`
            }}
          ></FaqItemsText>
        )}
      </FaqItemsContaner>
    </FaqItemsWrapper>
  );
};
