import React, { useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { Input } from "../../../ui";
import { GhostButton } from "../../../ui/Button/GhostButton";
import { UserContext } from "../../UserPofile";

import {
  useTranslation,
  useAlerts,
  useUpdatePassword
} from "../../../../hooks";

import {
  Form,
  BlockInputs,
  InputWrapp,
  BlockButtonsWrapper,
  DeleteButtonWrapp,
  ResetPasswordInput,
  UserInfoButton
} from "./ResetPasswordModal.style";
import { PromoButtonStart } from "../../../../pages/Landing/Landing.style";
import { IconType } from "../../../../consts";
interface ResetPasswordModalProps {
  title?: string;
  open?: boolean;
  onClose?: any;
  setShowConfirmDeleteModal?: any;
}

export const ResetPasswordModal = ({
  setShowConfirmDeleteModal
}: ResetPasswordModalProps) => {
  const { setNotification } = useAlerts();
  const { user } = useContext(UserContext);
  const translate = useTranslation();
  const [updatePassword, { data: updatePasswordData }] = useUpdatePassword();

  useEffect(() => {
    if (updatePasswordData) {
      setNotification({
        message: translate(({ messages }) => messages.passwordUpdated)
      });
    }
  }, [updatePasswordData]);

  const RESET_PASSWORD_SCHEMA = yup.object().shape({
    password: yup
      .string()
      .required(translate(({ inputs }) => inputs.password.required)),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Lösenord måste matcha")
      .required("Bekräfta lösenord")
  });

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleBlur
  } = useFormik({
    initialValues: { password: "", passwordConfirmation: "" },
    validationSchema: RESET_PASSWORD_SCHEMA,
    onSubmit: ({ password }) =>
      updatePassword({
        variables: {
          password,
          email: user?.email
        }
      })
  });

  return (
    <Form onSubmit={evt => evt.preventDefault()}>
      <BlockInputs>
        <InputWrapp marginRight={20}>
          <ResetPasswordInput
            padding={"20px 18px"}
            password
            placeholder={translate(({ inputs }) => inputs.password.label)}
            name="password"
            onChange={handleChange}
            value={values.password}
            error={touched.password ? errors.password : undefined}
            onBlur={handleBlur}
          />
        </InputWrapp>
        <InputWrapp>
          <ResetPasswordInput
            padding={"20px 18px"}
            password
            placeholder={translate(
              ({ inputs }) => inputs.confirmPassword.label
            )}
            name="passwordConfirmation"
            onChange={handleChange}
            value={values.passwordConfirmation}
            error={
              touched.passwordConfirmation
                ? errors.passwordConfirmation
                : undefined
            }
            onBlur={handleBlur}
          />
        </InputWrapp>
      </BlockInputs>
      <BlockButtonsWrapper>
        <DeleteButtonWrapp>
          <GhostButton
            title={translate(({ buttons }) => buttons.delete) + " profil"}
            leftIcon={IconType.EpTrash}
            onClick={setShowConfirmDeleteModal}
          />
        </DeleteButtonWrapp>
        <UserInfoButton
          onClick={handleSubmit}
          paddingTop={18}
          paddingBottom={18}
          shadowLeft={3}
          shadowTop={4}
          marginTop={1}
        >
          {translate(({ buttons }) => buttons.resetPassword)}
        </UserInfoButton>
      </BlockButtonsWrapper>
    </Form>
  );
};
