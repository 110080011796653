import styled from "styled-components";
import { deviceRange } from "../../../consts";

import { Fonts } from "../../../environment";

// const ScrollFrames = keyframes
//   0% {
//     transform: rotate(-45deg) translate(0, 0);
//     opacity: 0;
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     transform: rotate(-45deg) translate(-20px, 20px);
//     opacity: 0;
//   }
// ;

export const ScrollWrapper = styled.div`
  display: flex;
  //max-height: 631px;
  height: 100%;
  margin-top: auto;
  width: 100%;
  max-width: fit-content;
  margin-right: 10px;

  @media ${deviceRange.laptop} {
    margin-right: 10px;
  }

  @media ${deviceRange.mobile} {
    display: none;
  }
`;
export const ScrollLine = styled.div`
  height: 100%;
  width: 6px;
  background: #6bc9db;
  border-radius: 32px;
`;
interface TextProps {
  position: boolean;
  showArrow: boolean;
}

export const Text = styled.a<TextProps>`
  display: flex;
  margin-top: auto;
  margin-right: 10px;
  flex-direction: column;
  z-index: 3;
  text-decoration: none;
  font-family: ${Fonts.landingOpenSansSemibold};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: #425262;
  opacity: ${props => (props.position ? 0 : 1)};
  visibility: ${props =>
    props.position || props.showArrow ? "visible" : "hidden"};

  :hover {
    opacity: ${props => (props.position ? "0" : "0.5")};
    color: black;
    cursor: ${props => (props.position ? "default" : "pointer")};
  }

  @media ${deviceRange.laptop} {
    font-size: 15px;
  }

  @media ${deviceRange.tablet} {
    font-size: 15px;
  }
`;
