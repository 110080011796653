import React, { useEffect, useContext, useState } from "react";
import { FormikProps } from "formik";

import {
  PageContainer,
  UploadImageBlock,
  ImageContent,
  UserImage,
  UserAccoutGeneralInfoForm,
  BlockButtonsImage,
  BlockInputs,
  WrapperButtonUpdate,
  ProfileInput,
  UserInfoButton
} from "./UserGeneralInfo.style";
import { GhostButton } from "../../ui";
import { IconType } from "../../../consts";
import { Images } from "../../../environment";
import { useAddOrRemoveUserAvatar, useTranslation } from "../../../hooks";
import { UserContext } from "../UserPofile";
import { CropModal } from "./CropModal";
import { getTokenData } from "../../../helpers";

export interface UserAccountFormValues {
  firstName: string;
  lastName: string;
  email: string;
  organisationColor: string;
}
export interface UserAccountDraftForm {
  draftForm: string;
  draftChapters?: {
    [x: string]: any;
  };
}
interface UserGeneralInfoProps {
  updateLoading: boolean;
  formik: FormikProps<UserAccountFormValues>;
  submitFromOutside: boolean;
  setSubmitFromOutside: (value: boolean) => void;
}

export function UserGeneralInfo({
  updateLoading,
  formik: { values, handleChange, handleSubmit, errors, touched, handleBlur },
  submitFromOutside,
  setSubmitFromOutside
}: UserGeneralInfoProps) {
  const { user, setUser } = useContext(UserContext);
  const translate = useTranslation();
  const [showAvatarCropModal, setShowAvatarCropModal] = useState(false);
  const [
    removeUserAvatar,
    { data: removeUserAvatarData }
  ] = useAddOrRemoveUserAvatar();

  const loggedUser = getTokenData();

  function handleRemoveUserAvatar() {
    if (user) {
      removeUserAvatar({
        variables: {
          data: {
            userAccountWhereUniqueInput: { id: user.id },
            isRemove: true
          }
        }
      });
    }
  }

  useEffect(() => {
    if (removeUserAvatarData) {
      setUser(removeUserAvatarData.addOrRemoveUserAvatar);
    }
  }, [removeUserAvatarData]);

  useEffect(() => {
    if (submitFromOutside) {
      handleSubmit();
      setSubmitFromOutside(false);
    }
  }, [submitFromOutside]);

  function handleOpenModal() {
    setShowAvatarCropModal(true);
  }

  function handleCloseModal() {
    setShowAvatarCropModal(false);
  }

  return (
    <PageContainer>
      <CropModal
        showAvatarCropModal={showAvatarCropModal}
        handleCloseModal={handleCloseModal}
      />

      <UploadImageBlock>
        {/* Get Avatar */}
        <ImageContent>
          <UserImage
            src={user && user.avatar ? user.avatar.url : Images.avatar}
          />
        </ImageContent>

        <BlockButtonsImage>
          {/* Add Avatar */}
          <GhostButton
            leftIcon={IconType.EpArrowUp}
            title={translate(({ buttons }) => buttons.uploadNew)}
            onClick={handleOpenModal}
          />
          <GhostButton
            leftIcon={IconType.EpTrash}
            title={"Radera bild"}
            onClick={handleRemoveUserAvatar}
          />
        </BlockButtonsImage>
      </UploadImageBlock>

      {/* Form */}
      <UserAccoutGeneralInfoForm onSubmit={handleSubmit}>
        <BlockInputs>
          <ProfileInput
            padding={"20px 18px"}
            placeholder={translate(
              ({ inputs }) => inputs.firstName.placeholder
            )}
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            error={
              touched.firstName
                ? errors.firstName
                : values.firstName
                ? undefined
                : "Fyll i ditt namn (namnet kommer att synas i ditt överlämningsdokument)"
            }
            onBlur={handleBlur}
            borderAndShadow={false}
          />
          <ProfileInput
            padding={"20px 18px"}
            placeholder={translate(({ inputs }) => inputs.lastName.placeholder)}
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            error={
              touched.lastName
                ? errors.lastName
                : values.lastName
                ? undefined
                : "Fyll i ditt namn (namnet kommer att synas i ditt överlämningsdokument)"
            }
            onBlur={handleBlur}
            borderAndShadow={false}
          />
        </BlockInputs>
        <BlockInputs>
          <ProfileInput
            padding={"20px 18px"}
            placeholder={translate(({ inputs }) => inputs.email.placeholder)}
            name="email"
            value={values.email}
            onChange={handleChange}
            error={touched.email ? errors.email : undefined}
            onBlur={handleBlur}
            readOnly={loggedUser?.role !== "SUPER"}
            borderAndShadow={false}
          />
          <WrapperButtonUpdate>
            <UserInfoButton
              onClick={handleSubmit}
              paddingTop={18}
              paddingBottom={18}
              shadowLeft={3}
              shadowTop={4}
              marginTop={14}
              paddingRight={10}
              paddingLeft={10}
            >
              {translate(({ buttons }) => buttons.updateProfile)}
            </UserInfoButton>
          </WrapperButtonUpdate>
        </BlockInputs>
      </UserAccoutGeneralInfoForm>
    </PageContainer>
  );
}
