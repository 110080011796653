import React, { useContext } from "react";

import {
  FileInputContainer,
  FileInput as FileInputElement,
  FileInputLabel,
  LabelText,
  IconTrashContainer,
  FilesBlock,
  FileWrapper
} from "./FileInput.style";
import { Icon } from "../../../components/ui";
import {
  useMeQuery,
  useTranslation,
  useUploadEmbeddedFileMutation,
  useDeleteEmbeddedFileMutation,
  useUpdateUserAccountDetailsMutation
} from "../../../hooks";
import { IconType } from "../../../consts";
import {
  HandoverFile,
  HandoverDispatchContext,
  setHandoverFile,
  setfieldsValues
} from "./../../../pages/Handover";
import { Images } from "../../../environment";
import { FullImage as getFullImg } from "../../../helpers";

interface FileInputProps {
  accept: string;
  value?: HandoverFile;
  fieldName: string;
  onChange: (file: File) => void;
  onRemove: () => void;
}

export function FileInput({
  onChange,
  onRemove,
  accept,
  fieldName,
  value
}: FileInputProps) {
  const translate = useTranslation();
  const [uploadEmbeddedfile] = useUploadEmbeddedFileMutation();
  const [deleteEmbeddedFile] = useDeleteEmbeddedFileMutation();
  const [updateUserAccountDetails] = useUpdateUserAccountDetailsMutation();
  const handoverDispatch = useContext(HandoverDispatchContext);

  const userData = useMeQuery();

  async function uploadFiles(userAccountDetails: any) {
    const newFile = JSON.parse(
      userAccountDetails.data?.updateUserAccountDetails?.user?.draftForm || ""
    ).filter((field: any) => {
      if (field.type === "file" && field.value[0]?.length) {
        return field;
      }
    });

    newFile.map((embeddedFile: any) => {
      handoverDispatch(
        setHandoverFile(
          JSON.parse(embeddedFile.value[0]).filename,
          JSON.parse(embeddedFile.value[0]).fieldname
        )
      );
    });
    return true;
  }

  async function updateFieldValues(draftForm: any) {
    const newFieldsValues = JSON.parse(draftForm);
    handoverDispatch(setfieldsValues(newFieldsValues));
  }

  async function handleFileInputChange(
    evt: React.ChangeEvent<HTMLInputElement>
  ) {
    const currentEl = evt.target;
    if (
      currentEl.files &&
      currentEl.files[0]?.type.includes("image") &&
      currentEl.files.length > 0
    ) {
      onChange(currentEl.files[0]);
      const imageOptions = {
        name: currentEl.files[0].name,
        type: currentEl.files[0].type,
        path: URL.createObjectURL(currentEl.files[0])
      };
      const { imgFile: blobFile }: any = await getFullImg(imageOptions.path, {
        height: 0,
        width: 0,
        x: 0,
        y: 0
      });
      const croppedImgFile = new File([blobFile], imageOptions.name, {
        lastModified: Date.now(),
        type: imageOptions.type
      });
      const newAccDetails = await uploadEmbeddedfile({
        variables: {
          data: {
            userAccountWhereUniqueInput: {
              id: userData.data?.me.id
            },
            fieldName: fieldName,
            file: croppedImgFile
          }
        }
      });
      if (newAccDetails) {
        const userAccountDetails = await updateUserAccountDetails({
          variables: {
            data: {
              userAccountWhereUniqueInput: {
                id: userData.data?.me.id
              },
              draftForm: newAccDetails.data.uploadEmbeddedFile.draftForm
            }
          }
        });
        await uploadFiles(userAccountDetails);
        await updateFieldValues(
          newAccDetails.data.uploadEmbeddedFile.draftForm
        );
      }
    } else if (currentEl.files && currentEl.files[0]) {
      onChange(currentEl.files[0]);
      const newAccDetails = await uploadEmbeddedfile({
        variables: {
          data: {
            userAccountWhereUniqueInput: {
              id: userData.data?.me.id
            },
            fieldName: fieldName,
            file: currentEl.files[0]
          }
        }
      });

      if (newAccDetails) {
        const userAccountDetails = await updateUserAccountDetails({
          variables: {
            data: {
              userAccountWhereUniqueInput: {
                id: userData.data?.me.id
              },
              draftForm: newAccDetails.data.uploadEmbeddedFile.draftForm
            }
          }
        });

        await uploadFiles(userAccountDetails);
        await updateFieldValues(
          newAccDetails.data.uploadEmbeddedFile.draftForm
        );
      }
    }
  }

  const handleFileRemove = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    deleteEmbeddedFile({
      variables: {
        data: {
          userAccountWhereUniqueInput: {
            id: userData.data?.me.id
          },
          fieldName: fieldName
        }
      }
    });
    onRemove();
  };

  const getValue = () => {
    return typeof value?.file === "string" ? value?.file : value?.file?.name;
  };

  return (
    <FileInputContainer>
      <FileInputLabel>
        <FileInputElement
          type="file"
          onChange={handleFileInputChange}
          accept={accept}
        />
        {<img src={Images.landingExportLogo} alt="" />}

        <LabelText>{translate(({ buttons }) => buttons.upload)}</LabelText>
      </FileInputLabel>

      {!!value && (
        <FilesBlock>
          <FileWrapper>
            <div>
              <img
                src={Images.landingCheckIcon}
                style={{ marginRight: "7px", marginLeft: "5px" }}
                alt=""
              />
              {getValue()}
            </div>
            <IconTrashContainer onClick={e => handleFileRemove(e)}>
              <Icon name={IconType.EpTrash} />
            </IconTrashContainer>
          </FileWrapper>
        </FilesBlock>
      )}
    </FileInputContainer>
  );
}
