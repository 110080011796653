import gql from "graphql-tag";

export const UserAccountFragment = gql`
  fragment UserAccountFragment on UserAccount {
    id
    createdAt
    updatedAt
    firstName
    lastName
    email
    draftForm
    organisationColor
    draftChapters {
      id
      chapterId
      template
    }
    role
  }
`;
