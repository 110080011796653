import React from "react";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  ContentBlockWrapper
} from "./Modals.style";
import { ButtonPDF } from "./BaseButtonModal";

export const ModalContent12 = () => {
  return (
    <>
      <ContentBlockWrapper>
        <ContentBlock>
          <LeftBlockContent>
            <TextTitleWrapper>
              <TextTitle>För vem:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>Ersättare</SecondText>
            </TextWrapper>
            <TextTitleWrapper>
              <TextTitle>Hur dokumentet kan användas:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Här finns tips på hur du som ska påbörja ett nytt uppdrag kan
                förbereda dig.
              </SecondText>
            </TextWrapper>
          </LeftBlockContent>
          <RightBlockContent>
            <TextTitleWrapper>
              <TextTitle>Beskrivning:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Använd listan för att få tips eller använd den som en checklista
                inför din nya mandatperiod.
              </SecondText>
            </TextWrapper>
          </RightBlockContent>
        </ContentBlock>
      </ContentBlockWrapper>
      <FooterModal>
        <ButtonPDF
          downLink={
            "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Exempel på vad du kan förbereda inför din första dag.pdf"
          }
        />
      </FooterModal>
    </>
  );
};
