import React from "react";

import { ModalContainer } from "./ConfirmModal.style";
import { Modal, ModalSizes } from "./../../ui";
import {
  HeadetButtonLog,
  TargetBlockBtnRekommendera
} from "../../../pages/Landing/Landing.style";

interface ConfirmModalProps {
  showModal: boolean;
  title: string;
  loading?: boolean;
  onCloseModal: () => void;
  onClickYes: () => void;
}

export function ConfirmModal({
  onCloseModal,
  showModal,
  onClickYes,
  title,
  loading = false
}: ConfirmModalProps) {
  return (
    <Modal
      open={showModal}
      onClose={onCloseModal}
      size={ModalSizes.XS}
      autoHeight={true}
      title={title}
    >
      <ModalContainer>
        <HeadetButtonLog onClick={onCloseModal} marginLeft={1}>
          Nej
        </HeadetButtonLog>
        <TargetBlockBtnRekommendera
          onClick={onClickYes}
          width={90}
          paddingLeft={20}
          paddingRight={20}
          paddingTop={12}
          paddingBottom={12}
          shadowLeft={2}
          shadowTop={4}
        >
          Ja
        </TargetBlockBtnRekommendera>
      </ModalContainer>
    </Modal>
  );
}
