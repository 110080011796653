import React, { useEffect, useState } from "react";
import { NavLink as Link, useLocation } from "react-router-dom";

import {
  SidebarContainer,
  NavigationContainer,
  NavBar,
  NavItem,
  NavLink,
  LinkIcon,
  LinkTitle,
  LogOutBtn,
  LogOutBtnContainer,
  LogoContainer,
  LogOutBtnText,
  PartnersContainer,
  PartnersText,
  NameSiteLogo,
  SidebarLogoWrapp,
  PartnersLogo
} from "./SidebarMenu.style";
import { StorageKey, deviceSize } from "../../consts";
import {
  useNavigation,
  useTranslation,
  useEffectOnce,
  useViewport,
  useMeQuery
} from "../../hooks";
import { SuperAdminProtector } from "./../SuperAdminProtector";
import { Images } from "./../../environment";

export function SidebarMenu() {
  const [activeLink, setActiveLink] = useState<string | null>(null);
  const [showSidebar, setShowSidebar] = useState<number>(0);
  const translate = useTranslation();
  const { routes, navigate, history } = useNavigation();
  const location = useLocation();
  const { data: userData } = useMeQuery();
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [existUserInfo, setExistUserInfo] = useState<number>(0);

  useEffectOnce(() => {
    setActiveLink(history.location.pathname);
  });

  useEffect(() => {
    if (userData?.me) {
      setFirstName(userData?.me.firstName || null);
      setLastName(userData?.me.lastName || null);
    }
  }, [userData?.me]);

  useEffect(() => {
    if (firstName && lastName) {
      setExistUserInfo(1);
    }
  }, [firstName, lastName]);

  const width = useViewport();

  const navigationData = [
    {
      title: translate(({ sideMenuLinks }) => sideMenuLinks.handovers),
      icon: Images.landingMenuHandover,
      path: routes.handovers,
      withMarginBottom: false,
      superAdminProtector: false
    },
    {
      title: translate(({ sideMenuLinks }) => sideMenuLinks.editor),
      icon: Images.landingMenuRedactor,
      path: routes.editor,
      withMarginBottom: false,
      superAdminProtector: true
    },
    // {
    //   title: translate(({ sideMenuLinks }) => sideMenuLinks.members),
    //   icon: Images.landingMenuMedlemmar,
    //   path: routes.members,
    //   withMarginBottom: false,
    //   superAdminProtector: true
    // },
    {
      title: translate(({ sideMenuLinks }) => sideMenuLinks.history),
      icon: Images.landingMenuSkickade,
      path: routes.story,
      withMarginBottom: false,
      superAdminProtector: false
    },
    {
      title: translate(({ sideMenuLinks }) => sideMenuLinks.profile),
      icon: Images.landingMenuProfile,
      path: routes.profile,
      withMarginBottom: false,
      superAdminProtector: false
    },
    {
      title: translate(({ sideMenuLinks }) => sideMenuLinks.faq),
      icon: Images.landingMenuFaq,
      path: routes.logfaq,
      withMarginBottom: false,
      superAdminProtector: false
    }
  ];

  function logOut() {
    localStorage.removeItem(StorageKey.Token);
    navigate(routes.login);
  }

  const checkActiveLink = () => {
    if (
      activeLink?.includes("/handovers") ||
      activeLink?.includes("/thankyou")
    ) {
      return "/handovers";
    } else return activeLink;
  };

  return (
    <SidebarContainer
      sidebar={
        (location.pathname !== "/handovers/handover" &&
          width >= parseInt(deviceSize.laptopM.min)) ||
        showSidebar
          ? 1
          : 0
      }
      onMouseEnter={e => setShowSidebar(1)}
      onMouseLeave={e => setShowSidebar(0)}
    >
      <NavigationContainer>
        <div>
          <LogoContainer>
            <Link to={"/"}>
              <SidebarLogoWrapp>
                <img
                  src={Images.landingLogoSidebar}
                  style={{ width: "35px", height: "35px" }}
                />
                {(location.pathname !== "/handovers/handover" &&
                  width >= parseInt(deviceSize.laptopM.min)) ||
                showSidebar ? (
                  <img
                    src={Images.landingLogoName}
                    style={{
                      marginLeft: "10px",
                      width: "190px"
                    }}
                  />
                ) : null}
              </SidebarLogoWrapp>
            </Link>
          </LogoContainer>

          <NavBar>
            {navigationData.map((item, index) => {
              const navItem = (
                <NavItem key={`nav-item-${index}`}>
                  <NavLink
                    sidebar={
                      width >= parseInt(deviceSize.laptopM.min)
                        ? width >= parseInt(deviceSize.laptopM.min) &&
                          location.pathname !== "/handovers/handover"
                          ? 1
                          : showSidebar
                        : (location.pathname === "/handovers/handover" ||
                            location.pathname === "/handovers" ||
                            location.pathname === "/story" ||
                            location.pathname === "/profile" ||
                            location.pathname === "/loggedFAQ" ||
                            location.pathname === "/thankyou" ||
                            location.pathname ===
                              "/handovers/recommend-this-tool") &&
                          !showSidebar
                        ? 0
                        : 1
                    }
                    to={item.path}
                    onClick={() => setActiveLink(item.path)}
                    // disabled={+!existUserInfo}
                  >
                    <LinkIcon
                      active={item.path === checkActiveLink() ? true : false}
                    >
                      {item.title === "Editor" ? (
                        <img src={Images.landingMenuHandover} />
                      ) : null}

                      <img
                        src={item.icon}
                        style={{
                          marginLeft: "-1.7px",
                          marginTop: "-1.7px",
                          maxWidth: "32px",
                          maxHeight: "32px",
                          borderRadius: "50%"
                        }}
                      />
                    </LinkIcon>
                    {(location.pathname !== "/handovers/handover" &&
                      width >= parseInt(deviceSize.laptopM.min)) ||
                    showSidebar ? (
                      <LinkTitle>{item.title}</LinkTitle>
                    ) : null}
                  </NavLink>
                </NavItem>
              );
              return item.superAdminProtector ? (
                <SuperAdminProtector key={`nav-item-${index}`}>
                  {navItem}
                </SuperAdminProtector>
              ) : (
                navItem
              );
            })}
            <LogOutBtnContainer
              sidebar={
                width >= parseInt(deviceSize.laptopM.min)
                  ? width >= parseInt(deviceSize.laptopM.min) &&
                    location.pathname !== "/handovers/handover"
                    ? 1
                    : showSidebar
                  : (location.pathname === "/handovers/handover" ||
                      location.pathname === "/handovers" ||
                      location.pathname === "/story" ||
                      location.pathname === "/profile" ||
                      location.pathname === "/loggedFAQ" ||
                      location.pathname === "/thankyou" ||
                      location.pathname === "/handovers/recommend-this-tool") &&
                    !showSidebar
                  ? 0
                  : 1
              }
            >
              <LogOutBtn onClick={logOut}>
                <img
                  src={Images.landingMenuLogout}
                  style={{
                    marginLeft: "-1.7px",
                    marginTop: "-1.7px",
                    maxWidth: "32px",
                    maxHeight: "32px",
                    borderRadius: "50%"
                  }}
                />
                {(location.pathname !== "/handovers/handover" &&
                  width >= parseInt(deviceSize.laptopM.min)) ||
                showSidebar ? (
                  <>
                    <LogOutBtnText>
                      {translate(({ buttons }) => buttons.logout)}
                    </LogOutBtnText>
                  </>
                ) : null}
              </LogOutBtn>
            </LogOutBtnContainer>
          </NavBar>
        </div>
        {(location.pathname !== "/handovers/handover" &&
          width >= parseInt(deviceSize.laptopM.min)) ||
        showSidebar ? (
          <PartnersContainer>
            <PartnersText>
              {translate(({ titles }) => titles.partners)}
            </PartnersText>

            <PartnersLogo>
              <a
                href="https://www.arvsfonden.se/"
                target="blank"
                style={{ margin: "auto", width: "55%" }}
              >
                <img
                  src={Images.landingMenuPartner2}
                  alt=""
                  style={{ display: "block", maxWidth: "100%" }}
                />
              </a>
              <a
                href="https://viunga.se/"
                target="blank"
                style={{ margin: "auto", width: "23%" }}
              >
                <img
                  src={Images.landingMenuPartner1}
                  alt=""
                  style={{ display: "block", maxWidth: "100%" }}
                />
              </a>
            </PartnersLogo>

            <NameSiteLogo>&#169; 2021 Spridakunskap</NameSiteLogo>
          </PartnersContainer>
        ) : null}
      </NavigationContainer>
    </SidebarContainer>
  );
}
