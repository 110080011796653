import React from "react";
import {
  CheckboxContainer,
  CheckboxLabel,
  HiddenCheckbox,
  StyledCheckbox,
  CheckboxTextLabel
} from "./Checkbox.style";
import { Images } from "../../../environment";

interface CheckboxProps {
  name?: string;
  className?: string;
  label?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export function Checkbox({
  name,
  label,
  className,
  checked,
  onChange,
  disabled
}: CheckboxProps) {
  return (
    <CheckboxContainer className={className}>
      <CheckboxLabel>
        <HiddenCheckbox
          name={name}
          checked={checked}
          onChange={e => onChange(e)}
          disabled={disabled}
          hidden
        />
        <StyledCheckbox checked={checked} disabled={disabled}>
          <img
            src={Images.landingCheckboxIcon}
            style={{ color: `${checked ? "white" : "transparent"}` }}
            alt=""
          />
        </StyledCheckbox>
        {!!label && (
          <CheckboxTextLabel checked={checked}>{label}</CheckboxTextLabel>
        )}
      </CheckboxLabel>
    </CheckboxContainer>
  );
}
