import styled from "styled-components";
import { Fonts, Colors } from "./../../../environment";

export const HelpTextContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const HelpTextIcon = styled.div`
  cursor: pointer;
  z-index: 2;
  svg path {
    fill: ${Colors.neutralBlue[40]};
  }

  &:hover svg path {
    fill: rgba(79, 69, 245, 1);
  }

  &:hover + div {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const HelpTextWindow = styled.div`
  position: absolute;
  width: 100%;
  max-width: 332px;
  z-index: 1;
  top: 100%;
  right: 10px;
  transform: translate(0px, 10px);
  padding: 16px;
  background-color: ${Colors.white};
  border-radius: 8px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease;
  color: ${Colors.black[100]};
  font-family: ${Fonts.paragraph};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;
