import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const RECOMMEND_TOOL_QUERY = gql`
  query SendRecommendation($data: SendRecommendationInput!) {
    sendRecommendation(data: $data)
  }
`;

export function useRecommendToolQuery() {
  return useLazyQuery(RECOMMEND_TOOL_QUERY);
}
