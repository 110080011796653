import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";

import {
  PageWrapper,
  PageContainer,
  LeftBlockContainer,
  BaseButtonContainer,
  BlockContainer,
  ImageWrapperPeopleCall,
  VideoPopUpWrapp,
  VideoPopupContent,
  ButtonCloseVideoPopup,
  IntroVideo,
  PeopleCallImg,
  HandoversButtonStart,
  TargetBlockBtnRekommendera,
  HeadetButtonLog,
  PreviewButton
} from "./Handovers.style";
import { Images } from "../../environment";
import {
  useBreadcrumbs,
  useNavigation,
  useTranslation,
  useEffectOnce,
  useMeQuery,
  useUpdateUserAccountDetailsMutation,
  useViewport
} from "../../hooks";
import {
  PromoButtonStart,
  PromoBlockMainText,
  GlobalStyle
} from "../../pages/Landing/Landing.style";
import { ChapterTypes, deviceSize, UserTypes } from "../../consts";

export function Handovers() {
  const [user, setUser] = useState<any>(null);
  const [isEmptyForm, setIsEmptyForm] = useState<boolean>(true);
  const translate = useTranslation();
  const [isShowIntro, setIsShowIntro] = useState<boolean>(false);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { routes, history } = useNavigation();
  const { data: userData } = useMeQuery();
  const [
    _,
    { data: updateUserAccountDetailsData }
  ] = useUpdateUserAccountDetailsMutation();

  const width = useViewport();

  useEffect(() => {
    if (updateUserAccountDetailsData) {
      const { user } = updateUserAccountDetailsData.updateUserAccountDetails;
      setUser(user);
    }
  }, [updateUserAccountDetailsData]);

  useEffect(() => {
    if (userData?.me) {
      setUser(userData?.me);
    }
  }, [userData]);

  useEffect(() => {
    const emptyValues: any = user?.draftForm;
    let fields;

    if (emptyValues) {
      fields = JSON.parse(emptyValues);
    }
    fields?.map((el: any) => {
      if (el.type !== "date" && el.type !== "select") {
        if (el.value[0]) {
          setIsEmptyForm(false);
        }
      }
    });
  }, [user]);

  useEffectOnce(() => {
    const breadcrumbsData = [
      {
        label: translate(({ handoversPage }) => handoversPage.handovers),
        to: routes.handovers
      }
    ];

    setBreadcrumbs(breadcrumbsData);
  });

  useEffectOnce(() => {
    const keyVideo = localStorage.getItem("key-intro-video");
    if (keyVideo) {
      return;
    } else {
      setIsShowIntro(true);
    }
  });

  const [regularButtonDisabled, setRegularButtonDisabled] = useState(false);
  const [businessButtonDisabled, setBusinessButtonDisabled] = useState(false);

  const [chapterStatus, setChapterStatus] = useState(
    localStorage.getItem("ChapterStatus")
  );

  useEffect(() => {
    const chapterType = localStorage.getItem("ChapterType");

    if (
      chapterType === JSON.stringify("BUSINESS") &&
      chapterStatus === "DISABLED"
    ) {
      setRegularButtonDisabled(true);
    }

    if (
      chapterType === JSON.stringify("REGULAR") &&
      chapterStatus === "DISABLED"
    ) {
      setBusinessButtonDisabled(true);
    }
  }, [chapterStatus]);

  function NeverDontShowIntro() {
    localStorage.setItem("key-intro-video", "Dont show");
    setIsShowIntro(false);
  }

  const setChaptersType = (chapterType: string) => {
    localStorage.setItem("ChapterType", JSON.stringify(chapterType));
    localStorage.setItem("ChapterStatus", "DISABLED");
    history.push(routes.handover);
  };

  return (
    <PageWrapper>
      <GlobalStyle />
      <PageContainer>
        <BlockContainer>
          <LeftBlockContainer>
            <PromoBlockMainText fontSize={67} textAlign={"left"}>
              {translate(({ handoverPage }) => handoverPage.firstHandover)}
            </PromoBlockMainText>

            <BaseButtonContainer>
              {/* Continue on begun handover */}
              {regularButtonDisabled ? (
                <>
                  <ReactTooltip />
                  <div
                    data-tip=" Du kan endast arbeta med en överlämning åt gången, för att avsluta påbörjad överlämning, skicka eller nollställ påbörjat formulär."
                    data-background-color="#fff"
                    data-text-color="black"
                    data-border={true}
                    data-border-color="#4a3e76"
                    data-class="tooltipCustom"
                  >
                    <HandoversButtonStart
                      onClick={() => setChaptersType(ChapterTypes.Regular)}
                      paddingTop={21}
                      paddingBottom={21}
                      shadowLeft={3}
                      shadowTop={4}
                      marginTop={1}
                      width={320}
                      disabled={true}
                    >
                      Överlämna förtroendeuppdrag ttt
                    </HandoversButtonStart>
                  </div>
                </>
              ) : (
                <HandoversButtonStart
                  onClick={() => setChaptersType(ChapterTypes.Regular)}
                  paddingTop={21}
                  paddingBottom={21}
                  shadowLeft={3}
                  shadowTop={4}
                  marginTop={1}
                  width={320}
                >
                  Överlämna förtroendeuppdrag
                </HandoversButtonStart>
              )}
              {businessButtonDisabled ? (
                <>
                  <ReactTooltip />
                  <div
                    data-tip=" Du kan endast arbeta med en överlämning åt gången, för att avsluta påbörjad överlämning, skicka eller nollställ påbörjat formulär."
                    data-background-color="#fff"
                    data-text-color="black"
                    data-border={true}
                    data-border-color="#4a3e76"
                    data-class="tooltipCustom"
                  >
                    <PreviewButton
                      disabled={true}
                      onClick={() => {
                        setChaptersType(ChapterTypes.Business);
                      }}
                    >
                      Överlämna anställning
                    </PreviewButton>
                  </div>
                </>
              ) : (
                <PreviewButton
                  onClick={() => {
                    setChaptersType(ChapterTypes.Business);
                  }}
                >
                  Överlämna anställning
                </PreviewButton>
              )}
            </BaseButtonContainer>

            <BaseButtonContainer>
              <HeadetButtonLog
                padding={"0px 0px"}
                customWidth={320}
                marginLeft={1}
                onClick={() => history.push(routes.recommendTool)}
                fontSize={17}
              >
                {translate(
                  ({ recommendToolPage }) => recommendToolPage.recommendTool
                )}
              </HeadetButtonLog>
            </BaseButtonContainer>
          </LeftBlockContainer>
          {isShowIntro && (
            <VideoPopUpWrapp>
              <VideoPopupContent>
                <ButtonCloseVideoPopup onClick={() => setIsShowIntro(false)}>
                  <img src={Images.landingCloseIcon} alt="" />
                </ButtonCloseVideoPopup>
                <IntroVideo controls>
                  <source src={Images.handoversIntroVideo} type="video/mp4" />
                </IntroVideo>
                <TargetBlockBtnRekommendera
                  padding={"13px 30px"}
                  marginTop={45}
                  widthVW={width < parseInt(deviceSize.mobile.max) ? 60 : 18}
                  onClick={() => NeverDontShowIntro()}
                >
                  Visa inte video igen
                </TargetBlockBtnRekommendera>
              </VideoPopupContent>
            </VideoPopUpWrapp>
          )}
          <ImageWrapperPeopleCall>
            {width < parseInt(deviceSize.laptop.min) ? (
              <>
                <PeopleCallImg
                  src={Images.landingManForTable}
                  alt="not found"
                />
              </>
            ) : (
              <>
                <PeopleCallImg src={Images.landingPeopleCall} alt="not found" />
              </>
            )}
          </ImageWrapperPeopleCall>
        </BlockContainer>
      </PageContainer>
    </PageWrapper>
  );
}
