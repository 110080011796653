import React, { useState } from "react";
import { Images } from "../../../environment";

import {
  Container,
  BaseTextarea,
  ErrorLabel,
  Information
} from "./Textarea.style";

interface TextareaProps {
  value: string;
  error?: string;
  label?: string;
  rows?: number;
  autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined;
  disabled?: boolean;
  className?: string;
  name?: string;
  required?: boolean;
  placeholder?: string;
  description?: string;

  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export function Textarea({
  value,
  error,
  label,
  rows = 5,
  autoCapitalize = "none",
  disabled,
  className,
  name,
  required = false,
  placeholder,
  description,

  onChange,
  onFocus,
  onBlur
}: TextareaProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [showInformation, setShowInformation] = useState(false);

  function handleFocus(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (!isFocused) {
      setIsFocused(true);

      if (onFocus) {
        onFocus(e);
      }
    }
  }

  function handleBlur(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (isFocused) {
      setIsFocused(false);
    }

    if (onBlur) {
      onBlur(e);
    }
  }

  return (
    <Container className={className}>
      <BaseTextarea
        name={name}
        placeholder={placeholder}
        rows={rows}
        value={value}
        autoCapitalize={autoCapitalize}
        readOnly={disabled}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        invalid={!!error}
        focus={isFocused}
        withLabel={!!label}
      />
      {description && (
        <>
          <img
            src={Images.landingInformationIcon}
            style={{
              width: "20px",
              height: "20px",
              position: "absolute",
              right: "0",
              top: "0",
              marginRight: "5px",
              marginTop: "5px",
              cursor: "help"
            }}
            alt=""
            onMouseOver={e => setShowInformation(true)}
            onClick={e => setShowInformation(true)}
            onMouseOut={e => setShowInformation(false)}
          />
          {showInformation && <Information>{description}</Information>}
        </>
      )}

      <ErrorLabel>{error}</ErrorLabel>
    </Container>
  );
}
