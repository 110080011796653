import styled from "styled-components";
import { deviceRange } from "../../consts";
import { Colors, Fonts } from "../../environment";

export const PageWrapper = styled.div`
  width: 100%;
  position: relative;
  background: white;
`;

export const PageTopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.5px;
  padding: 19px 56px;
`;

export const PageTopBarItem = styled.div`
  display: flex;
  align-items: center;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  height: calc(100vh - 60px);
  width: calc(100vw - 50px);
  padding: 0px 0px 20px 100px;

  @media ${deviceRange.tablet} {
    padding: 0px 0px 20px 30px;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  @media ${deviceRange.laptop} {
    padding: 0px 0px 20px 50px;
  }

  @media ${deviceRange.laptopM} {
    padding: 0px 0px 20px 50px;
  }
`;

export const HandoverPageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  height: calc(100vh - 60px);
  width: 100%;
  padding: 0px 0px 20px 20px;

  @media ${deviceRange.tablet} {
    padding: 0px 0px 20px 15px;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  @media ${deviceRange.laptop} {
    padding: 0px 0px 20px 25px;
  }

  @media ${deviceRange.laptopM} {
    padding: 0px 0px 20px 25px;
  }

  @media ${deviceRange.mobile} {
    padding: 0px;
  }
`;

export const FormBuilderContainer = styled.div`
  padding: 2.4rem;
`;

export const HandoverControls = styled.div`
  display: flex;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #d8dce4;

  & > div:first-child {
    margin-right: auto;
  }

  & > div:last-child {
    margin-left: 2.4rem;
  }
`;

export const ScaleLabel = styled.span`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-size: 1.4rem;
  font-family: ${Fonts.SFPDisplayRegular};
  color: ${Colors.black[100]};
`;
