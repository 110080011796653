import React from "react";

import {
  PageWrapper,
  PageMainContent,
  PrivacyPolicyTitle,
  PrivacyPolicyText,
  PrivacyPolicyBlock,
  PrivacyPolicySubTitle,
  PrivacyPolicyTextInfo,
  PrivacyPolicyMainTitle,
  PrivacyPolicyList,
  PrivacyPolicyTextInfoContainer,
  SpanText
} from "./PrivacyPolicy.style";
import { useTranslation } from "../../hooks/i18n";
import { AuthHeader } from "../../components/auth";
import { BurgetMenu } from "../../components/BurgetMenu";
import { useViewport } from "../../hooks";
import { deviceSize } from "../../consts";

export const PrivacyPolicy = () => {
  const translate = useTranslation();
  const width = useViewport();

  return (
    <PageWrapper>
      {width > parseInt(deviceSize.mobile.max) ? (
        <AuthHeader />
      ) : (
        <BurgetMenu unLogged={true} />
      )}
      <PageMainContent>
        <PrivacyPolicyBlock>
          <PrivacyPolicyMainTitle>
            {translate(({ privacyAndTerms }) => privacyAndTerms.privacyPolicy)}
          </PrivacyPolicyMainTitle>
          <PrivacyPolicyText>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.privacyPolicyText
            )}
          </PrivacyPolicyText>

          <PrivacyPolicyTitle>
            1.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.InformationWeCollect
            )}
          </PrivacyPolicyTitle>
          <PrivacyPolicySubTitle>
            {translate(({ privacyAndTerms }) => privacyAndTerms.LogData)}
          </PrivacyPolicySubTitle>
          <PrivacyPolicyText>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.privacyPolicyTextSecond
            )}
          </PrivacyPolicyText>

          <PrivacyPolicySubTitle>
            {translate(({ privacyAndTerms }) => privacyAndTerms.DeviceData)}
          </PrivacyPolicySubTitle>
          <PrivacyPolicyText>
            {translate(
              ({ privacyAndTerms }) =>
                privacyAndTerms.privacyPolicyTextDeviceData
            )}
          </PrivacyPolicyText>

          <PrivacyPolicySubTitle>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.PersonalInformation
            )}
          </PrivacyPolicySubTitle>
          <PrivacyPolicyText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.WeMayAsk)}
          </PrivacyPolicyText>

          <PrivacyPolicyList>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(({ privacyAndTerms }) => privacyAndTerms.Name)}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(({ privacyAndTerms }) => privacyAndTerms.email)}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) => privacyAndTerms.SocialMediaProfiles
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) => privacyAndTerms.DateOfBirth
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) => privacyAndTerms.PhoneMobileNumber
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) => privacyAndTerms.HomeMailingAddress
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) => privacyAndTerms.WorkAddress
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) => privacyAndTerms.WebsiteAddress
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) => privacyAndTerms.PaymentInformation
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) => privacyAndTerms.DriversLicenceDetails
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) => privacyAndTerms.PassportNumber
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.TaxSSNMedicareEtcNumber
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
          </PrivacyPolicyList>

          <PrivacyPolicySubTitle>
            {translate(({ privacyAndTerms }) => privacyAndTerms.BusinessData)}
          </PrivacyPolicySubTitle>
          <PrivacyPolicyText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.BusinessText)}
          </PrivacyPolicyText>

          <PrivacyPolicyTitle>
            2. {translate(({ privacyAndTerms }) => privacyAndTerms.LegalInfo)}
          </PrivacyPolicyTitle>
          <PrivacyPolicyText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.LegalText)}
          </PrivacyPolicyText>
          <PrivacyPolicyText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.LegalTextSub)}
          </PrivacyPolicyText>

          <PrivacyPolicyList>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(({ privacyAndTerms }) => privacyAndTerms.Text1)}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(({ privacyAndTerms }) => privacyAndTerms.Text2)}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(({ privacyAndTerms }) => privacyAndTerms.Text3)}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(({ privacyAndTerms }) => privacyAndTerms.Text4)}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
          </PrivacyPolicyList>

          <PrivacyPolicyText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.Text5)}
          </PrivacyPolicyText>
          <PrivacyPolicyText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.Text6)}
          </PrivacyPolicyText>

          <PrivacyPolicyTitle>
            3.{" "}
            {translate(
              ({ privacyAndTerms }) =>
                privacyAndTerms.CollectionAndUseOfInformation
            )}
          </PrivacyPolicyTitle>
          <PrivacyPolicyText>
            {translate(
              ({ privacyAndTerms }) =>
                privacyAndTerms.CollectionAndUseOfInformationText
            )}
          </PrivacyPolicyText>

          <PrivacyPolicyList>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.CollectionAndUseOfInformationText1
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.CollectionAndUseOfInformationText2
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.CollectionAndUseOfInformationText3
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.CollectionAndUseOfInformationText4
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.CollectionAndUseOfInformationText5
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.CollectionAndUseOfInformationText6
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.CollectionAndUseOfInformationText7
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.CollectionAndUseOfInformationText8
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.CollectionAndUseOfInformationText9
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.CollectionAndUseOfInformationText10
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
          </PrivacyPolicyList>

          <PrivacyPolicyTitle>
            4.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.DisclosureOfPersonal
            )}
          </PrivacyPolicyTitle>
          <PrivacyPolicyText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.discloseText)}
          </PrivacyPolicyText>

          <PrivacyPolicyList>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.DisclosureOfPersonalText1
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>

            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.DisclosureOfPersonalText2
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.DisclosureOfPersonalText3
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.DisclosureOfPersonalText4
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.DisclosureOfPersonalText5
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.DisclosureOfPersonalText6
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
            <PrivacyPolicyTextInfoContainer>
              <PrivacyPolicyTextInfo>
                {translate(
                  ({ privacyAndTerms }) =>
                    privacyAndTerms.DisclosureOfPersonalText7
                )}
              </PrivacyPolicyTextInfo>
            </PrivacyPolicyTextInfoContainer>
          </PrivacyPolicyList>

          <PrivacyPolicyTitle>
            5.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.InternationalTransfers
            )}
          </PrivacyPolicyTitle>

          <PrivacyPolicyText>
            {translate(
              ({ privacyAndTerms }) =>
                privacyAndTerms.InternationalTransfersText1
            )}
          </PrivacyPolicyText>
          <PrivacyPolicyText>
            {translate(
              ({ privacyAndTerms }) =>
                privacyAndTerms.InternationalTransfersText2
            )}
          </PrivacyPolicyText>
          <PrivacyPolicyText>
            {translate(
              ({ privacyAndTerms }) =>
                privacyAndTerms.InternationalTransfersText3
            )}
          </PrivacyPolicyText>

          <PrivacyPolicyTitle>
            6.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.controllingInformation
            )}
          </PrivacyPolicyTitle>
          <PrivacyPolicyTextInfo>
            <SpanText>
              {translate(
                ({ privacyAndTerms }) => privacyAndTerms.ChoiceAndConsent
              )}
            </SpanText>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.controllingYourPersonal
            )}
          </PrivacyPolicyTextInfo>
          <PrivacyPolicyTextInfo>
            <SpanText>
              {translate(
                ({ privacyAndTerms }) => privacyAndTerms.InformationFromThird
              )}
            </SpanText>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.InformationFromThirdText
            )}
          </PrivacyPolicyTextInfo>
          <PrivacyPolicyTextInfo>
            <SpanText>
              {translate(({ privacyAndTerms }) => privacyAndTerms.Restrict)}
            </SpanText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.RestrictText)}
          </PrivacyPolicyTextInfo>
          <PrivacyPolicyTextInfo>
            <SpanText>
              {translate(
                ({ privacyAndTerms }) => privacyAndTerms.AccessAndData
              )}
            </SpanText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.RestrictText)}
          </PrivacyPolicyTextInfo>
          <PrivacyPolicyTextInfo>
            <SpanText>
              {translate(({ privacyAndTerms }) => privacyAndTerms.Correction)}
            </SpanText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.CorrectionText)}
          </PrivacyPolicyTextInfo>
          <PrivacyPolicyTextInfo>
            <SpanText>
              {translate(({ privacyAndTerms }) => privacyAndTerms.Notification)}
            </SpanText>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.NotificationText
            )}
          </PrivacyPolicyTextInfo>
          <PrivacyPolicyTextInfo>
            <SpanText>
              {translate(({ privacyAndTerms }) => privacyAndTerms.Complaints)}
            </SpanText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.ComplaintsText)}
          </PrivacyPolicyTextInfo>
          <PrivacyPolicyTextInfo>
            <SpanText>
              {translate(({ privacyAndTerms }) => privacyAndTerms.Unsubscribe)}
            </SpanText>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.UnsubscribeText
            )}
          </PrivacyPolicyTextInfo>
          <PrivacyPolicyTitle>
            7. {translate(({ privacyAndTerms }) => privacyAndTerms.Cookies)}
          </PrivacyPolicyTitle>
          <PrivacyPolicyText>
            {translate(({ privacyAndTerms }) => privacyAndTerms.CookiesText)}
          </PrivacyPolicyText>

          <PrivacyPolicyTitle>
            8.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.BusinessTransfers
            )}
          </PrivacyPolicyTitle>
          <PrivacyPolicyText>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.BusinessTransfersText
            )}
          </PrivacyPolicyText>

          <PrivacyPolicyTitle>
            9.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.LimitsOfOurPolicy
            )}
          </PrivacyPolicyTitle>
          <PrivacyPolicyText>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.LimitsOfOurPolicyText
            )}
          </PrivacyPolicyText>

          <PrivacyPolicyTitle>
            10.{" "}
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.ChangesToThisPolicy
            )}
          </PrivacyPolicyTitle>
          <PrivacyPolicyText>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.ChangesToThisPolicyText
            )}
          </PrivacyPolicyText>
          <PrivacyPolicyText>
            {translate(
              ({ privacyAndTerms }) => privacyAndTerms.ChangesToThisPolicyText2
            )}
          </PrivacyPolicyText>
        </PrivacyPolicyBlock>
      </PageMainContent>
    </PageWrapper>
  );
};
