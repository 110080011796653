import React, { useState } from "react";

import { Icon } from "../Icon";
import { Colors, Images } from "../../../environment";
import { IconType } from "../../../consts";

import {
  Container,
  BaseInput,
  Label,
  ErrorLabel,
  HelpText,
  IconViewLogin,
  IconViewLetter
} from "./Input.style";
import { Information } from "../Textarea/Textarea.style";

interface Props {
  value: string;
  error?: string;
  label?: string;
  password?: boolean;
  login?: boolean;
  autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined;
  maxLength?: number;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  name?: string;
  required?: boolean;
  placeholder?: string;
  helpText?: string;
  borderAndShadow?: boolean;
  borderRadius?: number;
  borderColor?: any;
  withInformation?: boolean;
  withI?: boolean;
  description?: string;
  isFirstChapter?: boolean;
  padding?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export function CustomInput({
  value,
  error,
  label,
  password,
  login,
  padding,
  autoCapitalize = "none",
  maxLength,
  disabled,
  readOnly = false,
  className,
  name,
  required = false,
  placeholder,
  helpText,
  borderAndShadow,
  borderRadius,
  borderColor,
  onChange,
  onFocus,
  onBlur,
  onClick,
  withI,
  withInformation,
  isFirstChapter,
  description
}: Props) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  // function handleFocus(
  //   e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) {
  //   if (!isFocused) {
  //     setIsFocused(true);

  //     if (onFocus) {
  //       onFocus(e);
  //     }
  //   }
  // }

  // function handleBlur(
  //   e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) {
  //   if (isFocused) {
  //     setIsFocused(false);
  //   }

  //   if (onBlur) {
  //     onBlur(e);
  //   }
  // }

  function handlePasswordVisibility() {
    setIsPasswordVisible(state => !state);
  }

  return (
    <Container className={className} onClick={onClick}>
      {label && <Label>{`${label}${required ? "*" : ""}`}</Label>}
      {isFirstChapter && (
        <div
          style={{
            position: "absolute",
            width: "2px",
            height: "2.2vw",
            backgroundColor: "#cc00a0",
            top: "9px"
          }}
        ></div>
      )}
      <BaseInput
        name={name}
        type={password && !isPasswordVisible ? "password" : "text"}
        placeholder={placeholder}
        value={value}
        autoCapitalize={autoCapitalize}
        maxLength={maxLength}
        readOnly={readOnly || disabled}
        onChange={onChange}
        autoComplete={"off"}
        borderAndShadow={borderAndShadow}
        borderRadius={borderRadius}
        borderColor={borderColor}
        isFirstChapter={false}
        padding={padding}
      />
      {description && (
        <>
          <img
            src={Images.landingInformationIcon}
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              marginRight: "5px",
              marginTop: "5px",
              cursor: "help"
            }}
            alt=""
            onMouseOver={e => setShowInformation(true)}
            onMouseOut={e => setShowInformation(false)}
          />
          {showInformation && <Information>{description}</Information>}
        </>
      )}

      {password && (
        <IconViewLogin onClick={handlePasswordVisibility}>
          <Icon
            name={isPasswordVisible ? IconType.EpEye : IconType.EpEyeSlash}
            color={Colors.gray[50]}
          />
        </IconViewLogin>
      )}
      {login && (
        <IconViewLetter>
          <Icon color={Colors.gray[50]} name={IconType.EpMail} />
        </IconViewLetter>
      )}
      {/* {name === "email" && (
        <IconView onClick={handlePasswordVisibility}>
          <Icon name={IconType.EpMail} color={Colors.gray[50]} />
        </IconView>
      )} */}
      <ErrorLabel>{error}</ErrorLabel>
      {!error && helpText && <HelpText>{helpText}</HelpText>}
    </Container>
  );
}
