import React, { useMemo, useState } from "react";
import { CardWrapper, ContentWrapper } from "./ContentCards.style";
import { Images } from "../../environment/theme/Images";
import { Card } from "./Card";
import { CurrentModal } from "./CurrentModal";
import {
  ModalContent1,
  ModalContent2,
  ModalContent3,
  ModalContent4,
  ModalContent5,
  ModalContent6,
  ModalContent7,
  ModalContent8,
  ModalContent9,
  ModalContent10,
  ModalContent11,
  ModalContent12,
  ModalContent13,
  ModalContent14,
  ModalContent15,
  ModalContent16,
  ModalContent17,
  ModalContent18,
  ModalContent19
} from "./Modals";

interface ContentCardsProps {
  contentNumber: number;
}

export function ContentCards({ contentNumber }: ContentCardsProps) {
  const [btnClick, setBtnClick] = useState<number>(0);

  const handleButton = (id: number) => setBtnClick(id);

  const contentThirdData = [
    {
      image: Images.landingThirdContentImg1,
      title:
        "En steg-för steg-metod på hur en muntlig överlämningsrutin kan tas fram ",
      text: "För vem: Processledare",
      buttonText: "Fler detaljer",
      id: 1,
      content: ModalContent1
    },
    {
      image: Images.landingThirdContentImg2,
      title: "Tom mall att använda som checklista ",
      text: "För vem: Processledare, överlämnare",
      buttonText: "Fler detaljer",
      id: 2,
      content: ModalContent2
    },
    {
      image: Images.landingThirdContentImg3,
      title: "Redigerbar mall att använda som checklista",
      text: "För vem: Processledare, överlämnare",
      buttonText: "Fler detaljer",
      id: 3,
      content: ModalContent3
    },
    {
      image: Images.landingThirdContentImg4,
      title:
        "Lista över dokument som är nyttiga att se på för den som är ny i organisationen",
      text: "För vem: Processledare",
      buttonText: "Fler detaljer",
      id: 4,
      content: ModalContent4
    },
    {
      image: Images.landingThirdContentImg5,
      title: "Utvärdering av överlämningsrutin",
      text: "För vem: Processledare",
      buttonText: "Fler detaljer",
      id: 5,
      content: ModalContent5
    },
    {
      image: Images.landingThirdContentImg6,
      title: "Att se tillbaka på uppdraget och överföra erfarenheterna",
      text: "För vem: Överlämnare",
      buttonText: "Fler detaljer",
      id: 6,
      content: ModalContent6
    },
    {
      image: Images.landingThirdContentImg7,
      title: "Lista över praktisk information och detaljer",
      text: "För vem: Processledare",
      buttonText: "Fler detaljer",
      id: 7,
      content: ModalContent7
    },
    {
      image: Images.landingThirdContentImg8,
      title: "Vad jag önskar inför min mandatperiod",
      text: "För vem: Ersättare",
      buttonText: "Fler detaljer",
      id: 8,
      content: ModalContent8
    },
    {
      image: Images.landingThirdContentImg9,
      title: "Spel för kunskapsöverföring",
      text: "För vem: Ersättare",
      buttonText: "Fler detaljer",
      id: 9,
      content: ModalContent19
    }
  ];

  const contentFourthData = [
    {
      image: Images.landingFourthContentImg1,
      title: "Exempel på saker att förbereda inför att en ny person ska börja",
      text: "För vem: Processledare, överlämnare",
      buttonText: "Fler detaljer",
      id: 9,
      content: ModalContent9
    },
    {
      image: Images.landingFourthContentImg2,
      title: "Tips på hur du kan skapa goda förutsättningar för överlämningar",
      text: "För vem:  Överlämnare",
      buttonText: "Fler detaljer",
      id: 10,
      content: ModalContent10
    },
    {
      image: Images.landingFourthContentImg3,
      title:
        "Tips på hur organisationen kan skapa goda förutsättningar för överlämningar",
      text: "För vem: Processledare, överlämnare",
      buttonText: "Fler detaljer",
      id: 11,
      content: ModalContent11
    },
    {
      image: Images.landingFourthContentImg4,
      title:
        "Exempel på vad du kan göra för att förbereda dig inför din första dag",
      text: "För vem: Ersättare",
      buttonText: "Fler detaljer",
      id: 12,
      content: ModalContent12
    }
  ];

  const contentFifthData = [
    {
      image: Images.manSeatWithLaptop,
      title: "Förbered dig inför din kunskapsöverföring/ kunskapsöverlämning",
      text: "",
      buttonText: "Titta på videon",
      id: 13,
      content: ModalContent13
    },
    {
      image: Images.manSeatWithPhone,
      title: "Ta fram en kunskapsöverföringsrutin för din organisation",
      text: "",
      buttonText: "Titta på videon",
      id: 14,
      content: ModalContent14
    },
    {
      image: Images.manOnBike,
      title: "Implicit och explicit kunskap - vad är det?",
      text: "",
      buttonText: "Titta på videon",
      id: 15,
      content: ModalContent15
    },
    {
      image: Images.YoutubeLogo,
      title: "Spridakunskap.se - intro",
      text: "",
      buttonText: "Titta på videon",
      id: 16,
      content: ModalContent16
    },
    {
      image: Images.manOnBooks,
      title: "Vad är kunskap egentligen?",
      text: "",
      buttonText: "Titta på videon",
      id: 17,
      content: ModalContent17
    },
    {
      image: Images.howItWorks,
      title:
        "Digital kunskapsöverlämning med Spridakunskap.se - så fungerar det",
      text: "",
      buttonText: "Titta på videon",
      id: 18,
      content: ModalContent18
    }
  ];

  let currentData: {
    image: any;
    title: string;
    text: string;
    buttonText: string;
    id: number;
    content: () => JSX.Element;
  }[] = [];

  switch (contentNumber) {
    case 3:
      currentData = contentThirdData;
      break;
    case 4:
      currentData = contentFourthData;
      break;
    case 5:
      currentData = contentFifthData;
      break;
    default:
      break;
  }

  const currentModalContent = useMemo(() => {
    return currentData.find(item => item.id === btnClick);
  }, [btnClick]);

  const closeHandler = () => {
    document.body.style.overflowY = "auto";
    document.body.style.overflowX = "hidden";
    document.body.style.backgroundColor = "white";
  };

  return (
    <ContentWrapper onBlur={closeHandler} contentNumber={contentNumber}>
      {currentData.map((item, index) => {
        return (
          <CardWrapper key={`cardwrapper-item-${contentNumber}-${index}`}>
            <Card
              key={`card-item-${contentNumber}-${index}`}
              id={item.id}
              imageCard={item.image}
              titleCard={item.title}
              textCard={item.text}
              buttonTextCard={item.buttonText}
              buttonClick={handleButton}
              contentNumber={contentNumber}
            />
          </CardWrapper>
        );
      })}
      {btnClick ? (
        <CurrentModal
          content={currentModalContent}
          buttonClick={handleButton}
        />
      ) : null}
    </ContentWrapper>
  );
}
