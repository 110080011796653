import React, { useEffect, useState } from "react";

import {
  FileInputContainer,
  FileInput as FileInputElement,
  FileInputLabel,
  LabelText,
  FilesBlock,
  FileWrapper
} from "./ImageBlock.style";
import {
  useMeQuery,
  useTranslation,
  useUploadImageMutation,
  useGetImageQuery
} from "../../../hooks";
import { HandoverFile } from "../../../pages/Handover";
import { Images } from "../../../environment";
import { FullImage as getFullImg } from "../../../helpers";
import { UserTypes } from "../../../consts";

interface FileInputProps {
  accept: string;
  value?: HandoverFile;
  fieldName: string;
  onChange: (file: File) => void;
  onRemove: () => void;
}

export function ImageBlock({
  onChange,
  accept,
  fieldName,
  value
}: FileInputProps) {
  const translate = useTranslation();
  const [uploadImage] = useUploadImageMutation();
  const [image, setImage] = useState<any>(null);
  const userData = useMeQuery();
  const [getImage, { data: illustrationImage }] = useGetImageQuery();

  useEffect(() => {
    getImageFromServer();
  }, []);

  useEffect(() => {
    if (illustrationImage && illustrationImage.getImage) {
      checkUrl(illustrationImage.getImage);
    }
  }, [illustrationImage]);

  const checkUrl = (url: string) => {
    if (url) {
      const img = new Image();
      img.onerror = function() {
        setImage(null);
      };
      img.src = url;
      setImage(url);
    }
  };

  const getImageFromServer = () => {
    return getImage({
      variables: {
        data: { name: fieldName + ".png" }
      }
    });
  };

  async function handleFileInputChange(
    evt: React.ChangeEvent<HTMLInputElement>
  ) {
    const currentEl = evt.target;
    if (
      currentEl.files &&
      currentEl.files[0]?.type.includes("image") &&
      currentEl.files.length > 0
    ) {
      onChange(currentEl.files[0]);

      const imageOptions = {
        name: currentEl.files[0].name,
        type: currentEl.files[0].type,
        path: URL.createObjectURL(currentEl.files[0])
      };
      const { imgPath: fullImage, imgFile: blobFile }: any = await getFullImg(
        imageOptions.path,
        {
          height: 0,
          width: 0,
          x: 0,
          y: 0
        }
      );
      const croppedImgFile = new File([blobFile], imageOptions.name, {
        lastModified: Date.now(),
        type: imageOptions.type
      });

      await uploadImage({
        variables: {
          data: {
            userAccountWhereUniqueInput: {
              id: userData.data?.me.id
            },
            fieldName: fieldName.replace(/\s/g, ""),
            file: croppedImgFile
          }
        }
      });
    }
  }

  const getValue = () => {
    return typeof value?.file === "string" ? value?.file : value?.file?.name;
  };

  return (
    <FileInputContainer>
      {userData?.data?.me.role === UserTypes.Super && (
        <FileInputLabel>
          <FileInputElement
            type="file"
            onChange={handleFileInputChange}
            accept={accept}
          />
          {<img src={Images.landingExportLogo} alt="" />}

          <LabelText>
            {translate(({ buttons }) => buttons.uploadImage)}
          </LabelText>
        </FileInputLabel>
      )}

      {image && (
        <FilesBlock>
          <FileWrapper>
            <div>
              <img
                src={image}
                style={{ width: "100%", height: "100%", overflow: "scroll" }}
                alt=""
              />
              {getValue()}
            </div>
          </FileWrapper>
        </FilesBlock>
      )}
    </FileInputContainer>
  );
}
