import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { UserAccount } from "../../../../api";

import { UserProfile } from "../../components/userProfile/UserPofile";
import {
  useWorkspaceMembersQuery,
  useAlerts,
  useBreadcrumbs,
  useNavigation,
  useEffectOnce,
  useUpdateUserAccountDetailsMutation,
  useTranslation
} from "../../hooks";
import { SuperAdminProtector } from "./../../components/SuperAdminProtector";
import { UserAccountFormValues } from "../../components/userProfile/UserGeneralInfo";

export function MemberProfile({ match }: RouteComponentProps<{ id: string }>) {
  const userAccountWhereUniqueInput = { id: match.params.id };
  const { data: userData } = useWorkspaceMembersQuery(
    userAccountWhereUniqueInput
  );
  const [
    updateUserAccountDetails,
    {
      data: updateUserAccountDetailsData,
      loading: updateUserAccountDetailsLoading
    }
  ] = useUpdateUserAccountDetailsMutation();
  const [user, setUser] = useState<UserAccount | null>(null);
  const [loading, setLoading] = useState(true);
  const { setError, setNotification } = useAlerts();
  const { setBreadcrumbs } = useBreadcrumbs();
  const translate = useTranslation();
  const { routes, history } = useNavigation();

  useEffectOnce(() => {
    const breadcrumbsData = [
      {
        label: translate(({ sideMenuLinks }) => sideMenuLinks.members),
        to: routes.members
      },
      {
        label: translate(({ titles }) => titles.member),
        to: "",
        disabled: true
      }
    ];

    setBreadcrumbs(breadcrumbsData);
  });

  useEffect(() => {
    if (updateUserAccountDetailsData) {
      const { user } = updateUserAccountDetailsData.updateUserAccountDetails;

      setNotification({
        message: translate(({ messages }) => messages.accountUpdated)
      });
      setUser(user);
    }
  }, [updateUserAccountDetailsData]);

  useEffect(() => {
    if (userData?.currentWorkspace) {
      if (!userData.currentWorkspace.members.length) {
        setError({ message: translate(({ errors }) => errors.userNotFound) });
        history.push(routes.members);
      } else {
        setUser(userData.currentWorkspace.members[0]);
        setLoading(false);
      }
    }
  }, [userData]);

  function handleUpdateUserAccountDetails(
    userAccountDetails: UserAccountFormValues
  ) {
    if (user) {
      updateUserAccountDetails({
        variables: {
          data: {
            userAccountWhereUniqueInput: { id: user.id },
            email: userAccountDetails.email,
            firstName: userAccountDetails.firstName,
            lastName: userAccountDetails.lastName
          }
        }
      });
    }
  }

  return (
    <SuperAdminProtector redirectTo={routes.handovers}>
      <UserProfile
        updateUserAccountLoading={updateUserAccountDetailsLoading}
        userAccount={user}
        isLoading={loading}
        setUser={setUser}
        updateUserAccount={handleUpdateUserAccountDetails}
      />
    </SuperAdminProtector>
  );
}
