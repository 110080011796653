import React, { useContext } from "react";
import { format, parse } from "date-fns";
import { Chapter } from "../../../../../api";

import { CheckboxGroup, SelectField, RadioGroup } from "./Fields";
import {
  Input,
  Textarea,
  Typography,
  DefaultDatePicker,
  FileInput
} from "./../../../components/ui";
import { DuplicateOrRemoveQuestionBlock } from "./Fields";
import { deviceSize, FieldTypes } from "./../../../consts";
import {
  TypographyWrapper,
  Paragraph,
  TextareaWrapper,
  InputLabel,
  LineStartQuestionBlock
} from "./GeneratedField.style";
import {
  HandoverDispatchContext,
  updateFieldValue,
  setHandoverFile,
  removeHandoverFile,
  updateHandoverChapter,
  setActiveChapter,
  HandoverFile
} from "./../../../pages/Handover";
import { removeTags } from "../../../helpers";
import { useViewport } from "../../../hooks";

import { ImageBlock } from "../../ui/ImageBlock";
import { ConsoleSqlOutlined } from "@ant-design/icons";

interface GeneratedFieldProps {
  value: Array<string>;
  fieldData: any;
  chapter: Chapter;
  isFirstChapter: boolean;
  chapterClickButton: boolean;
  handoverFiles: Array<HandoverFile>;
  onUpdateChapter?: (chapter: Chapter, chapterType: string) => any;
}

export const GeneratedField = ({
  chapterClickButton,
  fieldData,
  value,
  chapter,
  isFirstChapter,
  handoverFiles,
  onUpdateChapter
}: GeneratedFieldProps) => {
  const handoverDispatch = useContext(HandoverDispatchContext);
  let fieldElement = null;

  const onFieldChange = (fieldName: string, value: Array<string>) => {
    handoverDispatch(
      updateFieldValue(
        fieldName,
        value.map(item => removeTags(item || ""))
      )
    );
  };

  const width = useViewport();

  const setFile = (value: File, fieldName: string) =>
    handoverDispatch(setHandoverFile(value, fieldName));

  const removeFile = (fieldName: string) =>
    handoverDispatch(removeHandoverFile(fieldName));

  const updateChapter = (newChapter: Chapter) => {
    handoverDispatch(updateHandoverChapter(newChapter));
    handoverDispatch(setActiveChapter(newChapter));
  };

  switch (fieldData.type) {
    case FieldTypes.startQuestionBlock: {
      if (fieldData.name.includes("duplicate")) {
        fieldElement = <LineStartQuestionBlock />;
      }
      break;
    }

    case FieldTypes.radioGroup: {
      fieldElement = (
        <>
          <RadioGroup
            fieldData={fieldData}
            fieldValue={value[0] || ""}
            onFieldChange={onFieldChange}
          />
        </>
      );
      break;
    }

    case FieldTypes.date: {
      fieldElement = (
        <DefaultDatePicker
          startDate={
            value[0] ? parse(value[0], "dd-MM-yyyy", new Date()) : new Date()
          }
          setStartDate={date => {
            onFieldChange(fieldData.name, [format(date, "dd-MM-yyyy")]);
          }}
        />
      );
      break;
    }

    case FieldTypes.checkboxGroup: {
      fieldElement = (
        <CheckboxGroup
          fieldData={fieldData}
          onFieldChange={onFieldChange}
          fieldValue={Array.isArray(value) ? value : []}
        />
      );
      break;
    }

    case FieldTypes.select: {
      fieldElement = (
        <SelectField
          fieldData={fieldData}
          onFieldChange={onFieldChange}
          fieldValue={value[0]}
        />
      );
      break;
    }

    case FieldTypes.image: {
      fieldElement = (
        <ImageBlock
          onChange={(file: File) => setFile(file, fieldData.name)}
          onRemove={() => removeFile(fieldData.name)}
          fieldName={fieldData.name}
          accept="*"
          value={handoverFiles.find(
            ({ fieldName }) => fieldData.name === fieldName
          )}
        />
      );
      break;
    }

    case FieldTypes.file: {
      fieldElement = (
        <FileInput
          onChange={(file: File) => setFile(file, fieldData.name)}
          onRemove={() => removeFile(fieldData.name)}
          fieldName={fieldData.name}
          accept="*"
          value={handoverFiles.find(
            ({ fieldName }) => fieldData.name === fieldName
          )}
        />
      );
      break;
    }

    case FieldTypes.textarea: {
      fieldElement = (
        <TextareaWrapper>
          <InputLabel>{removeTags(fieldData.label || "")}</InputLabel>

          <Textarea
            placeholder={fieldData.placeholder}
            description={fieldData.description}
            rows={width <= parseInt(deviceSize.mobile.max) ? 3 : 5}
            onChange={({ target }) =>
              onFieldChange(fieldData.name, [target.value])
            }
            value={removeTags(value[0] || "")}
          />
        </TextareaWrapper>
      );
      break;
    }

    case FieldTypes.duplicatePrevField: {
      fieldElement = (
        <DuplicateOrRemoveQuestionBlock
          chapterClickButton={chapterClickButton}
          fieldData={fieldData}
          chapter={chapter}
          updateChapter={updateChapter}
        />
      );
      break;
    }

    case FieldTypes.text: {
      fieldElement = (
        <>
          <InputLabel>{removeTags(fieldData.label || "")}</InputLabel>
          <Input
            padding={"0px 13px"}
            onChange={({ target }) =>
              onFieldChange(fieldData.name, [target.value])
            }
            value={removeTags(value[0] || "")}
            placeholder={removeTags(fieldData.placeholder || "")}
            borderAndShadow={false}
            borderRadius={8}
            borderColor={"#6bc9db"}
            withInformation={true}
            withI={true}
            description={fieldData.description}
            isFirstChapter={isFirstChapter}
          />
        </>
      );
      break;
    }

    case FieldTypes.header: {
      fieldElement = (
        <TypographyWrapper>
          <Typography tag={fieldData.subtype}>
            {removeTags(fieldData.label || "")}
          </Typography>
        </TypographyWrapper>
      );
      break;
    }

    case FieldTypes.paragraph: {
      if (!fieldData.access) {
        fieldElement = (
          <TypographyWrapper>
            <Paragraph>{removeTags(fieldData.label || "")}</Paragraph>
          </TypographyWrapper>
        );
      }
      break;
    }
  }
  return fieldElement;
};
