import React from "react";

import { BaseButton, ButtonProps } from "./BaseButton";
import { Colors } from "../../../environment";

export function BlueButton(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      color={Colors.white}
      backgroundColor={"#4F45F5"}
      hoverColor={Colors.white}
      hoverBackgroundColor={"#342ACD"}
      withoutBorder
    />
  );
}
