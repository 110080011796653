import styled from "styled-components";
import { deviceRange } from "../../../consts";
import { Fonts } from "../../../environment";

export const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 80px);
  width: 100%;

  @media ${deviceRange.tablet} {
    width: 100%;
  }

  @media ${deviceRange.mobile} {
    width: 100%;
    height: calc(60% + 100px);
  }
`;
interface BodyLeftPartProps {
  marginTop?: string;
  width?: number;
  height?: number;
}

export const BodyLeftPart = styled.div<BodyLeftPartProps>`
  display: flex;
  flex-direction: column;
  width: 38vw;
  margin-top: ${props => props.marginTop && props.marginTop};
  ${props => (props.height ? "height: 100%;" : null)}

  @media ${deviceRange.tablet} {
    width: 90%;
  }

  @media ${deviceRange.mobile} {
    margin-top:0px;
    width: 90%;
  }
`;

interface BodyLeftPartWrapperProps {
  isMacintosh?: boolean;
  marginLeft?: string;
}

export const BodyLeftPartWrapper = styled.div<BodyLeftPartWrapperProps>`
  display: flex;
  width: 45vw;
  flex-direction: column;
  height: 100%;
  max-height: 830px;
  margin-left: ${props => props.marginLeft && props.marginLeft};
  ${props => (props.isMacintosh ? "zoom: 0.8;" : null)};

  @media ${deviceRange.tablet} {
    height: fit-content;
    width: 85%;
  }

  @media ${deviceRange.desktop} {
    max-height: none;
  }

  @media ${deviceRange.mobile} {
    width: 100%;
    height: 100%;
  }
  @media ${deviceRange.fourK} {
    max-height: 1200px;
  }
`;
export const BodyRightPart = styled.div`
  display: flex;
  flex-direction: column;
  width: 45vw;
  margin-top: 35px;
  position: relative;

  @media ${deviceRange.tablet} {
    width: 100%;
    margin-top: 0px;
  }
  @media ${deviceRange.mobile} {
    display: none;
  }
  @media ${deviceRange.laptop} {
  }

  @media ${deviceRange.laptopM} {
  }

  @media ${deviceRange.laptopL} {
  }

  @media ${deviceRange.mobile} {
    display: none;
  }
`;
export const Stepper = styled.h2`
  font-family: ${Fonts.paragraph};
  font-weight: 300;
  font-size: 11px;
  color: #86888c;
  margin-bottom: 10px;
  letter-spacing: 1.1px;
`;
interface TitleProps {
  marginLeft?: number;
}
export const Title = styled.h1<TitleProps>`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: calc(19px + 1.5vw);
  line-height: 5rem;
  color: #6bc9db;
  margin-bottom: 20px;
  //${props => (props.marginLeft === 0 ? "margin-bottom: -25px;" : null)}
  //margin-left: ${props => props.marginLeft && props.marginLeft + "px"};

  @media ${deviceRange.mobile}{
    //margin-left:13px;
    font-size: 28px;
  }
`;

export const PrevNextButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export const Hint = styled.div`
  display: flex;
  align-items: center;
  font-family: ${Fonts.paragraph};
  font-weight: 300;
  font-size: 15px;
  color: #86888c;
  margin-bottom: 10px;
  letter-spacing: 1.1px;
`;
interface BodyContentProps {
  paddingAndShadow?: boolean;
}

interface FormContainerProps {
  paddingLeft?: number;
}
export const FormContainer = styled.div<FormContainerProps>`
  max-height: 100%;
  padding-left: ${props => props.paddingLeft && props.paddingLeft + "px"};
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  direction: rtl;
  overflow-y: scroll;
  z-index: 5;
  &::-webkit-scrollbar {
    /* WebKit */
    width: 4px;
    height: 90%;
  }
  ::-webkit-scrollbar-thumb {
    background: #6bc9db;
    opacity: 0.2;
    border-radius: 1rem;
  }
  @media ${deviceRange.desktop} {
    padding-left: 13px;
    padding-right: 13px;
  }

  @media ${deviceRange.tablet} {
    padding-left: 13px;
    padding-right: 13px;
    direction: ltr;
  }
  @media ${deviceRange.mobile} {
    padding-left: 13px;
    padding-right: 13px;
    direction: ltr;
  }

  @media ${deviceRange.laptop} {
    margin-bottom: 0px;
  }

  @media ${deviceRange.laptopM} {
    margin-bottom: 0px;
  }

  @media ${deviceRange.laptopL} {
    margin-bottom: 0px;
  }
`;

export const BodyContent = styled.div<BodyContentProps>`
  height: 82%;
  //max-height: 630px;
  position: relative;
  ${props =>
    props.paddingAndShadow
      ? "box-shadow: 4px 4px 25px rgb(107 201 219 / 25%);"
      : null}
  border-radius: 16px;
  padding: 20px 20px 20px 0;

  @media ${deviceRange.desktop} {
    max-height: 900px;
  }

  @media ${deviceRange.mobile} {
    height: 51vh;
    padding: 20px 0 20px 0;
  }
`;

export const BlockButtonPreview = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

export const RightBlockButtonsContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;

  @media ${deviceRange.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 0px;
    justify-content: center;
  }

  @media ${deviceRange.laptop} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${deviceRange.laptopM} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${deviceRange.laptopL} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${deviceRange.desktop} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
export const BlockSteg = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #cc00a0;
  margin-right: 25px;
  padding: 10px;
  box-shadow: 4px 4px 25px rgb(107 201 219 / 25%);
  width: 84px;
  height: 84px;
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  z-index: 15;
  position: "relative";
  background: white;
`;
export const BlockStegWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  padding-top: 5px;
  overflow: hidden;
  z-index: 10;
  position: relative;
  margin-bottom: -1px;
`;
export const AssistantTextBlock = styled.div`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #929496;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  margin-bottom: 10px;
`;
export const AssistantBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const ProgresBarAndSendBtnWrapp = styled.div`
  display: flex;
  align-items: center;
  max-width: 565px;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 30px;
  margin-bottom: 10px;

  @media ${deviceRange.mobile} {
    position: absolute;
    top: 45%;
    left: 0%;
  }
`;
export const SendPopupContent = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #6bc9db;
  background: white;
  padding: 36px 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 20px rgba(107, 201, 219, 0.3);
  border-radius: 16px;
  width: 100%;
  display: flex;

  @media ${deviceRange.mobile} {
  }
`;

export const BodyBackgroundBlurBlue = styled.div`
  position: fixed;
  right: 0;
  bottom: 15%;
  width: 29%;
  height: 27%;
  background: #6bc9db;
  filter: blur(150px);
`;

export const BodyBackgroundBlurPurple = styled.div`
  position: fixed;
  right: 5%;
  bottom: 5%;
  width: 28%;
  height: 17%;
  background: rgba(204, 0, 160, 0.5);
  filter: blur(115px);
`;

export const SendPopupWrapp = styled.div`
  display: flex;
  width: 100%;
  max-width: 550px;
  margin-top: 0px;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 150;
  position: absolute;

  @media ${deviceRange.mobile} {
    top: 35%;
    left: 0%;
  }
`;
export const ButtonCloseSendPopup = styled.div`
  margin-left: auto;
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
  margin-right: -15px;
  margin-top: -18px;
`;
export const ButtonsSendPopupWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 410px;
  margin: auto;
  margin-top: 25px;
  @media (max-width: 1165px) {
    flex-direction: column;
    justify-content: space-between;
    height: 125px;
  }
`;
export const HelpManWrapp = styled.div`
  display: flex;
  position: relative;
  height: 100%;
`;
export const HelpManContent = styled.div``;
export const HelpManRullaWrapp = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #929496;
  margin-top: 15px;
  margin-right: 15px;
`;
export const HelpmanWrapper = styled.div`
  z-index: 5;

  @media ${deviceRange.mobile} {
    display: none;
  }
`;
export const AssistantBlockContent = styled.div`
  position: absolute;
  bottom: 40px;
  right: 55px;
  cursor: pointer;
`;
interface HelpManTextProps {
  isShow?: boolean;
  isMacintosh?: boolean;
}

export const HelpManText = styled.div<HelpManTextProps>`
  position: absolute;
  height: 200px;
  right: 205px;
  bottom: 200px;
  width: 60%;
  /* ::-webkit-scrollbar {
        width: 0.4rem;
        height: 20px;
    }
    ::-webkit-scrollbar-thumb {
        background: gray;
        opacity: 0.2;
        border-radius: 1rem;
    } */

  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;

  word-wrap: break-word;
  border: 2px solid white;
  background-color: white;
  border-radius: 25px;
  font-size: 14px;
  line-height: 20px;
  padding: 16px;

  @media ${deviceRange.laptop} {
    width: 55%;
  }
  @media ${deviceRange.tablet} {
    width: 40%;
  }
`;

export const HelpManTextWrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
  &::-webkit-scrollbar {
    /* WebKit */
    width: 4px;
    height: 90%;
  }
  ::-webkit-scrollbar-thumb {
    background: #6bc9db;
    opacity: 0.2;
    border-radius: 1rem;
  }
`;

export const HelpManImgWrap = styled.div``;

interface ChapterClickBodyButtonProps {
  borderColor?: string;
  disabled: boolean;
}
export const ChapterClickBodyButton = styled.button<
  ChapterClickBodyButtonProps
>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 2px solid ${props =>
    props.borderColor ? props.borderColor : "black"};
  width: fit-content;
  max-width: 50%;
  padding: 10px;
  background: transparent;
  outline: none;
  padding: 10px 40px;
  cursor: pointer;
  white-space: nowrap;
  color: black;
  font-family: ${Fonts.landingOpenSansRegular};
  ${props =>
    props.disabled &&
    `
    opacity: 30%;
    cursor: auto;
    
  `}

  /* @media ${deviceRange.mobile} {
    display: none;
  } */
`;
export const SuperUserButton = styled.div`
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
`;
export const ScrollAndFormWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 85%;
  max-height: 765px;
  position: relative;
  margin-bottom: 2%;

  @media ${deviceRange.fourK} {
    max-height: 1200px;
  }

  @media ${deviceRange.tablet} {
    justify-content: center;
  }

  @media ${deviceRange.mobile} {
    justify-content: center;
    height: 100%;
  }
`;
interface ChapterButtonsWrapperProps {
  maxWidth?: number;
  marginTop?: string;
  marginLeft?: string;
}
export const ChapterButtonsWrapper = styled.div<ChapterButtonsWrapperProps>`
  display: flex;
  max-width: ${props => props.maxWidth}px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-left: ${props => props.marginLeft};
  margin-top: ${props => props.marginTop};
  margin-bottom: 30px;

  @media ${deviceRange.desktop} {
    margin-top: 40px;
  }

  @media ${deviceRange.mobile} {
    display: none;
  }
`;

export const MandantoryFields = styled.div`
  padding-left: 5px;
  font-size: calc(8px + 0.5vw);
  border-left-color: #cc00a0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-style: solid;
  border-left-width: 2px;
  font-family: ${Fonts.landingOpenSansRegular};
  text-align: left;
  margin-bottom: 20px;

  @media ${deviceRange.laptop} {
  }
`;

export const BodyTabletWrapp = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: column;

  @media ${deviceRange.tablet} {
    flex-direction: column;
    overflow: auto;

    &::-webkit-scrollbar {
      /* WebKit */
      width: 4px;
      height: 90%;
    }
    ::-webkit-scrollbar-thumb {
      background: #6bc9db;
      opacity: 0.2;
      border-radius: 1rem;
    }
  }

  @media ${deviceRange.mobile} {
    margin-left: 0px;
    width: 100%;
  }
`;

export const PopupButtonConfirm = styled.button`
  font-size: 14px;
  min-width: 193px;
  width: 160px;
  border-radius: 80px;
  margin: 10px;

  padding: 10px 25px;
  background: #fca311;
  color: white;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px rgba(255, 161, 0, 62%);
  }
  &:active {
    box-shadow: -3px -5px rgba(255, 161, 0, 62%);
  }

  @media ${deviceRange.laptopL} {
  }

  @media ${deviceRange.laptop} {
    font-size: 12px;
  }

  @media ${deviceRange.mobile} {
    font-size: 12px;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }
`;

export const PreviewButton = styled.button`
  width: 250px;
  border-radius: 80px;
  margin: 10px;
  padding: 10px 20px;
  background: #fca311;
  box-shadow: 3px 4px 0px #f09800;
  color: white;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  font-size: calc(5px + 0.5vw);
  line-height: 24px;
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #f09800;
  }
  &:active {
    box-shadow: -3px -5px #f09800;
  }

  @media ${deviceRange.laptopL} {
  }

  @media ${deviceRange.laptop} {
    font-size: 12px;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }
  @media ${deviceRange.fourK} {
    width: 260px;
  }
`;

export const DropSaveButton = styled.button`
  width: 250px;
  background: none;
  border-radius: 80px;
  cursor: pointer;
  padding: 10px 20px;
  margin: 10px;
  border: 1px solid #ffa100;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: calc(5px + 0.5vw);
  line-height: 24px;
  color: #425262;

  &:hover {
    border: 1px solid rgba(252, 163, 17, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(255, 161, 0, 0.5);
  }
  &:active {
    background: #fca311;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 12px;
  }
  @media ${deviceRange.fourK} {
    width: 260px;
  }
`;

export const DiscardButton = styled(DropSaveButton)`
  border: 1px solid #221c7c;

  &:hover {
    border: 1px solid rgba(48, 68, 250, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(48, 68, 250, 0.5);
  }
  &:active {
    background: #4aa3f7;
  }
`;

export const DropPopupButton = styled.button`
  min-width: 193px;
  background: none;

  border-radius: 80px;
  cursor: pointer;

  padding: 10px 25px;
  margin: 10px;
  border: 1px solid #ffa100;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #425262;

  &:hover {
    border: 1px solid rgba(252, 163, 17, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(255, 161, 0, 0.5);
  }
  &:active {
    background: #fca311;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 12px;
  }
`;

export const SendAnswer = styled.button`
  width: 250px;
  background: #cc00a0;
  box-shadow: 3px 4px 0px #b2028c;
  border: #cc00a0;
  color: #ffffff;
  border-radius: 80px;
  margin: 10px;
  padding: 10px 25px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  font-size: calc(5px + 0.5vw);
  line-height: 24px;

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #b2028c;
  }
  &:active {
    box-shadow: -5px -5px 0px #b2028c;
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 12px;
  }
  @media ${deviceRange.fourK} {
    width: 260px;
  }
`;

export const SaveAndContinueLater = styled.button`
  width: 250px;
  background: none;
  border-radius: 80px;
  cursor: pointer;
  margin: 10px;
  padding: 10px 20px;

  border: 1px solid #cc00a0;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: calc(5px + 0.5vw);
  line-height: 24px;
  color: #425262;

  &:hover {
    border: 1px solid rgba(204, 0, 160, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(204, 0, 160, 0.5);
  }
  &:active {
    background: #cc00a0;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 12px;
  }
  @media ${deviceRange.fourK} {
    width: 260px;
  }
`;
