import React from "react";
import { ButtonPDF, ButtonPDFWithoutBackground } from "./BaseButtonModal";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  ContentBlockWrapper
} from "./Modals.style";

export const ModalContent6 = () => {
  return (
    <>
      <ContentBlockWrapper>
        <ContentBlock>
          <LeftBlockContent>
            <TextTitleWrapper>
              <TextTitle>För vem:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>Överlämnare</SecondText>
            </TextWrapper>
            <TextTitleWrapper>
              <TextTitle>Hur dokumentet kan användas:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Detta är en diskussionsövning som kan användas vid
                överlämningsträffar. (Överlämningsträffar syftar på träffar där
                kunskap lämnas över från en person som haft ett uppdrag till en
                annan som ska ta över uppdraget.). Den som ska överlämna ett
                uppdrag och den som ska ta över uppdraget går igenom listan
                tillsammans. Tanken är att varje del diskuteras muntligen men
                det finns även plats för anteckningar under respektive del.
              </SecondText>
            </TextWrapper>
          </LeftBlockContent>
          <RightBlockContent>
            <TextTitleWrapper>
              <TextTitle>Beskrivning:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Dokumentet innehåller en färdig utvärdering för organisationens
                överlämningsrutin.
              </SecondText>
            </TextWrapper>
          </RightBlockContent>
        </ContentBlock>
      </ContentBlockWrapper>
      <FooterModal notAliginSelf={1}>
        <LeftBlockContent>
          <ButtonPDFWithoutBackground
            downLink={
              "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Se tillbaka på uppdraget och överför erfarenheterna_ifyllbar PDF.pdf"
            }
          />
        </LeftBlockContent>
        <RightBlockContent>
          <ButtonPDF
            downLink={
              "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Se tillbaka på uppdraget och överför erfarenheterna.pdf"
            }
          />
        </RightBlockContent>
      </FooterModal>
    </>
  );
};
