import React from "react";
import { AvatarContainer, AvatarMainImage } from "./Avatar.style";
import { Images } from "../../../environment";

export enum AvatarSize {
  XXS = 16,
  XS = 24,
  SM = 32,
  MD = 40,
  LG = 56,
  XL = 64,
  XXL = 90
}

interface AvatarProps {
  className?: string;
  size?: AvatarSize;
  image: string | undefined;
}

export function Avatar({
  className,
  image = Images.avatar,
  size = AvatarSize.MD
}: AvatarProps) {
  return (
    <AvatarContainer className={className}>
      <AvatarMainImage src={image} width={size} height={size} />
    </AvatarContainer>
  );
}
