import React, { useState } from "react";
import { Indents } from "./QuillContainer";
import { BlueButton, WarningButton, Input } from "../ui";
import { BlockWithIndents, BlockWithButtons } from "./QuillContainer.style";

interface DropdownIndentsProps {
  indentsValues: Indents;
  setShowIndents: () => void;
  setIndentsValues: (state: Object) => void;
}

export function DropdownIndents({
  indentsValues,
  setShowIndents,
  setIndentsValues
}: DropdownIndentsProps) {
  const { left, top, right, bottom } = indentsValues;

  const [newState, setNewState] = useState({
    left: left,
    top: top,
    right: right,
    bottom: bottom
  });

  return (
    <BlockWithIndents>
      <Input
        value={newState.left}
        label={"Left(cm)"}
        onChange={e => setNewState({ ...newState, left: e.target.value })}
      />
      <Input
        value={newState.top}
        label={"Top(cm)"}
        onChange={e => setNewState({ ...newState, top: e.target.value })}
      />
      <Input
        value={newState.right}
        label={"Right(cm)"}
        onChange={e => setNewState({ ...newState, right: e.target.value })}
      />
      <Input
        value={newState.bottom}
        label={"Bottom(cm)"}
        onChange={e => setNewState({ ...newState, bottom: e.target.value })}
      />
      <BlockWithButtons>
        <WarningButton onClick={setShowIndents} title={"Cancel"} />
        <BlueButton
          onClick={() => {
            setIndentsValues(newState);
            setShowIndents();
          }}
          title={"Apply"}
        />
      </BlockWithButtons>
    </BlockWithIndents>
  );
}
