import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  PageWrapper,
  PageContainer,
  FormContainer
} from "./RecommendTool.style";
import { RecommendToolForm } from "./../../components/RecommendTool/RecommendToolForm";
import {
  useEffectOnce,
  useAlerts,
  useBreadcrumbs,
  useNavigation,
  useTranslation,
  useRecommendToolQuery
} from "../../hooks";

export function RecommendTool() {
  const { setNotification } = useAlerts();
  const translate = useTranslation();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { routes } = useNavigation();
  const [
    sendRecommendation,
    { data: recommendationData, loading: sendRecomendationLoading }
  ] = useRecommendToolQuery();
  const RECOMMEND_TOOL_VALIDATION = Yup.object().shape({
    email: Yup.string()
      .email(translate(({ inputs }) => inputs.email.invalid))
      .required(translate(({ inputs }) => inputs.email.required)),
    personal_message: Yup.string(),
    message: Yup.string().required(
      translate(({ inputs }) => inputs.message.required)
    )
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      personal_message: "",
      message: ""
    },
    validationSchema: RECOMMEND_TOOL_VALIDATION,
    onSubmit: ({ email, message }) => {
      sendRecommendation({
        variables: {
          data: {
            email,
            message
          }
        }
      });
    }
  });

  useEffect(() => {
    if (recommendationData) {
      setNotification({
        message: translate(
          ({ messages }) => messages.sendRecommendation.success
        )
      });

      formik.resetForm();
    }
  }, [recommendationData]);

  useEffectOnce(() => {
    const breadcrumbsData = [
      {
        label: translate(({ handoversPage }) => handoversPage.handovers),
        to: routes.handovers
      },
      {
        label: translate(
          ({ recommendToolPage }) => recommendToolPage.recommendTool
        ),
        to: routes.recommendTool
      }
    ];

    setBreadcrumbs(breadcrumbsData);
  });

  return (
    <PageWrapper>
      {/* <PageTopBarContainer>
        <PageTopBarItem>
          <Breadcrumbs />
        </PageTopBarItem>
      </PageTopBarContainer> */}

      <PageContainer>
        <FormContainer>
          <RecommendToolForm
            formik={formik}
            loading={sendRecomendationLoading}
          />
        </FormContainer>
      </PageContainer>
    </PageWrapper>
  );
}
