import React, { useState, useEffect } from "react";
import { UserAccount } from "../../../../api";

import { UserProfile } from "../../components/userProfile";
import {
  useMeQuery,
  useEffectOnce,
  useBreadcrumbs,
  useNavigation,
  useUpdateUserAccountDetailsMutation,
  useAlerts,
  useTranslation
} from "../../hooks";
import { UserAccountFormValues } from "../../components/userProfile/UserGeneralInfo";

export function Profile() {
  const { data: userData } = useMeQuery();
  const [user, setUser] = useState<UserAccount | null>(null);
  const [
    updateUserAccountDetails,
    {
      data: updateUserAccountDetailsData,
      loading: updateUserAccountDetailsLoading
    }
  ] = useUpdateUserAccountDetailsMutation();

  const [loading, setLoading] = useState(true);
  const { setNotification } = useAlerts();
  const { setBreadcrumbs } = useBreadcrumbs();
  const translate = useTranslation();
  const { routes } = useNavigation();

  useEffectOnce(() => {
    const breadcrumbsData = [
      {
        label: translate(({ sideMenuLinks }) => sideMenuLinks.profile),
        to: routes.profile
      }
    ];

    setBreadcrumbs(breadcrumbsData);
  });

  useEffect(() => {
    if (updateUserAccountDetailsData) {
      const { user } = updateUserAccountDetailsData.updateUserAccountDetails;

      setNotification({
        message: translate(({ messages }) => messages.accountUpdated)
      });
      setUser(user);
    }
  }, [updateUserAccountDetailsData]);

  useEffect(() => {
    if (userData?.me) {
      setUser(userData?.me);
      setLoading(false);
    }
  }, [userData]);

  function handleUpdateUserAccountDetails(
    userAccountDetails: UserAccountFormValues
  ) {
    if (user) {
      updateUserAccountDetails({
        variables: {
          data: {
            userAccountWhereUniqueInput: { id: user.id },
            email: userAccountDetails.email,
            firstName: userAccountDetails.firstName,
            lastName: userAccountDetails.lastName,
            organisationColor: userAccountDetails.organisationColor
          }
        }
      });
    }
  }

  return (
    <UserProfile
      updateUserAccountLoading={updateUserAccountDetailsLoading}
      userAccount={user}
      isLoading={loading}
      setUser={setUser}
      updateUserAccount={handleUpdateUserAccountDetails}
    />
  );
}
