// @ts-nocheck
import React, { useRef, useState, useEffect } from "react";
import $ from "jquery";

import { DrawerContentBlock, DrawerTitle } from "../../ui/Drawer/Drawer.style";

import { Container, ControlsContainer } from "./FormBuilder.style";
import { useEffectOnce } from "../../../hooks";
import { Dropdown, PrimaryButton, WrapperLoader } from "../../ui";
import { ConfirmModal } from "./../../ui";
import uuid from "uuid";
import { Icon } from "../../../components/ui/Icon";
import { IconType, ChapterTypes } from "../../../consts";
import { Colors } from "./../../../environment";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

interface FormBuilderProps {
  initialFormData: string;
  updateFormData: (formData: string, chapterType: string) => void;
  openDrawer: (open: boolean) => void;
}

const fields = [
  {
    label: "Add one more field Button",
    attrs: {
      type: "duplicatePrevField"
    }
  },

  {
    label: "Start question's block",
    attrs: {
      type: "startQuestionBlock"
    }
  },

  {
    label: "End question's block",
    attrs: {
      type: "endQuestionBlock"
    }
  },

  {
    label: "End of step",
    attrs: {
      type: "endOfStep"
    }
  },

  {
    label: "HelpMan text",
    type: "paragraph",
    subtype: "output",
    access: "true",
    icon: "💁"
  },
  {
    label: "HelpMan text line",
    attrs: {
      type: "helpManLine"
    }
  },
  {
    label: "Add picture",
    attrs: {
      type: "image"
    }
  }
];

const templates = {
  image: fieldData => ({
    field: `<div id="${fieldData.name}"></div>`
  }),
  duplicatePrevField: fieldData => ({
    field: `<button id="${fieldData.name}">${fieldData.label}</button>`
  }),
  startQuestionBlock: fieldData => ({
    field: `<div id="${fieldData.name}"></div>`
  }),
  endQuestionBlock: fieldData => ({
    field: `<div id="${fieldData.name}"></div>`
  }),
  endOfStep: fieldData => ({
    field: `<div id="${fieldData.name}"></div>`
  }),
  helpManLine: fieldData => ({
    // in the next update, this functionality will be cut (obsolete)
    field: `<textarea id="${fieldData.name}" placeholder="This field is deprecated, please use the new <HelpMan text>" 
    style="
      height: 54px;
      display: block;
      width: 100%;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      border-radius: 4px;
      background-color: #f59292;
    ">`
  })
};

export function FormBuilder({
  updateFormData,
  initialFormData,
  openDrawer
}: FormBuilderProps) {
  const container = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formBuilder, setFormBuilder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const isExist =
    localStorage.getItem("ChapterType") !== undefined
      ? localStorage.getItem("ChapterType")
      : ChapterTypes.Regular;

  const typeFromLocalStorage = JSON.parse(
    isExist !== null ? isExist : ChapterTypes.Regular
  );
  const [chapterType, setChapterType] = useState<string>(typeFromLocalStorage);

  useEffectOnce(() => {
    const formBuilderOptions = {
      onAddField: function(id, field) {
        if (!field?.className) {
          field.className = uuid();
        }
      },
      fields,
      templates,
      disabledActionButtons: ["data", "save", "clear"]
    };
    $(container.current)
      .formBuilder(formBuilderOptions)
      .promise.then(fb => {
        setFormBuilder(fb);
        setIsLoading(false);
        fb.actions.setData(initialFormData);
      });
  });

  useEffect(() => {
    if (formBuilder) {
      setIsLoading(false);
      formBuilder.actions.setData(initialFormData);
    }
  }, [initialFormData]);

  const onSaveClick = () => {
    const formData = formBuilder.actions.getData();
    const formDataStr = JSON.stringify(formData);
    if (!formData.length || initialFormData === formDataStr) return;
    setIsLoading(true);
    updateFormData(formDataStr, chapterType);
  };

  const onClearClick = () => {
    const formData = formBuilder.actions.getData();
    if (!formData.length) return;

    setIsLoading(true);
    updateFormData("", chapterType);
    formBuilder.actions.clearFields();
    openDrawer(true);
  };

  const onPreviewClick = () => {
    formBuilder.actions.getData();
  };

  return (
    <>
      {/* <Dropdown
        toggleComponent={() => (
          <>
            <Dropdown.TitleBold>{chapterType}</Dropdown.TitleBold>
            <Icon name={IconType.EpCornerDown} color={Colors.black[100]} />
          </>
        )}
      >
        <Dropdown.Item
          onClick={() => {
            setChapterType(ChapterTypes.Regular);
          }}
        >
          {ChapterTypes.Regular}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setChapterType(ChapterTypes.Business);
          }}
        >
          {ChapterTypes.Business}
        </Dropdown.Item>
      </Dropdown> */}

      <DrawerTitle style={{ textAlign: "center" }}>{chapterType}</DrawerTitle>

      <Container isLoading={isLoading} ref={container}>
        <ControlsContainer>
          <PrimaryButton onClick={onSaveClick} title="Save" />
          <PrimaryButton
            onClick={() => {
              openDrawer(false);
              setShowModal(true);
            }}
            title="Clear"
          />
          <PrimaryButton onClick={onPreviewClick} title="Preview" />
        </ControlsContainer>
      </Container>

      <ConfirmModal
        title="Are you sure you want to clear form builder?"
        onClickYes={() => {
          onClearClick();
          setShowModal(false);
        }}
        onCloseModal={() => {
          openDrawer(true);
          setShowModal(false);
        }}
        showModal={showModal}
      />
      <WrapperLoader isLoading={isLoading} />
    </>
  );
}
