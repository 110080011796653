import React, { useState, useCallback, useEffect, useRef } from "react";

import { ModalContainer } from "./ModalWindow.style";
import {
  DefaultButton,
  Modal,
  ModalSizes,
  Input,
  Dropdown,
  Icon
} from "./../../../../components/ui";
import { useKeyDownListener } from "./../../../../hooks";
import { IconType, ChapterTypes } from "../../../../consts";
import { Colors } from "../../../../environment";

interface ModalWindowProps {
  showModal: boolean;
  isLoading: boolean;
  setShowModal: (isShow: boolean) => void;
  onClickAddButton: (chapterName: string) => void;
  setChapterType: any;
}

export function ModalWindow({
  setShowModal,
  onClickAddButton,
  showModal,
  isLoading,
  setChapterType
}: ModalWindowProps) {
  const newChapterNameImmutable = useRef<string>("");
  const [newChapterName, setNewChapterName] = useState<string>("");
  const isExist =
    localStorage.getItem("ChapterType") !== undefined
      ? localStorage.getItem("ChapterType")
      : ChapterTypes.Regular;

  const typeFromLocalStorage = JSON.parse(
    isExist !== null ? isExist : ChapterTypes.Regular
  );
  const [localChapterType, setLocalChapterType] = useState<string>(
    typeFromLocalStorage
  );
  setChapterType(localChapterType);

  function changeChapterType(type: string) {
    setLocalChapterType(type);
    setChapterType(type);
  }

  const enterAddChapter = useCallback(
    event => {
      if (event.keyCode === 13 && !!newChapterNameImmutable.current.length) {
        onClickAddButton(newChapterNameImmutable.current);
      }
    },
    [newChapterNameImmutable.current]
  );
  const removeEnterAddChapter = useKeyDownListener(enterAddChapter);

  useEffect(() => removeEnterAddChapter, []);

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      size={ModalSizes.XS}
      autoHeight={true}
      title="Add new chapter"
    >
      <ModalContainer>
        <Input
          value={newChapterName}
          onChange={({ target }) => {
            newChapterNameImmutable.current = target.value;
            setNewChapterName(target.value);
          }}
        />

        <DefaultButton
          loading={isLoading}
          title="Add"
          disabled={!newChapterName.length}
          onClick={() => {
            onClickAddButton(newChapterName);
          }}
        />
      </ModalContainer>
      <Dropdown
        toggleComponent={() => (
          <>
            <Dropdown.TitleBold>{localChapterType}</Dropdown.TitleBold>
            <Icon name={IconType.EpCornerDown} color={Colors.black[100]} />
          </>
        )}
      >
        <Dropdown.Item
          onClick={() => {
            changeChapterType(ChapterTypes.Regular);
          }}
        >
          {ChapterTypes.Regular}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            changeChapterType(ChapterTypes.Business);
          }}
        >
          {ChapterTypes.Business}
        </Dropdown.Item>
      </Dropdown>
    </Modal>
  );
}
