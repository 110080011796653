import React, { ReactNode, useRef, useCallback } from "react";
import { createPortal } from "react-dom";

import { IconType } from "../../../consts";

import {
  ModalBackground,
  ModalContainer,
  ModalHeader,
  ModalBody,
  HeaderTitle,
  HeaderLeftIcon,
  HeaderRightIcon,
  ModalFooter
} from "./Modal.style";
import { useOutsideClick, useKeyDownListener } from "../../../hooks";
import { Icon } from "../Icon";

export enum ModalSizes {
  XS = "XS",
  SM = "SM",
  MD = "MD",
  LG = "LG",
  XL = "XL"
}

interface ModalProps {
  title?: string;
  children?: ReactNode;
  headerLeftIcon?: string;
  open: boolean;
  onClose: () => void;
  size?: ModalSizes;
  autoHeight?: boolean;
}

export function Modal({
  title,
  children,
  headerLeftIcon,
  open,
  onClose,
  size = ModalSizes.XS,
  autoHeight = false
}: ModalProps) {
  const ref = useRef<HTMLDivElement>(null);

  function handleOutsideClick(e: Event) {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      onClose();
    }
  }
  useOutsideClick(handleOutsideClick);

  const escFunction = useCallback(event => {
    if (event.keyCode === 27) {
      onClose();
    }
  }, []);

  useKeyDownListener(escFunction);

  return open
    ? createPortal(
        <ModalBackground>
          <ModalContainer ref={ref} size={ModalSizes.XS} autoHeight={true}>
            <ModalHeader>
              {!!headerLeftIcon && (
                <HeaderLeftIcon>
                  <Icon name={headerLeftIcon} />
                </HeaderLeftIcon>
              )}
              <HeaderTitle>{title}</HeaderTitle>
              <HeaderRightIcon onClick={onClose}>
                <Icon name={IconType.EpTimes} />
              </HeaderRightIcon>
            </ModalHeader>
            {children}
          </ModalContainer>
        </ModalBackground>,
        document.body
      )
    : null;
}

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
