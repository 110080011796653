//@ts-nocheck
import React from "react";
import Faq from "react-faq-component";

import { PageMainContent, PageWrapper } from "./FAQ.style";
import { data } from "./FAQ.data";
import { Colors } from "../../environment";
import { GlobalStyle } from "../../pages/Landing/Landing.style";
import { ContactUsBlock } from "../../components/userProfile/UserProfile.style";
import { Images } from "../../environment/theme/";
import { useNavigation } from "../../hooks";

const styles = {
  bgColor: `white`,
  titleTextColor: `${Colors.black[100]}`,
  rowContentColor: `${Colors.gray[100]}`
};

export default function LoggedFAQ() {
  const { routes, history } = useNavigation();

  const handleRouteAndScroll = () => {
    history.push(routes.root);
    setTimeout(() => {
      const targetBlock = document.getElementById("link2");
      targetBlock?.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }, 500);
  };
  return (
    <>
      <PageWrapper>
        <PageMainContent>
          <GlobalStyle />
          <Faq data={data} styles={styles} />
        </PageMainContent>
        <ContactUsBlock onClick={() => handleRouteAndScroll("link2")}>
          <img
            src={Images.landingCommentUs}
            style={{ cursor: "pointer" }}
            alt=""
          />
          Kontakta oss
        </ContactUsBlock>
      </PageWrapper>
    </>
  );
}
