import { useMutation } from "@apollo/react-hooks";
import {
  LoginData,
  LoginVariables,
  UpdateUserAccountDetailsData,
  AddOrRemoveUserAvatarData,
  AddOrRemoveOrganisationLogoData,
  SignupData,
  DeleteUserAccountData
} from "../../../../../api";
import gql from "graphql-tag";
import { UserAccountFragment } from "../fragments";

const DELETE_USER_ACCOUNT = gql`
  mutation DeleteUserAccount(
    $userAccountWhereUniqueInput: UserAccountWhereUniqueInput!
  ) {
    deleteUserAccount(userAccountWhereUniqueInput: $userAccountWhereUniqueInput)
  }
`;

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
const CREATE_DRAFTCHAPTER_MUTATION = gql`
  mutation Update($data: CreateDraftChpaterInput) {
    createUserAccountDraftChapter(data: $data) {
      id
      template
      savedForm
      chapterId
      recipientEmail
      dowloadUrl
    }
  }
`;

const SIGNUP_MUTATION = gql`
  mutation Signup($data: SignupInput!) {
    signup(data: $data) {
      id
    }
  }
`;

const UPDATE_USER_ACCOUNT_DETAILS = gql`
  mutation UpdateUserAccountDetails($data: UpdateUserAccountDetailsInput!) {
    updateUserAccountDetails(data: $data) {
      token
      user {
        ...UserAccountFragment
        avatar {
          url
        }
        organisationLogo {
          url
        }
      }
    }
  }
  ${UserAccountFragment}
`;

const ADD_OR_REMOVE_USER_AVATAR = gql`
  mutation AddOrRemoveUserAvatar($data: AddOrRemoveUserAvatarInput) {
    addOrRemoveUserAvatar(data: $data) {
      ...UserAccountFragment
      avatar {
        url
      }
      organisationLogo {
        url
      }
    }
  }
  ${UserAccountFragment}
`;

const ADD_OR_REMOVE_ORGANISATION_LOGO = gql`
  mutation AddOrRemoveOrganisationLogo(
    $data: AddOrRemoveOrganisationLogoInput
  ) {
    addOrRemoveOrganisationLogo(data: $data) {
      ...UserAccountFragment
      organisationLogo {
        url
      }
      avatar {
        url
      }
    }
  }
  ${UserAccountFragment}
`;

export function useDeleteUserAccountMutation() {
  return useMutation<DeleteUserAccountData>(DELETE_USER_ACCOUNT, {
    onError: () => {}
  });
}

export function useLoginMutation() {
  return useMutation<LoginData, LoginVariables>(LOGIN_MUTATION, {
    onError: () => {}
  });
}

export function useUpdateUserAccountDetailsMutation() {
  return useMutation<UpdateUserAccountDetailsData>(
    UPDATE_USER_ACCOUNT_DETAILS,
    {
      onError: () => {}
    }
  );
}

export function useCreateDraftChapter() {
  return useMutation<any>(CREATE_DRAFTCHAPTER_MUTATION, {
    onError: () => {}
  });
}

export function useAddOrRemoveUserAvatar() {
  return useMutation<AddOrRemoveUserAvatarData>(ADD_OR_REMOVE_USER_AVATAR, {
    onError: () => {}
  });
}

export function useAddOrRemoveOrgainsotionLogo() {
  return useMutation<AddOrRemoveOrganisationLogoData>(
    ADD_OR_REMOVE_ORGANISATION_LOGO,
    {
      onError: () => {}
    }
  );
}

export function useSignup() {
  return useMutation<SignupData>(SIGNUP_MUTATION, {
    onError: () => {}
  });
}
