import React from "react";

import { CheckboxBlock } from "./Fields.style";
import { Checkbox } from "../../../ui";

interface CheckboxGroupProps {
  fieldData: any;
  fieldValue: Array<string>;
  onFieldChange: (name: string, value: Array<string>) => void;
}

export const CheckboxGroup = ({
  fieldData,
  fieldValue,
  onFieldChange
}: CheckboxGroupProps) => {
  return (
    <CheckboxBlock>
      {fieldData.values.map(({ label, value }: any) => (
        <Checkbox
          key={value}
          label={label}
          checked={fieldValue.indexOf(value) !== -1}
          onChange={({ target }) => {
            let newValue: string | Array<string>;

            if (target.checked) {
              if (!fieldValue.length) {
                newValue = [value];
              } else {
                newValue = [...fieldValue, value];
              }
            } else {
              newValue = fieldValue.filter(
                (oldValue: string) => oldValue !== value
              );
            }

            onFieldChange(fieldData.name, newValue);
          }}
        />
      ))}
    </CheckboxBlock>
  );
};
