import React, { useState, createContext } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import moment from "moment";

import { UserAccount } from "../../../../api";

import {
  PageWrapper,
  NavButtonText,
  PageContainer,
  UserCardContainer,
  UserCardAvatarBlock,
  UserCardInfoBlock,
  UserCardInfoTitle,
  UserCardInfoText,
  PageNavContent,
  BlockNavButtons,
  UserCardBlock,
  NavButton1,
  NavButton2,
  NavButton3,
  UserCardBlockContent
} from "./UserProfile.style";
import { Avatar, AvatarSize } from "../ui";
import { useTranslation, useViewport } from "../../hooks";
import { UserGeneralInfo, UserAccountFormValues } from "./UserGeneralInfo";
import { UserSettings } from "./UserSettings";
import { UserOrganisationLogo } from "./UserOrganisationLogo";

import { Nullable } from "../../types";
import { WrapperLoader } from "../ui";
import { useDevice } from "../../hooks";

interface IUserContext {
  user: Nullable<UserAccount>;
  setUser: (user: UserAccount) => void;
  formik: any;
}

interface UserProfileProps {
  isLoading: boolean;
  updateUserAccountLoading: boolean;
  userAccount: UserAccount | null;
  setUser: (user: UserAccount | null) => void;
  updateUserAccount: (userData: UserAccountFormValues) => void;
}

export const UserContext = createContext<IUserContext>({
  user: null,
  setUser: () => null,
  formik: null
});

export function UserProfile({
  isLoading,
  userAccount,
  updateUserAccountLoading,
  setUser,
  updateUserAccount
}: UserProfileProps) {
  const [activeTab, setActiveTab] = useState<number>(0);
  const translate = useTranslation();
  const width = useViewport();
  const [submitFromOutside, setSubmitFromOutside] = useState(false);
  const device = useDevice();
  const deleteTextValidation = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  const USER_PROFILE_SCHEMA = yup.object().shape({
    firstName: yup
      .string()
      .required(translate(({ inputs }) => inputs.firstName.required)),
    lastName: yup
      .string()
      .required(translate(({ inputs }) => inputs.lastName.required)),
    email: yup
      .string()
      .required(translate(({ inputs }) => inputs.email.required)),
    organisationColor: yup
      .string()
      .matches(deleteTextValidation, "Färgen måste skrivas i HEX-format")
  });

  const userAccoutFormik = useFormik({
    initialValues: {
      firstName: userAccount?.firstName || "",
      lastName: userAccount?.lastName || "",
      email: userAccount?.email || "",
      organisationColor: userAccount?.organisationColor || ""
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: USER_PROFILE_SCHEMA,
    onSubmit: values => updateUserAccount(values)
  });

  function getFormatedDate(date: string | undefined) {
    if (date) {
      return moment(new Date(date)).format("l HH:mm");
    }
  }

  return (
    <WrapperLoader isLoading={isLoading}>
      <PageWrapper isMacintosh={device}>
        {/* <PageTopBarContainer>
          <PageTopBarItem>
            <Breadcrumbs />
          </PageTopBarItem>
          <PageTopBarItem>
            <GhostButton
              size={ButtonSize.SM}
              icon={IconType.EpMore}
              onClick={() => {}}
            />
          </PageTopBarItem>
        </PageTopBarContainer> */}
        <UserCardContainer>
          <UserCardBlock>
            <UserCardBlockContent>
              <UserCardAvatarBlock>
                <Avatar size={AvatarSize.XL} image={userAccount?.avatar?.url} />
              </UserCardAvatarBlock>
              <UserCardInfoTitle>
                {`${userAccount?.firstName || "User"} ${userAccount?.lastName ||
                  "User"}`}
              </UserCardInfoTitle>
            </UserCardBlockContent>

            <UserCardInfoBlock>
              <UserCardInfoText>
                {`${translate(
                  ({ titles }) => titles.userAdded
                )}  ${getFormatedDate(userAccount?.createdAt)}`}
              </UserCardInfoText>
              <UserCardInfoText>
                {`${translate(
                  ({ titles }) => titles.lastUserActivity
                )} ${getFormatedDate(userAccount?.updatedAt)}`}
              </UserCardInfoText>
              <UserCardInfoText>
                {`${translate(
                  ({ titles }) => titles.lastProfileUpdated
                )}  ${getFormatedDate(userAccount?.updatedAt)}`}
              </UserCardInfoText>
            </UserCardInfoBlock>
          </UserCardBlock>
        </UserCardContainer>
        <div
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <BlockNavButtons>
            <NavButton1 onClick={() => setActiveTab(0)} active={activeTab}>
              <NavButtonText>
                {translate(({ tabs }) => tabs.general)}
              </NavButtonText>
            </NavButton1>
            <NavButton2 onClick={() => setActiveTab(1)} active={activeTab}>
              <NavButtonText>
                {translate(({ tabs }) => tabs.accountSettings)}
              </NavButtonText>
            </NavButton2>
            <NavButton3 onClick={() => setActiveTab(2)} active={activeTab}>
              <NavButtonText>Organisation</NavButtonText>
            </NavButton3>
          </BlockNavButtons>
          <PageContainer>
            <UserContext.Provider
              value={{ user: userAccount, setUser, formik: userAccoutFormik }}
            >
              <PageNavContent>
                {activeTab === 0 && (
                  <UserGeneralInfo
                    updateLoading={updateUserAccountLoading}
                    formik={userAccoutFormik}
                    submitFromOutside={submitFromOutside}
                    setSubmitFromOutside={setSubmitFromOutside}
                  />
                )}
                {activeTab === 1 && <UserSettings />}
                {activeTab === 2 && (
                  <UserOrganisationLogo formik={userAccoutFormik} />
                )}
              </PageNavContent>
            </UserContext.Provider>
          </PageContainer>
        </div>
      </PageWrapper>
    </WrapperLoader>
  );
}
