import styled from "styled-components";
import { deviceRange } from "../../consts";
import { Colors, Fonts } from "../../environment/theme";

export const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  header {
    position: sticky;
    top: 0;
    background-color: ${Colors.backgroundColor};
    z-index: 2;
  }
`;

export const PageMainContent = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 70%;
  margin: 0 auto;

  @media ${deviceRange.mobile} {
    padding-top: 80px;
  }
`;

export const TermsServiceHeader = styled.div``;

export const TermsServiceMainTitle = styled.h2`
  font-family: ${Fonts.title};
  font-size: 32px;
  line-height: 51px;
  letter-spacing: 0px;
  margin-bottom: 8px;
  color: ${Colors.black[100]};
`;

export const PrivacyPolicyText = styled.p`
  margin: 0 auto;
  text-align: left;
  font-size: 1, 1rem;
  line-height: 2rem;
  padding-top: 1rem;
  text-align: left;
  font-weight: 400;
  color: #5a5a5a;
  font-family: ${Fonts.paragraph};
`;

export const TermsServiceTitle = styled.h2`
  font-family: ${Fonts.title};
  font-size: 26px;
  line-height: 51px;
  letter-spacing: 0px;
  margin: 8px 0;
  color: ${Colors.black[100]};
`;

export const TermsServiceList = styled.ol``;

export const TermsServiceText = styled.p`
  margin: 0 auto;
  text-align: left;
  font-size: 1, 1rem;
  line-height: 2rem;
  padding-top: 1rem;
  text-align: left;
  font-weight: 400;
  color: #5a5a5a;
  font-family: ${Fonts.paragraph};
`;

export const TermsServiceTextSub = styled.p`
  margin: 0 auto;
  text-align: left;
  font-size: 1, 1rem;
  line-height: 2rem;
  padding-top: 1rem;
  text-align: left;
  font-weight: 400;
  color: #5a5a5a;
  font-family: ${Fonts.paragraph};
`;
