import React, { useState } from "react";
import {
  ButtonPDF,
  ButtonStep,
  GoogleButton,
  Instruction,
  ButtonPDFWithoutBackground
} from "./BaseButtonModal";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  ContentBlockWrapper
} from "./Modals.style";

export const ModalContent19 = () => {
  const [step, setStep] = useState<number>(0);
  const changeStep = (id: number) => setStep(id);

  return (
    <>
      {step === 0 && (
        <ContentBlockWrapper>
          <ContentBlock>
            <LeftBlockContent>
              <TextTitleWrapper>
                <TextTitle>För vem:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>
                  Spelet är en diskussionsövning mellan dig som ska överlämna
                  ett förtroendeuppdrag och dig som ska ta över uppdraget.
                  <span style={{ display: "block" }}>
                    Spelet kräver minst två deltagare men kan spelas av flera.
                  </span>
                </SecondText>
              </TextWrapper>
              <TextTitleWrapper>
                <TextTitle>Hur dokumentet kan användas:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>
                  Skriv ut papperna och lägg ihop dem som en spelplan. Använd en
                  tärning och varsin spelpjäs. Det finns en version att skriva
                  ut med färdiga påståenden och en version där man kan fylla i
                  egna påståenden/frågor.
                </SecondText>
              </TextWrapper>
            </LeftBlockContent>
            <RightBlockContent>
              <TextTitleWrapper>
                <TextTitle>Beskrivning:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>
                  Spelet finns till för att göra kunskapsöverlämningen mer
                  lekfull. Regler: Hamnar ni på en blå fråga ska du som ska
                  lämna uppdraget fylla i påståendet. Hamnar ni på en grön fråga
                  ska du som ska ta över uppdraget fylla i påståendet.
                  <span style={{ display: "block" }}>
                    Den som just har svarat på en fråga får slå tärningen nästa
                    gång. Den som först kommer i mål har vunnit.
                  </span>
                </SecondText>
              </TextWrapper>
            </RightBlockContent>
          </ContentBlock>
        </ContentBlockWrapper>
      )}
      <FooterModal notAliginSelf={1}>
        <LeftBlockContent>
          <ButtonPDFWithoutBackground
            downLink={
              "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Kunskapsöverföringsspelet - Ifyllbart.pdf"
            }
          />
        </LeftBlockContent>
        <RightBlockContent>
          <ButtonPDF
            downLink={
              "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Kunskapsöverföringsspelet.pdf"
            }
          />
        </RightBlockContent>
      </FooterModal>
    </>
  );
};
