import React, { useState } from "react";
import { deviceSize } from "../../consts";
import { useViewport } from "../../hooks";
import {
  ReadMoreBlockMainText,
  ReadMoreBlockLeftPartText,
  LasMerButtonServiceBlock
} from "./Landing.style";

export const ReadMoreElement = () => {
  const [readMore, setReadMore] = useState<boolean>(false);
  const width = useViewport();

  return (
    <>
      <ReadMoreBlockMainText>Skapat av Förbundet Vi Unga</ReadMoreBlockMainText>
      <ReadMoreBlockLeftPartText>
        Spridakunskap.se är skapat av
        <a
          href="https://www.viunga.se"
          target="blank"
          style={{ textDecoration: "none" }}
        >
          {" "}
          Förbundet Vi Unga{" "}
        </a>{" "}
        med stöd från
        <a
          href="https://www.arvsfonden.se"
          target="blank"
          style={{ textDecoration: "none" }}
        >
          {" "}
          Allmänna Arvsfonden.{" "}
        </a>
      </ReadMoreBlockLeftPartText>
      {width >= parseInt(deviceSize.tablet.min) ? (
        <ReadMoreBlockLeftPartText>
          Du når oss enklast vi vår Facebook-sida,
          <a
            href="https://www.facebook.com/Spridakunskap.se/"
            target="blank"
            style={{ textDecoration: "none" }}
          >
            {" "}
            Digitalt verktyg för kunskapsöverföring{" "}
          </a>
        </ReadMoreBlockLeftPartText>
      ) : null}
      <ReadMoreBlockLeftPartText>
        Förbundet Vi Unga är ett förbund som riktar sig till personer mellan
        13-25 år i hela landet som vill ägna sig åt föreningsverksamhet.
      </ReadMoreBlockLeftPartText>
      <ReadMoreBlockLeftPartText>
        I förbundet kan barn och unga organisera sig i föreningsform utan vuxnas
        inblandning. De unga får själva välja i vilka teman de vill skapa och
        driva sina föreningar.
      </ReadMoreBlockLeftPartText>
      {readMore ? (
        <div>
          {width >= parseInt(deviceSize.tablet.min) ? null : (
            <ReadMoreBlockLeftPartText>
              Du når oss enklast vi vår Facebook-sida, &nbsp;
              <a
                href="https://www.facebook.com/Spridakunskap/"
                target="blank"
                style={{ textDecoration: "none" }}
              >
                Digitalt verktyg för kunskapsöverföring
              </a>
            </ReadMoreBlockLeftPartText>
          )}
          <ReadMoreBlockLeftPartText>
            I organisationen får unga ta ansvar, förverkliga sin idéer samt
            bilda egna åsikter. Förbundet är en demokratisk partipolitiskt och
            religiöst obunden plattform som är öppen för alla unga.
          </ReadMoreBlockLeftPartText>
          <ReadMoreBlockLeftPartText>
            Förbundets vision “Alla barn och ungdomar ska få utvecklas, känna
            sig betydelsefulla och ha möjlighet att förverkliga sina idéer’’.
          </ReadMoreBlockLeftPartText>
          <LasMerButtonServiceBlock onClick={() => setReadMore(false)}>
            Mindre
          </LasMerButtonServiceBlock>
        </div>
      ) : (
        <div>
          <LasMerButtonServiceBlock onClick={() => setReadMore(true)}>
            Läs mer
          </LasMerButtonServiceBlock>
        </div>
      )}
    </>
  );
};
