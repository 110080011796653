import React, { useState } from "react";

import {
  PageContainer,
  ThankYouContainer,
  ThankYouSign,
  ThankYouTitle
} from "./ThankYou.style";
import {
  HelpManWrapp,
  HelpManContent,
  HelpManRullaWrapp,
  HelpManImgWrap,
  AssistantBlockWrapper,
  AssistantBlockContent,
  AssistantTextBlock
} from "../../components/Handover/Body/Body.style";
import { Images } from "../../environment/theme/Images";

import { useTranslation } from "../../hooks";

export function ThankYou() {
  const [showAssistant, setShowAssistant] = useState<boolean>(false);
  const translate = useTranslation();

  return (
    <PageContainer>
      <ThankYouContainer>
        <ThankYouTitle>
          {translate(({ thankYou }) => thankYou.title)}
        </ThankYouTitle>

        <ThankYouSign>
          {translate(({ thankYou }) => thankYou.sign)}

          <br />

          {translate(({ thankYou }) => thankYou.niceDay)}
        </ThankYouSign>
      </ThankYouContainer>
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundImage: showAssistant
            ? `url(${Images.landingBackgroundBlur})`
            : "none",
          backgroundSize: showAssistant ? "cover" : "none"
        }}
      >
        {showAssistant ? (
          <HelpManWrapp>
            <HelpManContent>
              <HelpManRullaWrapp>
                <img
                  src={Images.landingImageArrowDown}
                  onClick={() => setShowAssistant(false)}
                  style={{
                    width: "30px",
                    height: "30px",
                    marginBottom: "5px",
                    cursor: "pointer",
                    zIndex: 80
                  }}
                  alt=""
                />
                Rulla app
              </HelpManRullaWrapp>
              <HelpManImgWrap>
                <img
                  src={Images.landingThankYouMan}
                  style={{
                    height: "100%"
                  }}
                  alt=""
                />
                {/* <HelpManText>
                  Fyll i grundläggande information om sändaren
                </HelpManText> */}
              </HelpManImgWrap>
            </HelpManContent>
          </HelpManWrapp>
        ) : (
          <AssistantBlockWrapper>
            <AssistantBlockContent onClick={() => setShowAssistant(true)}>
              <AssistantTextBlock>
                <img
                  src={Images.landingImageArrowUp}
                  style={{ cursor: "pointer", width: "35px", height: "35px" }}
                  alt=""
                />
                Behövs hjälp?
              </AssistantTextBlock>
              <img
                src={Images.landingEllipseMaskGroup}
                style={{ position: "relative" }}
                alt=""
              />
              <img
                src={Images.landingMaskGroup}
                style={{
                  position: "relative",
                  marginLeft: "-100px",
                  zIndex: 99
                }}
                alt=""
              />
            </AssistantBlockContent>
          </AssistantBlockWrapper>
        )}
      </div>
    </PageContainer>
  );
}
