import React, { useState, useEffect, useRef } from "react";
import {
  GlobalStyle,
  HeaderContainer,
  HeaderBlockLogo,
  HeaderBlockLinks,
  HeaderLink,
  HeaderBlockButtons,
  HeadetButtonReg,
  HeadetButtonLog,
  PromoBlockContaner,
  PromoBlockSecondContaner,
  PromoBlockLeftPart,
  PromoBlockText,
  PromoTextBeforeButton,
  PromoButtonStart,
  PromoBlockRightPart,
  BlockExchangeContainer,
  BlockExchangeContentWrapper,
  BlockExchangeMainText,
  BlockExchangeLeftPart,
  BlockExchangeRightPart,
  BlockExchangeRightPartAvatars,
  BlockExchangeWrapParts,
  BlockExchangeButtons,
  BlockExchangeButton1,
  BlockExchangeButton2,
  VemBlockPinkText,
  BlockWithArrowsContainer,
  BlockWithArrow1,
  BlockWithArrowCard,
  BlockWithArrowBlockCard,
  BlockWithArrow2,
  TargetBlockContainer,
  TargetBlockContentWrapper,
  TargetBlockContentLine1,
  TargetBlockMainText2,
  TargetBlockContentLine2,
  TargetBlockContentLine2Text,
  TargetBlockBtnRekommendera,
  QuestionBlockContaner,
  QuestionBlockLeftPart,
  QuestionBlockQuestions,
  QuestionManImage,
  ServicesBlock,
  ServicesBlockTitle,
  ServicesBlockWrapParts,
  AchievementsBlock,
  IdeasBlock,
  BlockBeforeIdeasBlock,
  IdeasBlockContentWrapper,
  FinanceBlockContainer,
  FooterLine,
  BlockButtonFooter,
  FooterContactsBlock,
  SocialBlock,
  SocialBlockTextBlock,
  SocialBlockText,
  DesignByBlock,
  SocialBlockTextBefSoc,
  FooterLink,
  FooterBlockLinks,
  FaqTitle,
  FaqItemsBlock,
  FirstImage,
  ServicesBlockText,
  FinanceBlockTextWrapper,
  HeaderBurgerMenu,
  ImageBurgerMenu,
  PopupBurgerMenu,
  ImageCloseButton,
  ImageCloseButtonWrapper,
  HeaderMobileWrapper,
  ImagePromoBlockContanerWrapper,
  ArrowPromoBlockContaner,
  PromoTextBeforeButtonFirstPage,
  ImageExchangeBlockContanerWrapper,
  BlockExchangeBoyAvatar,
  BlockExchangeArrow,
  BlockExchangeLeftPartTextOnLeft,
  ImageExchangeContanerLandingWrapper,
  BlockExchangeRightPartText,
  BlockExchangeLeftPartTextSecondPage,
  ImageWebPageExchangeBlockContanerWrapper,
  ImageWebPage,
  LandingCardImage,
  LandingImageGirl,
  LandingLikeImage,
  LandingPenImage,
  LandingMeanImage,
  LandingPenImageWrapper,
  LandingPenImageLikeWrapper,
  BlockExchangeRightPartTextSecondPage,
  BlockExchangeRightPartTextSecondPageDown,
  ImagePeopleWithSkateWrapper,
  ImagePeopleWithSkate,
  PromoBlockSecondLeftPart,
  PromoBlockSecondContainer,
  LongHorizontalArrowImageWrapper,
  LandingArrow4,
  LandingArrowImage,
  ManWithComputerImage,
  LandingIconMark,
  LandingIconQuestion,
  LandingImageTarget,
  LandingImageLike2,
  LandingMean,
  LandingArrow1,
  LandingChem,
  LandingGroupFace,
  LandingIcon65,
  LandingIconGirl,
  LandingIconGroup,
  LandingIconMans,
  LandingIconWoker,
  LandingImageCode,
  LandingImageLightBulb,
  LandingImageWifi,
  LandingLogoHeader,
  PromoLandingArrow1,
  LandingFinanceLogo,
  LandingFacebook,
  LandingFooterlogo,
  LandingInsta,
  LandingVector,
  LandingYoutube,
  QuestionServiceLeftPartText,
  IdeasConteinerText,
  BlockWithArrowsText,
  BlockIdeasMainText,
  BlockFinanceMainText,
  BlockWithArrows2MainText,
  BlockAchievementsMainText,
  LandingArrow4Wrapper,
  LandingGroupFaceWrapper,
  MainPeopleImageWrapper,
  LandingChemWrapper,
  LandingIconGroupWrapper,
  LandingIconMarkWrapper,
  LandingIconQuestionWrapper,
  LandingImageTargetWrapper,
  LandingMeanWrapper,
  LandingPeopleComputerWrapper,
  LandingPeopleQuestionsWrapper,
  LandingIconWokerWrapper,
  LandingIconGirlWrapper,
  LandingArrow1Wrapper,
  LandingIcon65Wrapper,
  LandingIconMansWrapper,
  LandingArrow1PromoBlockWrapper,
  LandingFinanceLogoWrapper,
  LandingImageCodeWrapper,
  LandingImageLightBulbWrapper,
  LandingImageWifiWrapper,
  BlockServiceMainText,
  BlockWithArrow1MainText,
  QuestionBlockLeftPartMainText,
  QuestionBlockLeftPartMainTextBottom,
  BlockFinanceText,
  PromoBlockContanerWrapper,
  PromoBlockSecondContainerMainText,
  PromoTextBeforeButtonText,
  MainTextBlockTarget,
  LandingPromoBlockMainText,
  LogIn,
  BlockExchangeButton3,
  BlockExchangeButton4,
  PromoButtonStartWrapper,
  BlockCardAchievements,
  BlockWithCardAchievements,
  PromoButtonStartServiceBlock,
  BeforeIdeasBlockBtnRekommendera,
  Wrapper,
  BlockVideoContainer,
  BlockVideoContentWrapper,
  VideoPopUpWrapp,
  VideoPopupContent,
  IntroVideo,
  VideoBlockTitle
} from "./Landing.style";
import { useNavigation, useViewport } from "../../hooks";
import { Images } from "../../environment/theme/Images";
import { QuestionElement } from "./QuestionElement";
import { FaqItem } from "./FaqItem";
import { ReadMoreElement } from "./ReadMoreElement";
import { data } from "../../pages/FAQ/FAQ.data";
import { deviceSize } from "../../consts";
import { Videos } from "../../environment";

export const Landing = () => {
  const [slider, setSlider] = useState<number>(0);
  const [scroll, setScroll] = useState<boolean>(false);
  const [isFull, setIsFull] = useState<boolean>(false);
  const [burgetMenuShow, setBurgetMenuShow] = useState<boolean>(false);
  const { routes, history } = useNavigation();
  const width = useViewport();

  const faqItems = [
    {
      title: "Vad är Spridakunskap.se?",
      content: `Spridakunskap.se innehåller ett verktyg som finns till för att underlätta vid överlämningar av förtroendeuppdrag i ideella organisationer, förbund och föreningar. Den som ska lämna sitt uppdrag fyller i svaren på frågorna i verktyget. Svaren sammanfattas sedan i ett pedagogiskt dokument som den som ska ta över förtroendeuppdraget får ta del av. Dokumentet ska fungera som en skriftlig introduktion.`
    },
    {
      title: "Vem är verktyget till för?",
      content:
        "Spridakunskap.se är skapat av Förbundet Vi Unga men är till för alla som är engagerade i ideella ungdomsorganisationer och som ska överlämna sina förtroendevalda uppdrag."
    },
    {
      title: "Vad är verktyget till för?",
      content: `Verktyget är till för den som ska överlämna ett förtroendevalt uppdrag till någon annan. `
    }
  ];

  useEffect(() => {
    function scrolling() {
      if (window.pageYOffset > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    }
    window.addEventListener("scroll", scrolling);
    return () => {
      window.removeEventListener("scroll", scrolling);
    };
  }, []);

  //SDPR
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://consent.cookiebot.com/5b164c2b-6602-4bca-9560-ab5e539cd79e/cd.js";
    script.id = "CookieDeclaration";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const link1: any = useRef();
  const link2: any = useRef();
  const link3: any = useRef();

  const scrollToElement = (el: any, a?: any) => {
    const y = el?.current?.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y - (a === "a" ? 0 : 130),
      behavior: "smooth"
    });
  };

  return (
    <>
      <Wrapper style={{ overflow: "hidden" }}>
        <GlobalStyle />
        {width >= parseInt(deviceSize.laptopM.min) ? (
          <HeaderContainer scroll={scroll}>
            <HeaderBlockLogo>
              <LandingLogoHeader src={Images.landingLogo} alt="" />
            </HeaderBlockLogo>
            <HeaderBlockLinks>
              <HeaderLink onClick={() => scrollToElement(link1)}>
                Varför kunskapsöverföring?
              </HeaderLink>
              <HeaderLink onClick={() => scrollToElement(link3, "a")}>
                För vem?
              </HeaderLink>
              <HeaderLink onClick={() => scrollToElement(link2)}>
                Om oss
              </HeaderLink>

              <HeaderLink onClick={() => history.push(routes.usefuldocuments)}>
                Användbart material
              </HeaderLink>
              <HeaderLink onClick={() => history.push(routes.dictionary)}>
                Ordbok
              </HeaderLink>
              <HeaderLink onClick={() => history.push(routes.blog)}>
                Blogg
              </HeaderLink>
            </HeaderBlockLinks>
            <HeaderBlockButtons>
              <HeadetButtonReg onClick={() => history.push(routes.signup)}>
                Skapa konto
              </HeadetButtonReg>
              <LogIn onClick={() => history.push(routes.login)}>Logga in</LogIn>
            </HeaderBlockButtons>
          </HeaderContainer>
        ) : width >= parseInt(deviceSize.tablet.min) ? (
          <HeaderContainer scroll={scroll}>
            <HeaderBlockLogo>
              <LandingLogoHeader src={Images.landingLogo} alt="" />
            </HeaderBlockLogo>
            <HeaderBlockButtons>
              <HeadetButtonReg onClick={() => history.push(routes.signup)}>
                Skapa konto
              </HeadetButtonReg>
              <LogIn onClick={() => history.push(routes.login)}>Logga in</LogIn>
              <HeaderBurgerMenu onClick={() => setBurgetMenuShow(true)}>
                <ImageBurgerMenu src={Images.landingBurgerMenu} alt="" />
              </HeaderBurgerMenu>
            </HeaderBlockButtons>
          </HeaderContainer>
        ) : (
          <HeaderContainer scroll={scroll}>
            <HeaderMobileWrapper>
              <HeaderBlockLogo>
                <LandingLogoHeader src={Images.landingLogo} alt="" />
              </HeaderBlockLogo>
              <HeaderBurgerMenu onClick={() => setBurgetMenuShow(true)}>
                <ImageBurgerMenu src={Images.landingBurgerMenu} alt="" />
              </HeaderBurgerMenu>
            </HeaderMobileWrapper>
            <HeaderMobileWrapper>
              <HeadetButtonReg onClick={() => history.push(routes.signup)}>
                Skapa konto
              </HeadetButtonReg>
              <LogIn onClick={() => history.push(routes.login)}>Logga in</LogIn>
            </HeaderMobileWrapper>
          </HeaderContainer>
        )}

        {width >= parseInt(deviceSize.laptop.min) ? (
          <PopupBurgerMenu isOpen={burgetMenuShow}>
            <HeaderBlockLinks>
              <HeaderLink onClick={() => scrollToElement(link1)}>
                Varför kunskapsöverföring?
              </HeaderLink>
              <HeaderLink onClick={() => scrollToElement(link3, "a")}>
                För vem?
              </HeaderLink>
              <HeaderLink onClick={() => scrollToElement(link2)}>
                Om oss
              </HeaderLink>

              <HeaderLink onClick={() => history.push(routes.usefuldocuments)}>
                Användbart material
              </HeaderLink>
              <HeaderLink onClick={() => history.push(routes.dictionary)}>
                Ordbok
              </HeaderLink>
              <HeaderLink onClick={() => history.push(routes.blog)}>
                Blogg
              </HeaderLink>
            </HeaderBlockLinks>
            <ImageCloseButtonWrapper onClick={() => setBurgetMenuShow(false)}>
              <ImageCloseButton src={Images.landingCloseIcon} />
            </ImageCloseButtonWrapper>
          </PopupBurgerMenu>
        ) : (
          <PopupBurgerMenu isOpen={burgetMenuShow}>
            <ImageCloseButtonWrapper onClick={() => setBurgetMenuShow(false)}>
              <ImageCloseButton src={Images.landingCloseIcon} />
            </ImageCloseButtonWrapper>
            <HeaderBlockLinks>
              <HeaderLink onClick={() => scrollToElement(link1)}>
                Varför kunskapsöverföring?
              </HeaderLink>
              <HeaderLink onClick={() => scrollToElement(link3, "a")}>
                För vem?
              </HeaderLink>
              <HeaderLink onClick={() => scrollToElement(link2)}>
                Om oss
              </HeaderLink>

              <HeaderLink onClick={() => history.push(routes.usefuldocuments)}>
                Användbart material
              </HeaderLink>
              <HeaderLink onClick={() => history.push(routes.dictionary)}>
                Ordbok
              </HeaderLink>
              <HeaderLink onClick={() => history.push(routes.blog)}>
                Blogg
              </HeaderLink>
            </HeaderBlockLinks>
          </PopupBurgerMenu>
        )}
        <PromoBlockContaner>
          <PromoBlockLeftPart maxWidth={700}>
            <LandingPromoBlockMainText>
              Har du ett uppdrag som du ska överlämna till någon annan?
            </LandingPromoBlockMainText>
            <PromoBlockText>
              Då har du kommit helt rätt, välkommen!
            </PromoBlockText>
            <PromoBlockText>
              Använd Spridakunskap.se för att dela med dig av din kunskap och
              överlämna den till den som ska ta över ditt uppdrag!
            </PromoBlockText>
            <PromoTextBeforeButtonFirstPage>
              <PromoBlockContanerWrapper>
                VILL DU SKAPA ETT KONTO OCH PÅBÖRJA ÖVERLÄMNINGEN DIREKT?
              </PromoBlockContanerWrapper>
              <ImagePromoBlockContanerWrapper>
                <ArrowPromoBlockContaner src={Images.landingArrow1} alt="" />
              </ImagePromoBlockContanerWrapper>
            </PromoTextBeforeButtonFirstPage>
            <PromoButtonStart
              onClick={() => history.push(routes.login)}
              width={320}
            >
              STARTA ÖVERLÄMNING
            </PromoButtonStart>
          </PromoBlockLeftPart>
          <PromoBlockRightPart>
            <MainPeopleImageWrapper>
              <FirstImage src={Images.landingMainPeopleImage} alt="" />
            </MainPeopleImageWrapper>
          </PromoBlockRightPart>
        </PromoBlockContaner>
        <BlockVideoContainer>
          <VideoBlockTitle>Hur fungerar det?</VideoBlockTitle>
          <BlockVideoContentWrapper>
            <VideoPopUpWrapp>
              <VideoPopupContent>
                <IntroVideo controls>
                  <source src={Videos.howItWorks} type="video/mp4" />
                </IntroVideo>
              </VideoPopupContent>
            </VideoPopUpWrapp>{" "}
          </BlockVideoContentWrapper>
        </BlockVideoContainer>
        <BlockExchangeContainer>
          <BlockExchangeContentWrapper>
            <BlockExchangeMainText>
              Hur verktyget underlättar vid kunskapsöverföring?
            </BlockExchangeMainText>

            {width >= parseInt(deviceSize.tablet.min) ? (
              <>
                {slider === 0 && (
                  <BlockExchangeWrapParts>
                    <BlockExchangeLeftPart>
                      <BlockExchangeLeftPartTextOnLeft>
                        Genom att genomföra en digital överlämning kan du vara
                        säker på att din ersättare får en trygg start i sitt nya
                        uppdrag. Dessutom kan du känna dig trygg med att din
                        kunskap och allt det som du bidragit med stannar i
                        organisationen trots att du själv valt att gå vidare.
                      </BlockExchangeLeftPartTextOnLeft>
                      <BlockExchangeLeftPartTextOnLeft>
                        Spridakunskap.se underlättar både för dig som ska lämna
                        ett förtroendeuppdrag och för dig som ska ta över
                        förtroendeuppdraget. Logga in och gör din
                        kunskapsöverföring på ett enkelt sätt.
                      </BlockExchangeLeftPartTextOnLeft>
                      <ImageExchangeBlockContanerWrapper>
                        <LandingCardImage
                          src={Images.landingCardImage}
                          alt=""
                        />
                      </ImageExchangeBlockContanerWrapper>
                    </BlockExchangeLeftPart>
                    <BlockExchangeRightPart>
                      <BlockExchangeRightPartAvatars>
                        <BlockExchangeBoyAvatar src={Images.landingBoyAvatar} />
                        <ImageExchangeContanerLandingWrapper>
                          <BlockExchangeArrow
                            src={Images.landingArrow2}
                            alt=""
                          />
                        </ImageExchangeContanerLandingWrapper>
                        <LandingImageGirl
                          src={Images.landingGirlAvatar}
                          alt=""
                        />
                      </BlockExchangeRightPartAvatars>
                      <BlockExchangeRightPartText>
                        Som bekant går förtroendeuppdrag vidare från en person
                        till en annan efter en viss tid. Vid sådana skiften är
                        muntliga introduktioner många gånger uppskattade men att
                        också överlämna en skriftlig introduktion är ett mycket
                        bra komplement!
                      </BlockExchangeRightPartText>
                      <BlockExchangeRightPartText>
                        Att författa en skriftlig instruktion från scratch kan
                        dock vara både tids- och energikrävande i en tid när du
                        själv är mitt uppe att slutföra sådant du själv
                        påbörjat. Och inte bara det, hur ska man komma ihåg allt
                        som borde skrivas ned?
                      </BlockExchangeRightPartText>
                    </BlockExchangeRightPart>
                  </BlockExchangeWrapParts>
                )}
                {slider === 1 && (
                  <BlockExchangeWrapParts>
                    <BlockExchangeLeftPart>
                      <BlockExchangeLeftPartTextSecondPage>
                        Det är i dessa lägen som Spridakunskap.se kommer som
                        räddaren i nöden med en redan uttänkt struktur, förslag
                        på ämnen att ta upp och förslag på bilagor att bifoga.
                      </BlockExchangeLeftPartTextSecondPage>
                      <BlockExchangeLeftPartTextSecondPage>
                        I verktyget kan du välja ut vilka frågor du vill svara
                        på och bygga din egen överlämning såsom du vill ha den.
                      </BlockExchangeLeftPartTextSecondPage>
                      <ImageWebPageExchangeBlockContanerWrapper>
                        <ImageWebPage src={Images.landingImageWeb} alt="" />
                      </ImageWebPageExchangeBlockContanerWrapper>
                    </BlockExchangeLeftPart>
                    <BlockExchangeRightPart>
                      <BlockExchangeRightPartAvatars>
                        <LandingPenImage src={Images.landingPen} alt="" />
                        <LandingPenImageWrapper>
                          <LandingMeanImage src={Images.landingMean} alt="" />
                        </LandingPenImageWrapper>
                        <LandingPenImageLikeWrapper>
                          <LandingLikeImage
                            src={Images.landingImageLike}
                            alt=""
                          />
                        </LandingPenImageLikeWrapper>
                      </BlockExchangeRightPartAvatars>
                      <BlockExchangeRightPartTextSecondPage>
                        När du fyllt i frågorna och tagit upp de ämnen som du
                        själv valt omvandlas svaren till ett pedagogiskt
                        textdokument som du skickar över till din ersättare med
                        ett enkelt klick. Lätt som en plätt, eller hur!
                      </BlockExchangeRightPartTextSecondPage>
                      <BlockExchangeRightPartTextSecondPageDown>
                        KOM IHÅG! Kombinera gärna din digitala
                        kunskapsöverlämning med en muntlig introduktion för att
                        ge din ersättare bästa möjliga start i sitt uppdrag.
                      </BlockExchangeRightPartTextSecondPageDown>
                    </BlockExchangeRightPart>
                  </BlockExchangeWrapParts>
                )}
              </>
            ) : (
              <>
                <BlockExchangeWrapParts>
                  {slider === 0 && (
                    <BlockExchangeLeftPart>
                      <BlockExchangeLeftPartTextOnLeft>
                        Genom att genomföra en digital överlämning kan du vara
                        säker på att din ersättare får en trygg start i sitt nya
                        uppdrag. Dessutom kan du känna dig trygg med att din
                        kunskap och allt det som du bidragit med stannar i
                        organisationen trots att du själv valt att gå vidare.
                      </BlockExchangeLeftPartTextOnLeft>
                      <BlockExchangeLeftPartTextOnLeft>
                        Spridakunskap.se underlättar både för dig som ska lämna
                        ett förtroendeuppdrag och för dig som ska ta över
                        förtroendeuppdraget. Logga in och gör din
                        kunskapsöverföring på ett enkelt sätt.
                      </BlockExchangeLeftPartTextOnLeft>
                      <ImageExchangeBlockContanerWrapper>
                        <LandingCardImage
                          src={Images.landingCardImage}
                          alt=""
                        />
                      </ImageExchangeBlockContanerWrapper>
                    </BlockExchangeLeftPart>
                  )}
                  {slider === 1 && (
                    <BlockExchangeRightPart>
                      <BlockExchangeRightPartAvatars>
                        <BlockExchangeBoyAvatar src={Images.landingBoyAvatar} />
                        <ImageExchangeContanerLandingWrapper>
                          <BlockExchangeArrow
                            src={Images.landingArrow2}
                            alt=""
                          />
                        </ImageExchangeContanerLandingWrapper>
                        <LandingImageGirl
                          src={Images.landingGirlAvatar}
                          alt=""
                        />
                      </BlockExchangeRightPartAvatars>
                      <BlockExchangeRightPartText>
                        Som bekant går förtroendeuppdrag vidare från en person
                        till en annan efter en viss tid. Vid sådana skiften är
                        muntliga introduktioner många gånger uppskattade men att
                        också överlämna en skriftlig introduktion är ett mycket
                        bra komplement!
                      </BlockExchangeRightPartText>
                      <BlockExchangeRightPartText>
                        Att författa en skriftlig instruktion från scratch kan
                        dock vara både tids- och energikrävande i en tid när du
                        själv är mitt uppe att slutföra sådant du själv
                        påbörjat. Och inte bara det, hur ska man komma ihåg allt
                        som borde skrivas ned?
                      </BlockExchangeRightPartText>
                    </BlockExchangeRightPart>
                  )}
                  {slider === 2 && (
                    <BlockExchangeLeftPart>
                      <BlockExchangeLeftPartTextSecondPage>
                        Det är i dessa lägen som Spridakunskap.se kommer som
                        räddaren i nöden med en redan uttänkt struktur, förslag
                        på ämnen att ta upp och förslag på bilagor att bifoga.
                      </BlockExchangeLeftPartTextSecondPage>
                      <BlockExchangeLeftPartTextSecondPage>
                        I verktyget kan du välja ut vilka frågor du vill svara
                        på och bygga din egen överlämning såsom du vill ha den.
                      </BlockExchangeLeftPartTextSecondPage>
                      <ImageWebPageExchangeBlockContanerWrapper>
                        <ImageWebPage src={Images.landingImageWeb} alt="" />
                      </ImageWebPageExchangeBlockContanerWrapper>
                    </BlockExchangeLeftPart>
                  )}
                  {slider === 3 && (
                    <BlockExchangeRightPart>
                      <BlockExchangeRightPartAvatars>
                        <LandingPenImage src={Images.landingPen} alt="" />
                        <LandingPenImageWrapper>
                          <LandingMeanImage src={Images.landingMean} alt="" />
                        </LandingPenImageWrapper>
                        <LandingPenImageLikeWrapper>
                          <LandingLikeImage
                            src={Images.landingImageLike}
                            alt=""
                          />
                        </LandingPenImageLikeWrapper>
                      </BlockExchangeRightPartAvatars>
                      <BlockExchangeRightPartTextSecondPage>
                        När du fyllt i frågorna och tagit upp de ämnen som du
                        själv valt omvandlas svaren till ett pedagogiskt
                        textdokument som du skickar över till din ersättare med
                        ett enkelt klick. Lätt som en plätt, eller hur!
                      </BlockExchangeRightPartTextSecondPage>
                      <BlockExchangeRightPartTextSecondPageDown>
                        KOM IHÅG! Kombinera gärna din digitala
                        kunskapsöverlämning med en muntlig introduktion för att
                        ge din ersättare bästa möjliga start i sitt uppdrag.
                      </BlockExchangeRightPartTextSecondPageDown>
                    </BlockExchangeRightPart>
                  )}
                </BlockExchangeWrapParts>
              </>
            )}

            {width >= parseInt(deviceSize.tablet.min) ? (
              <BlockExchangeButtons>
                <BlockExchangeButton1
                  onClick={() => setSlider(0)}
                  check={slider}
                />
                <BlockExchangeButton2
                  onClick={() => setSlider(1)}
                  check={slider}
                />
              </BlockExchangeButtons>
            ) : (
              <BlockExchangeButtons>
                <BlockExchangeButton1
                  onClick={() => setSlider(0)}
                  check={slider}
                />
                <BlockExchangeButton2
                  onClick={() => setSlider(1)}
                  check={slider}
                />
                <BlockExchangeButton3
                  onClick={() => setSlider(2)}
                  check={slider}
                />
                <BlockExchangeButton4
                  onClick={() => setSlider(3)}
                  check={slider}
                />
              </BlockExchangeButtons>
            )}
          </BlockExchangeContentWrapper>
        </BlockExchangeContainer>
        <PromoBlockSecondContaner id="link3">
          <PromoBlockSecondLeftPart>
            <ImagePeopleWithSkateWrapper>
              <ImagePeopleWithSkate
                src={Images.landingPeopleSkate}
                ref={link3}
                alt=""
              />
            </ImagePeopleWithSkateWrapper>
          </PromoBlockSecondLeftPart>
          <PromoBlockSecondContainer>
            <PromoBlockSecondContainerMainText>
              Vem verktyget är till för?
            </PromoBlockSecondContainerMainText>
            <PromoBlockText>
              Tanken var från början att verktyget skulle vara till för unga
              förtroendevalda personer i ideella ungdomsorganisationer. Men
              egentligen, varför ska verktyget begränsas och finnas till bara
              för en grupp?!
            </PromoBlockText>
            <PromoBlockText>
              Vi kan sammanfatta det så här; Verktyget är anpassat till unga
              förtroendevalda personer i ideella ungdomsorganisationer men om
              någon som inte tillhör denna grupp vill testa verktyget och tycker
              sig ha nytta av det så är det fritt fram!
            </PromoBlockText>
            <VemBlockPinkText>
              Verktyget är öppet för alla och gratis att använda!
            </VemBlockPinkText>
          </PromoBlockSecondContainer>
        </PromoBlockSecondContaner>
        <BlockWithArrowsContainer>
          <BlockWithArrow1>
            <BlockWithArrow1MainText>
              Verktyget är i första hand till för:
            </BlockWithArrow1MainText>
            <LongHorizontalArrowImageWrapper>
              {width >= parseInt(deviceSize.tablet.min) ? (
                <LandingArrowImage src={Images.landingArrow3} alt="" />
              ) : (
                <LandingArrowImage src={Images.landingArrow3Mobile} alt="" />
              )}
            </LongHorizontalArrowImageWrapper>
          </BlockWithArrow1>
          {width >= parseInt(deviceSize.tablet.min) ? (
            <>
              <BlockWithArrowBlockCard
                marginTop={-80}
                marginLeft={-300}
                width={"fit-content"}
              >
                <LandingGroupFaceWrapper>
                  <LandingGroupFace src={Images.landingGroupFace} alt="" />
                </LandingGroupFaceWrapper>
                <BlockWithArrowCard width={290}>
                  Unga förtroendevalda, oavsett verksamhet, organisation,
                  förbund och förening.
                </BlockWithArrowCard>
              </BlockWithArrowBlockCard>
              <BlockWithArrow2>
                <BlockWithArrows2MainText>
                  Verktyget passar även dig som:
                </BlockWithArrows2MainText>
                <LandingArrow4Wrapper>
                  <LandingArrow4 src={Images.landingArrow4} alt="" />
                </LandingArrow4Wrapper>
              </BlockWithArrow2>
              <BlockWithArrowBlockCard
                marginTop={50}
                marginLeft={-500}
                width={"fit-content"}
              >
                {/* <LandingEllipseChemWrapper>
<LandingEllipseChem src={Images.landingEllipseChem} alt="" />
</LandingEllipseChemWrapper> */}
                <LandingChemWrapper>
                  <LandingChem src={Images.landingChem} alt="" />
                </LandingChemWrapper>
                <BlockWithArrowCard width={290}>
                  Tillhör någon annan typ av organisation än en ideell
                  ungdomsorganisation.
                </BlockWithArrowCard>
              </BlockWithArrowBlockCard>
              {width >= parseInt(deviceSize.laptop.min) ? (
                <>
                  <BlockWithArrowBlockCard
                    marginTop={30}
                    marginLeft={70}
                    width={"fit-content"}
                  >
                    <LandingIconWokerWrapper>
                      <LandingIconWoker src={Images.landingIconWoker} alt="" />
                    </LandingIconWokerWrapper>
                    <BlockWithArrowCard width={290}>
                      Räknar dig till en yrkesgrupp.
                    </BlockWithArrowCard>
                  </BlockWithArrowBlockCard>
                  <BlockWithArrowBlockCard
                    marginTop={20}
                    marginLeft={620}
                    width={"fit-content"}
                  >
                    <LandingIconGroupWrapper>
                      <LandingIconGroup src={Images.landingIconGroup} alt="" />
                    </LandingIconGroupWrapper>
                    <BlockWithArrowCard width={290}>
                      Har ett eget företag.
                    </BlockWithArrowCard>
                  </BlockWithArrowBlockCard>
                  <BlockWithArrowBlockCard
                    marginTop={25}
                    marginLeft={70}
                    width={"fit-content"}
                  >
                    <LandingIconMarkWrapper>
                      <LandingIconMark src={Images.landingIconMark} alt="" />
                    </LandingIconMarkWrapper>
                    <BlockWithArrowCard width={290}>
                      {" "}
                      Är anställd någonstans.
                    </BlockWithArrowCard>
                  </BlockWithArrowBlockCard>
                </>
              ) : (
                <>
                  <BlockWithArrowBlockCard
                    marginTop={30}
                    marginLeft={50}
                    width={"fit-content"}
                  >
                    <LandingIconWokerWrapper>
                      <LandingIconWoker src={Images.landingIconWoker} alt="" />
                    </LandingIconWokerWrapper>
                    <BlockWithArrowCard width={290}>
                      Räknar dig till en yrkesgrupp.
                    </BlockWithArrowCard>
                  </BlockWithArrowBlockCard>
                  <BlockWithArrowBlockCard
                    marginTop={20}
                    marginLeft={530}
                    width={"fit-content"}
                  >
                    <LandingIconGroupWrapper>
                      <LandingIconGroup src={Images.landingIconGroup} alt="" />
                    </LandingIconGroupWrapper>
                    <BlockWithArrowCard width={290}>
                      Har ett eget företag.
                    </BlockWithArrowCard>
                  </BlockWithArrowBlockCard>
                  <BlockWithArrowBlockCard
                    marginTop={25}
                    marginLeft={50}
                    width={"fit-content"}
                  >
                    <LandingIconMarkWrapper>
                      <LandingIconMark src={Images.landingIconMark} alt="" />
                    </LandingIconMarkWrapper>
                    <BlockWithArrowCard width={290}>
                      {" "}
                      Är anställd någonstans.
                    </BlockWithArrowCard>
                  </BlockWithArrowBlockCard>
                </>
              )}
              <BlockWithArrowBlockCard
                marginTop={20}
                marginLeft={-500}
                width={"fit-content"}
              >
                <LandingIconQuestionWrapper>
                  <LandingIconQuestion
                    src={Images.landingIconQuestion}
                    alt=""
                  />
                </LandingIconQuestionWrapper>
                <BlockWithArrowCard width={290}>
                  Inte tillhör någon av ovanstående grupper men ändå är nyfiken
                  och tror dig ha nytta av det!
                </BlockWithArrowCard>
              </BlockWithArrowBlockCard>
            </>
          ) : (
            <>
              <BlockWithArrowBlockCard width={"fit-content"} marginTop={72}>
                <LandingGroupFaceWrapper>
                  <LandingGroupFace src={Images.landingGroupFace} alt="" />
                </LandingGroupFaceWrapper>
                <BlockWithArrowCard width={290}>
                  Unga förtroendevalda, oavsett verksamhet, organisation,
                  förbund och förening.
                </BlockWithArrowCard>
              </BlockWithArrowBlockCard>
              <BlockWithArrow2>
                <BlockWithArrows2MainText>
                  Verktyget passar även dig som:
                </BlockWithArrows2MainText>
                <LandingArrow4Wrapper>
                  <LandingArrow4 src={Images.landingArrow4Mobile} alt="" />
                </LandingArrow4Wrapper>
              </BlockWithArrow2>
              <BlockWithArrowBlockCard width={"fit-content"} marginTop={72}>
                {/* <LandingEllipseChemWrapper>
<LandingEllipseChem src={Images.landingEllipseChem} alt="" />
</LandingEllipseChemWrapper> */}
                <LandingChemWrapper>
                  <LandingChem src={Images.landingChem} alt="" />
                </LandingChemWrapper>
                <BlockWithArrowCard width={290}>
                  Tillhör någon annan typ av organisation än en ideell
                  ungdomsorganisation.
                </BlockWithArrowCard>
              </BlockWithArrowBlockCard>
              <BlockWithArrowBlockCard marginTop={72} width={"fit-content"}>
                <LandingIconWokerWrapper>
                  <LandingIconWoker src={Images.landingIconWoker} alt="" />
                </LandingIconWokerWrapper>
                <BlockWithArrowCard width={290}>
                  Räknar dig till en yrkesgrupp.
                </BlockWithArrowCard>
              </BlockWithArrowBlockCard>
              <BlockWithArrowBlockCard marginTop={72} width={"fit-content"}>
                <LandingIconGroupWrapper>
                  <LandingIconGroup src={Images.landingIconGroup} alt="" />
                </LandingIconGroupWrapper>
                <BlockWithArrowCard width={290}>
                  Har ett eget företag.
                </BlockWithArrowCard>
              </BlockWithArrowBlockCard>
              <BlockWithArrowBlockCard marginTop={72} width={"fit-content"}>
                <LandingIconMarkWrapper>
                  <LandingIconMark src={Images.landingIconMark} alt="" />
                </LandingIconMarkWrapper>
                <BlockWithArrowCard width={290}>
                  {" "}
                  Är anställd någonstans.
                </BlockWithArrowCard>
              </BlockWithArrowBlockCard>
              <BlockWithArrowBlockCard marginTop={72} width={"fit-content"}>
                <LandingIconQuestionWrapper>
                  <LandingIconQuestion
                    src={Images.landingIconQuestion}
                    alt=""
                  />
                </LandingIconQuestionWrapper>
                <BlockWithArrowCard width={290}>
                  Inte tillhör någon av ovanstående grupper men ändå är nyfiken
                  och tror dig ha nytta av det!
                </BlockWithArrowCard>
              </BlockWithArrowBlockCard>
            </>
          )}
        </BlockWithArrowsContainer>
        <TargetBlockContainer>
          <TargetBlockContentWrapper>
            <TargetBlockContentLine1>
              {width >= parseInt(deviceSize.tablet.min) ? (
                <LandingImageTargetWrapper>
                  <LandingImageTarget src={Images.landingImageTarget} alt="" />
                </LandingImageTargetWrapper>
              ) : null}
              <TargetBlockMainText2>
                <MainTextBlockTarget>
                  Rekommendera någon att använda Spridakunskap.se!
                </MainTextBlockTarget>
                <MainTextBlockTarget>
                  Om du själv ska påbörja ett nytt uppdrag kan du enkelt tipsa
                  personen som haft uppdraget innan dig om att göra en digital
                  kunskapsöverlämning.
                </MainTextBlockTarget>
              </TargetBlockMainText2>
              <LandingMeanWrapper>
                <LandingMean src={Images.landingMean} alt="" />
              </LandingMeanWrapper>
            </TargetBlockContentLine1>
            <TargetBlockContentLine2>
              {width >= parseInt(deviceSize.tablet.min) ? (
                <LandingImageLike2 src={Images.landingImageLike2} alt="" />
              ) : null}
              <TargetBlockBtnRekommendera
                onClick={() => history.push(routes.login)}
              >
                Rekommendera detta verktyg
              </TargetBlockBtnRekommendera>
              <TargetBlockContentLine2Text>
                Ju fler användare verktyget har desto bättre blir allas
                kunskapsöverlämningar!
              </TargetBlockContentLine2Text>
            </TargetBlockContentLine2>
          </TargetBlockContentWrapper>
        </TargetBlockContainer>
        <QuestionBlockContaner>
          {width >= parseInt(deviceSize.tablet.min) ? (
            <>
              <QuestionBlockLeftPart id={"link1"}>
                <QuestionBlockLeftPartMainText ref={link1}>
                  Varför är det så viktigt med kunskapsöverföring?
                </QuestionBlockLeftPartMainText>
                <QuestionServiceLeftPartText>
                  Att göra överlämningar kan vara enklare sagt än gjort men ack
                  så viktiga de är! Att förvärvade kunskaper, erfarenheter,
                  rutiner med mera stannar kvar i organisationen är guld värt.
                  Om dessa förvärvade guldklimpar lämnar organisationerna i takt
                  med att personer lämnar slutar det med organisationer som står
                  och stampar istället för att utvecklas.
                </QuestionServiceLeftPartText>
                <QuestionBlockLeftPartMainTextBottom>
                  Fördelar med en digital kunskapsöverföring:
                </QuestionBlockLeftPartMainTextBottom>
                <QuestionBlockQuestions>
                  <QuestionElement
                    text="Att fylla i allt som hör till din roll kan låta som ett hästjobb. Hur
ska du kunna sammanfatta allt du har gjort under din tid som
förtroendevald på ett och samma ställe? För att underlätta processen
är verktyget indelat i olika delar och varje del är valfri att fylla
i. Du kan även hoppa fram och tillbaka i verktyget och ta det i den
ordning som du själv föredrar. Skulle du vilja fylla i delar av
överlämningen idag och resten en annan dag kan du spara det du skrivit
i hittills och fortsätta med resten senare."
                    title={"Du lägger själv ambitionsnivån"}
                  />
                </QuestionBlockQuestions>
                <QuestionBlockQuestions>
                  <QuestionElement
                    text="Att vara förtroendevald i en ideell ungdomsorganisation är stort och medför ett stort ansvar. Oavsett om du är ny som förtroendevald eller om du tidigare haft en liknande roll så innebär alltid ett nytt förtroendeuppdrag nya rutiner och innefattar nya uppgifter. Även om organisationer, förbund och föreningar kan vara lika varandra har de i princip ändå alltid olika sätt att organisera sig på och drivas på. 

Vad du gjorde i en förtroendevaldroll i en organisation kan skilja sig mycket från vad den förtroendevalda rollen har för ansvar och uppgifter i en annan organisation."
                    title={"Du delar med dig av allt du lärt dig"}
                  />
                </QuestionBlockQuestions>
                <QuestionBlockQuestions>
                  <QuestionElement
                    text="Att göra överlämningar som beskriver sysslor, rutiner och tidigare arbetssätt syftar inte på att ge en bild av att nya sådana inte kan arbetas fram. Att beskriva hur tidigare arbetssätt fungerat, samt ge en bild av vilka sätt som fungerat mindre bra ger ett försprång för den som kommer in som ny. Likaså är det värdefullt för den som är ny få kännedom om organisationens historia, aktuella rutiner med mera och utifrån denna kännedom utveckla rollen och organisationen vidare."
                    title={
                      "Du ger en bild av befintliga rutiner och gör plats för nya arbetssätt "
                    }
                  />
                </QuestionBlockQuestions>
              </QuestionBlockLeftPart>
              <LandingPeopleQuestionsWrapper>
                <QuestionManImage src={Images.landingPeopleQuestions} alt="" />
              </LandingPeopleQuestionsWrapper>
            </>
          ) : (
            <QuestionBlockLeftPart>
              <QuestionBlockLeftPartMainText ref={link1}>
                Varför är det så viktigt med kunskapsöverföring?
              </QuestionBlockLeftPartMainText>
              <QuestionServiceLeftPartText>
                Att göra överlämningar kan vara enklare sagt än gjort men ack så
                viktiga de är! Att förvärvade kunskaper, erfarenheter, rutiner
                med mera stannar kvar i organisationen är guld värt. Om dessa
                förvärvade guldklimpar lämnar organisationerna i takt med att
                personer lämnar slutar det med organisationer som står och
                stampar istället för att utvecklas.
              </QuestionServiceLeftPartText>
              <LandingPeopleQuestionsWrapper>
                <QuestionManImage src={Images.landingPeopleQuestions} alt="" />
              </LandingPeopleQuestionsWrapper>
              <QuestionBlockLeftPartMainTextBottom>
                Fördelar med en digital kunskapsöverföring:
              </QuestionBlockLeftPartMainTextBottom>
              <QuestionBlockQuestions>
                <QuestionElement
                  text="Att fylla i allt som hör till din roll kan låta som ett hästjobb. Hur
ska du kunna sammanfatta allt du har gjort under din tid som
förtroendevald på ett och samma ställe? För att underlätta processen
är verktyget indelat i olika delar och varje del är valfri att fylla
i. Du kan även hoppa fram och tillbaka i verktyget och ta det i den
ordning som du själv föredrar. Skulle du vilja fylla i delar av
överlämningen idag och resten en annan dag kan du spara det du skrivit
i hittills och fortsätta med resten senare."
                  title={"Du lägger själv ambitionsnivån"}
                />
              </QuestionBlockQuestions>
              <QuestionBlockQuestions>
                <QuestionElement
                  text="Att vara förtroendevald i en ideell ungdomsorganisation är stort och medför ett stort ansvar. Oavsett om du är ny som förtroendevald eller om du tidigare haft en liknande roll så innebär alltid ett nytt förtroendeuppdrag nya rutiner och innefattar nya uppgifter. Även om organisationer, förbund och föreningar kan vara lika varandra har de i princip ändå alltid olika sätt att organisera sig på och drivas på. 
Vad du gjorde i en förtroendevaldroll i en organisation kan skilja sig mycket från vad den förtroendevalda rollen har för ansvar och uppgifter i en annan organisation."
                  title={"Du delar med dig av allt du lärt dig"}
                />
              </QuestionBlockQuestions>
              <QuestionBlockQuestions>
                <QuestionElement
                  text="Att göra överlämningar som beskriver sysslor, rutiner och tidigare arbetssätt syftar inte på att ge en bild av att nya sådana inte kan arbetas fram. Att beskriva hur tidigare arbetssätt fungerat, samt ge en bild av vilka sätt som fungerat mindre bra ger ett försprång för den som kommer in som ny. Likaså är det värdefullt för den som är ny få kännedom om organisationens historia, aktuella rutiner med mera och utifrån denna kännedom utveckla rollen och organisationen vidare."
                  title={
                    "Du ger en bild av befintliga rutiner och gör plats för nya arbetssätt "
                  }
                />
              </QuestionBlockQuestions>
            </QuestionBlockLeftPart>
          )}
        </QuestionBlockContaner>
        <ServicesBlock id="link2">
          <ServicesBlockTitle ref={link2}>OM OSS</ServicesBlockTitle>
          <ServicesBlockWrapParts>
            <ServicesBlockText>
              <ReadMoreElement />
            </ServicesBlockText>
            <LandingPeopleComputerWrapper>
              <ManWithComputerImage src={Images.landingPeopleComputer} alt="" />
            </LandingPeopleComputerWrapper>
            <ServicesBlockText>
              <BlockServiceMainText>
                Personliga, enkla och pedagogiska kunskapsöverlämningar
              </BlockServiceMainText>
              <BlockExchangeRightPartText>
                I ungdomsorganisationer sker skiften av förtroendevalda oftare
                än i andra organisationer, ofta med anledning av att unga väljer
                att gå vidare till nya uppdrag. När unga enbart stannar en
                begränsad tid i organisationer, förbund och föreningar finns
                risken att kunskap går förlorad i samband med att personerna
                lämnar. För att förhindra detta har vi skapat verktyget
                Spridakunskap.se där förtroendevalda kan göra personliga, enkla
                och pedagogiska överlämningar till sina ersättare.
              </BlockExchangeRightPartText>
              <PromoTextBeforeButton marginLeft={0}>
                Skapa konto och gör en överlämning
                <LandingArrow1Wrapper>
                  <LandingArrow1 src={Images.landingArrow1} alt="" />
                </LandingArrow1Wrapper>
              </PromoTextBeforeButton>
              <PromoButtonStartWrapper>
                <PromoButtonStartServiceBlock
                  onClick={() => history.push(routes.signup)}
                >
                  Skapa konto
                </PromoButtonStartServiceBlock>
              </PromoButtonStartWrapper>
            </ServicesBlockText>
          </ServicesBlockWrapParts>
        </ServicesBlock>
        <AchievementsBlock>
          {width >= parseInt(deviceSize.laptopM.min) ? (
            <>
              <BlockCardAchievements>
                <LandingIcon65Wrapper>
                  <LandingIcon65 src={Images.landingIcon65} alt="" />
                </LandingIcon65Wrapper>
                <BlockWithCardAchievements>
                  Vi är en organisation som har funnits i över 65 år
                </BlockWithCardAchievements>
              </BlockCardAchievements>
              <BlockCardAchievements>
                <LandingIconMansWrapper>
                  <LandingIconMans src={Images.landingIconMans} alt="" />
                </LandingIconMansWrapper>
                <BlockWithCardAchievements>
                  Vi har omkring 10 000 medlemmar runt om i landet
                </BlockWithCardAchievements>
              </BlockCardAchievements>
              <BlockCardAchievements>
                <LandingIconGirlWrapper>
                  <LandingIconGirl src={Images.landingIconGirl} alt="" />
                </LandingIconGirlWrapper>
                <BlockWithCardAchievements>
                  Vi riktar oss främst till unga personer i åldrarna 13-25 år
                </BlockWithCardAchievements>
              </BlockCardAchievements>
            </>
          ) : (
            <>
              <BlockCardAchievements>
                <LandingIcon65Wrapper>
                  <LandingIcon65 src={Images.landingIcon65} alt="" />
                </LandingIcon65Wrapper>
                <BlockWithCardAchievements width={290}>
                  Vi är en organisation som har funnits i över 65 år
                </BlockWithCardAchievements>
              </BlockCardAchievements>
              <BlockCardAchievements>
                <LandingIconMansWrapper>
                  <LandingIconMans src={Images.landingIconMans} alt="" />
                </LandingIconMansWrapper>
                <BlockWithCardAchievements width={290}>
                  Vi har omkring 10 000 medlemmar runt om i landet
                </BlockWithCardAchievements>
              </BlockCardAchievements>
              <BlockCardAchievements>
                <LandingIconGirlWrapper>
                  <LandingIconGirl src={Images.landingIconGirl} alt="" />
                </LandingIconGirlWrapper>
                <BlockWithCardAchievements width={290}>
                  Vi riktar oss främst till unga personer i åldrarna 13-25 år
                </BlockWithCardAchievements>
              </BlockCardAchievements>
            </>
          )}
        </AchievementsBlock>
        <BlockAchievementsMainText>
          Våra medlemmar ägnar sig åt föreningsverksamhet inom alla möjliga
          olika områden.
        </BlockAchievementsMainText>
        <BlockBeforeIdeasBlock>
          <PromoTextBeforeButton marginLeft={-3}>
            <PromoTextBeforeButtonText>
              Besök gärna Vi Ungas hemsida för att ta reda på mer!
            </PromoTextBeforeButtonText>
            {width >= parseInt(deviceSize.tablet.min) ? (
              <LandingArrow1PromoBlockWrapper>
                <PromoLandingArrow1 src={Images.landingArrow1} alt="" />
              </LandingArrow1PromoBlockWrapper>
            ) : null}
          </PromoTextBeforeButton>
          <BeforeIdeasBlockBtnRekommendera
            onClick={() => window.open("http://viunga.se", "_blank")}
          >
            Viunga.se
          </BeforeIdeasBlockBtnRekommendera>
        </BlockBeforeIdeasBlock>
        <IdeasBlock>
          <BlockIdeasMainText>Idén</BlockIdeasMainText>
          <IdeasBlockContentWrapper>
            {width >= parseInt(deviceSize.tablet.min) ? (
              <LandingImageWifiWrapper>
                <LandingImageWifi src={Images.landingImageWifi} alt="" />
              </LandingImageWifiWrapper>
            ) : null}
            <IdeasConteinerText>
              Idén med att ta fram Spridakunskap.se kom i samband med att vi i
              det egna förbundet själva ibland upplevde att förtroendevaldas
              kunskaper, erfarenheter och fungerande rutiner till viss del gick
              förlorade i samband med att förtroendevalda personer lämnade
              organisationen. Detta gjorde att utvecklingen av förbundet till
              viss del hämmades. Vi hade länge sett ett återkommande mönster i
              denna problematik och tyckte att det var dags att göra något åt
              saken.
            </IdeasConteinerText>
            <LandingImageLightBulbWrapper>
              <LandingImageLightBulb
                src={Images.landingImageLightBulb}
                alt=""
              />
            </LandingImageLightBulbWrapper>
            <IdeasConteinerText>
              Det var då vi kom på idén med att skapa en digital
              kunskapsöverföring. I förarbetet till projektet tog vi reda på om
              andra organisationer upplever samma problematik vid skiften av
              förtroendevalda. Det framkom då att vi inte var ensamma om denna
              upplevelse utan att många andra organisationer noterat liknande
              problem. Att skapa ett verktyg som alla ideella
              ungdomsorganisationer kunde använda var då en idé som växte fram.
            </IdeasConteinerText>
            {width >= parseInt(deviceSize.tablet.min) ? (
              <LandingImageCodeWrapper>
                <LandingImageCode src={Images.landingImageCode} alt="" />
              </LandingImageCodeWrapper>
            ) : null}
          </IdeasBlockContentWrapper>
        </IdeasBlock>
        <FinanceBlockContainer>
          {width >= parseInt(deviceSize.tablet.min) ? (
            <>
              <LandingFinanceLogoWrapper>
                <LandingFinanceLogo
                  src={Images.landingFinanceLogo}
                  onClick={() => window.open("https://arvsfonden.se", "_blank")}
                  alt=""
                />
              </LandingFinanceLogoWrapper>
              <FinanceBlockTextWrapper>
                <BlockFinanceMainText>
                  Finansiär Allmänna Arvsfonden
                </BlockFinanceMainText>
                <BlockWithArrowsText>
                  Med hjälp av medel från Allmänna Arvsfonden fick vi möjlighet
                  att ta fram detta verktyg. Vi är glada över att kunna bidra
                  till enklare kunskapsöverföringar för förtroendevalda och
                  därmed bidra till än mer välfungerade ideella
                  ungdomsorganisationer runt om i landet.
                </BlockWithArrowsText>
                <BlockFinanceText>
                  Verktyget är anpassat och skapat för att fungera för
                  förtroendevalda i ideella ungdomsorganisationer men det är
                  samtidigt öppet för vem som helst att använda, såväl
                  förtroendevalda i andra typer av organisationer, som
                  yrkesgrupper och allmänheten.
                </BlockFinanceText>
              </FinanceBlockTextWrapper>
            </>
          ) : (
            <>
              <FinanceBlockTextWrapper>
                <BlockFinanceMainText>
                  Finansiär Allmänna Arvsfonden
                </BlockFinanceMainText>
                <LandingFinanceLogoWrapper>
                  <LandingFinanceLogo
                    src={Images.landingFinanceLogo}
                    onClick={() =>
                      window.open("https://arvsfonden.se", "_blank")
                    }
                    alt=""
                  />
                </LandingFinanceLogoWrapper>
                <BlockWithArrowsText>
                  Med hjälp av medel från Allmänna Arvsfonden fick vi möjlighet
                  att ta fram detta verktyg. Vi är glada över att kunna bidra
                  till enklare kunskapsöverföringar för förtroendevalda och
                  därmed bidra till än mer välfungerade ideella
                  ungdomsorganisationer runt om i landet.
                </BlockWithArrowsText>
                <BlockFinanceText>
                  Verktyget är anpassat och skapat för att fungera för
                  förtroendevalda i ideella ungdomsorganisationer men det är
                  samtidigt öppet för vem som helst att använda, såväl
                  förtroendevalda i andra typer av organisationer, som
                  yrkesgrupper och allmänheten.
                </BlockFinanceText>
              </FinanceBlockTextWrapper>
            </>
          )}
        </FinanceBlockContainer>
        <FaqTitle>FAQ</FaqTitle>
        <FaqItemsBlock>
          {(isFull ? data.rows : faqItems).map((item, index) => {
            return (
              <FaqItem
                key={`FaqItem${index}`}
                title={item.title}
                text={item.content}
              />
            );
          })}
        </FaqItemsBlock>
        <BlockButtonFooter>
          <HeadetButtonLog
            onClick={() => setIsFull(prev => !prev)}
            marginLeft={1}
          >
            {isFull ? "Mindre" : "Visa mer"}
          </HeadetButtonLog>
        </BlockButtonFooter>
        <FooterLine />
        <FooterContactsBlock>
          {width >= parseInt(deviceSize.tablet.min) ? (
            <FooterBlockLinks>
              <FooterLink onClick={() => scrollToElement(link1)}>
                Varför kunskapsöverföring?
              </FooterLink>
              <FooterLink onClick={() => scrollToElement(link3, "a")}>
                För vem?
              </FooterLink>
              <FooterLink onClick={() => scrollToElement(link2)}>
                Om oss
              </FooterLink>
            </FooterBlockLinks>
          ) : (
            <FooterBlockLinks>
              <FooterLink>E-post</FooterLink>
              <FooterLink>info@viunga.se</FooterLink>
              <FooterLink>Postadress</FooterLink>
              <FooterLink>Förbundet Vi Unga c/o United Spaces</FooterLink>
              <FooterLink>Box 190</FooterLink>
              <FooterLink>101 23 Stockholm</FooterLink>
            </FooterBlockLinks>
          )}
          <LandingFooterlogo
            src={Images.landingFooterlogo}
            onClick={() => scrollToElement(0)}
            alt=""
          />
          <SocialBlock>
            <SocialBlockText>Följ oss i sociala medier</SocialBlockText>
            <SocialBlockTextBlock>
              <a
                href="https://www.instagram.com/forbundetviunga/"
                target="blank"
              >
                <LandingInsta src={Images.landingInsta} alt="" />
              </a>
              <a href="https://www.facebook.com/pg/Digitalt-verktyg-f%C3%B6r-kunskaps%C3%B6verf%C3%B6ring-110620453810556/">
                <LandingFacebook src={Images.landingFacebook} alt="" />
              </a>
              <a
                href="https://www.youtube.com/user/ForbundetViUnga"
                target="blank"
              >
                <LandingYoutube src={Images.landingYoutube} alt="" />
              </a>
            </SocialBlockTextBlock>
            <SocialBlockTextBlock>
              <LandingVector src={Images.landingVector} alt="" />
              <SocialBlockTextBefSoc>
                Alla rättigheter förbehållna
              </SocialBlockTextBefSoc>
            </SocialBlockTextBlock>
          </SocialBlock>
        </FooterContactsBlock>
        <DesignByBlock></DesignByBlock>
      </Wrapper>
    </>
  );
};
