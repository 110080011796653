import React from "react";
import { FormikProps } from "formik";

import { Form, InputLabel } from "./RecommendToolForm.style";
import { Input, Textarea } from "../../../components/ui";
import { useTranslation } from "../../../hooks";
import { PromoButtonStart } from "../../../pages/Landing/Landing.style";

interface RecommendToolFormValues {
  email: string;
  personal_message: string;
  message: string;
}

interface RecommendToolFormProps {
  formik: FormikProps<RecommendToolFormValues>;
  loading: boolean;
}

export const RecommendToolForm = ({
  formik: {
    values,
    handleSubmit,
    handleChange,
    isValid,
    touched,
    errors,
    handleBlur
  },
  loading
}: RecommendToolFormProps) => {
  const translate = useTranslation();

  return (
    <Form>
      <InputLabel>{translate(({ inputs }) => inputs.email.label)}</InputLabel>
      <Input
        value={values.email}
        onChange={handleChange}
        name="email"
        error={touched.email ? errors.email : undefined}
        onBlur={handleBlur}
        borderColor={"#6bc9db"}
        borderRadius={8}
      />

      <InputLabel>{translate(({ inputs }) => inputs.message.label)}</InputLabel>
      <Textarea
        value={values.message}
        onChange={handleChange}
        name="message"
        label={translate(({ inputs }) => inputs.message.label)}
        error={touched.message ? errors.message : undefined}
        onBlur={handleBlur}
      />
      <PromoButtonStart
        disabled={!isValid}
        onClick={handleSubmit}
        marginTop={20}
        width={300}
        paddingLeft={30}
        paddingRight={30}
        paddingTop={25}
        paddingBottom={25}
      >
        {translate(({ buttons }) => buttons.sendRecommendation)}
      </PromoButtonStart>
    </Form>
  );
};
