import styled from "styled-components";
import { deviceRange } from "../../consts";
import { Fonts } from "../../environment/theme/Fonts";

export const ModalContentWrapper = styled.div`
  position: absolute;
  width: 945px;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;

  @media ${deviceRange.laptop} {
    width: 770px;
  }

  @media ${deviceRange.tablet} {
    width: 690px;
  }

  @media ${deviceRange.mobile} {
    width: 90%;
    height: fit-content;
    max-height: 90%;
    top: 50%;
  }
`;

export const BlackoutBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const BackgroundModalContent = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  margin-right: 2.4rem;
  margin: 0 !important;

  @media ${deviceRange.mobile} {
    z-index: 101;
  }
`;

export const TitleWrapper = styled.div`
  max-width: 669px;
  display: flex;
  align-self: center;
  margin-top: 35px;

  @media ${deviceRange.laptop} {
    max-width: 445px;
  }

  @media ${deviceRange.tablet} {
    max-width: 425px;
  }

  @media ${deviceRange.mobile} {
    margin-top: 50px;
    justify-content: center;
  }
`;

export const SecondTitleTextCard = styled.h4`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #008c82;

  @media ${deviceRange.mobile} {
    width: 80%;
  }
`;

export const SecondTextCard = styled.p`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #425262;
`;

export const ContentBlock = styled.div``;

export const LeftBlockContent = styled.div`
  width: 50%;
  margin-left: 20px;
`;

export const ModalCloseButtonWrapper = styled.div`
  position: absolute;
  right: 0px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

export const ImageButtonClose = styled.img`
  width: 40px;
  height: 40px;
`;
