import React from "react";
import { FormikProps } from "formik";
import { useTranslation, useNavigation, useViewport } from "../../../hooks";
import {
  Form,
  Input,
  FormInfoBlock,
  FormInfoText,
  NavLink,
  LeftBlockForm,
  RightBlockForm,
  LoginFormWrapper,
  NavLinkForgotPassword,
  ForgotPasswordWrapper,
  CreateAccountButton,
  FormMobile,
  LoginMainText,
  LoginInput,
  ButtonLogin
} from "./LoginForm.style";
import {
  PromoButtonStart,
  PromoBlockMainText
} from "../../../pages/Landing/Landing.style";
import { deviceSize } from "../../../consts";
interface LoginFormValues {
  email: string;
  password: string;
}
interface LoginScreenViewProps {
  formik: FormikProps<LoginFormValues>;
  loading: boolean;
}

export function LoginFormMobile({
  formik: { values, handleChange, handleSubmit, errors, touched, handleBlur }
}: LoginScreenViewProps) {
  const translate = useTranslation();
  const { routes, history } = useNavigation();
  const width = useViewport();
  return (
    <LoginFormWrapper>
      <LoginMainText fontSize={28}>
        {translate(({ authNav }) => authNav.logIn)}
      </LoginMainText>
      <FormMobile onSubmit={handleSubmit}>
        <LeftBlockForm>
          <LoginInput
            login={true}
            placeholder={translate(({ inputs }) => inputs.email.placeholder)}
            name="email"
            onChange={handleChange}
            value={values.email}
            error={touched.email ? errors.email : undefined}
            borderAndShadow={true}
            padding={"18px 3.5vw"}
          />
          <LoginInput
            password
            placeholder={translate(
              ({ inputs }) => inputs.password.enterPassword
            )}
            name="password"
            onChange={handleChange}
            value={values.password}
            error={touched.password ? errors.password : undefined}
            onBlur={handleBlur}
            borderAndShadow={true}
            padding={"18px 3.5vw"}
          />
          <ButtonLogin
            onClick={handleSubmit}
            marginTop={30}
            paddingTop={20}
            paddingBottom={20}
            shadowLeft={3}
            shadowTop={4}
            paddingLeft={1}
            paddingRight={1}
          >
            {translate(({ authNav }) => authNav.logIn)}
          </ButtonLogin>
          <NavLinkForgotPassword to="/glömt-lösenord">
            Glömt ditt lösenord ?
          </NavLinkForgotPassword>

          <CreateAccountButton onClick={() => history.push(routes.signup)}>
            {translate(({ authNav }) => authNav.createAccount)}
          </CreateAccountButton>
          <ForgotPasswordWrapper>
            <FormInfoBlock>
              <FormInfoText>
                {translate(({ loginPage }) => loginPage.termsText)}
                &nbsp;
                <NavLink to="/villkor">Terms and Conditions</NavLink>
                &nbsp;{translate(({ loginPage }) => loginPage.and)}&nbsp;
                <NavLink to="/privat-policy">Privacy Policy.</NavLink>
              </FormInfoText>
            </FormInfoBlock>
          </ForgotPasswordWrapper>
        </LeftBlockForm>
      </FormMobile>
    </LoginFormWrapper>
  );
}
