import styled from "styled-components";

import {
  PrimaryButton as BasePrimaryButton,
  LightButton as BaseLightButton,
  Badge as BaseBadge,
  Icon
} from "../ui";
import { Colors, Fonts } from "../../environment";
import { deviceRange } from "../../consts";

interface PageWrapperProps {
  isMacintosh?: boolean;
}

export const PageWrapper = styled.div<PageWrapperProps>`
  width: 103%;
  position: relative;
  padding-left: 100px;
  background: white;
  margin-left: -20px;
  padding-top: ${props => (props.isMacintosh ? "0px" : "50px")};
  padding-right: 140px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @media ${deviceRange.tablet} {
    padding-right: 100px;
  }

  @media ${deviceRange.mobile} {
    padding-left: 0px;
    margin-left: 0px;
    padding-right: 0px;
    width: 100%;
    overflow-y: scroll;
  }
`;

export const PageTopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.4rem 0rem;
`;

export const PageTopBarItem = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownIcon = styled(Icon)`
  margin-right: 1.2rem;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  height: 100%;
  max-height: 510px;
  border-radius: 0px 25px 25px 25px;
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  box-shadow: 4px 4px 25px rgb(107 201 219 / 25%);

  @media ${deviceRange.mobile} {
    height: 100%;
  }
`;

export const PrimaryButton = styled(BasePrimaryButton)`
  margin-left: 0.8rem;
  margin-right: 2.6rem;
`;

export const LightButton = styled(BaseLightButton)`
  margin-left: 0.8rem;
`;

export const UserCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  height: fit-content;
  padding: 2.4rem 0rem;
`;

export const UserCardAvatarBlock = styled.div``;

export const UserCardInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const UserCardInfoTitle = styled.h1`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 18px;
  color: #425262;
  margin-left: 20px;

  @media ${deviceRange.mobile} {
    font-size: 18px;
  }
`;

export const UserCardInfoText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #425262;
  margin-bottom: 0.5rem;
  font-family: ${Fonts.landingOpenSansRegular};

  @media ${deviceRange.mobile} {
    font-size: 12px;
  }
`;

export const PageNavContainer = styled.ul`
  display: flex;
  height: 100%;
`;

export const PageNavContent = styled.div`
  width: 100%;
  padding: 5rem 0;
  display: flex;
  justify-content: flex-start;
  height: 100%;

  @media ${deviceRange.mobile} {
    padding: 0px;
  }
`;

interface PageNavItemProps {
  active: boolean;
}

export const PageNavItem = styled.li<PageNavItemProps>`
  float: left;
  list-style: none;
  font-family: ${Fonts.heading};
  font-size: 1.4rem;
  font-weight: 500;
  color: ${props =>
    props.active ? Colors.black[100] : Colors.typoPlaceholder};
  padding: 1.95rem 2.45rem;
  cursor: pointer;
  border-bottom: 0.1rem solid
    ${props => (props.active ? Colors.blue[100] : Colors.transparent)};

  :hover {
    color: ${Colors.black[100]};
  }
`;

export const Badge = styled(BaseBadge)`
  margin-bottom: 0.5rem;
`;

export const UserCardBlock = styled.div`
  border: none;
  padding: 20px;
  border-radius: 25px;
  background: #b5e4ed;
  box-shadow: 5px 4px 5px rgba(197, 207, 209, 1);

  @media ${deviceRange.mobile} {
    width: 87%;
    margin-left: 15px;
    max-width: fit-content;
  }
`;
export const BlockNavButtons = styled.div`
  display: flex;
  height: 54px;
  overflow: hidden;
  margin-bottom: -31px;
  padding-top: 10px;
  padding-left: 3px;
  margin-left: -3px;
  width: 100%;
  max-width: 650px;
  z-index: 100;

  @media ${deviceRange.mobile} {
    max-width: none;
    width: 96%;
    z-index: 99;
  }
`;
interface NavButton {
  active?: number;
}

export const NavButton = styled.button<NavButton>`
  outline: 0;
  width: 200px;
  height: 100px;
  border-radius: 25px;
  border: none;
  box-shadow: 10px 4px 25px rgb(107 201 219 / 25%);
  cursor: pointer;
  background: white;
  color: #6bc9db;
  @media ${deviceRange.tablet} {
    width: 170px;
  }

  @media ${deviceRange.mobile} {
    width: 50%;
  }
`;

export const NavButton1 = styled(NavButton)`
  background: ${props => props.active === 0 && "#6bc9db"};
  cursor: pointer;
  color: ${props => props.active === 0 && "white"};
`;

export const NavButton2 = styled(NavButton)`
  background: ${props => props.active === 1 && "#6bc9db"};
  color: ${props => props.active === 1 && "white"};
`;

export const NavButton3 = styled(NavButton)`
  background: ${props => props.active === 2 && "#6bc9db"};
  color: ${props => props.active === 2 && "white"};
`;

export const NavButtonText = styled.p`
  margin-top: -36px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  font-family: ${Fonts.landingOpenSansRegular};

  @media ${deviceRange.mobile} {
    font-size: 14px;
  }
`;
export const UserCardBlockContent = styled.div`
  display: flex;
  align-items: center;
`;
export const ContactUsBlock = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #425262;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${deviceRange.mobile} {
    position: relative;
    align-items: flex-end;
    display: none;
  }
`;
