import React, { useState } from "react";
import {
  ButtonPDF,
  ButtonStep,
  GoogleButton,
  Instruction
} from "./BaseButtonModal";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  Highlighter,
  ContentBlockWrapper
} from "./Modals.style";

export const ModalContent2 = () => {
  const [step, setStep] = useState<number>(0);
  const changeStep = (id: number) => setStep(id);

  return (
    <>
      {step === 0 && (
        <ContentBlockWrapper>
          <ContentBlock>
            <LeftBlockContent>
              <TextTitleWrapper>
                <TextTitle>För vem:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>Processledare, överlämnare</SecondText>
              </TextWrapper>
              <TextTitleWrapper>
                <TextTitle>Hur dokumentet kan användas:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>
                  Använd mallen i samband med att ni tar fram en
                  överlämningsrutin och gärna som en bilaga till{" "}
                  <Highlighter>
                    En steg-för steg-metod på hur en muntlig överlämningsrutin
                    kan tas fram.
                  </Highlighter>{" "}
                  I mallen skriver ni ner vilka ämnen er organisation ska ha med
                  i en muntlig överlämningsrutin.
                </SecondText>
              </TextWrapper>
            </LeftBlockContent>
            <RightBlockContent>
              <TextTitleWrapper>
                <TextTitle>Beskrivning:</TextTitle>
              </TextTitleWrapper>
              <TextWrapper>
                <SecondText>
                  Öppna dokumentet i Google Docs och fyll i mallen med de ämnen
                  som er organisation vill ha med i en muntlig
                  överlämningsrutin.
                </SecondText>
              </TextWrapper>
            </RightBlockContent>
          </ContentBlock>
        </ContentBlockWrapper>
      )}
      {step === 1 && <Instruction />}
      <FooterModal notAliginSelf={step ? 0 : 1}>
        {step === 0 && (
          <>
            <LeftBlockContent>
              <ButtonStep changeStep={changeStep} />
            </LeftBlockContent>
            <RightBlockContent>
              <ButtonPDF
                downLink={
                  "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Mall checklista överlämning ifyllbar PDF.pdf"
                }
              />
            </RightBlockContent>
          </>
        )}
        {step === 1 && (
          <GoogleButton
            downLink={
              "https://docs.google.com/document/d/1oo8gKpVhYBUIn_PdjSHykwukIqo9sBp5_aqfpDdbhqY/edit?usp=sharing"
            }
          />
        )}
      </FooterModal>
    </>
  );
};
