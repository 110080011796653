import React from "react";

import { Icons } from "../../../environment";

interface Props {
  name: string;
  color?: string;
  isActive?: boolean;
  iconWidth?: number;
  iconHeight?: number;
}

export function Icon({
  name,
  color,
  isActive,
  iconWidth = 24,
  iconHeight = 24
}: Props) {
  let IconView: any;

  switch (name) {
    case "Menu":
      IconView = Icons.Menu;
      break;
    case "ArrowLeft":
      IconView = Icons.ArrowLeft;
      break;
    case "ArrowRight":
      IconView = Icons.ArrowRight;
      break;
    case "SaveFloppy":
      IconView = Icons.SaveFloppy;
      break;
    case "PaperAirplane":
      IconView = Icons.PaperAirplane;
      break;
    case "EpPlus":
      IconView = Icons.EpPlus;
      break;

    case "EpSearch":
      IconView = Icons.EpSearch;
      break;

    case "EpMore":
      IconView = Icons.EpMore;
      break;
    case "EpChevronDown":
      IconView = Icons.EpChevronDown;
      break;
    case "EpChevronUp":
      IconView = Icons.EpChevronUp;
      break;

    case "EpEdit":
      IconView = Icons.EpEdit;
      break;

    case "EpMail":
      IconView = Icons.EpMail;
      break;
    case "EpTimes":
      IconView = Icons.EpTimes;
      break;

    case "EpMembers":
      IconView = Icons.EpMembers;
      break;

    case "EpDocument":
      IconView = Icons.EpDocument;
      break;
    case "EpDoc":
      IconView = Icons.EpDoc;
      break;

    case "EpPolls":
      IconView = Icons.EpPolls;
      break;
    case "EpSettings":
      IconView = Icons.EpSettings;
      break;
    case "EpEye":
      IconView = Icons.EpEye;
      break;
    case "EpEyeSlash":
      IconView = Icons.EpEyeSlash;
      break;
    case "EpArrowUp":
      IconView = Icons.EpArrowUp;
      break;
    case "EpArrowDown":
      IconView = Icons.EpArrowDown;
      break;
    case "EpArrowRight":
      IconView = Icons.EpArrowRight;
      break;
    case "EpArrowLeft":
      IconView = Icons.EpArrowLeft;
      break;
    case "EpTrash":
      IconView = Icons.EpTrash;
      break;
    case "EpCheck":
      IconView = Icons.EpCheck;
      break;
    case "EpCheckRound":
      IconView = Icons.EpCheckRound;
      break;
    case "EpInfo":
      IconView = Icons.EpInfo;
      break;
    case "EpExciamation":
      IconView = Icons.EpExciamation;
      break;
    case "EpCSVIcon":
      IconView = Icons.EpCSVIcon;
      break;
    case "EpUploadFile":
      IconView = Icons.EpUploadFile;
      break;
    case "EpNewDocument":
      IconView = Icons.EpNewDocument;
      break;
    case "EpPage":
      IconView = Icons.EpPage;
      break;
    case "EpFolder":
      IconView = Icons.EpFolder;
      break;
    case "EpFolderDefault":
      IconView = Icons.EpFolderDefault;
      break;
    case "EpMoveFolder":
      IconView = Icons.EpMoveFolder;
      break;
    case "EpEvent":
      IconView = Icons.EpEvent;
      break;
    case "EpPollFilled":
      IconView = Icons.EpPollFilled;
      break;
    case "EpPreferences":
      IconView = Icons.EpPreferences;
      break;
    case "EpZoomIn":
      IconView = Icons.EpZoomIn;
      break;
    case "EpZoomOut":
      IconView = Icons.EpZoomOut;
      break;
    case "EpHand":
      IconView = Icons.EpHand;
      break;
    case "EpTextTool":
      IconView = Icons.EpTextTool;
      break;
    case "EpText":
      IconView = Icons.EpText;
      break;
    case "EpCornerDown":
      IconView = Icons.EpCornerDown;
      break;
    case "EpCornerRight":
      IconView = Icons.EpCornerRight;
      break;
    case "EpClock":
      IconView = Icons.EpClock;
      break;
    case "EpClockArrow":
      IconView = Icons.EpClockArrow;
      break;
    case "EpAttachment":
      IconView = Icons.EpAttachment;
      break;
    case "EpOpen":
      IconView = Icons.EpOpen;
      break;
    case "EpDevice":
      IconView = Icons.EpDevice;
      break;
    case "EpBell":
      IconView = Icons.EpBell;
      break;
    case "EpExternalLink":
      IconView = Icons.EpExternalLink;
      break;
    case "EpStatus":
      IconView = Icons.EpStatus;
      break;
    case "EpRelation":
      IconView = Icons.EpRelation;
      break;
    case "EpSubItem":
      IconView = Icons.EpSubItem;
      break;
    case "EpDragHandle":
      IconView = Icons.EpDragHandle;
      break;
    case "EpAvatarIcon":
      IconView = Icons.EpAvatarIcon;
      break;
    case "LogOut":
      IconView = Icons.LogOut;
      break;
    case "Profile":
      IconView = Icons.Profile;
      break;
    case "Handover":
      IconView = Icons.Handover;
      break;
    case "ShareSend":
      IconView = Icons.ShareSend;
      break;
    case "ChevronDown":
      IconView = Icons.EpChevronDown;
      break;
    case "ChevronUp":
      IconView = Icons.EpChevronUp;
      break;
    case "Star":
      IconView = Icons.Star;
      break;
    default:
      IconView = Icons.EpMore;
  }

  return (
    <IconView
      color={color}
      isActive={isActive}
      height={iconHeight}
      width={iconWidth}
      onDoubleClick={(e: any) => e.preventDefault}
    />
  );
}
