import styled from "styled-components";
import { deviceRange } from "../../consts";
import { Fonts, Colors } from "../../environment";

export const PageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: white;

  @media ${deviceRange.mobile} {
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
  }
`;

export const PageMainContent = styled.main`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media ${deviceRange.mobile} {
    flex-direction: column-reverse;
  }
`;
export const BackgroundBlur2 = styled.div`
  position: absolute;
  left: 30.31%;
  right: 25.62%;
  top: 76.01%;
  bottom: 7.28%;

  background: rgba(204, 0, 160, 0.65);
  filter: blur(150px);
`;

export const BackgroundBlur = styled.div`
  position: absolute;
  left: 17.19%;
  right: 36.88%;
  top: 66.71%;
  bottom: 15.87%;

  background: #6bc9db;
  filter: blur(150px);
`;
export const LoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  @media ${deviceRange.tablet} {
    flex-direction: column-reverse;
  }

  @media ${deviceRange.mobile} {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

export const LandingLoginPic = styled.img`
  width: 48vw;
  height: 48vw;
  position: relative;
  left: -6vw;
  top: -2vw;
}
  @media ${deviceRange.tablet} {
    width: 70vw;
    height: 70vw;
    left: -5vw;
    top: 5vw;
  }
  @media ${deviceRange.mobile} {
    max-width: 370px;
    max-height: 370px;
    left: 0;
    top: 0;
  }
`;

export const LoginLeftBlock = styled.div`
  display: flex;
  width: 50%;

  @media ${deviceRange.tablet} {
    margin-top: -139px;
    width: fit-content;
    mask-image: radial-gradient(rgb(0 0 0) 50%, rgba(0, 0, 0, 0) 70%);
    background: radial-gradient(rgb(255, 255, 255) 60%, rgb(242, 243, 245) 70%);
  }

  @media ${deviceRange.mobile} {
    width: 100vw;
    justify-content: center;
  }
`;

export const LoginLeftBlockTitle = styled.h1`
  font-size: 3.2rem;
  font-weight: 800;
  font-family: ${Fonts.title};
  color: ${Colors.black[100]};
  margin-bottom: 85px;
  letter-spacing: 0.3px;
`;

export const LoginLeftBlockImage = styled.img``;

export const LoginRightBlock = styled.div``;
