import styled from "styled-components";

interface ContainerProps {
  isLoading: boolean;
}

export const Container = styled.div`
  display: ${({ isLoading }: ContainerProps) => (isLoading ? "none" : "flex")};
  flex-direction: column-reverse;
  width: 100%;
  padding: 1.4rem;
  transition: none;
`;

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > div:not(:last-child) {
    margin-right: 5px;
  }
`;
