import styled from "styled-components";

import { Fonts } from "../../environment";

export const PageContainer = styled.div`
  width: 103%;
  margin-left: -30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 150px;
  background: white;
`;

export const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 650px;
  padding: 0 2rem;
`;

export const ThankYouTitle = styled.h1`
  width: 100%;
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  line-height: 52px;
  color: #6bc9db;
  margin-bottom: 85px;
`;

export const ThankYouSign = styled.p`
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  font-family: ${Fonts.landingConcertOneRegular};
  color: #425262;
`;
