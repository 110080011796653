import React from "react";
import {
  ContentCard,
  ImageCard,
  ImageWrapper,
  SecondTitleTextCard,
  TextWrapper,
  SecondTextCard,
  ButtonWrapper,
  ButtonCard,
  SecondTextWrapper
} from "./Card.style";

interface CardProps {
  imageCard: string;
  titleCard: string;
  textCard: string;
  buttonTextCard: string;
  id: number;
  contentNumber: number;
  buttonClick: (id: number) => void;
}

export function Card({
  imageCard,
  titleCard,
  textCard,
  buttonTextCard,
  id,
  contentNumber,
  buttonClick
}: CardProps) {
  const openHandler = () => {
    buttonClick(id);
    document.body.style.overflowY = "hidden";
  };

  return (
    <ContentCard contentNumber={contentNumber}>
      <ImageWrapper>
        <ImageCard src={imageCard} alt="" />
      </ImageWrapper>
      <TextWrapper contentNumber={contentNumber}>
        <SecondTitleTextCard>{titleCard}</SecondTitleTextCard>
      </TextWrapper>
      <SecondTextWrapper contentNumber={contentNumber}>
        <SecondTextCard>{textCard}</SecondTextCard>
      </SecondTextWrapper>
      <ButtonWrapper>
        <ButtonCard onClick={() => openHandler()}>{buttonTextCard}</ButtonCard>
      </ButtonWrapper>
    </ContentCard>
  );
}
