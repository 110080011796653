import React, { useEffect } from "react";
import { useGetMe } from "../../../hooks";
import {
  HeadetButtonLog,
  TargetBlockBtnRekommendera
} from "../../../pages/Landing/Landing.style";
import {
  BackgroundPopupPdf,
  ConfirmPopupDeleteValues,
  ConfirmPopupDeleteValuesText,
  TestBlock,
  ConfirmPopupDeleteValuesBlockBtn
} from "../../QuillContainer/QuillContainer.style";

interface ConfirmValuesPopupProps {
  setShow: () => void;
  deleteClick: (draftForm: any) => void;
  isSave: boolean;
}

export const ConfirmValuesPopup = ({
  setShow,
  deleteClick,
  isSave
}: ConfirmValuesPopupProps) => {
  const [getMe, { data: meData }] = useGetMe();
  useEffect(() => {
    if (!meData?.me.draftForm) {
      getMe();
    }
  }, [meData?.me.draftForm]);

  const handleClick = () => {
    if (meData?.me.draftForm) {
      const form = JSON.parse(meData?.me.draftForm);
      deleteClick(form);
    } else {
      throw Error("Det går inte att rensa formen");
    }
  };

  return (
    <BackgroundPopupPdf>
      <TestBlock onClick={setShow} />
      <ConfirmPopupDeleteValues>
        <ConfirmPopupDeleteValuesText>
          {isSave
            ? "Spara och fortsätt senare?"
            : "Är du säker på att du vill nollställa frågeformuläret?"}
        </ConfirmPopupDeleteValuesText>
        <ConfirmPopupDeleteValuesBlockBtn>
          {isSave ? (
            <>
              <HeadetButtonLog>Nej</HeadetButtonLog>
              <TargetBlockBtnRekommendera
                onClick={setShow}
                width={100}
                paddingTop={14}
                paddingLeft={10}
                paddingRight={10}
                paddingBottom={14}
                shadowLeft={2}
                shadowTop={3}
              >
                Ja
              </TargetBlockBtnRekommendera>
            </>
          ) : (
            <>
              <HeadetButtonLog onClick={setShow}>Avbryt</HeadetButtonLog>
              <TargetBlockBtnRekommendera
                onClick={handleClick}
                width={100}
                paddingTop={14}
                paddingLeft={10}
                paddingRight={10}
                paddingBottom={14}
                shadowLeft={2}
                shadowTop={3}
              >
                Ja
              </TargetBlockBtnRekommendera>
            </>
          )}
        </ConfirmPopupDeleteValuesBlockBtn>
      </ConfirmPopupDeleteValues>
    </BackgroundPopupPdf>
  );
};
