import React, { useState } from "react";
import { deviceSize, IconType } from "../../consts";
import { useNavigation, useTranslation, useViewport } from "../../hooks";
import {
  HeaderBlockButtons,
  HeaderBlockLinks,
  HeaderBlockLogo,
  HeaderBurgerMenu,
  HeaderContainer,
  HeaderLink,
  HeaderMobileWrapper,
  HeadetButtonLog,
  HeadetButtonReg,
  ImageBurgerMenu,
  ImageCloseButton,
  ImageCloseButtonWrapper,
  LandingLogoHeader,
  PopupBurgerMenu,
  LogOutBtn,
  LogOutBtnText,
  LinkBurgetMenu,
  LinkText,
  BurgetMenuPageWrapper
} from "./BurgetMenu.style";
import { Link } from "react-router-dom";
import { Images } from "../../environment";
import { LinkIcon } from "../SidebarMenu/SidebarMenu.style";
import { Icon } from "../ui";
export enum StorageKey {
  LanguageCode = "spridakunskap-language-code",
  Token = "spridakunskap-auth-token",
  ActiveWorkspaceId = "spridakunskap-active-workspace-id"
}

interface BurgetMenuProps {
  activelink?: string;
  unLogged?: boolean;
}

export function BurgetMenu({ activelink, unLogged = false }: BurgetMenuProps) {
  const [burgetMenuShow, setBurgetMenuShow] = useState<boolean>(false);
  const width = useViewport();
  const { routes, navigate, history } = useNavigation();
  const translate = useTranslation();

  function logOut() {
    localStorage.removeItem(StorageKey.Token);
    navigate(routes.login);
  }

  function moveToHandover() {
    navigate(routes.handovers);
    setBurgetMenuShow(false);
  }

  function moveToProfile() {
    navigate(routes.profile);
    setBurgetMenuShow(false);
  }

  function moveToFaq() {
    navigate(routes.logfaq);
    setBurgetMenuShow(false);
  }
  function moveToSignup() {
    navigate(routes.signup);
    setBurgetMenuShow(false);
  }

  function moveToLogin() {
    navigate(routes.login);
    setBurgetMenuShow(false);
  }

  function moveToHistory() {
    navigate(routes.story);
    setBurgetMenuShow(false);
  }

  return (
    <BurgetMenuPageWrapper>
      {width >= parseInt(deviceSize.laptopM.min) ? (
        <HeaderContainer>
          <HeaderBlockLogo>
            <Link to="/">
              <LandingLogoHeader src={Images.landingLogo} alt="" />
            </Link>
          </HeaderBlockLogo>
          <HeaderBlockLinks>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Varför kunskapsöverföring?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              För vem?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Om oss
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Kontakta
            </HeaderLink>
            <HeaderLink
              backlight={1}
              onClick={() => history.push(routes.usefuldocuments)}
            >
              Användbart material
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.dictionary)}>
              Ordbok
            </HeaderLink>
          </HeaderBlockLinks>
          <HeaderBlockButtons>
            <HeadetButtonReg onClick={() => history.push(routes.signup)}>
              Skapa konto
            </HeadetButtonReg>
            <HeadetButtonLog
              customWidth={128}
              onClick={() => history.push(routes.login)}
            >
              Logga in
            </HeadetButtonLog>
          </HeaderBlockButtons>
        </HeaderContainer>
      ) : width >= parseInt(deviceSize.tablet.min) ? (
        <HeaderContainer>
          <HeaderBlockLogo>
            <Link to="/">
              <LandingLogoHeader src={Images.landingLogo} alt="" />
            </Link>
          </HeaderBlockLogo>
          <HeaderBlockButtons>
            <HeadetButtonReg onClick={() => history.push(routes.signup)}>
              Skapa konto
            </HeadetButtonReg>
            <HeadetButtonLog
              customWidth={128}
              onClick={() => history.push(routes.login)}
            >
              Logga in
            </HeadetButtonLog>
            <HeaderBurgerMenu onClick={() => setBurgetMenuShow(true)}>
              <ImageBurgerMenu src={Images.landingBurgerMenu} alt="" />
            </HeaderBurgerMenu>
          </HeaderBlockButtons>
        </HeaderContainer>
      ) : (
        <HeaderContainer>
          <HeaderMobileWrapper>
            <HeaderBlockLogo>
              <Link to="/">
                <LandingLogoHeader src={Images.landingLogo} alt="" />
              </Link>
            </HeaderBlockLogo>
            <HeaderBurgerMenu onClick={() => setBurgetMenuShow(true)}>
              <ImageBurgerMenu src={Images.landingBurgerMenu} alt="" />
            </HeaderBurgerMenu>
          </HeaderMobileWrapper>
        </HeaderContainer>
      )}
      {width >= parseInt(deviceSize.laptop.min) ? (
        <PopupBurgerMenu isOpen={burgetMenuShow}>
          <HeaderBlockLinks>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Varför kunskapsöverföring?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              För vem?
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Om oss
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.root)}>
              Kontakta
            </HeaderLink>
            <HeaderLink
              backlight={1}
              onClick={() => history.push(routes.usefuldocuments)}
            >
              Användbart material
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.dictionary)}>
              Ordbok
            </HeaderLink>
          </HeaderBlockLinks>
          <ImageCloseButtonWrapper onClick={() => setBurgetMenuShow(false)}>
            <ImageCloseButton src={Images.landingCloseIcon} />
          </ImageCloseButtonWrapper>
        </PopupBurgerMenu>
      ) : (
        <PopupBurgerMenu isOpen={burgetMenuShow}>
          <ImageCloseButtonWrapper onClick={() => setBurgetMenuShow(false)}>
            <ImageCloseButton src={Images.landingCloseIcon} />
          </ImageCloseButtonWrapper>
          {unLogged ? (
            <HeaderBlockLinks>
              <HeaderLink onClick={moveToSignup}>
                <LinkText backlight={activelink === "signup"}>
                  Skapa konto
                </LinkText>
              </HeaderLink>

              <HeaderLink onClick={moveToLogin}>
                <LinkText backlight={activelink === "login"}>Login</LinkText>
              </HeaderLink>

              <HeaderLink onClick={moveToFaq}>
                <LinkText backlight={activelink === "faq"}>FAQ</LinkText>
              </HeaderLink>
            </HeaderBlockLinks>
          ) : (
            <HeaderBlockLinks>
              <LinkBurgetMenu onClick={moveToHandover}>
                <img src={Images.landingMenuHandover} />
                <LinkText backlight={activelink === "handover"}>
                  {translate(({ sideMenuLinks }) => sideMenuLinks.handovers)}
                </LinkText>
              </LinkBurgetMenu>

              <LinkBurgetMenu onClick={moveToProfile}>
                <img src={Images.landingMenuProfile} />
                <LinkText backlight={activelink === "profile"}>
                  {translate(({ sideMenuLinks }) => sideMenuLinks.profile)}
                </LinkText>
              </LinkBurgetMenu>

              <LinkBurgetMenu onClick={moveToFaq}>
                <img src={Images.landingMenuFaq} />
                <LinkText backlight={activelink === "faq"}>
                  {translate(({ sideMenuLinks }) => sideMenuLinks.faq)}
                </LinkText>
              </LinkBurgetMenu>

              <LinkBurgetMenu onClick={moveToHistory}>
                <img
                  style={{ width: "32px", height: "32px" }}
                  src={Images.preview}
                />
                <LinkText backlight={activelink === "history"}>
                  {translate(({ sideMenuLinks }) => sideMenuLinks.history)}
                </LinkText>
              </LinkBurgetMenu>

              <LogOutBtn onClick={logOut}>
                <img src={Images.landingMenuLogout} />
                <LogOutBtnText>
                  {translate(({ buttons }) => buttons.logout)}
                </LogOutBtnText>
              </LogOutBtn>
            </HeaderBlockLinks>
          )}
        </PopupBurgerMenu>
      )}
    </BurgetMenuPageWrapper>
  );
}
