import React from "react";
import {
  Route as ReactRoute,
  RouteComponentProps,
  RouteProps,
  Redirect
} from "react-router-dom";

import { StorageKey } from "../../../consts";
import { Routes } from "../../../app/Router";

interface RouteParams {}
interface Props extends RouteProps {
  guarded?: boolean;
  component:
    | React.ComponentType<RouteComponentProps<RouteParams>>
    | React.ComponentType<any>;
}

export function Route({ guarded, component: Component, ...rest }: Props) {
  const logged = !!localStorage.getItem(StorageKey.Token);

  if (guarded) {
    if (logged) {
      return <ReactRoute {...rest} component={Component} />;
    }
    return <Redirect to={Routes.Login} />;
  } else if (logged) {
    return <Redirect to={Routes.Handovers} />;
  }

  return <ReactRoute {...rest} component={Component} />;
}
