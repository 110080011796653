import { deviceRange } from "./../../../consts";
import styled from "styled-components";
import { CustomInput } from "../../ui/Input";
import { Colors } from "../../../environment";
import { Link } from "react-router-dom";

export const Form = styled.form`
  max-width: 662px;
  width: 100%;
  display: flex;
  align-items: flex-start;

  @media ${deviceRange.mobile} {
    flex-direction: column;
    align-items: initial;
    margin-bottom: 20px;
  }
`;

export const LinkWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid lightgray;
  border-radius: 80px;

  a {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: ${Colors.gray[90]};
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 50px;
    text-decoration: none;
  }
`;

export const BlockInputs = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;

  @media ${deviceRange.mobile} {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const Label = styled.div`
  width: 100%;
  max-width: 662px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  color: ${Colors.gray[90]};
  text-align: left;
  @media ${deviceRange.mobile} {
    font-size: 12px;
  }
`;

export const AddColorDescription = styled(Label)`
  font-size: 12px;
  color: ${Colors.gray[60]};
`;

export const AddColorInput = styled(CustomInput)`
  width: 320px;
  margin-right: 20px;

  @media ${deviceRange.mobile} {
    width: 100%;
    max-width: 320px;
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const BlockButtonsWrapper = styled.div``;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  @media ${deviceRange.mobile} {
    padding-right: 0px;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  }
`;

export const ImageContent = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  margin-right: 3.2rem;
  border: 1px solid transparent;
  border-radius: 25px;
  margin-bottom: 20px;
  overflow: hidden;

  @media ${deviceRange.mobile} {
    width: 150px;
    height: 150px;
    margin-right: 1rem;
  }
`;

export const TopBlock = styled.div`
  display: flex;
  max-width: 662px;
  width: 100%;
  align-items: center;
`;

export const LogoImage = styled.img`
  display: block;
  max-width: 100%;
`;

export const UploadImageBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 662px;
  margin-bottom: 44px;

  @media ${deviceRange.mobile} {
    margin-bottom: 0rem;
  }
`;

export const BlockButtonsImage = styled.div`
  display: flex;
  flex-direction: column;
`;
