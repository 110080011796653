import React from "react";
import { Images } from "../../../environment";
import {
  ButtonPdf,
  Wrapper,
  ImagePDF,
  TextButtonWrapper,
  ButtonGoogle,
  ButtonPdfwb,
  TextTitleStep
} from "./BaseButtonModal.style";
import {
  CenterBlockContent,
  ContentBlock,
  ImageInstruction,
  LeftBlockContent,
  RightBlockContent,
  SecondText,
  TextTitleWrapper,
  TextWrapper
} from "./Modals.style";

interface ButtonProps {
  downLink?: string;
}

export const ButtonPDF = ({ downLink }: ButtonProps) => {
  return (
    <Wrapper>
      <ButtonPdf onClick={() => window.open(downLink, "_blank")}>
        <ImagePDF src={Images.landingModalpdfIcon} />
        <TextButtonWrapper>Ladda ner PDF</TextButtonWrapper>
      </ButtonPdf>
    </Wrapper>
  );
};

export const GoogleButton = ({ downLink }: ButtonProps) => {
  return (
    <Wrapper>
      <ButtonGoogle onClick={() => window.open(downLink, "_blank")}>
        Öppna i Google docs för redigering
      </ButtonGoogle>
    </Wrapper>
  );
};

export const ButtonPDFWithoutBackground = ({ downLink }: ButtonProps) => {
  return (
    <Wrapper>
      <ButtonPdfwb onClick={() => window.open(downLink, "_blank")}>
        <ImagePDF src={Images.landingModalpdfIcon} />
        <TextButtonWrapper>Öppna som ifyllbar PDF</TextButtonWrapper>
      </ButtonPdfwb>
    </Wrapper>
  );
};
export const ButtonGoogleDocumentWithoutBackground = ({
  downLink
}: ButtonProps) => {
  return (
    <Wrapper style={{ paddingBottom: "4rem" }}>
      <ButtonPdfwb onClick={() => window.open(downLink, "_blank")}>
        <ImagePDF src={Images.googleDocPreview} />
        <TextButtonWrapper>Öppna Google doc</TextButtonWrapper>
      </ButtonPdfwb>
    </Wrapper>
  );
};
interface ButtonStepProps {
  changeStep: (id: number) => void;
}

export const ButtonStep = ({ changeStep }: ButtonStepProps) => {
  return (
    <Wrapper>
      <ButtonGoogle onClick={() => changeStep(1)}>
        Öppna i Google docs för redigering
      </ButtonGoogle>
    </Wrapper>
  );
};

export const Instruction = () => {
  return (
    <>
      <CenterBlockContent>
        <TextTitleWrapper>
          <TextTitleStep>
            För att kunna redigera dokumenten följ dessa anvisningar
          </TextTitleStep>
        </TextTitleWrapper>
      </CenterBlockContent>
      <ContentBlock>
        <LeftBlockContent>
          <CenterBlockContent>
            <TextWrapper>
              <SecondText>Steg 1</SecondText>
            </TextWrapper>
          </CenterBlockContent>
          <ImageInstruction src={Images.landingModalImageStep1} alt="" />
        </LeftBlockContent>
        <RightBlockContent>
          <CenterBlockContent>
            <TextWrapper>
              <SecondText>Steg 2</SecondText>
            </TextWrapper>
          </CenterBlockContent>
          <ImageInstruction src={Images.landingModalImageStep2} alt="" />
        </RightBlockContent>
      </ContentBlock>
    </>
  );
};
