import React from "react";
import { Images } from "../../environment/theme/Images";
import {
  BackgroundModalContent,
  BlackoutBlock,
  ModalContentWrapper,
  SecondTitleTextCard,
  TitleWrapper,
  ModalCloseButtonWrapper,
  ImageButtonClose
} from "./CurrentModal.style";

interface ModalContentProps {
  buttonClick: (id: number) => void;
  content: any;
}

export function CurrentModal({ buttonClick, content }: ModalContentProps) {
  const { content: Modal } = content;

  const closeHandler = () => {
    buttonClick(0);
    document.body.style.overflowY = "auto";
    document.body.style.overflowX = "hidden";
    document.body.style.backgroundColor = "white";
  };

  return (
    <BackgroundModalContent>
      <BlackoutBlock onClick={() => buttonClick(0)} />
      <ModalContentWrapper>
        <ModalCloseButtonWrapper onClick={closeHandler}>
          <ImageButtonClose src={Images.landingImageClose} alt="" />
        </ModalCloseButtonWrapper>
        <TitleWrapper>
          <SecondTitleTextCard>{content.title}</SecondTitleTextCard>
        </TitleWrapper>
        <Modal />
      </ModalContentWrapper>
    </BackgroundModalContent>
  );
}
