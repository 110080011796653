import { deviceRange } from "../../consts";
import styled, { createGlobalStyle } from "styled-components";
import { Fonts } from "../../environment/theme/Fonts";
import { Colors } from "../../environment";

export const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 80px);
  width: 100%;

  @media ${deviceRange.tablet} {
    width: 100%;
  }
`;
interface BodyLeftPartProps {
  marginTop?: number;
  width?: number;
  height?: number;
}

export const BodyLeftPart = styled.div<BodyLeftPartProps>`
  display: flex;
  flex-direction: column;
  width: 38vw;
  margin-top: ${props => props.marginTop && props.marginTop + "px"};
  ${props => (props.height ? "height: 100%;" : null)}

  @media ${deviceRange.tablet} {
    width: 90%;
  }

`;

interface BodyLeftPartWrapperProps {
  isMacintosh?: boolean;
}

export const BodyLeftPartWrapper = styled.div<BodyLeftPartWrapperProps>`
  display: flex;
  width: 45vw;
  flex-direction: column;
  height: 100%;
  max-height: 830px;
  ${props => (props.isMacintosh ? "zoom: 0.8;" : null)}

  @media ${deviceRange.tablet} {
    
    height: 76%;
    width: 85%;
  }

  @media ${deviceRange.desktop} {
    max-height: none;
  }
`;
export const BodyRightPart = styled.div`
  display: flex;
  flex-direction: column;
  width: 45vw;
  margin-top: 35px;
  position: relative;

  @media ${deviceRange.tablet} {
    width: 100%;
  }

  @media ${deviceRange.laptop} {
  }

  @media ${deviceRange.laptopM} {
  }

  @media ${deviceRange.laptopL} {
  }
`;
export const Stepper = styled.h2`
  font-family: ${Fonts.paragraph};
  font-weight: 300;
  font-size: 11px;
  color: #86888c;
  margin-bottom: 10px;
  letter-spacing: 1.1px;
`;
interface TitleProps {
  marginLeft?: number;
}
export const Title = styled.h1<TitleProps>`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: calc(16px + 1vw);
  line-height: 5rem;
  color: #6bc9db;
  ${props => (props.marginLeft === 0 ? "margin-bottom: -25px;" : null)}
  margin-left: ${props => props.marginLeft && props.marginLeft + "px"};
`;

export const FormContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-bottom: 10px;

    @media ${deviceRange.laptop} {
      margin-bottom: 0px;
    }

    @media ${deviceRange.laptopM} {
      margin-bottom: 0px;
    }

    @media ${deviceRange.laptopL} {
      margin-bottom: 0px;
    }

    @media ${deviceRange.desktop} {
      margin-bottom: 2px;
    }
  }
`;
export const PrevNextButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export const Hint = styled.div`
  display: flex;
  align-items: center;
  font-family: ${Fonts.paragraph};
  font-weight: 300;
  font-size: 15px;
  color: #86888c;
  margin-bottom: 10px;
  letter-spacing: 1.1px;
`;
interface BodyContentProps {
  paddingAndShadow?: boolean;
}
export const BodyContent = styled.div<BodyContentProps>`
  height: 100%;
  max-height: 630px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  overflow-y: scroll;
  z-index: 5;
  position: relative;
  ${props =>
    props.paddingAndShadow
      ? "box-shadow: 4px 4px 25px rgb(107 201 219 / 25%); padding: 20px;"
      : null}
  border-radius: 16px;
  padding: 20px;
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  @media ${deviceRange.desktop} {
    max-height: none;
  }
`;

export const BlockButtonPreview = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;
export const RightBlockButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: center;
  flex-direction: row;

  @media ${deviceRange.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 0px;

    justify-content: center;
  }

  @media ${deviceRange.laptop} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${deviceRange.laptopM} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${deviceRange.laptopL} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${deviceRange.desktop} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
export const BlockSteg = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #cc00a0;
  margin-right: 25px;
  padding: 10px;
  box-shadow: 4px 4px 25px rgb(107 201 219 / 25%);
  width: 84px;
  height: 84px;
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  z-index: 15;
  position: "relative";
  background: white;
`;
export const BlockStegWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  padding-top: 5px;
  overflow: hidden;
  z-index: 10;
  position: relative;
  margin-bottom: -1px;
`;
export const AssistantTextBlock = styled.div`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #929496;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  margin-bottom: 10px;

  @media ${deviceRange.mobile} {
    height: fit-content;
  }
`;

interface PopupBurgerMenuProps {
  isOpen: boolean;
}

export const PopupBurgerMenu = styled.div<PopupBurgerMenuProps>`
  width: 224px;
  height: 187px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  left: 0px;
  bottom: 0px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(107, 201, 219, 0.15);
  border-radius: 0px 15px 15px 0px;
  z-index: 101;
  transform: translateX(${props => (props.isOpen ? "0px" : "-300px")});
  transition: 1.3s, ease-in-out;
  transition-duration: 0.8s;
  box-shadow: 10px -4px 15px rgb(107 201 219 / 24%);
  @media ${deviceRange.tablet} {
    flex-direction: column;
    height: 240px;
  }

  @media ${deviceRange.mobile} {
    flex-direction: column;
    height: 220px;
  }
`;

export const ImageCloseButton = styled.img`
  width: 16px;
  height: 16px;
`;

export const ImageCloseButtonWrapper = styled.div`
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  @media ${deviceRange.tablet} {
    margin-left: auto;
  }

  @media ${deviceRange.mobile} {
    margin-left: auto;
    margin-bottom: -28px;
    margin-right: 18px;
  }
`;

export const HeaderMobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;
interface HeadetButtonLogProps {
  marginLeft?: number;
  marginTop?: number;
  padding?: string;
  customWidth?: number;
  fontSize?: number;
  widthVW?: number;
}
export const LasMerButton = styled.button<HeadetButtonLogProps>`
  background: none;
  border-radius: 80px;
  cursor: pointer;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 95px;
  padding-right: 95px;
  border: 1px solid #ffa100;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #fca311;
  margin-left: ${props =>
    props.marginLeft ? props.marginLeft + "px;" : "0px;"};
  margin-top: 35px;
  &:hover {
    border: 1px solid rgba(252, 163, 17, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(255, 161, 0, 0.5);
  }
  &:active {
    background: #fca311;
    color: white;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.laptopL} {
    padding: 2vh 5vw;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
    padding: 20px 77px;
  }
`;

export const LasMerButtonServiceBlock = styled.button<HeadetButtonLogProps>`
  background: none;
  border-radius: 80px;
  cursor: pointer;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 95px;
  padding-right: 95px;
  border: 1px solid #ffa100;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #fca311;
  margin-left: ${props =>
    props.marginLeft ? props.marginLeft + "px;" : "0px;"};
  margin-top: 35px;
  &:hover {
    border: 1px solid rgba(252, 163, 17, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(255, 161, 0, 0.5);
  }
  &:active {
    background: #fca311;
    color: white;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.laptopL} {
    padding: 2vh 5vw;
  }

  @media ${deviceRange.tablet} {
    width: 210px;
    height: 56.54px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 0;
  }

  @media ${deviceRange.laptop} {
    width: 210px;
    height: 56.54px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 0;
  }

  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
    padding: 20px 77px;
  }
`;

export const PromoBlockContaner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  background-color: white;
  padding-top: 150px;

  @media ${deviceRange.desktop} {
    padding-top: 75px;
  }

  @media ${deviceRange.tablet} {
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media ${deviceRange.mobile} {
    flex-direction: column;
    padding-right: 20px;
    max-width: 410px;
    margin: auto;
  }
`;
export const LinkText = styled.span`
  margin-left: 9px;
  font-family: ${Fonts.paragraph};
  font-size: 12px;
`;

export const MenuText = styled.div`
  margin-left: 10px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 10px;
  line-height: 12px;
`;

export const LinkBurgetMenu = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  letter-spacing: 0.7px;
  color: ${Colors.gray[90]};
  outline: none;
  font-size: 20px;
  width: 170px;
  justify-content: flex-start;
`;

export const LinkSideMenu = styled.button`
  display: flex;
  align-items: flex-start;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  letter-spacing: 0.7px;
  color: ${Colors.gray[90]};
  outline: none;
  font-size: 20px;
`;

export const HeaderBlockLinks = styled.div`
  display: flex;
  align-self: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;

  @media ${deviceRange.tablet} {
    flex-direction: column;
    align-items: center;
  }

  @media ${deviceRange.mobile} {
    flex-direction: column;
    align-items: center;
    height: 80%;
    justify-content: space-around;
  }
`;
export const MascotImg = styled.img`
  position: relative;
  margin-left: -100px;
  border-radius: 48px;
  z-index: 99;
`;

export const TextUnderMascot = styled.div`
  text-align: center;
  font-size: 10px;
  font-family: ${Fonts.landingOpenSansRegular};
`;

export const AssistantBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 112px;
  z-index: 2;
`;

export const ProgresBarAndSendBtnWrapp = styled.div`
  display: flex;
  align-items: center;
  max-width: 565px;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
`;
export const SendPopupContent = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #6bc9db;
  background: white;
  padding: 36px 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 20px rgba(107, 201, 219, 0.3);
  border-radius: 16px;
  width: 100%;
  display: flex;
`;
export const SendPopupWrapp = styled.div`
  display: flex;
  width: 100%;
  max-width: 550px;
  margin-top: 0px;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 150;
  position: absolute;
`;
export const ButtonCloseSendPopup = styled.div`
  margin-left: auto;
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
  margin-right: -15px;
  margin-top: -18px;
`;
export const ButtonsSendPopupWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 410px;
  margin: auto;
  margin-top: 25px;
  @media (max-width: 1165px) {
    flex-direction: column;
    justify-content: space-between;
    height: 125px;
  }
`;
export const HelpManWrapp = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 129px;
  height: 100%;
  z-index: 2;
`;
export const HelpManContent = styled.div``;
export const HelpManRullaWrapp = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #929496;
  margin-top: 15px;
  margin-right: 15px;
`;

export const AssistantBlockContent = styled.div`
  max-width: 100px;
`;
interface HelpManTextProps {
  isShow?: boolean;
  isMacintosh?: boolean;
}

export const HelpManText = styled.div<HelpManTextProps>`
  position: absolute;
  height: 200px;
  right: 180px;
  bottom: 150px;
  width: 65%;
  /* ::-webkit-scrollbar {
        width: 0.4rem;
        height: 20px;
    }
    ::-webkit-scrollbar-thumb {
        background: gray;
        opacity: 0.2;
        border-radius: 1rem;
    } */
  @media ${deviceRange.laptop} {
    width: 55%;
  }

  @media ${deviceRange.mobile} {
    width: 211px;
    right: 90px;
    bottom: 101px;
  }

  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;

  word-wrap: break-word;
  border: 2px solid white;
  background-color: white;
  border-radius: 25px;
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
`;

export const BackgroundBlur = styled.div`
  display: none;
  @media ${deviceRange.mobile} {
    position: fixed;
    width: 100%;
    height: 421px;
    right: -2px;
    bottom: 0px;
    z-index: -1;
  }
`;

export const HelpManTextParagraph = styled.p`
  font-size: 12px;
  padding-right: 10px;
  font-family: ${Fonts.landingOpenSansRegular};
`;
export const HelpManTextWrapper = styled.div`
  overflow-y: scroll;
  height: 100%;

  @media ${deviceRange.mobile} {
    width: 191px;
  }
`;
export const HelpManBackground = styled.div`
  height: 110px;
  border-radius: 66px;
  width: 110px;
  position: fixed;
  margin-top: -5px;
  background: linear-gradient(
    transparent 0%,
    transparent 53%,
    #e6f5f9 54%,
    #e6f5f9 100%
  );
  margin-left: -5px;
`;
export const HelpManImgWrap = styled.div``;
interface ChapterClickBodyButtonProps {
  borderColor?: string;
}
export const ChapterClickBodyButton = styled.button<
  ChapterClickBodyButtonProps
>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 2px solid
    ${props => (props.borderColor ? props.borderColor : "black")};
  width: fit-content;
  padding: 10px;
  background: transparent;
  outline: none;
  padding: 10px 40px;
  cursor: pointer;
  white-space: nowrap;
`;
export const SuperUserButton = styled.div`
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
`;
export const ScrollAndFormWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 85%;
  max-height: 765px;
  position: relative;
  margin-bottom: 30px;

  @media ${deviceRange.desktop} {
    max-height: 1500px;
  }

  @media ${deviceRange.tablet} {
    justify-content: center;
  }
`;
interface ChapterButtonsWrapperProps {
  maxWidth?: number;
  marginTop?: string;
  marginLeft?: string;
}
export const ChapterButtonsWrapper = styled.div<ChapterButtonsWrapperProps>`
  display: flex;
  max-width: ${props => props.maxWidth}px;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-left: ${props => props.marginLeft};
  margin-top: ${props => props.marginTop};
  margin-bottom: 30px;

  @media ${deviceRange.desktop} {
    margin-top: 10px;
  }
`;

export const MandantoryFields = styled.div`
  padding-left: 5px;
  font-size: calc(8px + 0.5vw);
  border-left-color: #cc00a0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-style: solid;
  border-left-width: 2px;
  font-family: ${Fonts.landingOpenSansRegular};

  @media ${deviceRange.laptop} {
  }
`;

export const BodyTabletWrapp = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: column;

  @media ${deviceRange.tablet} {
    flex-direction: column;
  }
`;

export const PreviewButton = styled.button`
  font-size: calc(5px + 0.5vw);
  min-width: 120px;
  border-radius: 80px;
  margin: 10px;
  padding: 10px 25px;
  background: #fca311;
  color: white;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px rgba(255, 161, 0, 62%);
  }
  &:active {
    box-shadow: -3px -5px rgba(255, 161, 0, 62%);
  }

  @media ${deviceRange.laptopL} {
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }
`;

export const DropSaveButton = styled.button`
  min-width: 120px;
  background: none;

  border-radius: 80px;
  cursor: pointer;
  margin: 10px;
  padding: 10px 25px;

  border: 1px solid #ffa100;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #425262;

  &:hover {
    border: 1px solid rgba(252, 163, 17, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(255, 161, 0, 0.5);
  }
  &:active {
    background: #fca311;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }
`;

export const SendAnswer = styled.button`
  min-width: 120px;
  background: #cc00a0;
  border: #cc00a0;
  color: #ffffff;

  border-radius: 80px;
  margin: 10px;
  padding: 10px 25px;

  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 600;
  font-size: calc(5px + 0.5vw);
  line-height: 24px;

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 3px 6px 10px #b2028c;
  }
  &:active {
    box-shadow: -5px -5px 0px #b2028c;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }

  @media ${deviceRange.laptop} {
    font-size: 14px;
  }
`;

export const SaveAndContinueLater = styled.button`
  min-width: 120px;
  background: none;

  border-radius: 80px;
  cursor: pointer;
  margin: 10px;
  padding: 10px 25px;

  border: 1px solid #cc00a0;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: calc(5px + 0.5vw);
  line-height: 24px;
  color: #425262;

  &:hover {
    border: 1px solid rgba(204, 0, 160, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(204, 0, 160, 0.5);
  }
  &:active {
    background: #cc00a0;
  }
  &:focus {
    outline: none;
  }

  @media ${deviceRange.tablet} {
    font-size: 12px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  background: white;
  box-shadow: 0px -4px 10px rgba(107, 201, 219, 0.24);
  border-radius: 16px 16px 0px 0px;
  justify-content: space-between;
  width: 100%;
  height: 74px;
  z-index: 100;
  position: fixed;
  bottom: 0;
  margin-top: 60px;
  justify-content: center;
`;

export const MainBlock = styled.div`
  width: 90%;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

export const ItemText = styled.div`
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 10px;
`;
interface BodyLeftPartProps {
  disabled?: boolean;
}
export const MenuItem = styled.a``;
export const ArrowLeftItem = styled.a<BodyLeftPartProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  color: ${props => (props.disabled ? "#cacaca" : "#425262")};
`;

export const ArrowRightItem = styled.a<BodyLeftPartProps>`
  color: ${props => (props.disabled ? "#cacaca" : "#425262")};
`;
