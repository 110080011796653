import styled from "styled-components";

import { Colors, Fonts } from "../../../environment";
import { Icon as BaseIcon } from "../Icon";

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckboxLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })``;

export const Icon = styled(BaseIcon)``;

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
}

export const StyledCheckbox = styled.div<CheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 3px 4px 5px;
  background: ${props => (props.checked ? "#cc00a0" : Colors.transparent)};
  border: 1px solid;
  border-color: ${props => (props.checked ? "#cc00a0" : "#A1A9B1")};
  border-radius: 4px;
  opacity: ${props => props.disabled && 0.3};

  &:hover {
    border-color: black;
  }
`;
interface CheckboxTextLabelProps {
  checked?: boolean;
}
export const CheckboxTextLabel = styled.p<CheckboxTextLabelProps>`
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 13px;
  color: ${props => (props.checked ? "black" : "#425262")};
  margin-left: 10px;

  &:hover {
    color: black;
  }
`;
