import { Handover, Chapter, FormFieldData } from "../../../../api";

import { HandoverState, Action } from "./Handover.interfaces";
import { getfieldsValuesFromChapters } from "../../helpers/Chapters";

export const handoverInitialState = {
  handover: null,
  activeChapter: null,
  isLoading: true,
  fieldsValues: [],
  handoverFiles: [],
  showSendFormDataModal: false,
  currentStep: 0,
  documentEditor: ""
};

export function handoverReducer(
  handoverState: HandoverState,
  { type, payload }: Action
) {
  switch (type) {
    case "SET_HANDOVER": {
      return {
        ...handoverState,
        handover: payload
      };
    }
    case "SET_CHAPTERS": {
      return {
        ...handoverState,
        handover: { ...handoverState.handover, chapters: payload }
      };
    }

    case "SET_DOCUMENT_EDITOR": {
      return {
        ...handoverState,
        documentEditor: payload
      };
    }

    case "SET_ACTIVE_CHAPTER": {
      return {
        ...handoverState,
        activeChapter: payload
      };
    }

    case "SET_IS_LOADING": {
      return {
        ...handoverState,
        isLoading: payload
      };
    }

    case "SET_SHOW_SEND_FORM_DATA_MODAL": {
      return {
        ...handoverState,
        showSendFormDataModal: payload
      };
    }

    case "SET_HANDOVER_FILE": {
      const fileDuplicateIdx = handoverState.handoverFiles.findIndex(
        ({ fieldName }) => payload.fieldName === fieldName
      );
      let updatedHandoverFiles = [...handoverState.handoverFiles];

      if (!~fileDuplicateIdx) {
        updatedHandoverFiles.push(payload);
      } else {
        updatedHandoverFiles.splice(fileDuplicateIdx, 1, payload);
      }
      return {
        ...handoverState,
        handoverFiles: updatedHandoverFiles
      };
    }

    case "REMOVE_HANDOVER_FILE": {
      const filteredFiles = handoverState.handoverFiles.filter(
        ({ fieldName }) => fieldName !== payload
      );
      return {
        ...handoverState,
        handoverFiles: [...filteredFiles]
      };
    }

    case "REMOVE_ALL_HANDOVER_FILE": {
      return {
        ...handoverState,
        handoverFiles: []
      };
    }

    case "UPDATE_HANDOVER_CHAPTER": {
      if (!handoverState.handover) return handoverState;

      const prevHandover = handoverState.handover;
      const newChapter: Chapter = payload;
      const oldChapterIdx = prevHandover.chapters.findIndex(
        ({ id }) => newChapter.id === id
      );

      const updatedState = {
        ...handoverState,
        handover: {
          ...prevHandover,
          chapters: [
            ...prevHandover.chapters.slice(0, oldChapterIdx),
            newChapter,
            ...prevHandover.chapters.slice(oldChapterIdx + 1)
          ]
        }
      };
      return {
        ...updatedState,
        fieldsValues: getfieldsValuesFromChapters(
          updatedState.handover.chapters,
          updatedState.fieldsValues
        )
      };
    }

    case "SET_FIELD_VALUES": {
      return {
        ...handoverState,
        fieldsValues: payload
      };
    }

    case "UPDATE_FIELD_VALUE": {
      const { fieldsValues } = handoverState;
      const fieldIdx = fieldsValues.findIndex(
        ({ name }) => name === payload.name
      );

      return {
        ...handoverState,
        fieldsValues: [
          ...fieldsValues.slice(0, fieldIdx),
          {
            ...fieldsValues[fieldIdx],
            value: payload.value
          },
          ...fieldsValues.slice(fieldIdx + 1)
        ]
      };
    }

    case "SET_CURRENT_STEP": {
      if (payload < 0) payload = 0;
      return {
        ...handoverState,
        currentStep: payload
      };
    }

    default:
      return handoverState;
  }
}

// Action creators

export const setActiveChapter = (chapter: Chapter | null) => ({
  type: "SET_ACTIVE_CHAPTER",
  payload: chapter
});
export const setIsLoading = (isLoading: boolean) => ({
  type: "SET_IS_LOADING",
  payload: isLoading
});
export const setHandover = (handover: Handover | null) => ({
  type: "SET_HANDOVER",
  payload: handover
});
export const setDocumentEditor = (documentEditor: String | null) => {
  return {
    type: "SET_DOCUMENT_EDITOR",
    payload: documentEditor
  };
};

export const updateHandoverChapter = (chapter: Chapter | null) => ({
  type: "UPDATE_HANDOVER_CHAPTER",
  payload: chapter
});
export const setfieldsValues = (fieldsValues: Array<FormFieldData>) => ({
  type: "SET_FIELD_VALUES",
  payload: fieldsValues
});
export const setChapters = (chapters: any) => ({
  type: "SET_CHAPTERS",
  payload: chapters
});
export const updateFieldValue = (name: string, value: Array<string>) => ({
  type: "UPDATE_FIELD_VALUE",
  payload: { name, value }
});
export const setShowSendFormDataModal = (showModal: boolean) => ({
  type: "SET_SHOW_SEND_FORM_DATA_MODAL",
  payload: showModal
});
export const setCurrentStep = (stepNum: number) => ({
  type: "SET_CURRENT_STEP",
  payload: stepNum
});
export const setHandoverFile = (file: File | string, fieldName: string) => ({
  type: "SET_HANDOVER_FILE",
  payload: { file, fieldName }
});
export const removeHandoverFile = (fieldName: string) => ({
  type: "REMOVE_HANDOVER_FILE",
  payload: fieldName
});

export const removeAllHandoverFiles = () => ({
  type: "REMOVE_ALL_HANDOVER_FILE",
  payload: ""
});
