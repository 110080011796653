import { deviceRange } from "./../../../consts";
import styled from "styled-components";
import { Fonts, Colors } from "../../../environment";
import { ModalSizes } from "./Modal";

export const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${Colors.modalBackgroundColor};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 151;
  display: flex;
`;

interface ModalContainerProps {
  size: ModalSizes;
  autoHeight: boolean;
}

export const ModalContainer = styled.div<ModalContainerProps>`
  position: relative;
  z-index: 120;
  overflow: scroll;
  max-height: 100vh;
  width: 50vw;
  height: 70vh;
  background-color: ${Colors.white};
  border-radius: 0.8rem;
  outline: 0;
  display: flex;
  flex-direction: column;
  margin: auto;
  @media ${deviceRange.mobile} {
    width: 95vw;
    height: 40vh;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 0.1rem solid ${Colors.dividerLight};
  padding: 10px 28px;
`;

export const HeaderLeftIcon = styled.div`
  font-size: 2.5rem;
  font-family: ${Fonts.title};
  cursor: pointer;
  margin-right: 2rem;
`;

export const HeaderTitle = styled.h5`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 52px;
  color: #6bc9db;
  text-align: center;
`;

export const HeaderRightIcon = styled.div`
  font-size: 2.5rem;
  display: flex;
  font-family: ${Fonts.title};
  color: ${Colors.gray[50]};
  position: absolute;
  right: 2.5rem;
  top: 2.5rem;
  cursor: pointer;
  &:hover {
    color: black;
  }
`;

interface ModalBodyProps {
  withoutScroll?: boolean;
}

export const ModalBody = styled.div<ModalBodyProps>`
  display: flex;
  justify-content: center;
  overflow-y: ${props => (props.withoutScroll ? "visiable" : "scroll")};
  flex-grow: 1;
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
  ::-webkit-scrollbar-thumb {
    background: ${Colors.gray[50]};
    opacity: 0.2;
    border-radius: 1rem;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.gray[80]};
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 0.1rem solid ${Colors.gray[7]};
  padding: 1.6rem 3.2rem;
  > :not(:last-child) {
    margin-right: 10px;
  }
`;
