import styled from "styled-components";
import { NavLink as BaseNavLink } from "react-router-dom";
import { Fonts, Colors } from "../../../environment";

export const HeaderContainer = styled.header`
  height: 8.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2rem 2.5rem 2rem 8rem;
  background: transparent;
`;

export const HeaderNavBlock = styled.nav`
  margin-left: auto;
`;

export const NavBar = styled.ul`
  display: flex;
  align-items: center;
`;

export const NavBarItem = styled.li`
  float: left;
  list-style: none;
  margin-right: 3.2rem;
`;
interface NavLinkLoginProps {
  activelink?: string;
}
export const NavLinkLogin = styled(BaseNavLink)<NavLinkLoginProps>`
  text-decoration: none;
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 15px;
  color: ${props =>
    props.activelink === "login" ? "#CC00A0" : Colors.black[100]};
`;
interface NavLinkSignupProps {
  activelink?: string;
}
export const NavLinkSignup = styled(BaseNavLink)<NavLinkSignupProps>`
  text-decoration: none;
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 15px;
  color: ${props =>
    props.activelink === "signup" ? "#CC00A0" : Colors.black[100]};
`;
interface NavLinkFaqProps {
  activelink?: string;
}
export const NavLinkFaq = styled(BaseNavLink)<NavLinkFaqProps>`
  text-decoration: none;
  font-family: ${Fonts.landingOpenSansRegular};
  font-size: 15px;
  color: ${props =>
    props.activelink === "faq" ? "#CC00A0" : Colors.black[100]};
`;
interface HeaderLogoProps {
  sidebar?: boolean;
}
export const HeaderLogo = styled.img<HeaderLogoProps>`
  width: ${props => (props.sidebar ? "215px" : "24px")};
`;
