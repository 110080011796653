import styled from "styled-components";
import { Colors, Fonts } from "../../environment";
import { NavLink as Link } from "react-router-dom";
import { Icon } from "../ui";
import { deviceRange } from "./../../consts";
interface SidebarContainerProps {
  sidebar: number;
}
export const SidebarContainer = styled.div<SidebarContainerProps>`
  display: flex;
  transition: 0.3s;
  width: ${props => (props.sidebar ? "280px" : "80px")};
`;

export const NavigationContainer = styled.nav`
  width: 280px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px 0 31px 0;
  background-color: white;
  box-shadow: 4px 4px 25px rgb(107 201 219 / 25%);
  border-radius: 0px 25px 25px 0px;
  z-index: 100;
  overflow: hidden;
`;

export const PartnersContainer = styled.div`
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
`;

export const PartnersAllmanaImg = styled.img`
  width: 100%;
  max-width: 120px;
  margin-right: 15px;
`;

export const PartnersViungaImg = styled.img`
  width: 100%;
  max-width: 40px;
`;

export const PartnersLogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PartnersText = styled.p`
  font-family: ${Fonts.landingOpenSansRegular};
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: auto;
  margin-bottom: 20px;
  color: #a1a9b1;
  text-align: center;
`;

export const PartnersLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.2rem 2.2rem 1.2rem;
  padding: 0.4rem 0.8rem;
`;

export const HeaderLogo = styled.img`
  height: 3.4rem;
`;

export const HeaderTitle = styled.p`
  color: ${Colors.gray[100]};
  margin: 0 0.8rem;
  flex: 1;
  font-size: 1.4rem;
`;

export const HeaderToggleBlock = styled.div`
  display: flex;
`;

export const QuickSearchBlock = styled.div`
  padding: 0.2rem 0.4rem;
  margin-bottom: 1.6rem;
`;

export const CreateBlock = styled.div`
  display: flex;
  margin: 0 1.2rem 2.4rem 1.2rem;
`;

export const DropdownItemIcon = styled(Icon)`
  margin-right: 1.2rem;
`;

export const NavBar = styled.ul`
  width: 100%;
`;

export const NavbarTitle = styled.p`
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 0 0 2.8rem 1.8rem;
  color: ${Colors.overlay[40]};
`;

interface NavItemProps {
  withMarginBottom?: boolean;
}

export const NavItem = styled.li<NavItemProps>`
  margin-bottom: 12px;
  list-style-type: none;
`;
interface NavLinkProps {
  sidebar: number;
  disabled?: number;
}
export const NavLink = styled(Link)<NavLinkProps>`
  display: flex;
  align-items: center;
  padding: 1px 0 1px ${props => (props.sidebar ? "45px" : "23px")};
  color: ${Colors.gray[90]};
  border-left: 0.2rem solid ${Colors.transparent};
  text-decoration: none;
  cursor: pointer;
  ${props => (props.disabled ? "pointer-events: none;" : null)}

  &.active {
    color: #cc00a0;
  }

  @media ${deviceRange.laptop} {
    padding: 1px 0 1px 23px;
  }
`;

interface LinkIconProps {
  active?: boolean;
}
export const LinkIcon = styled.div<LinkIconProps>`
  margin-right: 11px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: white;
  ${props => (props.active ? "box-shadow: 0px 1px 12px #b2028c;" : null)}
`;

export const LinkTitle = styled.p`
  font-family: ${Fonts.paragraph};
  font-size: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 0px;
`;

export const LogOutBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  letter-spacing: 0.7px;
  color: ${Colors.gray[90]};
  outline: none;
  font-size: 20px;
  width: 150px;
`;
interface LogOutBtnContainerProps {
  sidebar: number;
}
export const LogOutBtnContainer = styled.div<LogOutBtnContainerProps>`
  width: 100%;
  padding-left: ${props => (props.sidebar ? "46px" : "24px")};

  @media ${deviceRange.laptop} {
    padding: 1px 0 1px 23px;
  }
`;

export const Logo = styled.img``;

export const LogOutBtnText = styled.span`
  margin-left: 9px;
  font-family: "Kanit Light";
  font-size: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 4px;
`;

export const LogoContainer = styled.div`
  margin: 0 0 53px 26px;
  height: 40px;
`;
export const NameSiteLogo = styled.div`
  margin: auto;
  width: 150px;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #a1a9b1;
  text-align: center;
  margin-top: 20px;
`;
export const SidebarLogoWrapp = styled.div`
  display: flex;
  align-items: center;
  margin-left: -6px;
`;
