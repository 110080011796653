import React from "react";

import { BaseButton, ButtonProps } from "./BaseButton";
import { Colors } from "../../../environment";

export function GhostButton(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      color={"#A1A9B1"}
      backgroundColor={Colors.transparent}
      hoverColor={Colors.black[100]}
    />
  );
}
