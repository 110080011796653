import React from "react";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  FooterModal,
  ContentBlockWrapper
} from "./Modals.style";
import { ButtonPDF } from "./BaseButtonModal";

export const ModalContent9 = () => {
  return (
    <>
      <ContentBlockWrapper>
        <ContentBlock>
          <LeftBlockContent>
            <TextTitleWrapper>
              <TextTitle>För vem:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>Överlämnare</SecondText>
            </TextWrapper>
            <TextTitleWrapper>
              <TextTitle>Hur dokumentet kan användas:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Använd dig av listan för att få tips eller använd den som en
                checklista där du checkar av vilka saker du gjort.
              </SecondText>
            </TextWrapper>
          </LeftBlockContent>
          <RightBlockContent>
            <TextTitleWrapper>
              <TextTitle>Beskrivning:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Tips på saker att förbereda inför att din ersättare ska ta över
                uppdraget.
              </SecondText>
            </TextWrapper>
          </RightBlockContent>
        </ContentBlock>
      </ContentBlockWrapper>
      <FooterModal>
        <ButtonPDF
          downLink={
            "https://spridakunskapdev.blob.core.windows.net/spridadropbox/Exempel på saker att förbereda inför att en ny person ska börja - en lista för dig som ska överlämna ett uppdrag.pdf"
          }
        />
      </FooterModal>
    </>
  );
};
