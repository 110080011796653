import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  BlockExchangeButton1,
  BlockExchangeButton2,
  BlockExchangeButton3,
  DesignByBlock,
  FooterBlockLinks,
  FooterContactsBlock,
  FooterLine,
  FooterLink,
  GlobalStyle,
  HeaderBlockButtons,
  HeaderBlockLinks,
  HeaderBlockLogo,
  HeaderBurgerMenu,
  HeaderContainer,
  HeaderLink,
  HeaderMobileWrapper,
  HeadetButtonLog,
  HeadetButtonReg,
  ImageBurgerMenu,
  ImageCloseButton,
  ImageCloseButtonWrapper,
  LandingLogoHeader,
  LogIn,
  PopupBurgerMenu,
  SocialBlock,
  SocialBlockText,
  SocialBlockTextBefSoc,
  SocialBlockTextBlock
} from "../Landing/Landing.style";
import {
  BlockExchangeButtons,
  MainTextBeforeCard,
  MainTextThirdPage,
  WrapperForTextElement
} from "./UsefulDocuments.style";
import { useNavigation, useViewport } from "../../hooks";
import { Images } from "../../environment/theme/Images";
import { ContentCards } from "./ContentCards";
import {
  BlackMainText,
  FirstPage,
  FourthContentWrapper,
  FourthMainTextWrapper,
  FourthPage,
  ImageFirstPage,
  ImageSecondPage,
  MainText,
  MainTextWrapper,
  MormaTextWrapper,
  NormalText,
  SecondContentWrapper,
  SecondHat,
  SecondImageWrapper,
  SecondLeftBlock,
  SecondLeftContentWrapper,
  SecondPage,
  SecondRightBlock,
  SecondRightContentWrapperOne,
  SecondRightContentWrapperTwo,
  SecondTextMainWrapper,
  SecondTextWrapper,
  SecondWhitePlaceForTextLeft,
  SecondWhitePlaceForTextRightOne,
  SecondWhitePlaceForTextRightTwo,
  ThirdContentWrapper,
  ThirdMainTextWrapper,
  ThirdPage
} from "./UsefulDocuments.style";
import { deviceSize } from "../../consts";

export function UsefulDocuments() {
  const [slider, setSlider] = useState<number>(0);
  const { routes, history } = useNavigation();
  const [scroll, setScroll] = useState<boolean>(false);
  const [burgetMenuShow, setBurgetMenuShow] = useState<boolean>(false);
  const width = useViewport();

  useEffect(() => {
    function scrolling() {
      if (window.pageYOffset > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    }
    window.addEventListener("scroll", scrolling);
    return () => {
      window.removeEventListener("scroll", scrolling);
    };
  }, []);

  const scrollToElement = (el: any, a?: any) => {
    const y = el?.current?.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y - (a === "a" ? 0 : 130),
      behavior: "smooth"
    });
  };

  const handleRouteAndScroll = (link: string) => {
    history.push(routes.root);
    setTimeout(() => {
      const targetBlock = document.getElementById(link);
      targetBlock?.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }, 1000);
  };

  return (
    <>
      <GlobalStyle />
      {width >= parseInt(deviceSize.laptopM.min) ? (
        <HeaderContainer scroll={scroll}>
          <HeaderBlockLogo>
            <Link to="/">
              <LandingLogoHeader src={Images.landingLogo} alt="" />
            </Link>
          </HeaderBlockLogo>
          <HeaderBlockLinks>
            <HeaderLink onClick={() => handleRouteAndScroll("link1")}>
              Varför kunskapsöverföring?
            </HeaderLink>
            <HeaderLink onClick={() => handleRouteAndScroll("link3")}>
              För vem?
            </HeaderLink>
            <HeaderLink onClick={() => handleRouteAndScroll("link2")}>
              Om oss
            </HeaderLink>

            <HeaderLink
              backlight={1}
              onClick={() => history.push(routes.usefuldocuments)}
            >
              Användbart material
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.dictionary)}>
              Ordbok
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.blog)}>
              Blogg
            </HeaderLink>
          </HeaderBlockLinks>
          <HeaderBlockButtons>
            <HeadetButtonReg onClick={() => history.push(routes.signup)}>
              Skapa konto
            </HeadetButtonReg>
            <HeadetButtonLog
              customWidth={128}
              onClick={() => history.push(routes.login)}
            >
              Logga in
            </HeadetButtonLog>
          </HeaderBlockButtons>
        </HeaderContainer>
      ) : width >= parseInt(deviceSize.tablet.min) ? (
        <HeaderContainer scroll={scroll}>
          <HeaderBlockLogo>
            <Link to="/">
              <LandingLogoHeader src={Images.landingLogo} alt="" />
            </Link>
          </HeaderBlockLogo>
          <HeaderBlockButtons>
            <HeadetButtonReg onClick={() => history.push(routes.signup)}>
              Skapa konto
            </HeadetButtonReg>
            <HeadetButtonLog
              customWidth={128}
              onClick={() => history.push(routes.login)}
            >
              Logga in
            </HeadetButtonLog>
            <HeaderBurgerMenu onClick={() => setBurgetMenuShow(true)}>
              <ImageBurgerMenu src={Images.landingBurgerMenu} alt="" />
            </HeaderBurgerMenu>
          </HeaderBlockButtons>
        </HeaderContainer>
      ) : (
        <HeaderContainer scroll={scroll}>
          <HeaderMobileWrapper>
            <HeaderBlockLogo>
              <Link to="/">
                <LandingLogoHeader src={Images.landingLogo} alt="" />
              </Link>
            </HeaderBlockLogo>
            <HeaderBurgerMenu onClick={() => setBurgetMenuShow(true)}>
              <ImageBurgerMenu src={Images.landingBurgerMenu} alt="" />
            </HeaderBurgerMenu>
          </HeaderMobileWrapper>
          <HeaderMobileWrapper>
            <HeadetButtonReg onClick={() => history.push(routes.signup)}>
              Skapa konto
            </HeadetButtonReg>
            <LogIn onClick={() => history.push(routes.login)}>Logga in</LogIn>
          </HeaderMobileWrapper>
        </HeaderContainer>
      )}
      {width >= parseInt(deviceSize.laptop.min) ? (
        <PopupBurgerMenu isOpen={burgetMenuShow}>
          <HeaderBlockLinks>
            <HeaderLink onClick={() => handleRouteAndScroll("link1")}>
              Varför kunskapsöverföring?
            </HeaderLink>
            <HeaderLink onClick={() => handleRouteAndScroll("link3")}>
              För vem?
            </HeaderLink>
            <HeaderLink onClick={() => handleRouteAndScroll("link2")}>
              Om oss
            </HeaderLink>

            <HeaderLink
              backlight={1}
              onClick={() => history.push(routes.usefuldocuments)}
            >
              Användbart material
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.dictionary)}>
              Ordbok
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.blog)}>
              Blogg
            </HeaderLink>
          </HeaderBlockLinks>
          <ImageCloseButtonWrapper onClick={() => setBurgetMenuShow(false)}>
            <ImageCloseButton src={Images.landingCloseIcon} />
          </ImageCloseButtonWrapper>
        </PopupBurgerMenu>
      ) : (
        <PopupBurgerMenu isOpen={burgetMenuShow}>
          <ImageCloseButtonWrapper onClick={() => setBurgetMenuShow(false)}>
            <ImageCloseButton src={Images.landingCloseIcon} />
          </ImageCloseButtonWrapper>
          <HeaderBlockLinks>
            <HeaderLink onClick={() => handleRouteAndScroll("link1")}>
              Varför kunskapsöverföring?
            </HeaderLink>
            <HeaderLink onClick={() => handleRouteAndScroll("link3")}>
              För vem?
            </HeaderLink>
            <HeaderLink onClick={() => handleRouteAndScroll("link2")}>
              Om oss
            </HeaderLink>
            <HeaderLink
              backlight={1}
              onClick={() => history.push(routes.usefuldocuments)}
            >
              Användbart material
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.dictionary)}>
              Ordbok
            </HeaderLink>
            <HeaderLink onClick={() => history.push(routes.blog)}>
              Blogg
            </HeaderLink>
          </HeaderBlockLinks>
        </PopupBurgerMenu>
      )}
      <FirstPage>
        <MainTextWrapper>
          <MainText>ANVÄNDBART MATERIAL VID KUNSKAPSÖVERFÖRING</MainText>
        </MainTextWrapper>
        <MormaTextWrapper>
          <NormalText textAlign={"center"}>
            Nedan finns material som kan komma till nytta vid
            kunskapsöverlämningar. Dokumenten är skapade för att komma till
            nytta för dig som är processledare, överlämnare eller ersättare.
          </NormalText>
        </MormaTextWrapper>
        <ImageFirstPage src={Images.imageFirstPage} alt="" />
      </FirstPage>
      <SecondPage>
        <SecondTextWrapper>
          <BlackMainText>
            Ta reda på vem av följande kategorier du är innan du tar del av
            dokumenten
          </BlackMainText>
        </SecondTextWrapper>

        {width <= parseInt(deviceSize.mobile.max) ? (
          <SecondContentWrapper>
            {slider === 0 && (
              <SecondRightContentWrapperOne>
                <SecondHat>
                  <SecondTextMainWrapper>
                    <BlackMainText>Processledare</BlackMainText>
                  </SecondTextMainWrapper>
                  <SecondImageWrapper>
                    <ImageSecondPage src={Images.landingFileHands} alt="" />
                  </SecondImageWrapper>
                </SecondHat>
                <SecondWhitePlaceForTextRightOne>
                  <NormalText textAlign={"left"}>
                    Många organisationer arrangerar fysiska överlämningsträffar,
                    där de som ska avsluta sina uppdrag ska lämna över och dela
                    sina kunskaper och erfarenheter till de som ska ta över
                    uppdragen.
                  </NormalText>
                  <NormalText textAlign={"left"}>
                    På dessa träffar är det vanligt att det finns en ledare som
                    strukturerar upp överlämningarna och ordnar med
                    diskussionsövningar och hjälpdokument. Låt oss kalla denna
                    person för processledare.
                  </NormalText>
                </SecondWhitePlaceForTextRightOne>
              </SecondRightContentWrapperOne>
            )}
            {slider === 1 && (
              <SecondRightContentWrapperOne>
                <SecondHat>
                  <SecondTextMainWrapper>
                    <BlackMainText>Överlämnare</BlackMainText>
                  </SecondTextMainWrapper>
                  <SecondImageWrapper>
                    <ImageSecondPage src={Images.landingHeadMen} alt="" />
                  </SecondImageWrapper>
                </SecondHat>
                <SecondWhitePlaceForTextRightOne>
                  <NormalText textAlign={"left"}>
                    Överlämnare kallar vi dig som suttit en mandatperiod och som
                    ska överlämna ditt uppdrag till någon annan.
                  </NormalText>
                </SecondWhitePlaceForTextRightOne>
              </SecondRightContentWrapperOne>
            )}
            {slider === 2 && (
              <SecondRightContentWrapperOne>
                <SecondHat>
                  <SecondTextMainWrapper>
                    <BlackMainText>Ersättare</BlackMainText>
                  </SecondTextMainWrapper>
                  <SecondImageWrapper>
                    <ImageSecondPage src={Images.landingHeadWoman} alt="" />
                  </SecondImageWrapper>
                </SecondHat>
                <SecondWhitePlaceForTextRightOne>
                  <NormalText textAlign={"left"}>
                    Ersättare kallar vi dig som ska påbörja ett nytt uppdrag.
                  </NormalText>
                </SecondWhitePlaceForTextRightOne>
              </SecondRightContentWrapperOne>
            )}
          </SecondContentWrapper>
        ) : (
          <SecondContentWrapper>
            <SecondLeftBlock>
              <SecondHat>
                <SecondTextMainWrapper>
                  <BlackMainText>Processledare</BlackMainText>
                </SecondTextMainWrapper>
                <SecondImageWrapper>
                  <ImageSecondPage src={Images.landingFileHands} alt="" />
                </SecondImageWrapper>
              </SecondHat>
              <SecondWhitePlaceForTextLeft>
                <NormalText textAlign={"left"}>
                  Många organisationer arrangerar fysiska överlämningsträffar,
                  där de som ska avsluta sina uppdrag ska lämna över och dela
                  sina kunskaper och erfarenheter till de som ska ta över
                  uppdragen.
                </NormalText>
                <NormalText textAlign={"left"}>
                  På dessa träffar är det vanligt att det finns en ledare som
                  strukturerar upp överlämningarna och ordnar med
                  diskussionsövningar och hjälpdokument. Låt oss kalla denna
                  person för processledare.
                </NormalText>
              </SecondWhitePlaceForTextLeft>
            </SecondLeftBlock>
            <SecondRightBlock>
              <SecondRightContentWrapperOne>
                <SecondHat>
                  <SecondTextMainWrapper>
                    <BlackMainText>Överlämnare</BlackMainText>
                  </SecondTextMainWrapper>
                  <SecondImageWrapper>
                    <ImageSecondPage src={Images.landingHeadMen} alt="" />
                  </SecondImageWrapper>
                </SecondHat>
                <SecondWhitePlaceForTextRightOne>
                  <NormalText textAlign={"left"}>
                    Överlämnare kallar vi dig som suttit en mandatperiod och som
                    ska överlämna ditt uppdrag till någon annan.
                  </NormalText>
                </SecondWhitePlaceForTextRightOne>
              </SecondRightContentWrapperOne>
              <SecondRightContentWrapperTwo>
                <SecondHat>
                  <SecondTextMainWrapper>
                    <BlackMainText>Ersättare</BlackMainText>
                  </SecondTextMainWrapper>
                  <SecondImageWrapper>
                    <ImageSecondPage src={Images.landingHeadWoman} alt="" />
                  </SecondImageWrapper>
                </SecondHat>
                <SecondWhitePlaceForTextRightTwo>
                  <NormalText textAlign={"left"}>
                    Ersättare kallar vi dig som ska påbörja ett nytt uppdrag.
                  </NormalText>
                </SecondWhitePlaceForTextRightTwo>
              </SecondRightContentWrapperTwo>
            </SecondRightBlock>
          </SecondContentWrapper>
        )}

        {width <= parseInt(deviceSize.mobile.max) && (
          <BlockExchangeButtons>
            <BlockExchangeButton1 onClick={() => setSlider(0)} check={slider} />
            <BlockExchangeButton2 onClick={() => setSlider(1)} check={slider} />
            <BlockExchangeButton3 onClick={() => setSlider(2)} check={slider} />
          </BlockExchangeButtons>
        )}
      </SecondPage>
      <ThirdPage>
        <ThirdMainTextWrapper>
          <MainTextThirdPage>ÖVNINGAR CHECKLISTOR MALLAR</MainTextThirdPage>
        </ThirdMainTextWrapper>
        <ThirdContentWrapper>
          <ContentCards contentNumber={3} />
        </ThirdContentWrapper>
      </ThirdPage>
      <FourthPage>
        <FourthMainTextWrapper>
          <MainTextBeforeCard>TIPS OCH GODA RÅD</MainTextBeforeCard>
        </FourthMainTextWrapper>
        <FourthContentWrapper>
          <ContentCards contentNumber={4} />
        </FourthContentWrapper>
      </FourthPage>
      <FourthPage>
        <FourthMainTextWrapper>
          <MainTextBeforeCard>
            Filmer i ämnet kunskapsöverföring
          </MainTextBeforeCard>
        </FourthMainTextWrapper>
        <FourthContentWrapper>
          <ContentCards contentNumber={5} />
        </FourthContentWrapper>
      </FourthPage>
      <FooterLine />
      <FooterContactsBlock>
        <FooterBlockLinks>
          <FooterLink onClick={() => handleRouteAndScroll("link1")}>
            Varför kunskapsöverföring?
          </FooterLink>
          <FooterLink onClick={() => handleRouteAndScroll("link3")}>
            För vem?
          </FooterLink>
          <FooterLink onClick={() => handleRouteAndScroll("link2")}>
            Om oss
          </FooterLink>
        </FooterBlockLinks>
        <img
          src={Images.landingFooterlogo}
          onClick={() => scrollToElement(0)}
          style={{ cursor: "pointer" }}
          alt=""
        />
        <SocialBlock>
          <SocialBlockText>Följ oss i sociala medier</SocialBlockText>
          <SocialBlockTextBlock>
            <a href="https://www.instagram.com/forbundetviunga/" target="blank">
              <img src={Images.landingInsta} alt="" />
            </a>
            <a href="https://www.facebook.com/pg/Digitalt-verktyg-f%C3%B6r-kunskaps%C3%B6verf%C3%B6ring-110620453810556/">
              <img src={Images.landingFacebook} alt="" />
            </a>
            <a
              href="https://www.youtube.com/user/ForbundetViUnga"
              target="blank"
            >
              <img src={Images.landingYoutube} alt="" />
            </a>
          </SocialBlockTextBlock>
          <SocialBlockTextBlock>
            <img src={Images.landingVector} alt="" />
            <SocialBlockTextBefSoc>
              Alla rättigheter förbehållna
            </SocialBlockTextBefSoc>
          </SocialBlockTextBlock>
        </SocialBlock>
      </FooterContactsBlock>
      <DesignByBlock></DesignByBlock>
    </>
  );
}
