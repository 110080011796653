import React from "react";

import { RadioBlock } from "./Fields.style";
import { Radio } from "../../../ui";
import { removeTags } from "../../../../helpers";

interface RadioGroupProps {
  fieldData: any;
  fieldValue: string;
  onFieldChange: (name: string, value: Array<string>) => void;
}

export const RadioGroup = ({
  fieldData,
  fieldValue,
  onFieldChange
}: RadioGroupProps) => {
  return (
    <RadioBlock>
      {fieldData.values.map(({ label, value }: any) => (
        <Radio
          key={removeTags(value || "")}
          label={removeTags(label || "")}
          checked={fieldValue === value}
          name={removeTags(fieldData.name || "")}
          value={removeTags(value || "")}
          onChange={() =>
            onFieldChange(removeTags(fieldData.name || ""), [value])
          }
        />
      ))}
    </RadioBlock>
  );
};
