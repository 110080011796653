import React from "react";

import { UserAccount } from "@spridakunskap/api";

import {
  MemberCardContainer,
  CardAvatar,
  CardInfo,
  CardInfoTitle,
  CardInfoEmail,
  CardOptions,
  OptionsIconContainer
} from "./MemberCard.style";
import { Icon, Avatar, AvatarSize } from "../../ui";
import { IconType } from "../../../consts";

interface MemberCardProps {
  member: UserAccount;
  onClick: (member: UserAccount) => void;
}

export function MemberCard({ member, onClick }: MemberCardProps) {
  function handleOnCardClick() {
    onClick(member);
  }

  return (
    <MemberCardContainer onClick={handleOnCardClick}>
      <CardAvatar>
        <Avatar image={member.avatar?.url} size={AvatarSize.LG} />
      </CardAvatar>
      <CardInfo>
        <CardInfoTitle>{`${member.firstName || "User"} ${member.lastName ||
          "User"}`}</CardInfoTitle>
        <CardInfoEmail>{member.email}</CardInfoEmail>
      </CardInfo>
      <CardOptions>
        <OptionsIconContainer>
          <Icon name={IconType.EpEdit} />
        </OptionsIconContainer>
      </CardOptions>
    </MemberCardContainer>
  );
}
