import React, { useCallback, useMemo } from "react";
import { Chapter } from "../../../../../../api";

import { processedFields, FieldTypes } from "./../../../../consts";
import { lastIndexOf, removeTags } from "./../../../../helpers";
import { DuplicateOrRemoveQuestionBlockContainer } from "./Fields.style";
import { useTranslation } from "../../../../hooks";
import {
  ButtonLagg,
  ButtonRemove
} from "./DuplicateOrRemoveQuestionBlockButtons.style";
import { Images } from "../../../../environment";
interface DuplicateOrRemoveQuestionBlockProps {
  fieldData: any;
  chapter: Chapter;
  chapterClickButton: boolean;
  updateChapter: (chapter: Chapter) => void;
}

export const DuplicateOrRemoveQuestionBlock = ({
  chapterClickButton,
  fieldData,
  chapter,
  updateChapter
}: DuplicateOrRemoveQuestionBlockProps) => {
  const translate = useTranslation();

  const chapterTemplate = chapter.template ? JSON.parse(chapter.template) : [];

  const getIndexOfThisElemenentInTemplate = () =>
    chapterTemplate.findIndex(
      (templateEl: { name: string }) => templateEl.name === fieldData.name
    );

  const getQuestionBlockBeforeButton = useCallback(() => {
    const indexOfThisElementInTemplate = getIndexOfThisElemenentInTemplate();
    const templateBeforeButton = chapterTemplate.slice(
      0,
      indexOfThisElementInTemplate
    );

    const startQuestionBlockIdx = lastIndexOf(
      templateBeforeButton,
      FieldTypes.startQuestionBlock,
      "type"
    );

    const endQuestionBlockIdx = lastIndexOf(
      templateBeforeButton,
      FieldTypes.endQuestionBlock,
      "type"
    );

    return templateBeforeButton.slice(
      startQuestionBlockIdx,
      endQuestionBlockIdx + 1
    );
  }, [chapter.template, chapterClickButton]);

  const onAddQuestionBlockClick = () => {
    const indexOfThisElementInTemplate = getIndexOfThisElemenentInTemplate();

    const templateBeforeButton = chapterTemplate.slice(
      0,
      indexOfThisElementInTemplate
    );
    const templateAfterButton = chapterTemplate.slice(
      indexOfThisElementInTemplate
    );
    const questionBlock = getQuestionBlockBeforeButton();

    const questionBlockCopy = questionBlock.map(
      (field: { type: string; name: string; isCopy: boolean }) => {
        const isStartQuestionBlock =
          field.type === FieldTypes.startQuestionBlock;
        const isEndQuestionBlock = field.type === FieldTypes.endQuestionBlock;
        const isProcessedField = processedFields.indexOf(field.type) >= 0;

        if (!isStartQuestionBlock && !isEndQuestionBlock && !isProcessedField)
          return field;

        let newName = `${field.name}_duplicate_1`;

        if (field.isCopy) {
          const copyOrder = field.name.match(/_(\d)/);

          if (copyOrder) {
            newName = field.name.replace(/_(\d)/, `_${+copyOrder[1] + 1}`);
          }
        }
        return {
          ...field,
          name: newName,
          isCopy: true,
          required: false
        };
      }
    );

    const updatedTemplate = JSON.stringify([
      ...templateBeforeButton,
      ...questionBlockCopy,
      ...templateAfterButton
    ]);

    updateChapter({
      ...chapter,
      template: updatedTemplate
    });
  };

  const onRemoveQuestionBlockClick = () => {
    const fieldIdx = chapterTemplate.findIndex(
      (field: any) => fieldData.name === field.name
    );

    const templateBeforeButton = chapterTemplate.slice(0, fieldIdx);

    const startQuestionBlockIdx = lastIndexOf(
      templateBeforeButton,
      "startQuestionBlock",
      "type"
    );

    const endQuestionBlockIdx = lastIndexOf(
      templateBeforeButton,
      "endQuestionBlock",
      "type"
    );

    const updatedTemplate = JSON.stringify([
      ...chapterTemplate.slice(0, startQuestionBlockIdx),
      ...chapterTemplate.slice(endQuestionBlockIdx + 1)
    ]);

    updateChapter({
      ...chapter,
      template: updatedTemplate
    });
  };

  const showRemoveBlockButton = useMemo(() => {
    let hasCopiedBlockBeforeButton = false;
    const questionBlockBeforeButton = getQuestionBlockBeforeButton();

    if (!questionBlockBeforeButton.length) return hasCopiedBlockBeforeButton;

    hasCopiedBlockBeforeButton = questionBlockBeforeButton[0].isCopy;

    return hasCopiedBlockBeforeButton;
  }, [chapter.template]);

  return (
    <DuplicateOrRemoveQuestionBlockContainer>
      <ButtonLagg onClick={onAddQuestionBlockClick}>
        <img
          src={Images.landingPlusIcon}
          style={{ marginRight: "5px" }}
          alt=""
        />
        {removeTags(fieldData.label || "")}
      </ButtonLagg>

      {showRemoveBlockButton ? (
        <ButtonRemove onClick={onRemoveQuestionBlockClick}>
          <img
            src={Images.landingTrashIcon}
            style={{ marginRight: "5px" }}
            alt=""
          />
          {translate(
            ({ handoversPage }) => handoversPage.formBuilder.removeBlock
          )}
        </ButtonRemove>
      ) : null}
    </DuplicateOrRemoveQuestionBlockContainer>
  );
};
