import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { RestorePasswordData } from "../../../../../api";

import { UserAccountFragment } from "../fragments";

const CREATE_USER_IDENTITY_MUTATION = gql`
  mutation CreateUserIdentity($password: String!, $token: String!) {
    createUserIdentity(password: $password, token: $token) {
      token
      user {
        ...UserAccountFragment
      }
    }
  }
  ${UserAccountFragment}
`;

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePasswordForIdentity($password: String!, $email: String!) {
    updatePasswordForIdentity(password: $password, email: $email)
  }
`;

export function useUpdatePassword() {
  return useMutation(UPDATE_PASSWORD_MUTATION);
}

export function useCreateUserIdentityMutation() {
  return useMutation(CREATE_USER_IDENTITY_MUTATION, {
    onError: () => {}
  });
}

const RESTORE_PASSWORD_FOR_IDENTITY = gql`
  mutation RestorePasswordForIdentity($data: RestorePasswordForIdentityInput!) {
    restorePasswordForIdentity(data: $data)
  }
`;

export function useRestorePasswordForIdentityMutation() {
  return useMutation<RestorePasswordData>(RESTORE_PASSWORD_FOR_IDENTITY, {
    onError: () => {}
  });
}
