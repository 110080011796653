import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  UpdateHandoverData,
  UpdateDraftChapterData,
  UpdateDraftChapterInput
} from "../../../../../api";
import { UserAccount } from "../../../../../api/dist/generated";

import { HandoverFragment } from "./../fragments";

const ADD_CHAPTER = gql`
  mutation AddChapter($data: UpdateHandoverInput!) {
    updateHandover(data: $data) {
      id
      chapters {
        id
        template
        name
        chapterType
      }
    }
  }
`;

const UPDATE_HANDOVER = gql`
  mutation UpdateHandover($data: UpdateHandoverInput!) {
    updateHandover(data: $data) {
      ...HandoverFragment
    }
  }
  ${HandoverFragment}
`;

const UPDATE_DRAFT_CHAPTER = gql`
  mutation UpdateDraftChapter($data: UpdateDraftChapterInput!) {
    updateDraftChapter(data: $data)
  }
`;

const UPLOAD_FILE = gql`
  mutation UploadEmbeddedFile($data: UploadEmbeddedFileInput!) {
    uploadEmbeddedFile(data: $data) {
      id
      draftForm
    }
  }
`;

const DELETE_FILE = gql`
  mutation DeleteEmbeddedFile($data: DeleteEmbeddedFileInput!) {
    deleteEmbeddedFile(data: $data) {
      id
    }
  }
`;

const GET_FILE_LIST = gql`
  mutation GetEmbeddedFilesList($data: UserAccountWhereUniqueInput!) {
    getEmbeddedFilesList(data: $data) {
      fieldName
      fileName
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation uploadImageForForm($data: UploadEmbeddedFileInput!) {
    uploadImageForForm(data: $data)
  }
`;

export function useAddChapterToHandoverMutation() {
  return useMutation<UpdateHandoverData>(ADD_CHAPTER, { onError: () => {} });
}

export function useUpdateHandoverMutation() {
  return useMutation<UpdateHandoverData>(UPDATE_HANDOVER, {
    onError: () => {}
  });
}

export function useUpdateFormMutation() {
  return useMutation<UpdateDraftChapterData, { data: UpdateDraftChapterInput }>(
    UPDATE_DRAFT_CHAPTER,
    { onError: () => {} }
  );
}

export function useUploadEmbeddedFileMutation() {
  return useMutation<any>(UPLOAD_FILE, {
    //, { data: UploadEmbeddedFileInput }
    onError: () => {}
  });
}

export function useUploadImageMutation() {
  return useMutation<boolean>(UPLOAD_IMAGE, {
    onError: () => {}
  });
}

export function useDeleteEmbeddedFileMutation() {
  return useMutation<UserAccount>(DELETE_FILE, { onError: () => {} });
}

export function useGetEmbeddedFilesListMutation() {
  return useMutation<any>(GET_FILE_LIST, { onError: () => {} });
}
