export const data = {
  title: "FAQ",
  rows: [
    {
      title: "Vad är Spridakunskap.se?",
      content: `Spridakunskap.se innehåller ett verktyg som finns till för att underlätta vid överlämningar av förtroendeuppdrag i ideella organisationer, förbund och föreningar. Den som ska lämna sitt uppdrag fyller i svaren på frågorna i verktyget. Svaren sammanfattas sedan i ett pedagogiskt dokument som den som ska ta över förtroendeuppdraget får ta del av. Dokumentet ska fungera som en skriftlig introduktion.`
    },
    {
      title: "Vem är verktyget till för?",
      content:
        "Spridakunskap.se är skapat av Förbundet Vi Unga men är till för alla som är engagerade i ideella ungdomsorganisationer och som ska överlämna sina förtroendevalda uppdrag."
    },
    {
      title: "Vad är verktyget till för?",
      content: `Verktyget är till för den som ska överlämna ett förtroendevalt uppdrag till någon annan. `
    },
    {
      title: "I vilka sammanhang kan verktyget användas?",
      content: `Verktyget används i samband med att någon ska avsluta sitt förtroendeuppdrag och någon annan ska ta över det.`
    },
    {
      title:
        "Kan verktyget användas i andra sammanhang än när förtroendevalda uppdrag ska överlämnas?",
      content: `Verktyget är i första hand framtaget för ungdomar som ska överlämna förtroendevalda uppdrag i ideella ungdomsorganisationer. Skulle verktyget komma till nytta för någon annan i något annan sammanhang så är det fritt fram att använda verktyget även då.`
    },
    {
      title:
        "Varför är verktyget anpassat just för förtroendevalda i ideella ungdomsorganisationer?",
      content: `I många ideella ungdomsorganisationer sker skiften av förtroendevalda i snabbare takt än i andra organisationer eftersom att unga “växer ur” sina organisationer och väljer att gå vidare. När dessa skiften sker så frekvent är risken stor att kunskap försvinner i samma takt som ungdomarna går vidare. Verktyget är framtaget för att förhindra att kunskap försvinner ur organisationerna när skiftena sker.`
    },
    {
      title: "Vad betyder förtroendevald?",
      content: `Förtroendevald kallas den som blivit vald i en organisation för att ta hand om ett speciellt uppdrag. Uppdraget kan handla om att engagera sig i organisationens styrelse eller om att ta hand om uppgifter som ligger utanför styrelsearbetet.`
    },
    {
      title: "Kostar det något att använda?",
      content: `Verktyget är gratis att använda.`
    },
    {
      title: "Hur delar jag min överlämning?",
      content: `Du fyller i e-postadressen till den/de som ska ta del av överlämningen när du påbörjat själva överlämningen.`
    },
    {
      title:
        "Kan jag välja att svara på endast några av frågorna i överlämningen?",
      content: `Du har möjlighet att hoppa över de delar av överlämningen som du inte önskar fylla i. Du lägger själv ambitionsnivån på din överlämning.`
    },
    {
      title: "Hur ändrar jag min profilbild?",
      content: `Gå in på din profil i menyn till vänster och välj sedan “ladda upp nytt”.`
    },
    {
      title: "Kan jag dela min överlämning till fler än en person?",
      content: `Ja, fyll i e-postadress till den/de du vill dela överlämningen med när du påbörjat själva överlämningen.`
    },
    {
      title: "Vem står bakom sidan?",
      content: `Spridakunskap.se har skapats av Förbundet Vi Unga med finansiering från Allmänna Arvsfonden <a href='https://www.arvsfonden.se/'>arvsfonden.se</a>. Läs mer om Förbundet Vi Unga (<a href='http://viunga.se/'>viunga.se</a>)`
    },
    {
      title: "Varför har Förbundet Vi Unga skapat detta verktyg?",
      content: `I många ideella ungdomsorganisationer sker skiften av förtroendevalda i snabbare takt än i andra organisationer eftersom att unga “växer ur” sina organisationer och väljer att gå vidare. När dessa skiften sker frekvent är risken stor att kunskap försvinner i samma takt som ungdomarna går vidare. Verktyget är framtaget för att förhindra att kunskap försvinner ur organisationer.`
    },
    {
      title: "Kan jag skriva ut min överlämning?",
      content: `Ja, välj skriv ut när du sparat din överlämning.`
    },
    {
      title: "Vad är Allmänna Arvsfonden?",
      content: `Allmänna Arvsfonden är en statlig fond. Till Arvsfonden går pengar från dödsbon när någon arvinge ej finns. Läs mer om Allmänna Arvsfonden på <a href='https://www.arvsfonden.se/'>arvsfonden.se</a>`
    },
    {
      title:
        "Jag har flera uppdrag – kan jag göra flera överlämningar samtidigt?",
      content: `Nej, du kan enbart göra en överlämning i taget. Om du vill göra flera överlämningar på samma gång behöver du skapa ett konto för varje överlämning. Däremot kan du påbörja en ny överlämning direkt efter att du avslutat en annan, utan att skapa ett nytt konto.`
    },
    {
      title: "Hur jobbar ni med GDPR?",
      content:
        "Din personliga information lagras på ett säkert ställe. Vi lämnar aldrig ut dina uppgifter till tredje part."
    },
    {
      title:
        "Behöver mottagaren av överlämningen ha ett konto på Spridakunskap för att ta emot överlämningen?",
      content:
        "Nej, mottagaren behöver inte ett eget konto för att motta överlämningen. Mottagaren får överlämningen som en PDF skickad till sin mail"
    },
    {
      title:
        "Kan jag lägga in min logga och organisationens färger i överlämningen?",
      content: "Ja, du kan lägga in logga och färg i Profil - Organisation."
    }
  ]
};
