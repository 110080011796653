export const Dictionary = {
  buttons: {
    done: "buttons.done",
    login: "buttons.login",
    logout: "buttons.logout",
    signup: "buttons.signup",
    invite: "buttons.invite",
    create: "buttons.create",
    save: "buttons.save",
    saveChanges: "buttons.saveChanges",
    update: "buttons.update",
    updateProfile: "buttons.updateProfile",
    cancel: "buttons.cancel",
    open: "buttons.open",
    select: "buttons.select",
    decline: "buttons.decline",
    next: "buttons.next",
    prev: "buttons.prev",
    continue: "buttons.continue",
    manage: "buttons.manage",
    sortOrFilter: "buttons.sortOrFilter",
    search: "buttons.search",
    searchByName: "buttons.searchByName",
    quickSearch: "buttons.quickSearch",
    message: "buttons.message",
    profilePage: "buttons.profilePage",
    showMore: "buttons.showMore",
    viewAsRegularUser: "buttons.viewAsRegularUser",
    upload: "buttons.upload",
    uploadNew: "buttons.uploadNew",
    uploadImage: "buttons.uploadImage",
    remove: "buttons.remove",
    add: "buttons.add",
    change: "buttons.change",
    changeEmail: "buttons.changeEmail",
    changeSettings: "buttons.changeSettings",
    changeStatus: "buttons.changeStatus",
    resetPassword: "buttons.resetPassword",
    deactivateAccount: "buttons.deactivateAccount",
    delete: "buttons.delete",
    crop: "buttons.crop",
    edit: "buttons.edit",
    move: "buttons.move",
    preview: "buttons.preview",
    uploadAdditionalFiles: "buttons.uploadAdditionalFiles",
    unpublish: "buttons.unpublish",
    approveSuggestionDocument: "buttons.approveSuggestionDocument",
    choose: "buttons.choose",
    manageProfile: "buttons.manageProfile",
    list: "buttons.list",
    upcoming: "buttons.upcoming",
    copy: "buttons.copy",
    addDocument: "buttons.addDocument",
    ok: "buttons.ok",
    viewProfile: "buttons.viewProfile",
    quickInfo: "buttons.quickInfo",
    temporaryHide: "buttons.temporaryHide",
    rename: "buttons.rename",
    removeFromAttachments: "buttons.removeFromAttachments",
    uploadFile: "buttons.uploadFile",
    uploadConfirmButton: "buttons.uploadConfirmButton",
    uploadFileButton: "buttons.uploadFileButton",
    uploadModalHeader: "buttons.uploadModalHeader",
    page: "buttons.page",
    event: "buttons.event",
    sendRecommendation: "buttons.sendRecommendation",
    saveAndContinueLater: "buttons.saveAndContinueLater"
  },
  inputs: {
    email: {
      label: "inputs.email.label",
      placeholder: "inputs.email.placeholder",
      required: "inputs.email.required",
      invalid: "inputs.email.invalid"
    },
    verifyEmail: {
      label: "inputs.verifyEmail.label",
      placeholder: "inputs.verifyEmail.placeholder",
      required: "inputs.verifyEmail.required",
      invalid: "inputs.verifyEmail.invalid"
    },
    password: {
      label: "inputs.password.label",
      placeholder: "inputs.password.placeholder",
      enterPassword: "inputs.password.enterPassword",
      required: "inputs.password.required",
      invalid: "inputs.password.invalid",
      minLength: "inputs.password.minLength"
    },
    confirmPassword: {
      label: "inputs.confirmPassword.label",
      placeholder: "inputs.confirmPassword.placeholder",
      required: "inputs.confirmPassword.required",
      invalid: "inputs.confirmPassword.invalid"
    },
    firstName: {
      label: "inputs.firstName.label",
      placeholder: "inputs.firstName.placeholder",
      required: "inputs.firstName.required"
    },
    lastName: {
      label: "inputs.lastName.label",
      placeholder: "inputs.lastName.placeholder",
      required: "inputs.lastName.required"
    },
    phone: {
      label: "inputs.phone.label",
      placeholder: "inputs.phone.placeholder",
      required: "inputs.phone.required",
      invalid: "inputs.phone.invalid"
    },
    color: {
      label: "inputs.color.label",
      placeholder: "inputs.color.placeholder",
      invalid: "inputs.color.invalid"
    },
    country: {
      label: "inputs.country.label",
      required: "inputs.country.required",
      invalid: "inputs.country.invalid"
    },
    label: {
      label: "inputs.label.label",
      required: "inputs.label.required"
    },
    description: {
      label: "inputs.description.label",
      placeholder: "inputs.description.placeholder"
    },
    personalInformation: {
      label: "inputs.personalInformation.label",
      placeholder: "inputs.personalInformation.placeholder",
      required: "inputs.personalInformation.required"
    },
    name: {
      label: "inputs.name.label",
      placeholder: "inputs.name.placeholder",
      required: "inputs.name.required"
    },
    location: {
      label: "inputs.location.label",
      placeholder: "inputs.location.placeholder",
      invalid: "inputs.location.invalid"
    },
    message: {
      label: "inputs.message.label",
      required: "inputs.message.required"
    }
  },
  messages: {
    login: {
      notAnAdmin: "messages.login.notAnAdmin",
      alreadyLoggedIn: "messages.login.alreadyLoggedIn",
      workspaceNotFound: "messages.login.workspaceNotFound"
    },
    signUp: {
      success: "messages.signUp.success",
      organizationInfoFailed: "messages.signUp.organizationInfoFailed",
      countryDetectionFailed: "messages.signUp.countryDetectionFailed"
    },
    fileUpload: {
      couldNotSave: "messages.fileUpload.couldNotSave",
      notSupportedType: "messages.fileUpload.notSupportedType"
    },
    inviteMember: {
      success: "messages.inviteMember.success"
    },
    sendRecommendation: {
      success: "messages.sendRecommendation.success"
    },
    createdChapter: "messages.createdChapter",
    accountDeleted: "messages.accountDeleted",
    passwordUpdated: "messages.passwordUpdated",
    documentSaved: "messages.documentSaved",
    documentSent: "messages.documentSent",
    accountUpdated: "messages.accountUpdated",
    emailVerified: "messages.emailVerified",
    codeSentEmail: "messages.codeSentEmail"
  },
  errors: {
    missedRequiredFields: "errors.missedRequiredFields",
    tryAgain: "errors.tryAgain",
    userNotFound: "errors.userNotFound"
  },
  sideMenuLinks: {
    settings: "sideMenuLinks.settings",
    ongoingHandovers: "sideMenuLinks.ongoingHandovers",
    completedHandovers: "sideMenuLinks.completedHandovers",
    handovers: "sideMenuLinks.handovers",
    profile: "sideMenuLinks.profile",
    faq: "sideMenuLinks.faq",
    editor: "sideMenuLinks.editor",
    members: "titles.members",
    history: "sideMenuLinks.history"
  },
  titles: {
    addNewUserToWorkspace: "titles.addNewUserToWorkspace",
    createNewCommittee: "titles.createNewCommittee",
    profileInfo: "titles.profileInfo",
    meetingInfo: "titles.meetingInfo",
    documentInfo: "titles.documentInfo",
    overview: "titles.overview",
    upcomingEvents: "titles.upcomingEvents",
    committees: "titles.committees",
    allCommittees: "titles.allCommittees",
    numberOfCommittees: "titles.numberOfCommittees",
    members: "titles.members",
    member: "titles.member",
    allMembers: "titles.allMembers",
    numberOfUsers: "titles.numberOfUsers",
    calendar: "titles.calendar",
    allMeetings: "titles.allMeetings",
    meetings: "titles.meetings",
    documents: "titles.documents",
    polls: "titles.polls",
    information: "titles.information",
    generalInformation: "titles.generalInformation",
    membership: "titles.membership",
    personalInfo: "titles.personalInfo",
    email: "titles.email",
    phone: "titles.phone",
    totalDuration: "titles.totalDuration",
    additionalSettings: "titles.additionalSettings",
    agendaList: "titles.agendaList",
    invitedMembers: "titles.invitedMembers",
    allEntitiesAttachedOrRelated: "titles.allEntitiesAttachedOrRelated",
    workspace: "titles.workspace",
    userAdded: "titles.userAdded",
    lastUserActivity: "titles.lastUserActivity",
    lastProfileUpdated: "titles.lastProfileUpdated",
    party: "titles.party",
    partyInfoVisibleText: "titles.partyInfoVisibleText",
    infoAboutCommitteeMembership: "titles.infoAboutCommitteeMembership",
    relatedItems: "titles.relatedItems",
    lastUpdated: "titles.lastUpdated",
    lastUpdatedBy: "titles.lastUpdatedBy",
    representors: "titles.representors",
    invited: "titles.invited",
    agendaItems: "titles.agendaItems",
    items: "titles.items",
    replies: "titles.replies",
    meetingMembersAndGuestsMessage: "titles.meetingMembersAndGuestsMessage",
    userNotRespondedYet: "titles.userNotRespondedYet",
    updatedBy: "titles.updatedBy",
    attachedBy: "titles.attachedBy",
    accountSettings: "titles.accountSettings",
    accountEmail: "titles.accountEmail",
    userAccount: "titles.userAccount",
    loginSettings: "titles.loginSettings",
    loginOptions: "titles.loginOptions",
    password: "titles.password",
    deleteUserAccountButKeepData: "titles.deleteUserAccountButKeepData",
    deleteUserAccountAndAllUserInfo: "titles.deleteUserAccountAndAllUserInfo",
    learnMoreAboutGlobal: "titles.learnMoreAboutGlobal",
    inviteUserToCommittee: "titles.inviteUserToCommittee",
    addCounterToEventPreview: "titles.addCounterToEventPreview",
    turnOnAutomaticReplacment: "titles.turnOnAutomaticReplacment",

    noSelectedDocuments: "titles.noSelectedDocuments",
    documentsInThisAgendaItem: "titles.documentsInThisAgendaItem",
    sortItems: "titles.sortItems",
    filterItems: "titles.filterItems",
    showFirst: "titles.showFirst",
    newCommittee: "titles.newCommittee",
    selectBlocksForCommitteeStructure:
      "titles.selectBlocksForCommitteeStructure",
    accessAndVisibility: "titles.accessAndVisibility",
    hidenFromEveryone: "titles.hidenFromEveryone",
    newFolderIsHidenForOtherPeople: "titles.newFolderIsHidenForOtherPeople",
    committeOverviewPage: "titles.committeOverviewPage",
    membersPage: "titles.membersPage",
    generalDocuments: "titles.generalDocuments",
    doubleClickToSetDuration: "titles.doubleClickToSetDuration",
    draft: "titles.draft",
    committeProtocols: "titles.committeProtocols",
    topics: "titles.topics",
    formBuilder: "titles.formBuilder",
    partners: "titles.partners",
    changePassword: "titles.changePassword",
    confirmChangePassword: "titles.confirmChangePassword"
  },
  tabs: {
    general: "tabs.general",
    activity: "tabs.activity",
    personalInfo: "tabs.personalInfo",
    accountSettings: "tabs.accountSettings",
    overview: "tabs.overview",
    information: "tabs.information",
    orgainsation: "tabs.orgainsation"
  },
  authNav: {
    logIn: "authNav.logIn",
    signUp: "authNav.signUp",
    createAccount: "authNav.createAccount",
    contact: "authNav.contact",
    termsAndConditions: "authNav.termsAndConditions",
    helpAndSupport: "authNav.helpAndSupport"
  },
  signUpPage: {
    step: "signUpPage.step",
    signUp: "signUpPage.signUp",
    verify: "signUpPage.verify",
    termsText: "signUpPage.termsText",
    and: "signUpPage.and",
    accountCreated: "signUpPage.accountCreated"
  },
  loginPage: {
    login: "loginPage.login",
    asAnOrganizationMember: "loginPage.asAnOrganizationMember",
    forgotPassword: "loginPage.forgotPassword",
    termsText: "loginPage.termsText",
    and: "signUpPage.and"
  },
  handoversPage: {
    handovers: "handoversPage.handovers",
    nextChapter: "handoversPage.nextChapter",
    prevChapter: "handoversPage.prevChapter",
    addNewChapter: "handoversPage.addNewChapter",
    updateForm: "handoversPage.updateForm",
    send: "handoversPage.send",
    from: "handoversPage.from",
    stepsCompleted: "handoversPage.stepsCompleted",
    step: "signUpPage.step",
    requiredFields: "handoversPage.requiredFields",
    sendHandoverModal: {
      sendFile: "handoversPage.sendHandoverModal.sendFile",
      enterEmail: "handoversPage.sendHandoverModal.enterEmail"
    },
    formBuilder: {
      selectOption: "handoversPage.formBuilder.selectOption",
      removeBlock: "handoversPage.formBuilder.removeBlock"
    }
  },
  handoverPage: {
    handover: "handoverPage.handover",
    handoverStart: "handoverPage.handoverStart",
    handoverContinue: "handoverPage.handoverContinue",
    firstHandover: "handoverPage.firstHandover",
    transferKnowledge: "handoverPage.transferKnowledge"
  },
  thankYou: {
    title: "thankYou.title",
    sign: "thankYou.sign",
    niceDay: "thankYou.niceDay"
  },
  recommendToolPage: {
    recommendTool: "recommendToolPage.recommendTool"
  },
  tooltips: {
    moreActions: "tooltips.moreActions"
  },
  privacyAndTerms: {
    addNewUserToWorkspace: "privacyAndTerms.addNewUserToWorkspace",
    createNewCommittee: "privacyAndTerms.createNewCommittee",
    profileInfo: "privacyAndTerms.profileInfo",
    meetingInfo: "privacyAndTerms.meetingInfo",
    documentInfo: "privacyAndTerms.documentInfo",
    overview: "privacyAndTerms.overview",
    upcomingEvents: "privacyAndTerms.upcomingEvents",
    committees: "privacyAndTerms.committees",
    allCommittees: "privacyAndTerms.allCommittees",
    numberOfCommittees: "privacyAndTerms.numberOfCommittees",
    members: "privacyAndTerms.members",
    allMembers: "privacyAndTerms.allMembers",
    numberOfUsers: "privacyAndTerms.numberOfUsers",
    calendar: "privacyAndTerms.calendar",
    allMeetings: "privacyAndTerms.allMeetings",
    meetings: "privacyAndTerms.meetings",
    documents: "privacyAndTerms.documents",
    polls: "privacyAndTerms.polls",
    information: "privacyAndTerms.information",
    generalInformation: "privacyAndTerms.generalInformation",
    membership: "privacyAndTerms.membership",
    personalInfo: "privacyAndTerms.personalInfo",
    email: "privacyAndTerms.email",
    phone: "privacyAndTerms.phone",
    totalDuration: "privacyAndTerms.totalDuration",
    additionalSettings: "privacyAndTerms.additionalSettings",
    agendaList: "privacyAndTerms.agendaList",
    invitedMembers: "privacyAndTerms.invitedMembers",
    allEntitiesAttachedOrRelated:
      "privacyAndTerms.allEntitiesAttachedOrRelated",
    workspace: "privacyAndTerms.workspace",
    userAdded: "privacyAndTerms.userAdded",
    lastUserActivity: "privacyAndTerms.lastUserActivity",
    lastProfileUpdated: "privacyAndTerms.lastProfileUpdated",
    party: "privacyAndTerms.party",
    partyInfoVisibleText: "privacyAndTerms.partyInfoVisibleText",
    infoAboutCommitteeMembership:
      "privacyAndTerms.infoAboutCommitteeMembership",
    relatedItems: "privacyAndTerms.relatedItems",
    lastUpdated: "privacyAndTerms.lastUpdated",
    lastUpdatedBy: "privacyAndTerms.lastUpdatedBy",
    representors: "privacyAndTerms.representors",
    invited: "privacyAndTerms.invited",
    agendaItems: "privacyAndTerms.agendaItems",
    items: "privacyAndTerms.items",
    replies: "privacyAndTerms.replies",
    meetingMembersAndGuestsMessage:
      "privacyAndTerms.meetingMembersAndGuestsMessage",
    userNotRespondedYet: "privacyAndTerms.userNotRespondedYet",
    updatedBy: "privacyAndTerms.updatedBy",
    attachedBy: "privacyAndTerms.attachedBy",
    accountSettings: "privacyAndTerms.accountSettings",
    accountEmail: "privacyAndTerms.accountEmail",
    userAccount: "privacyAndTerms.userAccount",
    loginSettings: "privacyAndTerms.loginSettings",
    loginOptions: "privacyAndTerms.loginOptions",
    password: "privacyAndTerms.password",
    deleteUserAccountButKeepData:
      "privacyAndTerms.deleteUserAccountButKeepData",
    deleteUserAccountAndAllUserInfo:
      "privacyAndTerms.deleteUserAccountAndAllUserInfo",
    learnMoreAboutGlobal: "privacyAndTerms.learnMoreAboutGlobal",
    inviteUserToCommittee: "privacyAndTerms.inviteUserToCommittee",
    addCounterToEventPreview: "privacyAndTerms.addCounterToEventPreview",
    turnOnAutomaticReplacment: "privacyAndTerms.turnOnAutomaticReplacment",
    noSelectedDocuments: "privacyAndTerms.noSelectedDocuments",
    documentsInThisAgendaItem: "privacyAndTerms.documentsInThisAgendaItem",
    sortItems: "privacyAndTerms.sortItems",
    filterItems: "privacyAndTerms.filterItems",
    showFirst: "privacyAndTerms.showFirst",
    newCommittee: "privacyAndTerms.newCommittee",
    selectBlocksForCommitteeStructure:
      "privacyAndTerms.selectBlocksForCommitteeStructure",
    accessAndVisibility: "privacyAndTerms.accessAndVisibility",
    hidenFromEveryone: "privacyAndTerms.hidenFromEveryone",
    newFolderIsHidenForOtherPeople:
      "privacyAndTerms.newFolderIsHidenForOtherPeople",
    committeOverviewPage: "privacyAndTerms.committeOverviewPage",
    membersPage: "privacyAndTerms.membersPage",
    generalDocuments: "privacyAndTerms.generalDocuments",
    doubleClickToSetDuration: "privacyAndTerms.doubleClickToSetDuration",
    draft: "privacyAndTerms.draft",
    committeProtocols: "privacyAndTerms.committeProtocols",
    topics: "privacyAndTerms.topics",
    hiddenFromEveryone: "privacyAndTerms.hiddenFromEveryone",
    privateSpace: "privacyAndTerms.privateSpace",
    allDocuments: "privacyAndTerms.allDocuments",
    allFolders: "privacyAndTerms.allFolders",
    sharedFolders: "privacyAndTerms.sharedFolders",
    everyoneInThisListHasAccess: "privacyAndTerms.everyoneInThisListHasAccess",
    saveMeetingAfterYouCanManage:
      "privacyAndTerms.saveMeetingAfterYouCanManage",
    folders: "privacyAndTerms.folders",
    committeeUpdates: "privacyAndTerms.committeeUpdates",
    recentlyOpened: "privacyAndTerms.recentlyOpened",
    hours: "privacyAndTerms.hours",
    minutes: "privacyAndTerms.minutes",
    createAgendaItem: "privacyAndTerms.createAgendaItem",
    createAgendaSubItem: "privacyAndTerms.createAgendaSubItem",
    listIsEmpty: "privacyAndTerms.listIsEmpty",
    assignRepresentor: "privacyAndTerms.assignRepresentor",
    nameAgendaItem: "privacyAndTerms.nameAgendaItem",
    setDescription: "privacyAndTerms.setDescription",

    published: "privacyAndTerms.published",
    unpublished: "privacyAndTerms.unpublished",
    nestedFolders: "privacyAndTerms.nestedFolders",
    workspaceOverview: "privacyAndTerms.workspaceOverview",
    notifications: "privacyAndTerms.notifications",
    highSecurity: "privacyAndTerms.highSecurity",
    companyInfo: "privacyAndTerms.companyInfo",
    workspaceName: "privacyAndTerms.workspaceName",
    workspaceOwner: "privacyAndTerms.workspaceOwner",
    workspaceOwnerHasFullAccess: "privacyAndTerms.workspaceOwnerHasFullAccess",
    committeeWithMembers: "privacyAndTerms.committeeWithMembers",
    parties: "privacyAndTerms.parties",
    timeZone: "privacyAndTerms.timeZone",
    timeZoneText: "privacyAndTerms.timeZoneText",
    workspaceDefaultLanguage: "privacyAndTerms.workspaceDefaultLanguage",
    workspaceDefaultLanguageText:
      "privacyAndTerms.workspaceDefaultLanguageText",
    deleteWorkspace: "privacyAndTerms.deleteWorkspace",
    deleteWorkspaceText: "privacyAndTerms.deleteWorkspaceText",
    preventAnyMember: "privacyAndTerms.preventAnyMember",
    disableAdmins: "privacyAndTerms.disableAdmins",
    disableAllDocuments: "privacyAndTerms.disableAllDocuments",
    disableAllHighSecurityDocuments:
      "privacyAndTerms.disableAllHighSecurityDocuments",
    requestPasswordForDocuments: "privacyAndTerms.requestPasswordForDocuments",
    disableExport: "privacyAndTerms.disableExport",
    adminNotifications: "privacyAndTerms.adminNotifications",
    emailMe: "privacyAndTerms.emailMe",
    sendMeDigest: "privacyAndTerms.sendMeDigest",
    sendMeReport: "privacyAndTerms.sendMeReport",
    notifyMeByEmailAboutAllMembersActivities:
      "privacyAndTerms.notifyMeByEmailAboutAllMembersActivities",
    notifyOnlyOnCertain: "privacyAndTerms.notifyOnlyOnCertain",
    newItemSubmittedOnReview: "privacyAndTerms.newItemSubmittedOnReview",
    someoneWantsToReplyOnItemDuringLiveMeeting:
      "privacyAndTerms.someoneWantsToReplyOnItemDuringLiveMeeting",
    someoneLeftCommentsOnItems: "privacyAndTerms.someoneLeftCommentsOnItems",
    someoneMentionedMeInComments:
      "privacyAndTerms.someoneMentionedMeInComments",
    anotherAdminInvitedNewMembers:
      "privacyAndTerms.anotherAdminInvitedNewMembers",
    deputyMemberIsAcceptedReplacementForAMeeting:
      "privacyAndTerms.deputyMemberIsAcceptedReplacementForAMeeting",
    meetingProtocolIsGenerated: "privacyAndTerms.meetingProtocolIsGenerated",
    highSecurityDocumentHasBeenViewed:
      "privacyAndTerms.highSecurityDocumentHasBeenViewed",
    personAcceptedInvitationToTheWorkspace:
      "privacyAndTerms.personAcceptedInvitationToTheWorkspace",
    dailySummaryOfSelectedActivities:
      "privacyAndTerms.dailySummaryOfSelectedActivities",
    weeklySummaryOfSelectedActivities:
      "privacyAndTerms.weeklySummaryOfSelectedActivities",
    summaryAndResultsWhenPollIsCompleted:
      "privacyAndTerms.summaryAndResultsWhenPollIsCompleted",
    attendanceReportWhenMeetingIsEnded:
      "privacyAndTerms.attendanceReportWhenMeetingIsEnded",
    monthlyReportOnEachMember: "privacyAndTerms.monthlyReportOnEachMember",
    committeeLaguage: "privacyAndTerms.committeeLaguage",
    deleteCommittee: "privacyAndTerms.deleteCommittee",
    pleaseType: "privacyAndTerms.pleaseType",
    delete: "privacyAndTerms.delete",
    toConfirm: "privacyAndTerms.toConfirm",
    settings: "privacyAndTerms.settings",
    deleteConfirmQuestion: "privacyAndTerms.deleteConfirmQuestion",
    generalInfo: "privacyAndTerms.generalInfo",
    committeeLeaders: "privacyAndTerms.committeeLeaders",
    committeesAdmins: "privacyAndTerms.committeesAdmins",

    autoInvitation: "privacyAndTerms.autoInvitation",
    userLists: "privacyAndTerms.userLists",
    userListRuleText: "privacyAndTerms.userListRuleText",
    user: "privacyAndTerms.user",
    membersReplace: "privacyAndTerms.membersReplace",
    committeeMemberEmptyMessage: "privacyAndTerms.committeeMemberEmptyMessage",
    created: "privacyAndTerms.created",
    areYouGoing: "privacyAndTerms.areYouGoing",
    uploadContent: "privacyAndTerms.uploadContent",
    uploadFromDevice: "privacyAndTerms.uploadFromDevice",
    fileSelect: "privacyAndTerms.fileSelect",
    fileSelectSubTitle: "privacyAndTerms.fileSelectSubTitle",
    uploadMore: "privacyAndTerms.uploadMore",
    committeeSettings: "privacyAndTerms.committeeSettings",
    meetingRelatedTo: "privacyAndTerms.meetingRelatedTo",
    agenda: "privacyAndTerms.agenda",
    upload: "privacyAndTerms.upload",
    noDocumentUploaded: "privacyAndTerms.noDocumentUploaded",
    noCalendarEventsYet: "privacyAndTerms.noCalendarEventsYet",
    createMeeting: "privacyAndTerms.createMeeting",
    noCommitteesAndSharedDirectoriesYet:
      "privacyAndTerms.noCommitteesAndSharedDirectoriesYet",
    createDirectory: "privacyAndTerms.createDirectory",
    noMembersCommitteeYet: "privacyAndTerms.noMembersCommitteeYet",
    addMembers: "privacyAndTerms.addMembers",
    confirm: "privacyAndTerms.confirm",
    confirmMessage: "privacyAndTerms.confirmMessage",
    uploadSettings: "privacyAndTerms.uploadSettings",
    membersInCollaboration: "privacyAndTerms.membersInCollaboration",
    noRelation: "privacyAndTerms.noRelation",
    noLabels: "privacyAndTerms.noLabels",
    confirmRouting: "privacyAndTerms.confirmRouting",
    rolesPermissions: "privacyAndTerms.rolesPermissions",
    creator: "privacyAndTerms.creator",
    admin: "privacyAndTerms.admin",
    committeeMember: "privacyAndTerms.committeeMember",
    deputy: "privacyAndTerms.deputy",
    guest: "privacyAndTerms.guest",
    viewAll: "privacyAndTerms.viewAll",
    updateAll: "privacyAndTerms.updateAll",
    updateOwn: "privacyAndTerms.updateOwn",
    accessSettingsForAll: "privacyAndTerms.accessSettingsForAll",
    accessSettingsForOwn: "privacyAndTerms.accessSettingsForOwn",
    generalSettingsForAll: "privacyAndTerms.generalSettingsForAll",
    all: "privacyAndTerms.all",
    generalSettingsForOwn: "privacyAndTerms.generalSettingsForOwn",
    createNew: "privacyAndTerms.createNew",
    deleteAll: "privacyAndTerms.deleteAll",
    deleteOwn: "privacyAndTerms.deleteOwn",
    deleteMembers: "privacyAndTerms.deleteMembers",
    addDocument: "privacyAndTerms.addDocument",
    deleteOwnDocument: "privacyAndTerms.deleteOwnDocument",
    deleteAnyDocument: "privacyAndTerms.deleteAnyDocument",
    viewWorkspaceInfo: "privacyAndTerms.viewWorkspaceInfo",
    updateGeneralSettings: "privacyAndTerms.updateGeneralSettings",
    updateAccessSettings: "privacyAndTerms.updateAccessSettings",
    viewAllMembers: "privacyAndTerms.viewAllMembers",
    addNewMembers: "privacyAndTerms.addNewMembers",
    viewAgenda: "privacyAndTerms.viewAgenda",
    viewAttachedDocument: "privacyAndTerms.viewAttachedDocument",
    updateAgenda: "privacyAndTerms.updateAgenda",
    submitToAgenda: "privacyAndTerms.submitToAgenda",
    viewAttendies: "privacyAndTerms.viewAttendies",
    replayToMatter: "privacyAndTerms.replayToMatter",
    manageInvitations: "privacyAndTerms.manageInvitations",
    viewMembersProfileInfo: "privacyAndTerms.viewMembersProfileInfo",
    userProfile: "privacyAndTerms.userProfile",
    showLess: "privacyAndTerms.showLess",
    confirmText: "privacyAndTerms.confirmText",
    confirmTitle: "privacyAndTerms.confirmTitle",
    workspaceDashboard: "privacyAndTerms.workspaceDashboard",
    Hello: "privacyAndTerms.Hello",
    lastMonths: "privacyAndTerms.lastMonths",
    events: "privacyAndTerms.events",
    pleaseRespondInTheNext: "privacyAndTerms.pleaseRespondInTheNext",
    IamGoing: "privacyAndTerms.IamGoing",
    yourAttendance: "privacyAndTerms.yourAttendance",
    forLastDays: "privacyAndTerms.forLastDays",
    bookmarks: "privacyAndTerms.bookmarks",
    directories: "privacyAndTerms.directories",
    docs: "privacyAndTerms.docs",
    newShared: "privacyAndTerms.newShared",
    InCollaboration: "privacyAndTerms.InCollaboration",
    privacyPolicy: "privacyAndTerms.privacyPolicy",
    privacyPolicyText: "privacyAndTerms.privacyPolicyText",
    InformationWeCollect: "privacyAndTerms.InformationWeCollect",
    LogData: "privacyAndTerms.LogData",
    privacyPolicyTextSecond: "privacyAndTerms.privacyPolicyTextSecond",
    DeviceData: "privacyAndTerms.DeviceData",
    privacyPolicyTextDeviceData: "privacyAndTerms.privacyPolicyTextDeviceData",
    PersonalInformation: "privacyAndTerms.PersonalInformation",
    WeMayAsk: "privacyAndTerms.WeMayAsk",
    Name: "privacyAndTerms.Name",
    SocialMediaProfiles: "privacyAndTerms.SocialMediaProfiles",
    DateOfBirth: "privacyAndTerms.DateOfBirth",
    PhoneMobileNumber: "privacyAndTerms.PhoneMobileNumber",
    HomeMailingAddress: "privacyAndTerms.HomeMailingAddress",
    WorkAddress: "privacyAndTerms.WorkAddress",
    WebsiteAddress: "privacyAndTerms.WebsiteAddress",
    PaymentInformation: "privacyAndTerms.PaymentInformation",
    DriversLicenceDetails: "privacyAndTerms.DriversLicenceDetails",
    PassportNumber: "privacyAndTerms.PassportNumber",
    TaxSSNMedicareEtcNumber: "privacyAndTerms.TaxSSNMedicareEtcNumber",
    BusinessData: "privacyAndTerms.BusinessData",
    BusinessText: "privacyAndTerms.BusinessText",
    LegalInfo: "privacyAndTerms.LegalInfo",
    LegalText: "privacyAndTerms.LegalText",
    LegalTextSub: "privacyAndTerms.LegalTextSub",
    Text1: "privacyAndTerms.Text1",
    Text2: "privacyAndTerms.Text2",
    Text3: "privacyAndTerms.Text3",
    Text4: "privacyAndTerms.Text4",
    Text5: "privacyAndTerms.Text5",
    Text6: "privacyAndTerms.Text6",
    CollectionAndUseOfInformation:
      "privacyAndTerms.CollectionAndUseOfInformation",
    CollectionAndUseOfInformationText:
      "privacyAndTerms.CollectionAndUseOfInformationText",
    CollectionAndUseOfInformationText1:
      "privacyAndTerms.CollectionAndUseOfInformationText1",
    CollectionAndUseOfInformationText2:
      "privacyAndTerms.CollectionAndUseOfInformationText2",
    CollectionAndUseOfInformationText3:
      "privacyAndTerms.CollectionAndUseOfInformationText3",
    CollectionAndUseOfInformationText4:
      "privacyAndTerms.CollectionAndUseOfInformationText4",
    CollectionAndUseOfInformationText5:
      "privacyAndTerms.CollectionAndUseOfInformationText5",
    CollectionAndUseOfInformationText6:
      "privacyAndTerms.CollectionAndUseOfInformationText6",
    CollectionAndUseOfInformationText7:
      "privacyAndTerms.CollectionAndUseOfInformationText7",
    CollectionAndUseOfInformationText8:
      "privacyAndTerms.CollectionAndUseOfInformationText8",
    CollectionAndUseOfInformationText9:
      "privacyAndTerms.CollectionAndUseOfInformationText9",
    CollectionAndUseOfInformationText10:
      "privacyAndTerms.CollectionAndUseOfInformationText10",
    DisclosureOfPersonal: "privacyAndTerms.DisclosureOfPersonal",
    discloseText: "privacyAndTerms.discloseText",
    DisclosureOfPersonalText1: "privacyAndTerms.DisclosureOfPersonalText1",
    DisclosureOfPersonalText2: "privacyAndTerms.DisclosureOfPersonalText2",
    DisclosureOfPersonalText3: "privacyAndTerms.DisclosureOfPersonalText3",
    DisclosureOfPersonalText4: "privacyAndTerms.DisclosureOfPersonalText4",
    DisclosureOfPersonalText5: "privacyAndTerms.DisclosureOfPersonalText5",
    DisclosureOfPersonalText6: "privacyAndTerms.DisclosureOfPersonalText6",
    DisclosureOfPersonalText7: "privacyAndTerms.DisclosureOfPersonalText7",
    InternationalTransfers: "privacyAndTerms.InternationalTransfers",
    InternationalTransfersText1: "privacyAndTerms.InternationalTransfersText1",
    InternationalTransfersText2: "privacyAndTerms.InternationalTransfersText2",
    InternationalTransfersText3: "privacyAndTerms.InternationalTransfersText3",
    controllingInformation: "privacyAndTerms.controllingInformation",
    ChoiceAndConsent: "privacyAndTerms.ChoiceAndConsent",
    controllingYourPersonal: "privacyAndTerms.controllingYourPersonal",
    InformationFromThird: "privacyAndTerms.InformationFromThird",
    InformationFromThirdText: "privacyAndTerms.InformationFromThirdText",
    Restrict: "privacyAndTerms.Restrict",
    RestrictText: "privacyAndTerms.RestrictText",
    AccessAndData: "privacyAndTerms.AccessAndData",
    Correction: "privacyAndTerms.Correction",
    CorrectionText: "privacyAndTerms.CorrectionText",
    Notification: "privacyAndTerms.Notification",
    NotificationText: "privacyAndTerms.NotificationText",
    Complaints: "privacyAndTerms.Complaints",
    ComplaintsText: "privacyAndTerms.ComplaintsText",
    Unsubscribe: "privacyAndTerms.Unsubscribe",
    UnsubscribeText: "privacyAndTerms.UnsubscribeText",
    Cookies: "privacyAndTerms.Cookies",
    CookiesText: "privacyAndTerms.CookiesText",
    BusinessTransfers: "privacyAndTerms.BusinessTransfers",
    BusinessTransfersText: "privacyAndTerms.BusinessTransfersText",
    LimitsOfOurPolicy: "privacyAndTerms.LimitsOfOurPolicy",
    LimitsOfOurPolicyText: "privacyAndTerms.LimitsOfOurPolicyText",
    ChangesToThisPolicy: "privacyAndTerms.ChangesToThisPolicy",
    ChangesToThisPolicyText: "privacyAndTerms.ChangesToThisPolicyText",
    ChangesToThisPolicyText2: "privacyAndTerms.ChangesToThisPolicyText2",
    ICMASTermsOfService: "privacyAndTerms.ICMASTermsOfService",
    Terms: "privacyAndTerms.Terms",
    TermsText: "privacyAndTerms.TermsText",
    UseLicence: "privacyAndTerms.UseLicence",
    UseLicenceText: "privacyAndTerms.UseLicenceText",
    UseLicenceText2: "privacyAndTerms.UseLicenceText2",
    UseLicenceText3: "privacyAndTerms.UseLicenceText3",
    UseLicenceText4: "privacyAndTerms.UseLicenceText4",
    UseLicenceText5: "privacyAndTerms.UseLicenceText5",
    UseLicenceText6: "privacyAndTerms.UseLicenceText6",
    UseLicenceTextSub: "privacyAndTerms.UseLicenceTextSub",
    Disclaimer: "privacyAndTerms.Disclaimer",
    DisclaimerText: "privacyAndTerms.DisclaimerText",
    DisclaimerText2: "privacyAndTerms.DisclaimerText2",
    Limitations: "privacyAndTerms.Limitations",
    LimitationsText: "privacyAndTerms.LimitationsText",
    AccuracyOfMaterials: "privacyAndTerms.AccuracyOfMaterials",
    AccuracyOfMaterialsText: "privacyAndTerms.AccuracyOfMaterialsText",
    Links: "privacyAndTerms.Links",
    LinksText: "privacyAndTerms.LinksText",
    Modifications: "privacyAndTerms.Modifications",
    ModificationsText: "privacyAndTerms.ModificationsText",
    GoverningLaw: "privacyAndTerms.GoverningLaw",
    GoverningLawText: "privacyAndTerms.GoverningLawText",
    CookiePolice: "privacyAndTerms.CookiePolice",
    CookiePoliceText: "privacyAndTerms.CookiePoliceText",
    CookiePoliceText2: "privacyAndTerms.CookiePoliceText2",
    WhatIsCookie: "privacyAndTerms.WhatIsCookie",
    WhatIsCookieText: "privacyAndTerms.WhatIsCookieText",
    WhatIsCookieText2: "privacyAndTerms.WhatIsCookieText2",
    WhatIsCookieText3: "privacyAndTerms.WhatIsCookieText3",
    TypeOfCookies: "privacyAndTerms.TypeOfCookies",
    TypeOfCookiesSubTitle: "privacyAndTerms.TypeOfCookiesSubTitle",
    TypeOfCookiesText: "privacyAndTerms.TypeOfCookiesText",
    TypeOfCookiesSubTitle2: "privacyAndTerms.TypeOfCookiesSubTitle2",
    TypeOfCookiesTextPerformance:
      "privacyAndTerms.TypeOfCookiesTextPerformance",
    FunctionalityCookies: "privacyAndTerms.FunctionalityCookies",
    FunctionalityCookiesText: "privacyAndTerms.FunctionalityCookiesText",
    Targeting: "privacyAndTerms.Targeting",
    TargetingText: "privacyAndTerms.TargetingText",
    ThirdPartyCookies: "privacyAndTerms.ThirdPartyCookies",
    ThirdPartyCookiesText: "privacyAndTerms.ThirdPartyCookiesText",
    thirdPartyPrivacy: "privacyAndTerms.thirdPartyPrivacy",
    thirdPartyPrivacyText: "privacyAndTerms.thirdPartyPrivacyText",
    controlCookies: "privacyAndTerms.controlCookies",
    controlCookiesText: "privacyAndTerms.controlCookiesText",
    controlCookiesText2: "privacyAndTerms.controlCookiesText2",
    controlCookiesText3: "privacyAndTerms.controlCookiesText3"
  }
};

// "privacyPolicy": {
// 	"privacyPolicy": "Privacy policy",
// 	"subtitle": "Your privacy is important to us. It is Förbundet Vi Ungas policy to respect your privacy regarding any information we may collect from you across our website, https://spridakunskap.se, and other sites we own and operate.",
// 	"informationWeCollect": "Information we collect",
// 	"logData": "Log data",
// 	"logDataText": "When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your computer’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details.",
// 	"deviceData": "Device data",
// 	"deviceDataText": "We may also collect data about the device you’re using to access our website. This data may include the device type, operating system, unique device identifiers, device settings, and geo-location data. What we collect can depend on the individual settings of your device and software. We recommend checking the policies of your device manufacturer or software provider to learn what information they make available to us.",
// 	"personalInformation": "Personal information",
// 	"": "",
// 	"": "",
// 	"": "",
// 	"": "",
// 	"": "",
// 	"": "",
// 	"": "",
// }
