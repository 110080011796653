import React from "react";
import { Images } from "../../../environment";
import {
  ContentBlock,
  LeftBlockContent,
  TextTitleWrapper,
  TextTitle,
  SecondText,
  TextWrapper,
  RightBlockContent,
  ImageModalRightBlock,
  ImageTextWrapper,
  FooterModal,
  ContentBlockWrapper
} from "./Modals.style";
import { ButtonPDF } from "./BaseButtonModal";

export const ModalContent1 = () => {
  return (
    <>
      <ContentBlockWrapper>
        <ContentBlock>
          <LeftBlockContent>
            <TextTitleWrapper>
              <TextTitle>För vem:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>Processledare</SecondText>
            </TextWrapper>
            <TextTitleWrapper>
              <TextTitle>Hur dokumentet kan användas:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Kan användas som en gruppövning. Låt de som varit
                förtroendevalda diskutera och komma fram till hur en muntlig
                överlämningsrutin ska se ut. Inför sedan rutinen i
                organisationen.
              </SecondText>
            </TextWrapper>
            <TextTitleWrapper>
              <TextTitle>Beskrivning:</TextTitle>
            </TextTitleWrapper>
            <TextWrapper>
              <SecondText>
                Följ dokumentet för att ta fram en rutin över hur en muntlig
                överlämning i er organisation ska se ut.
              </SecondText>
            </TextWrapper>
          </LeftBlockContent>
          <RightBlockContent>
            <TextTitleWrapper>
              <TextTitle>Bilagor att använda ihop med dokumentet:</TextTitle>
            </TextTitleWrapper>
            <ImageTextWrapper>
              <ImageModalRightBlock
                src={Images.landingModalContentCheckMark}
                alt=""
              />
              <TextWrapper>
                <SecondText>Tom mall att använda som checklista.</SecondText>
              </TextWrapper>
            </ImageTextWrapper>
            <ImageTextWrapper>
              <ImageModalRightBlock
                src={Images.landingModalContentCheckMark}
                alt=""
              />
              <TextWrapper>
                <SecondText>
                  Redigerbar mall att använda som checklista.
                </SecondText>
              </TextWrapper>
            </ImageTextWrapper>
            <ImageTextWrapper>
              <ImageModalRightBlock
                src={Images.landingModalContentCheckMark}
                alt=""
              />
              <TextWrapper>
                <SecondText>
                  Lista över dokument som är nyttiga att se på för den som är ny
                  i organisationen.
                </SecondText>
              </TextWrapper>
            </ImageTextWrapper>
            <ImageTextWrapper>
              <ImageModalRightBlock
                src={Images.landingModalContentCheckMark}
                alt=""
              />
              <TextWrapper>
                <SecondText>Utvärdering av överlämningsrutin.</SecondText>
              </TextWrapper>
            </ImageTextWrapper>
          </RightBlockContent>
        </ContentBlock>
      </ContentBlockWrapper>
      <FooterModal>
        <ButtonPDF
          downLink={
            "https://spridakunskapdev.blob.core.windows.net/spridadropbox/En steg för steg metod på hur en muntlig överlämningsrutin kan tas fram i din organisation.pdf"
          }
        />
      </FooterModal>
    </>
  );
};
