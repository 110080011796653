import styled from "styled-components";
import { deviceRange } from "../../../consts";
import { Fonts } from "../../../environment/theme/Fonts";

export const ContentBlock = styled.div`
  display: flex;
  flex-direction: row;

  @media ${deviceRange.mobile} {
    width: 100%;
    display: flex;
    height: 100%;
    overflow-y: scroll;
    flex-direction: column;
    ::-webkit-scrollbar {
      width: 0.4rem;
      background-color: #6bc9db;
    }
    ::-webkit-scrollbar-thumb {
      opacity: 0.2;
      border-radius: 1rem;
    }
  }
`;

export const IntroVideo = styled.video`
  width: 46vw;
  height: fit-content;

  @media ${deviceRange.tablet} {
    width: 600px;
    height: 40vh;
  }

  @media ${deviceRange.mobile} {
    width: 98%;
    height: 28vh;
  }
`;

export const VideoPopupContent = styled.div`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #6bc9db;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 40px 0px;
`;

export const VideoPopUpWrapp = styled.div`
  display: flex;
  position: relative;
`;
export const ContentBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media ${deviceRange.mobile} {
    width: 100%;
    padding-right: 10px;
    display: flex;
    height: 100%;
    overflow-y: scroll;
    flex-direction: column;
  }
`;

export const LeftBlockContent = styled.div`
  width: 38%;
  margin-left: 90px;

  @media ${deviceRange.laptop} {
    margin-left: 70px;
  }

  @media ${deviceRange.tablet} {
    margin-left: 60px;
  }

  @media ${deviceRange.mobile} {
    width: 100%;
    margin-left: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const TextTitleWrapper = styled.div`
  margin-top: 35px;

  @media ${deviceRange.laptop} {
    margin-top: 15px;
  }
`;

export const TextTitle = styled.h4`
  font-family: ${Fonts.landingConcertOneRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color: #425262;
`;

export const TextWrapper = styled.div`
  margin-top: 15px;

  @media ${deviceRange.laptop} {
    margin-top: 5px;
  }
`;

export const SecondText = styled.p`
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #425262;
`;

export const RightBlockContent = styled.div`
  width: 30%;
  margin-left: 132px;

  @media ${deviceRange.laptop} {
    margin-left: 70px;
    width: 32%;
  }

  @media ${deviceRange.tablet} {
    margin-left: 70px;
    width: 32%;
  }

  @media ${deviceRange.mobile} {
    margin-left: 0px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const ImageModalRightBlock = styled.img`
  width: 25px;
  height: 25px;
  align-self: center;
  margin-top: 10px;
`;

export const ImageTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

interface FooterModalProps {
  notAliginSelf?: number;
}

export const FooterModal = styled.div<FooterModalProps>`
  display: flex;
  align-items: center;
  ${props => (props.notAliginSelf ? null : "align-self: center;")}
  margin-top: 35px;
  margin-bottom: 50px;

  @media ${deviceRange.laptop} {
    margin-top: 20px;
    margin-bottom: 25px;
  }

  @media ${deviceRange.mobile} {
    flex-direction: column-reverse;
  }
`;

export const Highlighter = styled.span`
  color: #cc00a0;
`;

export const CenterBlockContent = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
`;

export const ImageInstruction = styled.img`
  width: 180px;
  height: 180px;
  display: flex;
  margin: auto;
  margin-top: 10px;
`;
