import styled from "styled-components";
import { deviceRange } from "../../../../consts";
import { Fonts } from "../../../../environment";

export const ButtonLagg = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: fit-content;
  padding: 10px 30px;
  background: #ffffff;
  border: 2px solid #6bc9db;
  border-radius: 8px;
  outline: 0;
  cursor: pointer;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #425262;

  @media ${deviceRange.mobile} {
  }
`;
export const ButtonRemove = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: fit-content;
  padding: 10px 30px;
  background: #ffffff;
  border: 2px solid #6bc9db;
  border-radius: 8px;
  outline: 0;
  cursor: pointer;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #425262;
  margin-left: 20px;

  @media (max-width: 1100px) {
    margin-left: 0px;
  }

  @media ${deviceRange.mobile} {
    margin-top: 5px;
  }
`;
