import { useQuery } from "@apollo/react-hooks";
import {
  CurrentWorkspaceData,
  UserAccountWhereInput,
  UserAccountOrderByInput
} from "../../../../../api";
import { UserAccountFragment } from "./../fragments";
import gql from "graphql-tag";

const WORKSPACE_MEMBERS = gql`
  query CurrentWorkspaceQuery(
    $where: UserAccountWhereInput
    $orderBy: UserAccountOrderByInput
  ) {
    currentWorkspace {
      members(where: $where, orderBy: $orderBy) {
        ...UserAccountFragment
        avatar {
          id
          name
          url
        }
      }
    }
  }
  ${UserAccountFragment}
`;

export function useWorkspaceMembersQuery(
  where?: UserAccountWhereInput,
  orderBy?: UserAccountOrderByInput
) {
  return useQuery<CurrentWorkspaceData>(WORKSPACE_MEMBERS, {
    variables: {
      where,
      orderBy
    },
    fetchPolicy: "no-cache"
  });
}
