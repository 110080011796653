import styled from "styled-components";
import { Colors, Fonts } from "../../../../environment";
import { deviceRange } from "../../../../consts";

export const CropModalContentWrapper = styled.div`
  width: 90%;
`;

export const FileInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FileInput = styled.input`
  display: none;
`;

export const FileInputLabel = styled.label`
  cursor: pointer;
  display: flex;
  width: 11rem;
  height: 5rem;
  border: 2px solid #6bc9db;
  padding: 2rem;
  border-radius: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  font-family: ${Fonts.landingOpenSansRegular};
  color: #425262;
  background-color: ${Colors.transparent};
  transition: 0.3s;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & :hover {
    box-shadow: 4px 4px 25px rgb(107 201 219 / 25%);
  }
`;

export const CropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40vh;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImagePreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 20rem;
  padding: 2rem;
`;

export const ImagePreviewBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  border: 2px solid #6bc9db;
  padding: 10px;
`;

export const ImagePreview = styled.img`
  display: block;
  width: 100%;
  max-width: 400px;
  @media ${deviceRange.mobile} {
    max-width: 300px;
  }
`;
