import styled from "styled-components";
import { deviceRange } from "../../consts";
import { Colors } from "../../environment";

export const PageWrapper = styled.div`
  width: 103%;
  margin-left: -30px;
  position: relative;
  background: ${Colors.white};
  padding-left: 100px;

  @media ${deviceRange.laptop} {
    padding-left: 30px;
  }
  @media ${deviceRange.mobile} {
    padding-left: 20px;
    margin-left: 0px;
  }
`;

export const PageTopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 2.4rem;
`;

export const PageTopBarItem = styled.div`
  display: flex;
  align-items: center;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0.2rem;
`;

export const FormContainer = styled.div`
  margin-top: 7rem;

  @media ${deviceRange.mobile} {
    margin-top: 30vh;
    display: flex;
    align-items: center;
  }
`;
