import React, { useEffect, useState, useContext } from "react";

import { Modal } from "../../../ui";
import { useAddOrRemoveOrgainsotionLogo } from "../../../../hooks";
import { UserContext } from "../../UserPofile";
import { ModalSizes } from "../../../ui/Modal/Modal";
import { CropModalContent } from "../CropModalContent";
import { FullImage as getFullImg } from "../../../../helpers";
import { useAlerts, useTranslation } from "../../../../hooks";
import { PromoButtonStart } from "../../../../pages/Landing/Landing.style";

export interface UserAccountFormValues {
  firstName: string;
  lastName: string;
  email: string;
}

interface ImageOptions {
  path: string;
  name: string;
  type: string;
}

interface UserGeneralInfoProps {
  showLogoCropModal: boolean;
  handleCloseModal: () => void;
}

export function CropModalOrganisation({
  showLogoCropModal,
  handleCloseModal
}: UserGeneralInfoProps) {
  const translate = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const { setError } = useAlerts();
  const [imageOptions, setImageOptions] = useState<ImageOptions | null>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [organisationLogoFile, setOrganisationLogoFile] = useState<File | null>(
    null
  );
  const [
    addOrganisationLogo,
    { data: addOrganisationLogoData, loading: addOrganisationLogoLoading }
  ] = useAddOrRemoveOrgainsotionLogo();

  useEffect(() => {
    if (addOrganisationLogoData) {
      setUser(addOrganisationLogoData.addOrRemoveOrganisationLogo);
      handleCloseModal();
      setImageOptions(null);
    }

    console.log("addOrganisationLogoData ...", addOrganisationLogoData);
  }, [addOrganisationLogoData]);

  function handleGetLogoFile(file: File) {
    setOrganisationLogoFile(file);
  }

  function handleAddUserLogo() {
    if (organisationLogoFile && user) {
      console.log(
        "handleAddUserLogo / organisationLogoFile ...",
        organisationLogoFile
      );
      console.log("handleAddUserLogo / user ...", user);

      addOrganisationLogo({
        variables: {
          data: {
            userAccountWhereUniqueInput: { id: user.id },
            organisationLogo: organisationLogoFile,
            isRemove: false
          }
        }
      });
    }
  }

  const showCroppedImage = async (imageOptions: any) => {
    try {
      if (!imageOptions) throw new Error();

      const {
        imgFile: blobFile,
        imgPath: croppedImage
      }: any = await getFullImg(imageOptions.path, {
        height: 0,
        width: 0,
        x: 0,
        y: 0
      });

      setCroppedImage(croppedImage);

      const croppedImgFile = new File([blobFile], imageOptions.name, {
        lastModified: Date.now(),
        type: imageOptions.type
      });

      handleGetLogoFile(croppedImgFile);
    } catch (e) {
      setError({ message: translate(({ errors }) => errors.tryAgain) });
    }
  };

  return (
    <Modal
      title={translate(({ buttons }) => buttons.uploadModalHeader)}
      open={showLogoCropModal}
      onClose={handleCloseModal}
      size={ModalSizes.XS}
    >
      <Modal.Body>
        <CropModalContent
          imageOptions={imageOptions}
          setImageOptions={setImageOptions}
          setCroppedImage={setCroppedImage}
          showCroppedImage={showCroppedImage}
        />
      </Modal.Body>
      <Modal.Footer>
        {/* Add Avatar */}
        <PromoButtonStart
          disabled={!croppedImage || addOrganisationLogoLoading}
          onClick={handleAddUserLogo}
          marginTop={1}
          width={150}
          paddingLeft={20}
          paddingRight={20}
          paddingTop={15}
          paddingBottom={15}
          shadowLeft={2}
          shadowTop={4}
        >
          {translate(({ buttons }) => buttons.uploadConfirmButton)}
        </PromoButtonStart>
      </Modal.Footer>
    </Modal>
  );
}
