import styled from "styled-components";
import { deviceRange } from "../../../consts";

export const Form = styled.form`
  margin: 0 0.2rem;
  width: 100%;
  max-width: 650px;
  margin: auto;

  @media ${deviceRange.mobile} {
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
export const InputLabel = styled.p`
  margin-bottom: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #425262;
`;
