import styled from "styled-components";
import { deviceRange } from "../../consts";
import { Fonts } from "../../environment/theme/Fonts";

export const BlogContainer = styled.div`
  max-width: 1400px;
  min-height: 60vh;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  padding-top: 150px;
  margin: auto;
  @media ${deviceRange.laptopM} {
  }

  @media ${deviceRange.laptopL} {
  }

  @media ${deviceRange.mobile} {
  }
  @media ${deviceRange.desktop} {
  }
  @media ${deviceRange.fourK} {
  }
`;

export const BlogWrapper = styled.div`
  width: 86%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media ${deviceRange.tablet} {
    width: 90%;
  }

  @media ${deviceRange.mobile} {
    width: 100%;
  }
`;

export const MainTitle = styled.h1`
  width: 100%;
  font-family: ${Fonts.landingOpenSansRegular};
  font-family: "Concert One";
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  line-height: 60px;
  text-align: left;
  color: #6bc9db;
  margin-bottom: 100px;

  @media ${deviceRange.laptop} {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${deviceRange.laptop} {
    margin-bottom: 70px;
  }

  @media ${deviceRange.tablet} {
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 45px;
  }

  @media ${deviceRange.mobile} {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 35px;
  }
`;

export const Article = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  @media ${deviceRange.mobile} {
    flex-direction: column;
  }
`;

export const ArticleLeft = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  @media ${deviceRange.mobile} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const ImageWrapper = styled.div`
  width: 40%;
  @media ${deviceRange.mobile} {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const ArticleImage = styled.img`
  display: block;
  max-width: 100%;
`;

export const ArticleTitle = styled.div`
  width: 100%;
  font-family: ${Fonts.landingOpenSansRegular};
  font-family: "Concert One";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 55px;
  color: #008c82;
  text-decoration: none;
  text-decoration-thickness: 1px;
  margin-bottom: 20px;
  &:hover {
    color: #cc00a0;
  }

  @media ${deviceRange.laptopM} {
    font-size: 36px;
    line-height: 45px;
  }
  @media ${deviceRange.laptop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${deviceRange.tablet} {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  @media ${deviceRange.mobile} {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }
`;

export const ArticleDate = styled.div`
  width: 100%;
  font-family: ${Fonts.landingOpenSansRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;

  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
  }
  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 15px;
  }
`;

export const ArticleTeaser = styled.div`
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #425262;
  @media ${deviceRange.tablet} {
    font-size: 16px;
    line-height: 22px;
  }
  @media ${deviceRange.mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;
