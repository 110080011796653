import { useHistory } from "react-router-dom";

const routes = {
  root: "/",
  story: "/skickade",
  logfaq: "/loggedFAQ",
  unlogfaq: "/FAQ",
  login: "/logga-in",
  dictionary: "/ordbok",
  signup: "/skapa-konto",
  handovers: "/overlamning",
  handover: "/overlamning/overlamning",
  recommendTool: "/overlamning/rekommendera-detta-verktyg",
  profile: "/profil",
  members: "/användare",
  editor: "/redaktör",
  thankYou: "/tack",
  privacyPolicy: "/privat-policy",
  forgotPassword: "/glömt-lösenord",
  termsAndConditions: "/villkor",
  usefuldocuments: "/anvandbara-dokument",
  blog: "/blogg",
  RestorePassword: (user: string) => `/restore-password/${user}`,
  memberProfile: (id: string) => `/members/${id}`
};

export function useNavigation() {
  const history = useHistory();

  function navigate(route: string) {
    history.push(route);
  }

  function replace(route: string) {
    history.replace(route);
  }

  function back() {
    history.goBack();
  }

  return {
    routes,
    history,
    navigate,
    replace,
    back
  };
}
